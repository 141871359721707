import {
    SET_CONVERSATIONS,
    LOADING_CONVERSATION,
    SET_LOADING_ERRORS_CONVERSATION,
    CLEAR_LOADING_ERRORS_CONVERSATION,
    SET_CONVERSATION_BY_ID
  } from '../constants/conversations.constants';
  
  import IConversationModel from '../models/IConversation.model';
  
  const initialState = {
    loading: false,
    loadingUpdate: false,
    conversations: [] as ReadonlyArray<IConversationModel>,
    conversation: null as unknown as Readonly<IConversationModel>,
    errors: ""
  }
  
  export type UserState = Readonly<typeof initialState>;
  
  const conversationsReducer = (state: UserState = initialState, action: any): UserState  => {
    switch (action.type) {
      case SET_CONVERSATIONS:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          conversations: action.payload
        }
      case SET_CONVERSATION_BY_ID:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          conversation: action.payload,
        };
      case LOADING_CONVERSATION:
        return {
          ...state,
          loading: true
        };
      case SET_LOADING_ERRORS_CONVERSATION:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          errors: action.payload
        };
      case CLEAR_LOADING_ERRORS_CONVERSATION:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          errors: ""
        };
      default:
        return state;
    }
  }
  
  export default conversationsReducer;