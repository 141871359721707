import * as React from "react";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import { IAgentModel } from "../../../../models/Agent.model";
import { useNavigate } from "react-router-dom";
import { setAgentData } from "../../../../actions/agent-admin.actions";
import { useDispatch } from "react-redux";
import DashboardModal from "./dashboard.modal";
import { namespace_1 } from "../../../../constants/enviroment";
import "../../../../css/agent.component.css";
import Franchise from "../../franchise/franchise";

interface IProps {
    agent: IAgentModel;
}

const AgentComponent = (props: IProps) => {
    const { agent } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getImageLink = (agent: IAgentModel) => {
        let link = "/assets/images/no_image.jpg";
        let profileImage = agent.profile_image;

        if (!agent.userImgs || agent.userImgs.length === 0)
            return profileImage ?? link;

        const links: any = {
            e9a9ebd6_6ae8_461f_8586_3f409d472e41:
                agent.userImgs[2]?.Location ?? profileImage ?? link,
            cde31b48_79aa_40f4_a8c0_b6ab37cf68f6:
                agent.userImgs[1]?.Location ?? profileImage ?? link,
        };

        return links[agent.bot_id!] ?? link;
    };

    const getProducto = () => {
        return agent.bot_id === namespace_1 ? "creditos" : "distribuciones";
    };

    const getDetailLink = () => {
        dispatch(setAgentData(agent));
        const links: any = {
            IS_AGENT: `/agente/${agent.id}`,
            IS_TOP: `/agente/${agent.id}`,
            IS_COORDINATOR: `/${getProducto()}/coordinador/${agent.id}`,
            IS_MENTOR: `/${getProducto()}/mentor/${agent.id}`,
            IS_DISTRIBUIDOR: `/distribuidor/${agent.id}`,
            IS_GESTIONADOR: `/gestionador/${agent.id}`,
            IS_FRANQUICIADO: `/franquiciado/${agent.id}`,
        };

        return links[agent.type ?? "IS_AGENT"];
    };

    return (
        <Card
            className="agent-container"
            color={agent.status === "Active" ? "green" : "grey"}
        >
            <Image
                className="pointer"
                src={getImageLink(agent)}
                wrapped
                ui={false}
                onClick={() => navigate(getDetailLink())}
            />
            ID: {agent.id}
            <Card.Content>
                <Card.Header>
                    {agent.type === "IS_TOP" ? (
                        <Icon name="star" color="yellow" />
                    ) : null}
                    {agent.name} {agent.lastName}
                </Card.Header>
                <Card.Meta>
                    <span className="date">{agent.email}</span>
                </Card.Meta>
                <Card.Description>{agent.phone}</Card.Description>
            </Card.Content>
            <Card.Content extra>
                <div className="button-container">
                    {agent.type === ""}
                    <Button
                        color="blue"
                        onClick={() => {
                            dispatch(setAgentData(agent));
                            navigate(`/${getProducto()}/editar/${agent.id}`);
                        }}
                    >
                        <Icon name="edit" /> Editar
                    </Button>
                    <DashboardModal agent={agent} />
                    <Button
                        color="red"
                        onClick={() => {
                            dispatch(setAgentData(agent));
                            navigate(`/pendingChat`);
                        }}
                    >
                        <Icon name="conversation" /> Chats Pendientes
                    </Button>
                    {agent.type === "IS_FRANQUICIADO" ? (
                        <Franchise agent={agent} />
                    ) : null}
                </div>
            </Card.Content>
        </Card>
    );
};

export default AgentComponent;
