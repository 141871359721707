import {
    SET_AGENTS,
    SET_AGENT_BY_ID,
    SET_LOADING_ERRORS_ADMIN_AGENT,
    SET_ADMIN_AGENTS_PAGE,
    SET_DISTRIBUIDORES,
    SET_MENTORES,
    SET_GESTIONADORES,
    SET_COORDINATORS,
    SET_CONTADORES,
    SET_ADMINS,
    SET_ADMIN_AGENTS_TOTAL,
    SET_FRANQUICIADOS,
} from "../../constants/agent-admin.constants";
import { IAgentModel } from "../../models/Agent.model";

export const setAgentsTotal = (total: number) => (dispatch: any) => {
    dispatch({
        type: SET_ADMIN_AGENTS_TOTAL,
        payload: total,
    });
};

export const setAgentsPage = (page: number) => (dispatch: any) => {
    dispatch({
        type: SET_ADMIN_AGENTS_PAGE,
        payload: page,
    });
};

export const setAgentsData =
    (role: string, agents: IAgentModel[]) => (dispatch: any) => {
        const types: any = {
            IS_TOP: SET_AGENTS,
            IS_AGENT: SET_AGENTS,
            IS_DISTRIBUIDOR: SET_DISTRIBUIDORES,
            IS_FRANQUICIADO: SET_FRANQUICIADOS,
            IS_GESTIONADOR: SET_GESTIONADORES,
            IS_COORDINATOR: SET_COORDINATORS,
            IS_MENTOR: SET_MENTORES,
            IS_CONTADOR: SET_CONTADORES,
            IS_ADMIN: SET_ADMINS,
        };

        dispatch({
            type: types[role] ?? SET_AGENTS,
            payload: agents,
        });
    };

export const setAgentData = (agent: IAgentModel) => (dispatch: any) => {
    dispatch({
        type: SET_AGENT_BY_ID,
        payload: agent,
    });
};

export const setErrors = (errors: string) => (dispatch: any) => {
    dispatch({
        type: SET_LOADING_ERRORS_ADMIN_AGENT,
        payload: errors,
    });
};
