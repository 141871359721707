import React, { useState } from 'react';
import { Segment, Header, Input, Grid, Button, Label, Tab } from 'semantic-ui-react';

interface IPercentageCalculatorState {
    total: number | null;
    subtotal: number | null;
    percentage: number;
    difference: number;
    percentageDifference: number;
    amount: number;
}

const PercentageCalculator = () => {
    const [state, setState] = useState<IPercentageCalculatorState>({
        total: null,
        subtotal: null,
        percentage: 0,
        difference: 0,
        percentageDifference: 0,
        amount: 0
    });

    const panes = [
        {
            menuItem: 'Crecimiento',
            pane: '',
            render: () => 
                <Tab.Pane 
                    attached={true}
                >
                    <Grid>
                        <Grid.Row>
                            <Header>
                                Calculadora de Porcentage en Crecimento
                            </Header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Cantidad menor"
                                    value={state.subtotal}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            subtotal: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Cantidad mayor"
                                    value={state.total}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            total: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Button
                                    color='blue'
                                    onClick={
                                        () => calculatePorcentageDifferece(state.subtotal!, state.total!)
                                    }
                                >
                                    Calcular
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Label
                                    color='green'
                                    size='big'
                                >
                                    CRECIMIENTO DE {state.percentageDifference.toFixed(2)}%
                                </Label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
        },
        {
            menuItem: 'Perdida',
            render: () => 
                <Tab.Pane 
                    attached={true}
                >
                    <Grid>
                        <Grid.Row>
                            <Header>
                                Calculadora de Porcentaje en Perdidas
                            </Header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Cantidad mayor"
                                    value={state.subtotal}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            subtotal: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Cantidad menor"
                                    value={state.total}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            total: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Button
                                    color='blue'
                                    onClick={
                                        () => calculatePorcentageDiffereceLost(state.subtotal!, state.total!)
                                    }
                                >
                                    Calcular
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Label
                                    color='red'
                                    size='big'
                                >
                                    PERDIDAS DE {state.percentageDifference.toFixed(2)}%
                                </Label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
        },
        {
            menuItem: 'Porcentaje',
            render: () => 
                <Tab.Pane 
                    attached={true}
                >
                    <Grid>
                        <Grid.Row>
                            <Header>
                                Calculadora de Porcentaje
                            </Header>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Total"
                                    value={state.total}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            total: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column computer={8}>
                                <Input
                                    label="Porcentaje"
                                    value={state.percentage}
                                    type="number"
                                    onChange={(e) => 
                                        setState({
                                            ...state, 
                                            percentage: Number(e.currentTarget.value)
                                        })
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Button
                                    color='blue'
                                    onClick={
                                        () => calculatePorcentage(state.total!, state.percentage)
                                    }
                                >
                                    Calcular
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <Label
                                    color='green'
                                    size='big'
                                >
                                    ${state.amount.toFixed(2)}%
                                </Label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
        },
    ];

    const calculatePorcentageDifferece = (subtotal: number, total: number) => {
        let result = 0;
        let difference = total - subtotal;
        result = (difference / total) * 100;
        setState({
            ...state,
            percentageDifference: result
        })
    }

    const calculatePorcentageDiffereceLost = (subtotal: number, total: number) => {
        let result = 0;
        let difference = subtotal - total;
        result = (difference / subtotal) * 100;
        setState({
            ...state,
            percentageDifference: result
        })
    }

    const calculatePorcentage = (total: number, percentage: number) => {
        let result = 0;
        result = (total * percentage *.01 );
        setState({
            ...state,
            amount: result
        })
    }

    return(
        <Segment>
            <Tab
                onTabChange={
                    () => setState({
                        ...state,
                        total: null,
                        subtotal: null,
                        percentage: 0,
                        percentageDifference: 0,
                        difference: 0
                    })
                }
                panes={panes}
            />
            
        </Segment>
    );
}

export default PercentageCalculator;