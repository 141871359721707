import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    Button,
    Modal,
    Input,
    Image,
    Grid,
    Label,
    Dropdown,
} from "semantic-ui-react";
import { IAgentModel } from "../../../models/Agent.model";
import { RootState } from "../../../store/store";
import { create } from "../../../actions/Agent/create.actions";
import { update, updateImage } from "../../../actions/Agent/update.actions";
import {
    types as typesAgent,
    services as servicesAgent,
} from "../../../constants/agents/type.agent";
import ImageSlider from "./components/image-slider";
import { status as statusAgent } from "../../../constants/agents/status.agent";
import ChangePasswordModal from "./components/change.password";
import "../../../css/agents.css";
import { useNavigate, useParams } from "react-router-dom";
import { getByType } from "../../../actions/Agent/get.actions";
import { states } from "../../../constants/states.constants";
export interface IEditAgentState {
    isNew: boolean;
    name: string;
    nameValido: boolean;
    password: string;
    passwordValido: boolean;
    adminPassword: string;
    adminPasswordValido: boolean;
    lastName: string;
    lastNameValido: boolean;
    phone: string;
    phoneValido: boolean;
    email: string;
    emailValido: boolean;
    type?: string;
    typeValido: boolean;
    bot_id?: string;
    bot_idValido: boolean;
    images?: any[];
    imagesValido: boolean;
    profile_image: any;
    status?: string;
    statusValido: boolean;
    username?: string;
    verified: boolean;
    showPassword?: boolean;
    coordinatorId?: string;
    coordinatorIdValido: boolean;
    distribuidorId?: string;
    distribuidorIdValido: boolean;
    isChatEnabled: boolean;
    estado: string;
    county: string;
    address: string;
    bank: string;
    CLABE: string;
    agentCommission: any;
    coordinatorCommission: any;
    bankValido: boolean;
    CLABEvalido: boolean;
    agentCommissionValido: boolean;
    coordinatorCommissionValido: boolean;
}

const EditAgent: React.FC<IEditAgentProps> = ({
    profile,
    agent,
    bot_id,
    errors,
    loading,
    coordinadores,
    distribuidores,
    isCoordinator,
    isDistributor,
    isFranchise,
    isMentor,
    isAdmin,
    create,
    update,
    updateImage,
    getByType,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState<IEditAgentState>({
        isNew: id && agent ? true : false,
        name: id && agent ? agent.name : "",
        nameValido: true,
        password: "",
        passwordValido: true,
        adminPassword: "",
        adminPasswordValido: true,
        lastName: id && agent ? agent!.lastName : "",
        lastNameValido: true,
        phone: id && agent ? agent.phone : "",
        username: id && agent ? agent.username : "",
        phoneValido: true,
        email: id && agent ? agent.email : "",
        emailValido: true,
        type: id && agent ? agent.type : "IS_AGENT",
        typeValido: true,
        bot_id: id && agent ? agent.bot_id : bot_id,
        bot_idValido: true,
        images: id && agent ? agent.userImgs : [],
        profile_image:
            id &&
            agent &&
            agent.profile_image !== "" &&
            agent.profile_image !== null
                ? agent.profile_image
                : "../../../assets/images/avatar.jpg",
        imagesValido: true,
        status: id && agent ? agent.status : "Inactive",
        statusValido: true,
        verified: id && agent ? agent.verified : false,
        showPassword: false,
        coordinatorId:
            id && agent ? agent.coordinatorId : isMentor ? profile.id : "",
        coordinatorIdValido: true,
        distribuidorId: id && agent ? agent.distribuidorId : "",
        distribuidorIdValido: true,
        isChatEnabled: id && agent ? agent.isChatEnabled! : false,
        estado: id && agent ? agent.state! : "",
        county: id && agent ? agent.county! : "",
        address: id && agent ? agent.address! : "",

        bank: id && agent ? agent.bank : "",
        CLABE: id && agent ? agent.CLABE : "",
        agentCommission: id && agent ? agent.agentCommission ?? 0.05 : 0.05,
        coordinatorCommission:
            id && agent ? agent.coordinatorCommission ?? 0.005 : 0.05,
        bankValido: true,
        CLABEvalido: true,
        agentCommissionValido: true,
        coordinatorCommissionValido: true,
    });

    useEffect(() => {
        if (profile.id) {
            loadEntities();
        }
    }, [, id, agent, profile]);

    const onClose = () => {
        navigate(-1);
    };

    const loadEntities = () => {
        getByType(
            {
                $or: [{ type: "IS_COORDINATOR" }, { type: "IS_MENTOR" }],
                bot_id: bot_id,
            },
            1,
            9999,
            { type: 1, name: 1 },
            "IS_COORDINATOR"
        );
        getByType(
            {
                $or: [{ type: "IS_DISTRIBUIDOR" }, { type: "IS_FRANQUICIADO" }],
                bot_id: bot_id,
            },
            1,
            9999,
            { name: 1 },
            "IS_DISTRIBUIDOR"
        );
    };

    const saveAgent = async () => {
        const {
            name,
            lastName,
            phone,
            email,
            type,
            bot_id,
            images,
            status,
            username,
            verified,
            password,
            coordinatorId,
            distribuidorId,
            estado,
            county,
            address,
            profile_image,
            isChatEnabled,
            bank,
            CLABE,
            agentCommission,
            coordinatorCommission,
        } = state;
        let {
            nameValido,
            lastNameValido,
            phoneValido,
            emailValido,
            passwordValido,
            typeValido,
            imagesValido,
            statusValido,
        } = state;

        nameValido = name !== "";
        lastNameValido = lastName !== "";
        phoneValido = phone !== "";
        emailValido = email !== "";
        typeValido = type !== "";
        imagesValido = images!.length >= 6;
        statusValido = status !== "";

        console.log("PROGILE IMAGE 1", profile_image);

        if (
            nameValido &&
            lastNameValido &&
            phoneValido &&
            emailValido &&
            typeValido &&
            (type !== "IS_AGENT" && type !== "IS_TOP" ? true : imagesValido) &&
            (type !== "IS_AGENT" && type !== "IS_TOP" ? true : statusValido) &&
            (state.isNew ? true : passwordValido)
        ) {
            let agent: IAgentModel = {
                name,
                lastName,
                phone,
                email,
                type,
                bot_id,
                images,
                status,
                verified,
                username,
                password,
                coordinatorId,
                distribuidorId,
                state: estado,
                county,
                address,
                profile_image,
                isChatEnabled,
                bank,
                CLABE,
                agentCommission,
                coordinatorCommission,
            };
            if (!state.isNew) {
                let created = await create(agent);
                console.log("AGENTE CREADO", created);
                if (created) {
                    onClose();
                }
            } else {
                agent.id = id;
                delete agent.profile_image;
                let updated = await update(agent);
                if (updated) {
                    onClose();
                }
            }
        }

        setState({
            ...state,
            nameValido,
            lastNameValido,
            phoneValido,
            passwordValido,
            emailValido,
            typeValido,
            imagesValido,
        });
    };

    const onImageProfileChange = async (event: any) => {
        let path = "../../../assets/images/avatar.jpg";

        console.log("PROFILE IMAGE CHANGE", event.target.files[0]);

        if (event.target.files[0]) {
            path = event.target.files[0];
        }

        if (state.isNew) {
            await updateImage(agent!, event.target.files[0], -1);
        }
        await setState({
            ...state,
            profile_image: path,
        });
    };

    const onFileChange = async (event: any) => {
        let images: any[] = state.images!;
        if (images.length <= 7) {
            if (event.target.files[0]) {
                images.push(event.target.files[0]);
            }
        }
        // Update the state
        await setState({
            ...state,
            images: images,
            imagesValido: images.length >= 6,
        });
    };

    const onChangeType = (status: string) => {
        setState({
            ...state,
            type: status,
            typeValido: status !== "",
        });
    };

    const onChangeService = (status: string) => {
        setState({
            ...state,
            bot_id: status,
            bot_idValido: status !== "",
        });
    };

    const onChangeStatus = (status: string) => {
        setState({
            ...state,
            status: status,
            statusValido: status !== "",
        });
    };

    const getImageError = (count: number) => {
        let title: string = "";
        switch (count) {
            case 0:
                title = "Falta imagen de presentación";
                break;
            case 1:
                title = "Falta imagen para solicitar el teléfono";
                break;
            case 2:
                title = "Falta imagen de identificación del agente";
                break;
            case 3:
                title = "Falta imagen de tabla de amortización";
                break;
            case 4:
                title = "Falta imagen de seguimiento de 3 horas";
                break;
            case 5:
                title = "Falta imagen de seguimiento de 6 días";
                break;
        }
        return title;
    };

    const onDeleteImage = async (index: number) => {
        const { images } = state;
        let imagesTemp: any[] = images ? images : [];
        if (imagesTemp.length <= 7) {
            if (imagesTemp[index]) {
                imagesTemp.splice(index, 1);
            }
        }
        // Update the state
        await setState({ ...state, images: imagesTemp });
    };

    const onEditImage = async (index: number, image: File) => {
        const { images } = state;
        let imagesTemp: any[] = images ? images!.map((m) => m) : [];

        if (image) {
            await updateImage(agent!, image, index);
            imagesTemp[index] = { Location: URL.createObjectURL(image) };
        }
        // Update the state
        await setState({ ...state, images: imagesTemp });
    };

    const chatEnabledOptions = [
        {
            key: "true",
            value: true,
            text: "Activo",
        },
        {
            key: "false",
            value: false,
            text: "Inactivo",
        },
    ];

    return (
        <Modal
            className="edit-agent-modal"
            onClose={onClose}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeOnPortalMouseLeave={false}
            closeOnTriggerBlur={false}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={false}
            closeOnTriggerClick={false}
            open={true}
            size="large"
        >
            <Modal.Header>
                {!state.isNew ? "Registro" : `Agente: ${id}`}
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <input
                                style={{ visibility: "hidden" }}
                                id="btn-upload-profile"
                                type="file"
                                onChange={onImageProfileChange}
                                multiple
                                name="btn-foto"
                                title="Subir fotos"
                            />
                            <Image
                                centered
                                src={
                                    state.profile_image instanceof File
                                        ? URL.createObjectURL(
                                              state.profile_image
                                          )
                                        : state.profile_image
                                }
                                size="medium"
                                circular
                                onClick={() => {
                                    document
                                        .getElementById("btn-upload-profile")!
                                        .click();
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} stretched>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                label="Nombre"
                                value={state.name}
                                error={!state.nameValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        name: e.currentTarget.value,
                                        nameValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16} stretched>
                            <Input
                                label="Apellido"
                                value={state.lastName}
                                error={!state.lastNameValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        lastName: e.currentTarget.value,
                                        lastNameValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                label="Nombre de usuario"
                                type="text"
                                value={state.username}
                                error={!state.phoneValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        username: e.target.value.toLowerCase(),
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                label="Teléfono"
                                type="number"
                                value={state.phone}
                                error={!state.phoneValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        phone: e.currentTarget.value,
                                        phoneValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16} stretched>
                            <Input
                                label="Email"
                                value={state.email}
                                error={!state.emailValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        email: e.currentTarget.value,
                                        emailValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={8}>
                            <span>Estado:</span>
                            <Dropdown
                                search
                                clearable
                                value={state.estado}
                                placeholder="Tipo de agente"
                                fluid
                                selection
                                options={states}
                                onChange={(e, data) =>
                                    setState({
                                        ...state,
                                        estado: data.value!.toString(),
                                    })
                                }
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                label="Municipio"
                                value={state.county}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        county: e.currentTarget.value,
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                label="Dirección"
                                value={state.address}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        address: e.currentTarget.value,
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                disabled={
                                    state.type !== "IS_AGENT" &&
                                    state.type !== "IS_TOP"
                                }
                                label="Banco"
                                value={state.bank}
                                error={!state.bankValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        bank: e.currentTarget.value,
                                        bankValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                disabled={
                                    state.type !== "IS_AGENT" &&
                                    state.type !== "IS_TOP"
                                }
                                label="CLABE Interbancaria"
                                value={state.CLABE}
                                error={!state.CLABEvalido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        CLABE: e.currentTarget.value,
                                        CLABEvalido:
                                            e.currentTarget.value.length >= 18,
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                type="number"
                                disabled={
                                    state.type !== "IS_AGENT" &&
                                    state.type !== "IS_TOP"
                                }
                                label="Comisión del Agente %"
                                value={state.agentCommission}
                                error={!state.agentCommissionValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        agentCommission: e.currentTarget.value,
                                        agentCommissionValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={16}>
                            <Input
                                type="number"
                                disabled={
                                    state.type !== "IS_AGENT" &&
                                    state.type !== "IS_TOP"
                                }
                                label="Comisión del Coordinador %"
                                value={state.coordinatorCommission}
                                error={!state.coordinatorCommissionValido}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        coordinatorCommission:
                                            e.currentTarget.value,
                                        coordinatorCommissionValido:
                                            e.currentTarget.value !== "",
                                    });
                                }}
                                style={{ margin: "5px" }}
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={8}>
                            <span>Tipo:</span>
                            <Dropdown
                                search
                                disabled={!isAdmin}
                                value={state.type}
                                placeholder="Tipo de agente"
                                fluid
                                selection
                                options={typesAgent}
                                onChange={(e, data) =>
                                    onChangeType(data.value!.toString())
                                }
                            />
                        </Grid.Column>
                        <Grid.Column computer={8} mobile={8}>
                            <span>Coordinador:</span>
                            <Dropdown
                                search
                                clearable
                                disabled={isMentor}
                                value={state.coordinatorId}
                                placeholder="Coordinador"
                                fluid
                                selection
                                options={
                                    coordinadores
                                        ? coordinadores.map((coordinador) => ({
                                              key: coordinador.id,
                                              value: coordinador.id,
                                              text: `${
                                                  coordinador.type ===
                                                  "IS_COORDINATOR"
                                                      ? "COORDINADOR"
                                                      : "MENTOR"
                                              } - ${coordinador.name} ${
                                                  coordinador.lastName
                                              }`,
                                          }))
                                        : []
                                }
                                onChange={(e, data) =>
                                    setState({
                                        ...state,
                                        coordinatorId: data.value!.toString(),
                                        coordinatorIdValido:
                                            data.value !== null,
                                    })
                                }
                            />
                        </Grid.Column>
                        {state.type === "IS_AGENT" ||
                        state.type === "IS_TOP" ? (
                            <Grid.Column computer={8} mobile={8}>
                                <span>Distribuidor:</span>
                                <Dropdown
                                    search
                                    value={state.distribuidorId}
                                    placeholder="Distribuidor"
                                    fluid
                                    selection
                                    options={
                                        distribuidores
                                            ? distribuidores.map(
                                                  (distribuidor) => ({
                                                      key: distribuidor.id,
                                                      value: distribuidor.id,
                                                      text: `${distribuidor.name} ${distribuidor.lastName}`,
                                                  })
                                              )
                                            : []
                                    }
                                    onChange={(e, data) =>
                                        setState({
                                            ...state,
                                            distribuidorId:
                                                data.value!.toString(),
                                            distribuidorIdValido:
                                                data.value !== null,
                                        })
                                    }
                                />
                            </Grid.Column>
                        ) : null}
                        <Grid.Column computer={8} mobile={8}>
                            <span>Servicio:</span>
                            <Dropdown
                                disabled={!isAdmin}
                                value={state.bot_id}
                                placeholder="SERVICIO"
                                fluid
                                selection
                                options={servicesAgent}
                                onChange={(e, data) =>
                                    onChangeService(data.value!.toString())
                                }
                            />
                        </Grid.Column>
                        {isAdmin ? (
                            <Grid.Column computer={8} mobile={8}>
                                <span>Estatus:</span>
                                <Dropdown
                                    disabled={!isAdmin}
                                    value={state.status}
                                    placeholder="Estado"
                                    fluid
                                    selection
                                    options={statusAgent}
                                    onChange={(e, data) =>
                                        onChangeStatus(data.value!.toString())
                                    }
                                    style={{ marginTop: "5px" }}
                                />
                            </Grid.Column>
                        ) : null}
                        <Grid.Column computer={8} mobile={16} stretched>
                            {!state.isNew ? (
                                <Input
                                    label="Contraseña"
                                    value={state.password}
                                    error={!state.password}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            password: e.currentTarget.value,
                                        });
                                    }}
                                    type="password"
                                    style={{ margin: "5px" }}
                                />
                            ) : (
                                <ChangePasswordModal agent={agent!} />
                            )}
                        </Grid.Column>
                        {isAdmin ? (
                            <Grid.Column computer={8} mobile={8}>
                                <span>Chat CRM:</span>
                                <Dropdown
                                    disabled={!isAdmin}
                                    value={state.isChatEnabled}
                                    placeholder="Chat CRM"
                                    fluid
                                    selection
                                    options={chatEnabledOptions}
                                    onChange={(e, data) => {
                                        setState({
                                            ...state,
                                            isChatEnabled:
                                                data.value?.toString() ===
                                                "true",
                                        });
                                    }}
                                    style={{ marginTop: "5px" }}
                                />
                            </Grid.Column>
                        ) : null}
                        <Grid.Column>
                            <div>
                                <input
                                    style={{ visibility: "hidden" }}
                                    id="btn-upload"
                                    type="file"
                                    onChange={onFileChange}
                                    multiple
                                    name="btn-foto"
                                    title="Subir fotos"
                                />
                                {!state.isNew ? (
                                    <Button
                                        floated="right"
                                        onClick={() => {
                                            document
                                                .getElementById("btn-upload")!
                                                .click();
                                        }}
                                        color="green"
                                    >
                                        Subir imágenes
                                    </Button>
                                ) : null}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    {state.type === "IS_AGENT" || state.type === "IS_TOP" ? (
                        <>
                            <ImageSlider
                                isNew={state.isNew}
                                galery={state.images!}
                                onDeleteImage={onDeleteImage}
                                onEditImage={onEditImage}
                            />
                            {!state.imagesValido ? (
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "1px solid #e0b4b4",
                                        backgroundColor: "#fff6f6",
                                        color: "red",
                                    }}
                                >
                                    <h5>
                                        {getImageError(state.images!.length)}
                                    </h5>
                                </div>
                            ) : null}
                        </>
                    ) : null}
                    {errors ? (
                        <Grid.Row>
                            <Grid.Column>
                                <Label
                                    attached="bottom"
                                    color="red"
                                    style={{ textAlign: "center" }}
                                >
                                    {errors}
                                </Label>
                            </Grid.Column>
                        </Grid.Row>
                    ) : null}
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onClose}>
                    Cerrar
                </Button>
                <Button
                    content={!state.isNew ? "Registrar" : "Editar"}
                    color="green"
                    onClick={saveAgent}
                    disabled={loading}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

interface IProps {
    bot_id: string;
}

const mapStateToProps = (state: RootState, props: IProps) => {
    return {
        profile: state.users.profile,
        agent: state.adminAgents.agent,
        coordinadores: state.adminAgents.cooridnators,
        distribuidores: state.adminAgents.distribuidores,
        errors: state.adminAgents.errors,
        loading: state.adminAgents.loadingUpdate,
        isAdmin: state.users.user.type === "IS_ADMIN",
        isCoordinator: state.users.user.type === "IS_COORDINATOR",
        isMentor: state.users.user.type === "IS_MENTOR",
        isDistributor: state.users.user.type === "IS_DISTRIBUTOR",
        isFranchise: state.users.user.type === "IS_FRANCHISE",
        bot_id: props.bot_id,
    };
};

const mapActionsToProps = {
    create,
    update,
    updateImage,
    getByType,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IEditAgentProps = ConnectedProps<typeof connector>;

export default connector(EditAgent);
