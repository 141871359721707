import {
  SET_TEMPLATES,
  SET_TEMPLATE,
  SET_LOADING_TEMPLATE,
  SET_LOADING_TEMPLATES,
  SET_LOADING_ERRORS_TEMPLATE,
  CLEAR_LOADING_ERRORS_TEMPLATE,
  SET_TEMPLATE_BY_ID,
} from "../constants/template.constants";
import { Templates } from "../layout/components/templates/interfaces/templates.interface";

const initialState = {
  loading: false,
  loadingTemplate: false,
  templates: [] as ReadonlyArray<Templates>,
  template: null as unknown as Readonly<Templates>,
  errors: "",
};

export type UserState = Readonly<typeof initialState>;

const templatesReducer = (
  state: UserState = initialState,
  action: any
): UserState => {
  switch (action.type) {
    case SET_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        template: action.payload,
        errors: "",
      };
    case SET_TEMPLATES:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        templates: action.payload,
        errors: "",
      };
    case SET_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        template: action.payload,
        errors: "",
      };
    case SET_LOADING_TEMPLATES:
      return {
        ...state,
        loading: true,
        errors: "",
      };
    case SET_LOADING_TEMPLATE:
      return {
        ...state,
        loadingTemplate: true,
        errors: "",
      };
    case SET_LOADING_ERRORS_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        errors: action.payload,
      };
    case CLEAR_LOADING_ERRORS_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        errors: "",
      };
    default:
      return state;
  }
};

export default templatesReducer;
