import {
    SET_FRANCHISE,
    SET_FRANCHISES,
    SET_LOADING_FRANCHISE,
    SET_LOADING_FRANCHISES,
} from "../constants/franchise.constants";

const initialState = {
    loading: false,
    loadingFranchise: false,
    franchises: [],
    franchise: {},
    errors: "",
};

export type State = Readonly<typeof initialState>;

const franchiseReducer = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case SET_FRANCHISE:
            return {
                ...state,
                loading: false,
                loadingFranchise: false,
                franchise: action.payload,
                errors: "",
            };
        case SET_FRANCHISES:
            return {
                ...state,
                loading: false,
                loadingFranchise: false,
                franchises: action.payload,
                errors: "",
            };
        case SET_LOADING_FRANCHISES:
            return {
                ...state,
                loading: true,
                errors: "",
            };
        case SET_LOADING_FRANCHISE:
            return {
                ...state,
                loadingFranchise: true,
                errors: "",
            };
        default:
            return state;
    }
};

export default franchiseReducer;
