export const status = [
    {
        key: "Active",
        text: " Activo",
        value: "Active",
    },
    {
        key: "Inactive",
        text: " Inactivo",
        value: "Inactive",
    }
];