import React from "react";
import { logoutUser } from "../actions/users.actions";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid,
  Image,
  Segment,
  Button,
  GridColumn,
  Popup,
} from "semantic-ui-react";
import { RootState } from "../store/store";
import { useNavigate } from "react-router";
import { setOpenMenu } from "../actions/profile.actions";
import Notification from "./components/notificationsCRM/Notification";
import "../css/header.css";

const Header: React.FC<IHeaderProps> = ({
  authenticated,
  isOpenMenu,
  type,
  setOpenMenu,
}) => {
  const navigate = useNavigate();

  const redirect = () => {
    switch (type) {
      case "IS_COORDINATOR":
      case "IS_ADMIN":
        navigate("/dashboard");
        break;
      case "IS_TOP":
      case "IS_AGENT":
        navigate("/agent/dashboard");
        break;
      default:
        navigate("/");
        break;
    }
  };

  return (
    <Segment>
      <header
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "20px",
        }}
      >
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column computer={14} mobile={13}>
              <Image
                className="pointer"
                src="/images/logo.png"
                size="small"
                onClick={redirect}
              />
            </Grid.Column>
            <GridColumn computer={2} mobile={1}>
              <Notification />
            </GridColumn>
            <Grid.Column computer={2} mobile={3}>
              {authenticated ? (
                <Button
                  className={"header-button"}
                  color="black"
                  icon="bars"
                  onClick={() => setOpenMenu(!isOpenMenu)}
                ></Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </header>
    </Segment>
  );
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
  return {
    authenticated: state.users.authenticated,
    userRole: state.users.user.type,
    type: state.users.user.type,
    isOpenMenu: state.users.openMenu,
  };
};

const mapActionsToProps = {
  logoutUser,
  setOpenMenu,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IHeaderProps = ConnectedProps<typeof connector>;

export default connector(Header);
