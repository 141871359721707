import {
    IFormField,
    IDocumentoExpediente
} from "../models/utils.model";

export const initialFormField: IFormField = {
    value: '',
    type: 'text',
    error_message: '',
    rule: new RegExp(/^(?:[a-zA-ZÀ-ÿ\s']+)?$/).source,
    isValid: true,
    options: []
};

export const initialDocument: IDocumentoExpediente = {
    name: '',
    title: '',
    url: '',
    isValid: true,
    error_message: '',
    extensions: []
}