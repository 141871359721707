import React, { useEffect } from "react";
import { RootState } from "../../../store/store";
import { connect, ConnectedProps } from "react-redux";
import {
	Grid,
	Segment,
	Header,
	Loader,
	Menu,
	Icon,
	Table,
} from "semantic-ui-react";
import { getData, setTime } from "../../../actions/dashboard.agent.actions";
import AgentChart from "./components/agent.chart";
import Sale from "../../../models/Sale.model";
import "../../../css/agent.dashboard.css";

const AgentDashboard: React.FC<IProps> = ({
	isAgent,
	profile,
	authenticated,
	agent,
	loading,
	data,
	time,
	getData,
	setTime,
}) => {
	const {
		leads,
		leadsByStatus,
		followments,
		validations,
		cotizations,
		sales,
	} = data;

	const agente = isAgent ? profile : agent;

	useEffect(() => {
		loadData();
	}, [, time, profile, agent]);

	const loadData = () => {
		if (authenticated) {
			getData(isAgent ? profile.id! : agent.id!, time.toString());
		}
	};

	return !loading ? (
		<Grid>
			<Grid.Row className="margin-container" columns={1}>
				<Grid.Column>
					<Menu compact icon="labeled" size="huge">
						<Menu.Item
							name="week"
							active={time === "week"}
							color={time === "week" ? "red" : "black"}
							onClick={() => setTime("week")}
						>
							<Icon name="calendar minus" />
							Semana
						</Menu.Item>
						<Menu.Item
							name="month"
							active={time === "month"}
							color={time === "month" ? "red" : "black"}
							onClick={() => setTime("month")}
						>
							<Icon name="calendar plus" />
							Mes
						</Menu.Item>
						<Menu.Item
							name="year"
							active={time === "year"}
							color={time === "year" ? "red" : "black"}
							onClick={() => setTime("year")}
						>
							<Icon name="calendar" />
							Año
						</Menu.Item>
					</Menu>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row stretched>
				<Grid.Column computer={3} mobile={8}>
					<Segment>
						<Header as="h2" icon color="red">
							<Icon name="users" />
							{leads}
							<Header.Subheader>Leads Asignados</Header.Subheader>
						</Header>
					</Segment>
					<Segment>
						<Header as="h2" icon color="blue">
							<Icon name="calculator" />
							{cotizations}
							<Header.Subheader>Cotizaciones</Header.Subheader>
						</Header>
					</Segment>
				</Grid.Column>
				<Grid.Column computer={3} mobile={8}>
					<Segment>
						<Header as="h2" icon color="yellow">
							<Icon name="check" />
							{validations}
							<Header.Subheader>Validaciones</Header.Subheader>
						</Header>
					</Segment>
					<Segment>
						<Header as="h2" icon color="green">
							<Icon name="calendar check" />
							{followments}
							<Header.Subheader>Seguimientos</Header.Subheader>
						</Header>
					</Segment>
				</Grid.Column>
				<Grid.Column
					className="chart-container"
					computer={10}
					mobile={16}
				>
					<Segment>
						<AgentChart data={leadsByStatus} />
					</Segment>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header style={{ margin: "10px" }}>
						<Header.Content>
							{`${agente.name} ${agente.lastName}`} - Últimas
							ventas
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>ID</Table.HeaderCell>
								<Table.HeaderCell>ID Cliente</Table.HeaderCell>
								<Table.HeaderCell>Monto</Table.HeaderCell>
								<Table.HeaderCell>
									Fecha de Registro
								</Table.HeaderCell>
								<Table.HeaderCell>
									Fecha de Cierre
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{sales?.map((sale: Sale) => {
								return (
									<Table.Row key={`sale-${sale.id}`}>
										<Table.Cell>{sale.id}</Table.Cell>
										<Table.Cell>{sale.userId}</Table.Cell>
										<Table.Cell>{sale.amount}</Table.Cell>
										<Table.Cell>
											{sale.createdAt}
										</Table.Cell>
										<Table.Cell>{sale.date}</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	) : (
		<Loader active={loading}>Cargando...</Loader>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		loading: state.agentDashboard.loading,
		data: state.agentDashboard.data,
		time: state.agentDashboard.time,
		authenticated: state.users.authenticated,
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getData,
	setTime,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(AgentDashboard);
