import { LOADING_UPDATE_ADMIN_AGENT } from "../../constants/agent-admin.constants";

import axios from "axios";
import { IAgentModel } from "../../models/Agent.model";
import { apiUrl } from "../../constants/enviroment";
import { setErrors, setAgentData } from "./utils.actions";
import FormData from "form-data";

export const create = (agent: IAgentModel) => async (dispatch: any) => {
	let created = false;

	try {
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

		const formData = new FormData();

		formData.append("name", agent.name);

		formData.append("lastName", agent.lastName);

		formData.append("phone", agent.phone);

		formData.append("email", agent.email);

		formData.append("state", agent.state);
		formData.append("county", agent.county);
		formData.append("address", agent.address);

		formData.append("type", agent.type!);

		formData.append("bot_id", agent.bot_id!);

		formData.append("password", agent.password!);

		formData.append("username", agent.username!);

		formData.append("status", agent.status!);

		formData.append("isChatEnabled", agent.isChatEnabled!);

		formData.append("coordinatorId", agent.coordinatorId!);

		formData.append("distribuidorId", agent.distribuidorId!);

		console.log("PROFILE IMAGE", agent.profile_image);

		formData.append("images", agent.profile_image, `profile_image.png`);

		// Update the formData object
		for (let i: number = 0; i < agent.images!.length; i++) {
			// Details of the uploaded file
			console.log("IMAGES", i, agent.images![i]);
			formData.append(
				"images",
				agent.images![i],
				`${(i + 1).toString()}.png`
			);
		}

		await axios
			.post(`${apiUrl}/agent/`, formData, {
				headers: {
					Accept: "multipart/form-data",
					"Content-Type": "multipart/form-data;",
				},
			})
			.then((res) => {
				if (res.status !== 200) {
					console.warn("CREATE", res.data.message);
					dispatch(setErrors(res.data.message));
					return created;
				}
				dispatch(setAgentData(res.data.agent));
				created = true;
			})
			.catch((err) => {
				console.error("CREATE", err.response.data.message);
				dispatch(setErrors(err.response.data.message));
				return created;
			});
	} catch (err) {
		console.error("Error al crear al agente", err);
		dispatch(setErrors(`Error al crear al agente ${err}`));
	}

	return created;
};
