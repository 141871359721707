import React from 'react';
import { Grid, Input, Image, Label } from 'semantic-ui-react';
import "../../../../css/cotizador.css";

interface IProps {
	capitalAmount: number,
	payment: number,
	handleField: (field: string, value: number) => void;
}

const CotizadorRenovation: React.FC<IProps> = ({
	capitalAmount,
	payment,
    handleField
}) => {

	return (
		<div>
			<Grid
				id='cotizador-renovacion-01'
				className='cotizador-container padding-0'
			>
				<Grid.Row columns={2}>
					<Grid.Column>
						<Image
							floated='left' 
							src={`https://i.imgur.com/sNFK20r.png`}
							className={"img-logo"}
							alt="LOGO"
						/>
					</Grid.Column>
					<Grid.Column>
						<Image
							floated='right' 
							src={"https://i.imgur.com/zyo9nDq.png"} 
							className={"img-logo"}
							alt="LOGO MULTIVA"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-10'>
					<Grid.Column textAlign='left'>
						<h3 className='base-text'>COTIZACIÓN PRELIMINAR</h3>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-10'>
					<Grid.Column textAlign='center'>
						<span className='text-blue'>
							Firme su renovación<br/>
							y reciba su beneficio en efectivo<br/>
							por {"    "}
						</span>
						<Input
							className='input-field'
							iconPosition='left'
							icon='dollar'
							type='number'
							value={capitalAmount}
							onChange={
								(e) => handleField('capitalAmount', Number(e.currentTarget.value))
							}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-10'>
					<Grid.Column>
						<Label className='label-field base-field bg-blue'>Su mensualidad será de:</Label>
						<Input
							className='input-field base-field'
                            iconPosition='left'
                            icon='dollar'
                            type='number'
							value={payment}
                            onChange={
                                (e) => handleField('payment', Number(e.currentTarget.value))
                            }
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-10'>
					<Grid.Column textAlign='left'>
						<ul>
							<li>Pago anticipado sin penalización.</li>
							<li>Seguro de vida gratis.</li>
							<li>Sin comisión por apertura.</li>
							<li>Pre-autorización en 15 minutos.</li>
							<li>Trámite en línea y depósito en 72 hrs, en la cuenta donde usted recibe su pensión.</li>
						</ul>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-10'>
					<Grid.Column textAlign='left'>
						<h6 className='base-text'>REQUISITOS:</h6>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className='margin-7'>
					<Grid.Column textAlign='left'>
						<ul>
							<li>Imagen escaneada legible de dos credenciales de las siguientes opciones: INE, Credencial del IMSS,
							INAPAM, licencia de conducir, Pasaporte, cédula profesional no mayor a 10 años, formato migratorio FM2,
							cartilla de servicio militar, por ambos lados sin flash ni sombra, es necesario que las cuatro esquinas de la
							credencial sean visibles.</li>
							<li>Comprobante de domicilio escaneado (recibo de CFE de preferencia) Agua, predial o servicio de teléfono
							vigencia menor a 2 meses.</li>
							<li>Estado de cuenta completo donde percibe su pensión o su informe de pago con vigencia menor a 2 meses,
							expedido del IMSS.</li>
							<li>Fotografía tipo selfie, sosteniendo su credencial (sin cubrebocas, lentes oscuros o cualquier accesorio que
							interfiera o tape parcialmente su rostro).</li>
						</ul>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default CotizadorRenovation;