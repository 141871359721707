import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Loader,
	Button,
	Modal,
	Grid,
	TextArea,
	Label,
	Dropdown,
} from "semantic-ui-react";
import { RootState } from "../../../store/store";
import {
	getByLeadByAgent,
	update,
	uploadFile,
} from "../../../actions/expedientes.actions";
import { useNavigate, useParams } from "react-router-dom";
import FileInput from "./components/file.input";
import FieldInput from "./components/field.input";
import "../../../css/expedientes.css";
import DownloadDocuments from "./components/download.documents";

const ExpedienteEdit: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	expediente,
	loading,
	getByLeadByAgent,
	update,
	uploadFile,
}) => {
	const { id, agente_id } = useParams();
	const [state, setState] = useState<any>();
	const [open, setOpen] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		console.log("EXPEDIENTE", id, profile.id, agent.id);
		if (profile.id) {
			getByLeadByAgent(
				id!,
				agente_id ? agente_id : isAgent ? profile.id! : agent.id!
			);
		}
	}, [, profile]);

	useEffect(() => {
		if (expediente) {
			setState(expediente);
		}
	}, [expediente]);

	const save = async () => {
		const updated = await update(expediente.id, state);

		if (updated) {
			onClose();
		}
	};

	const onClose = () => {
		navigate(-1);
		setOpen(false);
	};

	const onFileChange = async (name: string, file: any) => {
		const uploaded = uploadFile(expediente.id, name, file);
	};

	const onFileStatusChange = async (name: string, isValid: boolean) => {
		let documents_state = Object.assign({}, state.documents);

		documents_state[name] = {
			...documents_state[name],
			isValid: isValid,
		};

		const updated = await update(expediente.id, {
			...state,
			documents: documents_state,
		});
	};

	const validateFormFields = async (
		field: string,
		value: any,
		index?: number
	) => {
		let form_state = Object.assign({}, state.form);

		console.log("FIELD", field);
		console.log("REGEXP", form_state[field].rule);

		form_state[field] = {
			...form_state[field],
			value: value,
			isValid: new RegExp(form_state[field].rule).test(value),
		};

		setState({
			...state,
			form: form_state,
		});
	};

	const validateRefFields = async (
		field: string,
		value: any,
		index?: number
	) => {
		console.log("VALUE", value, "INDEX", index);

		let ref_state = Object.assign({}, state.referencias[index!]);
		let referencias_state = Object.assign({}, state.referencias);
		console.log("RSS", referencias_state);

		ref_state[field] = {
			...ref_state[field],
			value: value,
			isValid: new RegExp(ref_state[field].rule).test(value),
		};

		referencias_state[index!] = ref_state;

		referencias_state = [referencias_state[0], referencias_state[1]];

		console.log("RS", referencias_state);

		setState({
			...state,
			referencias: referencias_state,
		});

		console.log("SR", state.referencias);
	};

	const getDocumentKeys = (document: any) => {
		return Object.keys(document).map((key) => {
			return {
				title: key.replaceAll("_", " "),
				name: key,
			};
		});
	};

	const getFormKeys = (form: any) => {
		return Object.keys(form).map((key) => {
			return {
				key: key,
				title: key.replaceAll("_", " "),
				value: form[key].value,
				type: form[key].type,
				error_message: form[key].error_message,
				rule: form[key].rule,
				isValid: form[key].isValid,
				options: form[key].options,
			};
		});
	};

	const getOptions = () => {
		let options = [
			{ key: "NUEVO", text: "NUEVO", value: "NUEVO" },
			{ key: "EN REVISION", text: "EN REVISION", value: "EN REVISION" },
		];

		if (isAgent) {
			return options;
		}
		options = state?.form?.status.options.map((option: string) => {
			return {
				key: option,
				text: option,
				value: option,
			};
		});

		return options;
	};

	return !loading ? (
		<Grid divided="vertically" centered>
			<Grid.Row>
				<Grid.Column computer={10} mobile={16}>
					<Grid>
						<Label color="blue">DATOS DEL SOLICITANTE</Label>

						<Grid.Row columns={2} className="inputs-container">
							{state?.form
								? getFormKeys(state.form).map(
										(form_field: any, index: number) => {
											if (
												form_field.key ===
													"observaciones" ||
												form_field.key === "status"
											) {
												return;
											}
											return (
												<FieldInput
													key={`form-feld-input-${form_field.key}-${index}`}
													index={index}
													field={form_field.key}
													type={form_field.type}
													value={form_field.value}
													isValid={form_field.isValid}
													options={form_field.options}
													error_message={
														form_field.error_message
													}
													title={form_field.title}
													computer={8}
													mobile={16}
													validateFields={
														validateFormFields
													}
												/>
											);
										}
								  )
								: null}
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column computer={6} mobile={16}>
					<Grid>
						<Label color="blue">ESTATUS</Label>
						<Grid.Row>
							<Grid.Column>
								<Dropdown
									floating
									fluid
									selection
									value={state?.form?.status.value}
									onChange={(e, data) => {
										validateFormFields(
											"status",
											data.value
										);
									}}
									options={getOptions()}
									placeholder={"Estatus"}
								/>
							</Grid.Column>
						</Grid.Row>
						<Label color="blue">DOCUMENTOS</Label>
						<Grid.Row columns={1} className="inputs-container">
							{state?.documents
								? getDocumentKeys(state.documents).map(
										(data: any, index: number) => {
											return (
												<FileInput
													key={`file-input-${index}`}
													title={data.title.toUpperCase()}
													name={data.name}
													computer={8}
													mobile={16}
													documento={
														state.documents[
															data.name
														]
													}
													onFileChange={onFileChange}
													onFileStatusChange={
														onFileStatusChange
													}
												/>
											);
										}
								  )
								: null}
							<Grid.Column>
								<DownloadDocuments
									id={state?.id ?? ""}
									documents={state?.documents ?? {}}
									nombreCompleto={`${
										state?.form.apellido_paterno?.value ??
										""
									} ${
										state?.form.apellido_materno?.value ??
										""
									} ${state?.form.nombres?.value ?? ""}`}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column computer={16} mobile={16}>
					<Grid>
						<Label color="blue">REFERENCIAS</Label>
						{state?.referencias.map(
							(referencia: any, index: number) => {
								return (
									<Grid.Row
										key={`rr-${index}`}
										columns={3}
										className="inputs-container"
									>
										{getFormKeys(referencia).map(
											(form_field: any) => {
												return (
													<FieldInput
														key={`ref-feld-input-${form_field.key}-${index}`}
														field={form_field.key}
														type={form_field.type}
														value={form_field.value}
														isValid={
															form_field.isValid
														}
														options={
															form_field.options
														}
														error_message={
															form_field.error_message
														}
														title={form_field.title}
														computer={5}
														mobile={16}
														index={index}
														validateFields={
															validateRefFields
														}
													/>
												);
											}
										)}
									</Grid.Row>
								);
							}
						)}
					</Grid>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Label
						color={
							state?.form?.observaciones.value !== ""
								? "red"
								: "green"
						}
					>
						Observaciones
					</Label>
				</Grid.Column>
				<Grid.Column>
					<TextArea
						rows={6}
						value={state?.form?.observaciones.value}
						onChange={(e) =>
							validateFormFields(
								"observaciones",
								e.currentTarget.value
							)
						}
						style={{ width: 800 }}
						placeholder="Observaciones"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Button
						content={"Guardar"}
						icon="checkmark"
						onClick={save}
						positive
						disabled={loading}
						loading={loading}
						floated="right"
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	) : (
		<Loader active={loading} size="big">
			Cargando Expediente...
		</Loader>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		user: state.users.user,
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		lead: state.adminUsers.user,
		expediente: state.expedientesReducer.expediente,
		errors: state.expedientesReducer.errors,
		loading: state.expedientesReducer.loadingExpediente,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getByLeadByAgent,
	update,
	uploadFile,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(ExpedienteEdit);
