import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import { RootState } from "../../../../store/store";
import { connect, ConnectedProps } from "react-redux";
import {
    Card,
    Grid,
    Label,
    Loader,
    Button,
    Dimmer,
    Icon,
    List,
} from "semantic-ui-react";
import { CategoryTemplates } from "../interfaces/categoryTemplates.interface";
import { getByGroupId } from "../../../../actions/templates.actions";
import { ModalCreateTemplate } from "./CreateTemplate/ModalCreateTemplate";
import { deleteTemplates } from "./CreateTemplate/services/deleteTemplate";
import { disabledTemplates } from "./CreateTemplate/services/deleteTemplate";
import { PreviewTemplate } from "./previewTemplate";
import "../group_templates.css";

const Templates: React.FC<IGroupTemplateEditProps> = ({
    groupTemplate,
    templates,
    agent,
    getByGroupId,
}) => {
    const [loading, setLoading] = useState(false);
    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [preview, setPreview] = useState<any>();
    const selectCategory = groupTemplate;

    const disabledTemplate = (template: any) => {
        Swal.fire({
            title: `Inhabilitar ${template.alias} ?`,
            text: "Este template se no sera visible en los templates disponibles de ChatCRM",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Inhabilitar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                await disabledTemplates(template)
                    .then((res) => {
                        setLoading(false);
                        console.log(res);
                        Swal.fire(
                            "Template Inhabilitado en ChatCRM",
                            `El template ${template.alias} ha sido inhabilitado`,
                            "success"
                        );
                        getByGroupId(groupTemplate._id!);
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        Swal.fire(
                            "Error",
                            "Algo sucedio al inhabilitar el template",
                            "error"
                        );
                    });
            }
        });
    };

    const deleteTemplate = (template: any) => {
        Swal.fire({
            title: `Eliminar ${template.alias} ?`,
            text: "Este template se eliminara permanentemente",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Eliminar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                await deleteTemplates(template)
                    .then((res) => {
                        setLoading(false);
                        console.log(res);
                        Swal.fire(
                            "Template Eliminado!",
                            `El template ${template.alias} ha sido eliminado`,
                            "success"
                        );
                        getByGroupId(groupTemplate._id!);
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        Swal.fire(
                            "Error",
                            "Algo sucedio al eliminar el template",
                            "error"
                        );
                    });
            }
        });
    };

    useEffect(() => {
        if (groupTemplate._id) {
            getByGroupId(groupTemplate._id!);
        }
    }, [, groupTemplate]);

    return (
        <>
            {!loading ? (
                <>
                    <br />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <ModalCreateTemplate
                                    selectCategory={selectCategory}
                                    agentData={agent}
                                    getByGroupId={getByGroupId}
                                    groupTemplate={groupTemplate}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br />
                    <Card.Group>
                        {templates &&
                            templates.map((template, index) => {
                                return (
                                    <Card
                                        key={`card-template-${groupTemplate._id}-${index}`}
                                        className="template-item"
                                        color={
                                            template.deleted ? "red" : "green"
                                        }
                                    >
                                        <Card.Content
                                            style={{
                                                backgroundColor: "grey",
                                            }}
                                        >
                                            <Card.Header>
                                                <h3
                                                    style={{
                                                        color: "white",
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {template.alias}
                                                </h3>
                                                <List divided inverted relaxed>
                                                    <List.Item>
                                                        <List.Content>
                                                            {template.name}
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Content>
                                                            <List.Header>
                                                                ChatCRM:{" "}
                                                                {template.deleted ? (
                                                                    <Label color="red">
                                                                        Inhabilitado
                                                                    </Label>
                                                                ) : (
                                                                    <Label color="green">
                                                                        Habilitado
                                                                    </Label>
                                                                )}
                                                            </List.Header>
                                                        </List.Content>
                                                        <br />
                                                        <List.Content>
                                                            <List.Header>
                                                                Estatus:{" "}
                                                                {template.status ===
                                                                "PENDING" ? (
                                                                    <Label color="yellow">
                                                                        En
                                                                        revisión
                                                                    </Label>
                                                                ) : (
                                                                    <Label color="green">
                                                                        Aprovado
                                                                    </Label>
                                                                )}
                                                            </List.Header>
                                                        </List.Content>
                                                    </List.Item>
                                                </List>
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <div className="button-container">
                                                <Button
                                                    inverted
                                                    color="blue"
                                                    onClick={() => {
                                                        setOpenPreviewModal(
                                                            true
                                                        );
                                                        setPreview(template);
                                                    }}
                                                >
                                                    <Icon name="comment alternate" />
                                                    Preview
                                                </Button>
                                                {!template.deleted ? (
                                                    <Button
                                                        inverted
                                                        color="red"
                                                        onClick={() =>
                                                            disabledTemplate(
                                                                template
                                                            )
                                                        }
                                                    >
                                                        <Icon name="eye slash" />
                                                        Inhabilitar
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        inverted
                                                        color="green"
                                                    >
                                                        <Icon name="eye" />
                                                        Habilitar
                                                    </Button>
                                                )}
                                                <Button
                                                    color="red"
                                                    onClick={() =>
                                                        deleteTemplate(template)
                                                    }
                                                >
                                                    <Icon name="trash alternate" />
                                                    Eliminar
                                                </Button>
                                            </div>
                                            <PreviewTemplate
                                                setOpenPreviewModal={
                                                    setOpenPreviewModal
                                                }
                                                openPreviewModal={
                                                    openPreviewModal
                                                }
                                                preview={preview}
                                            />
                                        </Card.Content>
                                    </Card>
                                );
                            })}
                    </Card.Group>
                </>
            ) : (
                <Dimmer active inverted>
                    <Loader indeterminate content="Eliminando Template" />
                </Dimmer>
            )}
        </>
    );
};

interface IProps {
    groupTemplate: CategoryTemplates;
}

const mapStateToProps = (state: RootState, props: IProps) => {
    return {
        groupTemplate: props.groupTemplate,
        agent: state.adminAgents.agent,
        user: state.users.user,
        templates: state.templates.templates,
        loading: state.templates.loading,
    };
};

const mapActionsToProps = {
    getByGroupId,
};

const connector = connect(mapStateToProps, mapActionsToProps);
type IGroupTemplateEditProps = ConnectedProps<typeof connector>;
export default connector(Templates);
