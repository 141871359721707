import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Grid,
	Table,
	Button,
	Pagination,
	Header,
	Loader,
} from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { getAll, setPage } from "../../../actions/Biometricos";
import Filters from "./components/biometricos.filters";
import { useNavigate } from "react-router-dom";

const Expedientes: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	loading,
	errors,
	biometricos,
	page,
	total,
	getAll,
	setPage,
}) => {
	const navigate = useNavigate();
	const paginate = () => {
		return (
			<Pagination
				activePage={page}
				totalPages={Math.trunc(total / 15) + 1}
				onPageChange={(event, data) => {
					console.log("PAGE", data.activePage);
					setPage(Number(data.activePage));
				}}
			/>
		);
	};

	return (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header>
						<Header.Content>
							Expedientes
							{profile.id}
						</Header.Content>
					</Header>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Filters
						getAll={getAll}
						setPage={setPage}
						isAgent={isAgent}
						agent={agent}
						profile={profile}
						page={page}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
			{!loading ? (
				<Grid.Row columns={1}>
					<Grid.Column>
						<Table>
							<Table.Header>
								<Table.HeaderCell>ID</Table.HeaderCell>
								<Table.HeaderCell>
									ID Solicitud
								</Table.HeaderCell>
								<Table.HeaderCell>FOLIO</Table.HeaderCell>
								<Table.HeaderCell>
									FECHA AGENDADA
								</Table.HeaderCell>
								<Table.HeaderCell>ESTATUS</Table.HeaderCell>
								<Table.HeaderCell>ACCIONES</Table.HeaderCell>
							</Table.Header>
							<Table.Body>
								{biometricos?.map(
									(biometrico, index: number) => {
										return (
											<Table.Row
												key={`${index}-${biometrico.id}`}
												columns={1}
											>
												<Table.Cell>
													{biometrico.id}
												</Table.Cell>
												<Table.Cell>
													{biometrico.solicitudId}
												</Table.Cell>
												<Table.Cell>
													{
														biometrico.form.folio
															.value
													}
												</Table.Cell>
												<Table.Cell>
													{
														biometrico.form
															.fecha_llamada.value
													}
												</Table.Cell>
												<Table.Cell>
													{
														biometrico.form.status
															.value
													}
												</Table.Cell>

												<Table.Cell>
													<Button
														onClick={() =>
															navigate(
																`/biometrico/${biometrico.id}`
															)
														}
														color="blue"
													>
														Editar
													</Button>
												</Table.Cell>
											</Table.Row>
										);
									}
								)}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.Cell>{errors}</Table.Cell>
								</Table.Row>
							</Table.Footer>
						</Table>
					</Grid.Column>
				</Grid.Row>
			) : (
				<Grid.Row>
					<Loader active={loading}>Cargando...</Loader>
				</Grid.Row>
			)}
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		biometricos: state.biometricos.biometricos,
		solicitud: state.solicitudes.solicitud,
		page: state.biometricos.page,
		total: state.biometricos.counter,
		errors: state.biometricos.errors,
		loading: state.biometricos.loading,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getAll,
	setPage,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Expedientes);
