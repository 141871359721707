import React, { useState } from 'react'
import { Modal, Button, Icon, Image, Segment } from 'semantic-ui-react';

interface IProps {
    url: string;
    filename: string;
}

export const FileViewModal:React.FC<IProps> = ({
    url,
    filename
}) => {

    const [open, setOpen] = useState(false);

    const buildDocument = () => {

        const file_extension = filename.split('.').pop();

        switch(file_extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return <Image
                    size='big'
                    src={url}
                />
            case '':
                return <Segment textAlign='center'>
                    {filename}<br/> <h4 style={{ color: 'red' }}>No se ha cargado archivo</h4>
                </Segment>
            default:
                return <Segment textAlign='center'>
                    {filename}<br/> <h4 style={{ color: 'red' }}>Archivo sin vista previa</h4>
                </Segment>
        }
    }

    const download = async () => {
        const response = await fetch(url);
		const blob = await response.blob();
		const enlaceDescarga = document.createElement('a');
		enlaceDescarga.href = URL.createObjectURL(blob);
		enlaceDescarga.download = filename;
		enlaceDescarga.style.display = 'none';
		document.body.appendChild(enlaceDescarga);
		enlaceDescarga.click();
		document.body.removeChild(enlaceDescarga);
	};

    return (
        <Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			size="large"
			dimmer={'blurring'}
			style={{
				left: 'auto',
				right: 'auto',
				top: 'auto',
				bottom: 'auto',
				height: 'fit-content',
				width: 'fit-content'
			}}
            trigger={
                <Button
                    color="blue"
                    onClick={() => setOpen(true)}
                >
                    <Icon name='eye'/>Ver
                </Button>
            }
		>
            <Modal.Content>
                {buildDocument()}
            </Modal.Content>
            <Modal.Actions >
				<Button color='black' onClick={() => setOpen(false)}>
					Cerrar
				</Button>
                <Button disabled={url === ''} color='blue' onClick={download}>
					Download
				</Button>
			</Modal.Actions>
        </Modal>
    );
}