import React from "react";
import { IAgentModel } from "../../../../models/Agent.model";
import AgentComponent from "./agent.component";
import { Grid } from "semantic-ui-react";

interface IProps {
	agents: IAgentModel[];
}

const AgentsBox: React.FC<IProps> = ({ agents }) => {
	return (
		<Grid>
			<Grid.Row>
				{agents?.map((agent) => {
					return (
						<Grid.Column
							key={`agent-box-${agent.id}`}
							computer={4}
							mobile={16}
						>
							<AgentComponent key={agent.id} agent={agent} />
						</Grid.Column>
					);
				})}
			</Grid.Row>
		</Grid>
	);
};

export default AgentsBox;
