import React, { useState, useEffect } from "react";
import {
	Accordion,
	Button,
	Dropdown,
	Grid,
	Icon,
	Label,
} from "semantic-ui-react";
import IBiometrico from "../../../../models/Biometrico.model";
import FieldInput from "../../expedientes/components/field.input";
import FileInput from "../../expedientes/components/file.input";
import { IDocumentoExpediente } from "../../../../models/utils.model";
import { update, uploadFile } from "../../../../actions/Biometricos";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface IProps {
	biometrico: IBiometrico;
	isAgent: boolean;
	loading: boolean;
}

interface IState {
	active: boolean;
	biometrico: IBiometrico;
}

export const BiometricoComponent: React.FC<IProps> = ({
	biometrico,
	isAgent,
	loading,
}) => {
	const navigate = useNavigate();

	useEffect(() => {
		console.log("SOLICITUD", biometrico);
	}, []);

	const dispatch = useDispatch();
	const [state, setState] = useState<IState>({
		active: true,
		biometrico: biometrico,
	});

	const save = async () => {
		dispatch(update(biometrico.id!, state.biometrico));
	};

	const onFileChange = async (name: string, file: any) => {
		dispatch(uploadFile(biometrico.id!, name, file));
	};

	const onFileStatusChange = async (name: string, isValid: boolean) => {
		let documents_state: any = Object.assign({}, biometrico.documents);

		documents_state[name] = {
			...documents_state[name],
			isValid: isValid,
		};

		dispatch(
			update(biometrico.id!, {
				...biometrico,
				documents: documents_state,
			})
		);
	};

	const getDocumentKeys = (documents: any) => {
		return Object.keys(documents).map((key) => {
			return {
				title: key.replaceAll("_", " "),
				name: key,
			};
		});
	};

	const getFormKeys = (form: any) => {
		return Object.keys(form).map((key) => {
			return {
				key: key,
				title: key.replaceAll("_", " "),
				value: form[key].value,
				type: form[key].type,
				error_message: form[key].error_message,
				rule: form[key].rule,
				isValid: form[key].isValid,
				options: form[key].options,
			};
		});
	};

	const validateFormFields = async (
		field: string,
		value: any,
		index?: number
	) => {
		let form_state: any = Object.assign({}, state.biometrico.form);

		form_state[field] = {
			...form_state[field],
			value: value,
			isValid: new RegExp(form_state[field].rule).test(value),
		};

		setState({
			...state,
			biometrico: {
				...state.biometrico,
				form: form_state,
			},
		});
	};

	const getOptions = () => {
		let options = [
			{ key: "NUEVO", text: "NUEVO", value: "NUEVO" },
			{ key: "EN REVISION", text: "EN REVISION", value: "EN REVISION" },
		];

		if (isAgent) {
			return options;
		}
		options = state?.biometrico?.form.status.options.map(
			(option: string) => {
				return {
					key: option,
					text: option,
					value: option,
				};
			}
		);

		return options;
	};

	return (
		<Accordion fluid styled style={{ margin: "10px" }}>
			<Accordion.Title
				active={state.active}
				onClick={() => {
					setState({ ...state, active: !state.active });
				}}
			>
				<Icon name="dropdown" />
				Biométrico
			</Accordion.Title>
			<Accordion.Content active={state.active}>
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>
							<Label color="blue">DATOS DE SOLICITUD</Label>
							<Grid.Row columns={2}>
								{state.biometrico?.form
									? getFormKeys(state.biometrico.form).map(
											(
												form_field: any,
												index: number
											) => {
												if (
													form_field.key ===
														"observaciones" ||
													form_field.key === "status"
												) {
													return;
												}
												return (
													<FieldInput
														key={`form-feld-input-solicitud-${form_field.key}-${index}`}
														index={index}
														disabled={
															isAgent &&
															form_field.key ===
																"folio"
														}
														field={form_field.key}
														type={form_field.type}
														value={form_field.value}
														isValid={
															form_field.isValid
														}
														options={
															form_field.options
														}
														error_message={
															form_field.error_message
														}
														title={form_field.title}
														computer={8}
														mobile={16}
														validateFields={
															validateFormFields
														}
													/>
												);
											}
									  )
									: null}
							</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid>
								<Label color="blue">ESTATUS</Label>
								<Grid.Row>
									<Grid.Column>
										<Dropdown
											floating
											fluid
											selection
											value={
												state?.biometrico.form?.status
													.value!
											}
											onChange={(e, data) => {
												validateFormFields(
													"status",
													data.value
												);
											}}
											options={getOptions()}
											placeholder={"Estatus"}
										/>
									</Grid.Column>
								</Grid.Row>
								<Label color="blue">DOCUMENTOS</Label>
								<Grid.Row columns={1}>
									{state.biometrico?.documents
										? getDocumentKeys(
												state.biometrico.documents
										  ).map(
												(
													document: any,
													index: number
												) => {
													console.log(
														"KEY",
														document
													);
													const documento: IDocumentoExpediente =
														state.biometrico
															.documents[
															document.name as keyof typeof state.biometrico.documents
														];
													return (
														<FileInput
															key={`file-input-solicitud-${index}`}
															title={document.title.toUpperCase()}
															name={document.name}
															computer={8}
															mobile={16}
															disabled={
																isAgent &&
																document.name ===
																	"solicitud_foliada"
															}
															documento={
																documento
															}
															onFileChange={
																onFileChange
															}
															onFileStatusChange={
																onFileStatusChange
															}
														/>
													);
												}
										  )
										: null}
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Button
								content={"Guardar"}
								onClick={save}
								positive
								disabled={loading}
								loading={loading}
								floated="right"
							/>
							<Button
								content={"Cerrar venta"}
								onClick={() =>
									navigate(`/ventas/${biometrico.id}`)
								}
								color="blue"
								disabled={loading}
								loading={loading}
								floated="right"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
};
