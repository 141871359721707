import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";

export const PromptAgent = () => {
    const [openPrompt, setOpenPrompt] = useState(true);
    const navigate = useNavigate();

    return (
        <Modal
            open={openPrompt}
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
                justifyContent: "center",
            }}
            size="mini"
            dimmer={"blurring"}
        >
            <Modal.Header>
                <p>
                    <b>Otro Agente estaba atendiendo a este Cliente</b>
                </p>
            </Modal.Header>
            <Modal.Content>
                <p>Revisa los templates de Calidad</p>
                <p>¿Deseas retomar esta conversación?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => navigate(-1)}>
                    No
                </Button>
                <Button positive onClick={() => setOpenPrompt(false)}>
                    Si
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
