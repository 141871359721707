import {
	SET_USERS,
	SET_USER_BY_ID,
	LOADING_ADMIN_USER,
	LOADING_ADMIN_USERS,
	SET_LOADING_ERRORS_ADMIN_USER,
	CLEAR_LOADING_ERRORS_ADMIN_USER,
	LOADING_UPDATE_ADMIN_USER,
	SET_COUNTER_ADMIN_USER,
	SET_PAGE_ADMIN_USER,
	SET_FOLLOWMENT_USERS,
	SET_FOLLOWMENT_PAGE_ADMIN_USER,
	SET_FOLLOWMENT_COUNTER_ADMIN_USER,
	SET_ACTIONS_OPEN,
	LOADING_FOLLOWMENT_ADMIN_USER,
	SET_USERS_CONVERSATION,
	SET_FOLLOWMENT_USERS_CONVERSATION,
	SET_USER_FILTERS,
} from "../constants/user-admin.constants";
import axios from "axios";
import { IUserModel, response_total_Users } from "../models/User.model";
import { apiUrl } from "../constants/enviroment";
import User_Api from "../api/user.api";
import { Total_users_params } from "../models/api.models";
import IRegistro from "../models/registros.leads.model";
import ILeadsResponse from "../models/ILeadsResponse.model";

export const getAll =
	(page: number, limit: number, filters: any, order: any) =>
	async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: LOADING_ADMIN_USERS });
		try {
			const body = {
				page: page,
				limit: limit,
				filters: filters,
				order: order,
			};
			const response = await axios.post(`${apiUrl}/lead/all`, body);
			if (response.status !== 200) {
				console.warn(response.data.message);
				dispatch(setErrors(response.data.message));
				return loaded;
			}
			const leads: ILeadsResponse[] = response.data.leads;

			console.log("LEADS", response.data);

			dispatch(setPage(response.data.page));
			dispatch(setCounter(response.data.count));
			dispatch(setUsersConversationData(leads));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar usuarios", e);
			dispatch(setErrors("Error al cargar usuarios"));
		}
		return loaded;
	};

export const getAllByFollowment =
	(page: number, limit: number, filters: any, order: any) =>
	async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: LOADING_FOLLOWMENT_ADMIN_USER });
		try {
			const body = {
				page: page,
				limit: limit,
				filters: filters,
				order: order,
			};
			const response = await axios.post(`${apiUrl}/lead/all`, body);
			if (response.status !== 200) {
				console.warn(response.data.message);
				dispatch(setErrors(response.data.message));
				return loaded;
			}
			const leads: ILeadsResponse[] = response.data.leads;
			dispatch(setFollowmentPage(response.data.page));
			dispatch(setFollowmentCounter(response.data.count));
			dispatch(setFollowmentUsersConversationData(leads));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar usuarios", e);
			dispatch(setErrors("Error al cargar usuarios"));
		}
		return loaded;
	};

export const getById =
	(userId: string, agentId: string) => async (dispatch: any) => {
		await dispatch({ type: LOADING_ADMIN_USER });
		await axios
			.get(`${apiUrl}/lead/${agentId}/${userId}`)
			.then((res) => {
				if (res.status !== 200) {
					dispatch({
						type: SET_LOADING_ERRORS_ADMIN_USER,
						payload: "No se ha podido cargar el usuario",
					});
					return;
				}
				let userResponse = res.data.lead;
				dispatch(setUserData(userResponse));
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: SET_LOADING_ERRORS_ADMIN_USER,
					payload: "Error al obtener usuarios",
				});
			});
	};

export const getByPhone =
	(agentId: string, userPhone: any) => async (dispatch: any) => {
		await dispatch({ type: LOADING_ADMIN_USER });
		await axios
			.get(`${apiUrl}/lead/leadByPhone/${agentId}/${userPhone}`)
			.then((res) => {
				if (res.status !== 200) {
					dispatch({
						type: SET_LOADING_ERRORS_ADMIN_USER,
						payload: "No se ha podido cargar el usuario",
					});
					return;
				}
				let userResponse = res.data.lead;
				dispatch(setUserData(userResponse));
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: SET_LOADING_ERRORS_ADMIN_USER,
					payload: "Error al obtener usuarios",
				});
			});
	};

export const getUsers = () => async (dispatch: any) => {
	await dispatch({ type: LOADING_ADMIN_USERS });
	await axios
		.get(`${apiUrl}/user/getUsers`)
		.then((res) => {
			if (res.status !== 200) {
				console.error(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			const usersList = res.data.users;
			dispatch(setUsersData(usersList));
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: SET_LOADING_ERRORS_ADMIN_USER,
				payload: "Error al cargar usuarios",
			});
		});
};

export const getFilteredUsers =
	(page: number, allUsers?: boolean) => async (dispatch: any) => {
		let loaded = false;
		try {
			await dispatch({ type: LOADING_ADMIN_USERS });
			const response = await axios.get(
				`${apiUrl}/user/getTotalUsers/${page}/${allUsers ?? false}`
			);
			if (response.status !== 200) {
			}
			dispatch(setUsersData(response.data.users));
			loaded = true;
		} catch (err) {
			console.error(err);
		}
		return loaded;
	};

export const getTotalUsers = (params: Total_users_params) => {
	return new Promise<response_total_Users>(async (resolve, reject) => {
		switch (params.isFilter) {
			case false:
				try {
					await User_Api.getTotalUsers(
						params.page!,
						params.allUsers!
					).then((users) => resolve(users));
				} catch (err) {
					reject(err);
				}
				break;
			case true:
				try {
					await User_Api.getTotalFilteredUsers(
						params.filter!,
						params.isDateFilter!,
						params.isRangeFilter!,
						params.Date,
						params.secondary_filter!,
						params.page!,
						params.allUsers!,
						params.agentFilter!,
						params.macroType!
					).then((users) => resolve(users));
				} catch (err) {
					console.error(err);
					reject(err);
				}
				break;
		}
	});
};

export const getUsersByAgent = (agentId: string) => async (dispatch: any) => {
	await dispatch({ type: LOADING_ADMIN_USERS });
	axios
		.get(`${apiUrl}/agent/getUsers/${agentId}`)
		.then((res) => {
			let users = res.data.users;
			if (res.status !== 202) {
				console.warn(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			dispatch(setUsersData(users));
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: SET_LOADING_ERRORS_ADMIN_USER,
				payload: err.response.data.errors
					? err.response.data.errors[0].message
					: "error al cargar usuarios",
			});
		});
};

export const updateUltimoSeguimiento =
	(userId: string, agentId: string, ultimoSeguimiento: string) =>
	async (dispatch: any) => {
		let updated = false;
		try {
			dispatch({ type: LOADING_ADMIN_USER });

			const response = await axios.put(
				`${apiUrl}/lead/ultimoSeguimiento/${userId}`,
				{
					agentId: agentId,
					ultimoSeguimiento: ultimoSeguimiento,
				}
			);

			if (response.status !== 200) {
				console.warn(response.data.message);
				dispatch(setErrors(response.data.message));
			}

			dispatch(
				setUserData({
					wa_id: userId,
					agent: agentId,
					ultimoSeguimiento: ultimoSeguimiento,
					name: "",
					lastName: "",
					phone: userId,
					pageId: "",
					agentStatus: "",
				})
			);
			updated = true;
		} catch (error) {
			console.error("Error al actualizar último seguimiento", error);
			dispatch(setErrors("Error al actualizar último seguimiento"));
		}
		return updated;
	};

export const create = (user: IUserModel) => async (dispatch: any) => {
	let created = false;
	dispatch({ type: LOADING_UPDATE_ADMIN_USER });

	const body = {
		wa_id: `${user.wa_id ?? user.phone}`,
		agentId: user.agent,
		name: user.name,
		lastName: user.lastName,
		phoneList: [],
		phone: user.phone,
		mobile: user.mobile,
		email: user.email,
		curp: user.CURP,
		nss: user.NSS,
		pageId: user.pageId,
		creditosCompra: [],
		creditosRenovacion: [],
		capacidadCredito: 0.0,
		validationURL: "",
		isMacro: false,
		type_macro: "",
		status: "Assigned",
		isMultiva: false,
		isRenovation: false,
		responseMessage: true,
		title: "CLIENTE REGISTRADO CON ÉXITO",
		subtitle: "Los datos del cliente se han registrado correctamente",
		type: "OWN_AGENT",
		bot_id: user.pageId,
		toTop: false,
		isFijo: user.isFijo,
		salario: user.salario,
		isNL: user.isNL,
	};

	await axios
		.post(`${apiUrl}/lead`, body)
		.then((res) => {
			if (res.status !== 200) {
				console.warn("LEAD CANT BE CREATED", res.data.message);
				dispatch(setErrors(res.data.message));
			}

			const lead = res.data.lead;
			dispatch(setUserData(lead));
			created = true;
		})
		.catch((err) => {
			console.error(err);
			dispatch({
				type: SET_LOADING_ERRORS_ADMIN_USER,
				payload: err.response.data.message,
			});
			created = false;
		});
	return created;
};

export const update = (user: IUserModel) => async (dispatch: any) => {
	let edited = false;
	dispatch({ type: LOADING_UPDATE_ADMIN_USER });

	await axios
		.put(`${apiUrl}/lead/${user.Fid ?? user.wa_id}`, user)
		.then((res) => {
			if (res.status !== 200) {
				console.warn("error al editar usuario");
				return edited;
			}
			let userResponse = res.data.lead;
			dispatch(setUserData(userResponse));
			edited = true;
		})
		.catch((err) => {
			console.error(err.response.data.message);
			dispatch({
				type: SET_LOADING_ERRORS_ADMIN_USER,
				payload: err.response.data.message,
			});
			edited = false;
		});

	return edited;
};

export const deleteUser =
	(user: IUserModel, adminPassword: string) => async (dispatch: any) => {
		let deleted = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_USER });

		const userRequest = {
			Fid: user.Fid,
			adminPassword: adminPassword,
		};

		await axios
			.post(`${apiUrl}/api/v1/admin/deleteUser`, userRequest)
			.then((res) => {
				dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_USER });
				deleted = true;
			})
			.catch((err) => {
				console.error(err.response.data.message);
				dispatch({
					type: SET_LOADING_ERRORS_ADMIN_USER,
					payload: err.response.data.message,
				});
				deleted = false;
			});
		return deleted;
	};

export const fillUsers =
	(agentId: string, amount: number = 5, filters: any) =>
	async (dispatch: any) => {
		let filled = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_USER });

		await axios
			.post(`${apiUrl}/lead/fill/${agentId}`, {
				amount,
				filters,
			})
			.then(async (res) => {
				if (res.status !== 200) {
					console.warn("LEAD CANT BE FILLED", res.data.message);
					dispatch(setErrors(res.data.message));
					return filled;
				}
				await dispatch(getUsersByAgent(agentId));
				filled = true;
			})
			.catch((err) => {
				console.error("ERROR AT FILL LEADS", err.response.data.message);
				dispatch(setErrors(err.response.data.message));
				filled = false;
			});
		return filled;
	};

export const fillListUsers =
	(agentId: string, registros: IRegistro[]) => async (dispatch: any) => {
		let filled = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_USER });

		const body = {
			registros: registros,
		};

		await axios
			.post(`${apiUrl}/lead/fill/list/${agentId}`, body)
			.then(async (res) => {
				if (res.status !== 200) {
					console.warn("LEAD CANT BE FILLED", res.data.message);
					dispatch(setErrors(res.data.message));
					return filled;
				}
				dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_USER });
				filled = true;
			})
			.catch((err) => {
				console.error("ERROR AT FILL LEADS", err.response.data.message);
				dispatch(setErrors(err.response.data.message));
				filled = false;
			});
		return filled;
	};

export const setFilters = (filters: any) => (dispatch: any) => {
	dispatch({
		type: SET_USER_FILTERS,
		payload: filters,
	});
};

export const setUsersConversationData =
	(users: ILeadsResponse[]) => (dispatch: any) => {
		dispatch({
			type: SET_USERS_CONVERSATION,
			payload: users,
		});
	};

export const setUsersData = (users: IUserModel[]) => (dispatch: any) => {
	dispatch({
		type: SET_USERS,
		payload: users,
	});
};

export const setUserData = (user: IUserModel) => (dispatch: any) => {
	dispatch({
		type: SET_USER_BY_ID,
		payload: user,
	});
};

export const setErrors = (errors: string) => (dispatch: any) => {
	dispatch({
		type: SET_LOADING_ERRORS_ADMIN_USER,
		payload: errors,
	});
};

export const setCounter = (counter: number) => (dispatch: any) => {
	dispatch({
		type: SET_COUNTER_ADMIN_USER,
		payload: counter,
	});
};

export const setPage = (page: number) => (dispatch: any) => {
	dispatch({
		type: SET_PAGE_ADMIN_USER,
		payload: page,
	});
};

export const setFollowmentUsersConversationData =
	(users: ILeadsResponse[]) => (dispatch: any) => {
		dispatch({
			type: SET_FOLLOWMENT_USERS_CONVERSATION,
			payload: users,
		});
	};

export const setFollowmentUsersData =
	(users: IUserModel[]) => (dispatch: any) => {
		dispatch({
			type: SET_FOLLOWMENT_USERS,
			payload: users,
		});
	};

export const setFollowmentCounter = (counter: number) => (dispatch: any) => {
	dispatch({
		type: SET_FOLLOWMENT_COUNTER_ADMIN_USER,
		payload: counter,
	});
};

export const setFollowmentPage = (page: number) => (dispatch: any) => {
	dispatch({
		type: SET_FOLLOWMENT_PAGE_ADMIN_USER,
		payload: page,
	});
};

export const setActionsOpen = (isOpen: boolean) => (dispatch: any) => {
	dispatch({
		type: SET_ACTIONS_OPEN,
		payload: isOpen,
	});
};
