import React from "react";
import { Grid, Input, Button, SemanticWIDTHS } from "semantic-ui-react";
import { FileViewModal } from "./file.view";
import "../../../../css/expedientes.css";
interface IProps {
	name: string;
	title: string;
	documento: any;
	computer?: SemanticWIDTHS;
	mobile?: SemanticWIDTHS;
	disabled?: boolean;
	onFileChange: (name: string, file: any) => void;
	onFileStatusChange: (name: string, isValid: boolean) => void;
}

const FileInput: React.FC<IProps> = ({
	name,
	title,
	documento,
	computer,
	mobile,
	disabled,
	onFileChange,
	onFileStatusChange,
}) => {
	return (
		<Grid.Column className="file-input-container">
			<Input
				id={`fi-${name}`}
				type="file"
				onChange={(e) => {
					const file = e.currentTarget.files![0];

					console.log("FLAG FILE", file);

					onFileChange(name, e.currentTarget.files![0]);
				}}
				accept={documento.extensions.join(",")}
				name={name}
				style={{ display: "none" }}
			/>
			<Input placeholder="Cargar..." disabled value={documento?.name} />
			<Button.Group>
				<Button
					className="label-file-input"
					color="green"
					onClick={() => {
						document.getElementById(`fi-${name}`)!.click();
					}}
					disabled={disabled}
				>
					{title}
				</Button>
				<FileViewModal filename={documento.name} url={documento.url} />
				<Button
					color={documento.isValid ? "green" : "red"}
					onClick={(e) =>
						onFileStatusChange(name ?? "", !documento.isValid)
					}
				>
					{documento.isValid ? "Correcto" : "Observaciones"}
				</Button>
			</Button.Group>
		</Grid.Column>
	);
};

export default FileInput;
