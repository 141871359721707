import React, { useState, useEffect } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
	Button,
	Header,
	Modal,
	Input,
	Grid,
	Label,
	Segment,
	Dropdown,
} from "semantic-ui-react";
import { IUserModel } from "../../../models/User.model";
import { RootState } from "../../../store/store";
import { create, update, getById } from "../../../actions/user-admin.actions";
import { useNavigate, useParams } from "react-router-dom";
import { DELEGACIONES } from "../../../constants/validaciones/delegaciones.constants";
import CAMPAIGNS from "../../../constants/dashboard/campaigns.constants";
import { getType } from "./helpers/leads.utils";
export interface IState {
	isNew: boolean;
	isJubilado: boolean;
	name: string;
	nameValido: boolean;
	lastName: string;
	phone: string;
	phoneValido: boolean;
	email: string;
	curp: string;
	curpValido: boolean;
	nss: string;
	nssValido: boolean;
	matricula: string;
	matriculaValido: boolean;
	delegacion: string;
	delegacionValido: boolean;
	images?: any[];
	salario: number;
	tipoLead: string;
}

const EditCustomer: React.FC<IEditUserProps> = ({
	user,
	agent,
	customer,
	errors,
	loading,
	isAdmin,
	isCoordinator,
	isAgent,
	getById,
	create,
	update,
}) => {
	const { id } = useParams();

	const initialState = {
		isNew: !id ? true : false,
		isJubilado: id && customer ? customer.isJubilado ?? false : false,
		name: id && customer ? customer.name ?? "" : "",
		nameValido: true,
		lastName: id && customer ? customer.lastName ?? "" : "",
		phone: id && customer ? customer.phone ?? "" : "",
		phoneValido: true,
		email: id && customer ? customer.email ?? "" : "",
		emailValido: true,
		curp: id && customer ? customer.CURP ?? "" : "",
		curpValido: true,
		nss: id && customer ? customer.NSS ?? "" : "",
		nssValido: true,
		matricula: id && customer ? customer.matricula ?? "" : "",
		matriculaValido: true,
		delegacion: id && customer ? customer.NSS ?? "" : "",
		delegacionValido: true,
		salario: id && customer ? customer.salario ?? 0.0 : 0.0,
		tipoLead: "Tradicional",
	};

	const [state, setState] = useState<IState>(initialState);
	const [open, setOpen] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		if ((isAdmin || isCoordinator) && id) {
			getById(id, agent.id!);
		} else {
			if (id) {
				getById(id, user.id);
			}
		}
	}, [, id]);

	useEffect(() => {
		setState(initialState);
	}, [open, customer]);

	const saveUser = async () => {
		const { name, lastName, phone, email, curp, nss, tipoLead } = state;
		let { nameValido, phoneValido, curpValido, nssValido } = state;

		nameValido = name !== "";
		phoneValido = phone !== "" && phone.length >= 10;
		curpValido = curp !== "" && curp.length === 18;
		nssValido = nss !== "" && nss.length === 11;

		if (nameValido && phoneValido) {
			let customerU: IUserModel = {
				wa_id: isAgent ? `own-${phone}` : `assigned-${phone}`,
				name: name.trim(),
				lastName: lastName.trim(),
				NSS: nss,
				CURP: curp,
				phone: phone,
				email: email,
				mobile: phone,
				agent: isAdmin || isCoordinator ? agent.id! : user.id,
				status: id ? customer.status : "Assigned",
				agentStatus: id ? customer.agentStatus : "NUEVO",
				pageId: agent.bot_id!,
				matricula: state.matricula,
				delegacion: state.delegacion,
				isFijo: false,
				salario: state.salario,
				isTradicional: false,
				isRenovation: false,
				isCompra: false,
				isJubilado: false,
				isUNAM: false,
				isNL: false,
				isGEM: false,
				...getType(tipoLead ?? "Tradicional"),
			};
			if (state.isNew) {
				let created = await create(customerU);
				if (created) {
					onClose();
				}
			} else {
				customerU.wa_id = id;
				customerU.Fid = id;
				if (await update(customerU)) {
					onClose();
				}
			}
		}
		setState({
			...state,
			nameValido,
			phoneValido,
			curpValido,
			nssValido,
		});
	};

	const onClose = () => {
		navigate(-1);
		setOpen(false);
	};

	return (
		<Modal
			onClose={onClose}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={
				!customer ? (
					<Button
						color="green"
						style={{
							float: "right",
							marginBottom: "20px",
						}}
					>
						Crear
					</Button>
				) : (
					<Button
						color="blue"
						onClick={() => {
							setOpen(true);
						}}
					>
						Editar
					</Button>
				)
			}
			size="large"
			style={{
				left: "auto",
				right: "auto",
				top: "20%",
				height: "fit-content",
			}}
		>
			<Modal.Header>
				{state.isNew ? "Registrar usuario" : "Editar usuario"}
				{!state.isNew ? (
					<Header style={{ margin: "5px" }}>ID: {id}</Header>
				) : (
					""
				)}
			</Modal.Header>
			<Modal.Content>
				<Grid divided="vertically" centered>
					<h1>Datos</h1>
					<Grid.Row>
						<Grid.Column>
							<Segment compact floated="right">
								<Dropdown
									value={state.tipoLead}
									placeholder="Tipo de lead"
									search
									options={CAMPAIGNS}
									onChange={(e, data) => {
										setState({
											...state,
											tipoLead: data.value?.toString()!,
										});
									}}
								/>
							</Segment>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={2} stretched>
						<Grid.Column computer={8} mobile={16}>
							<Input
								label="Nombre"
								value={state.name}
								error={!state.nameValido}
								onChange={(e) => {
									setState({
										...state,
										name: e.currentTarget.value,
										nameValido:
											e.currentTarget.value.trim() !== "",
									});
								}}
								style={{ margin: "5px" }}
							/>
							{!state.nameValido ? (
								<Label color="red">
									Nombre no puede estar vacío
								</Label>
							) : null}
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="Apellido"
								value={state.lastName}
								onChange={(e) => {
									setState({
										...state,
										lastName: e.currentTarget.value,
									});
								}}
								style={{ margin: "5px" }}
							/>
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="Whatsapp"
								value={state.phone}
								error={!state.phoneValido}
								type="number"
								onChange={(e) => {
									setState({
										...state,
										phone: e.currentTarget.value.trim(),
										phoneValido:
											e.currentTarget.value.trim() !==
												"" &&
											e.currentTarget.value.length == 10,
									});
								}}
								style={{ margin: "5px" }}
							/>
							{!state.phoneValido ? (
								<Label color="red">
									Teléfono debe ser igual a 10 dígitos
								</Label>
							) : null}
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="Correo"
								value={state.email}
								onChange={(e) => {
									setState({
										...state,
										email: e.currentTarget.value.trim(),
									});
								}}
								style={{ margin: "5px" }}
							/>
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="CURP"
								value={state.curp}
								error={!state.curpValido}
								onChange={(e) => {
									setState({
										...state,
										curp: e.currentTarget.value
											.toUpperCase()
											.trim(),
										curpValido:
											e.currentTarget.value.trim() !==
												"" &&
											e.currentTarget.value.length === 18,
									});
								}}
								style={{ margin: "5px" }}
							/>
							{!state.curpValido ? (
								<Label color="red">
									CURP debe contener 18 caracteres
								</Label>
							) : null}
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="Salario"
								value={state.salario}
								min={0}
								step={0.1}
								defaultValue={0}
								type="number"
								onChange={(e) => {
									setState({
										...state,
										salario: Number(e.currentTarget.value),
									});
								}}
								style={{ margin: "5px" }}
							/>
							{!state.curpValido ? (
								<Label color="red">
									CURP debe contener 18 caracteres
								</Label>
							) : null}
						</Grid.Column>
						<Grid.Column computer={8} mobile={16} stretched>
							<Input
								label="NSS"
								value={state.nss}
								error={!state.nssValido}
								type="number"
								onChange={(e) => {
									setState({
										...state,
										nss: e.currentTarget.value.trim(),
										nssValido:
											e.currentTarget.value.trim() !==
												"" &&
											e.currentTarget.value.length === 11,
									});
								}}
								style={{ margin: "5px" }}
							/>
							{!state.nssValido ? (
								<Label color="red">
									NSS debe contener 11 dígitos
								</Label>
							) : null}
						</Grid.Column>
						{state.isJubilado ? (
							<Grid.Column computer={8} mobile={16}>
								<Input
									label="Matrícula"
									type="number"
									className="validation_field"
									value={state.matricula}
									error={!state.matriculaValido}
									onChange={(e) => {
										setState({
											...state,
											matricula:
												e.currentTarget.value.trim(),
											matriculaValido:
												e.currentTarget.value.trim() !==
												"",
										});
									}}
								/>
								{!state.matriculaValido ? (
									<Label color="red">
										Matrícula no debe estar vacío
									</Label>
								) : null}
							</Grid.Column>
						) : null}
						{state.isJubilado ? (
							<Grid.Column computer={8} mobile={16}>
								<Dropdown
									value={state.delegacion}
									placeholder="Delegación"
									search
									options={DELEGACIONES.map(
										(delegacion: string, index: number) => {
											return {
												key: `delegacion-${index}-${delegacion}`,
												text: delegacion,
												value: delegacion,
											};
										}
									)}
									onChange={(event: any, data: any) => {
										setState({
											...state,
											delegacion: data.value,
											delegacionValido:
												data.value !== null,
										});
									}}
								/>
								{!state.delegacionValido ? (
									<Label color="red">
										Seleccione una opción
									</Label>
								) : null}
							</Grid.Column>
						) : null}
					</Grid.Row>
					{errors ? (
						<Grid.Row>
							<Grid.Column>
								<Label
									attached="bottom"
									color="red"
									style={{ textAlign: "center" }}
								>
									{errors.toUpperCase()}
								</Label>
							</Grid.Column>
						</Grid.Row>
					) : null}
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={onClose}>
					Cerrar
				</Button>
				<Button
					content={state.isNew ? "Registrar" : "Editar"}
					icon="checkmark"
					onClick={saveUser}
					positive
					disabled={loading}
					loading={loading}
				/>
			</Modal.Actions>
		</Modal>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		user: state.users.user,
		agent: state.adminAgents.agent,
		customer: state.adminUsers.user,
		errors: state.adminUsers.errors,
		loading: state.adminUsers.loadingUpdate,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	create,
	update,
	getById,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IEditUserProps = ConnectedProps<typeof connector>;

export default connector(EditCustomer);
