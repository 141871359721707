import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { RootState } from '../../../../store/store';
import { getConversations, getConversationsByAgentId, setConversationData } from '../../../../actions/conversations.actions';
import useChat from '../../../../context/chatContext';

const ConversationList: React.FC<IConversationListProps> = ({
    conversationList,
    user,
    authenticated,
    getConversations,
    getConversationsByAgentId,
    setConversationData
}) => {

    let useWebhook = useChat(user.id);

    const NEW_CHAT_MESSAGE_EVENT = "NEW_MESSAGE_CRM";

    useEffect(() => {
        loadConversations();
    }, []);

    useEffect(() => {
    }, [conversationList]);

    const loadConversations = async () => {
        if(authenticated) {
            if(user.type === "IS_AGENT") {
                await getConversationsByAgentId(user.id);
            } else {
                await getConversations();
            }
        }
    }

    useEffect(() => {
        useWebhook.socketRef.on(NEW_CHAT_MESSAGE_EVENT, (message: any) => {
            console.log("LLEGO_MENSAJE_CONVERSATION");
            loadConversations();
        });
    }, [useWebhook.socketRef])

    const getTextMessage = (type: string, msg: any) => {
        if(Array.isArray(msg)) { 
            msg = msg[0];
        }
        let textMessage:string = "";
        if (type === "image") {
            textMessage = msg.image.link;
        } else if(type === "text") {
            let jsonMessage = JSON.parse(msg);
            if(jsonMessage.type === "button"){
                textMessage = jsonMessage.button.text;
            } else if(jsonMessage.type === "text") {
                textMessage = jsonMessage.text.body;
            }
        } else if(type === "payload") {
            if(msg.type === "BUTTONS") {
                if(Array.isArray(msg.buttons)) {
                    textMessage = msg.buttons[msg.buttons.length - 1].text;                    
                } else {
                    textMessage = msg.buttons.text;                    
                }
                // textMessage = msg.button.text;
            } else if(msg.type === "BODY") {
                textMessage = msg.text;
            } else if(msg.type === "HEADER") {
                textMessage = "MENÚ";
            } else {
                let jsonMessage = JSON.parse(msg);
                if(jsonMessage.type === "text") {
                    textMessage = jsonMessage.text.body;
                } else if(jsonMessage.type === "image") {
                    textMessage = jsonMessage.image.link;
                }
            }
        }
        return textMessage;
    }

    const formatNumber = (num: number) => {
        let result = `${num}`;
        if(num < 10) {
            result = `0${num}`
        }
        return result;
    }

    const formatDate = (date: string) => {
        let formattedDate = "";
        let today = new Date();
        let toDate = new Date(date);
        formattedDate = `${formatNumber(toDate.getDay())}/${formatNumber(toDate.getMonth())}/${formatNumber(toDate.getFullYear())}`

        if(toDate.getFullYear() >= today.getFullYear()) {
            if(toDate.getMonth() >= today.getMonth()) {
                if(today.getDay() === toDate.getDay()) {
                    formattedDate = `${formatNumber(toDate.getHours())}:${formatNumber(toDate.getMinutes())}`
                } 
            }
        }
        return formattedDate;
    }

    return(
        <div className='conversations-list-container'>
            {conversationList? conversationList.map((conversation) => {
                return ( 
                    <div 
                        id={`cl-${conversation.id}`}
                        onClick={() => {setConversationData(conversation); localStorage.setItem('conversationId', conversation.id)}}
                        key={`cv-${conversation.id}`}
                    >
                        <Grid className='pointer conversation-container'>
                            <Grid.Row>
                                <Grid.Column computer={12}
                                    className="conversation-container-title"
                                >
                                    {conversation.userId}
                                </Grid.Column>
                                <Grid.Column computer={4}>
                                    {formatDate(conversation.updatedAt)}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Row>
                                    <Grid.Column
                                        className='conversation-container-text'
                                    >
                                        {conversation.lastMessage? 
                                            conversation.lastMessage.message ?
                                                getTextMessage( 
                                                    conversation.lastMessage!.type, 
                                                    conversation.lastMessage!.message
                                                )
                                            : "" 
                                        : ""}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid.Row>
                        </Grid>
                    </div>
                )
            }): null}
        </div>
    );
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        authenticated: state.users.authenticated,
        conversationList: state.conversation.conversations,
        user: state.users.user,
    }
}

const mapActionsToProps = {
    getConversations,
    getConversationsByAgentId,
    setConversationData
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IConversationListProps = ConnectedProps<typeof connector>;

export default connector(ConversationList);