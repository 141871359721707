import {
  SET_PROFILE_ERRORS,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER_PROFILE,
  SET_USER_AGENT_PROFILE,
  CLEAR_PROFILE_ERRORS,
  LOADING_USER_PROFILE,
  SET_OPEN_MENU
} from '../constants/profile.constants'
import { IAgentModel } from '../models/Agent.model';
import IUserProfile from '../models/IAgentProfile.model';

const initialState = {
  authenticated: false,
  credentials: {},
  loading: false,
  user: {} as Readonly<IUserProfile>,
  profile: {} as Readonly<IAgentModel>,
  errors: "",
  openMenu: false
}

export type UserState = Readonly<typeof initialState>;

const userReducer = (state: UserState = initialState, action: any): UserState  => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        loading: false,
        errors: '',
        authenticated: true
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER_PROFILE:
      return {
        ...state,
        authenticated: true,
        loading: false,
        errors: "",
        user: action.payload,
      };
    case SET_USER_AGENT_PROFILE:
      return {
        ...state,
        loading: false,
        authenticated: true,
        errors: "",
        profile: action.payload,
      };
    case LOADING_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case SET_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        authenticated: false,
        errors: action.payload
      };
    case CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        errors: ""
      };
    case SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.payload
      };
    default:
      return state;
  }
}

export default userReducer;