import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const AudioMessage = ({
    emitMessage,
    messageSubmit,
    message,
    profile,
}: any) => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        recordState: null,
        delete: false,
    });
    const { id } = useParams();

    const startAudio = () => {
        setSeconds(0);
        setMinutes(0);

        setState({
            recordState: RecordState.START,
            delete: false,
        });
        setLoading(false);
    };

    const stopAudio = () => {
        clearInterval(setTimer);

        setState({
            recordState: RecordState.STOP,
            delete: false,
        });
        setLoading(true);
    };

    const deleteAudio = () => {
        setState({
            recordState: RecordState.STOP,
            delete: true,
        });
        setLoading(true);
    };

    const audioSubmit = (audioData: any) => {
        if (state.delete === false) {
            emitMessage({
                phone: id,
                audio: audioData.blob,
                type: audioData.type,
                agent_id: profile.id,
                agent_phone: profile.phone,
            });
        }
        setState({
            recordState: null,
            delete: false,
        });
    };

    let setTimer: any;
    useEffect(() => {
        setTimer = setInterval(() => {
            setSeconds(seconds + 1);

            if (seconds === 59) {
                setMinutes(minutes + 1);
                setSeconds(0);
            }
        }, 1000);

        return () => clearInterval(setTimer);
    });

    return (
        <>
            {loading ? (
                <>
                    {message === "" ? (
                        <button onClick={startAudio}>
                            <Icon name="microphone" size="large" />
                        </button>
                    ) : (
                        <button className="buttonSend" onClick={messageSubmit}>
                            <Icon name="send" size="large" />
                        </button>
                    )}
                </>
            ) : (
                <>
                    <h4
                        style={{
                            color: "white",
                            marginTop: 12,
                            marginLeft: 10,
                        }}
                    >
                        {minutes < 10 ? "0" + minutes : minutes}:
                        {seconds < 10 ? "0" + seconds : seconds}
                    </h4>
                    <button onClick={stopAudio}>
                        <Icon name="send" size="large" />
                    </button>
                    <button
                        style={{
                            backgroundColor: "red",
                        }}
                        onClick={deleteAudio}
                    >
                        <Icon name="trash alternate outline" size="large" />
                    </button>
                </>
            )}
            <div style={{ visibility: "hidden", display: "none" }}>
                <AudioReactRecorder
                    state={state.recordState}
                    onStop={audioSubmit}
                />
            </div>
        </>
    );
};
