import React, { useState, useEffect } from "react";
import {
	Accordion,
	Grid,
	Icon,
	Input,
	Dropdown,
	Button,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { IAgentModel } from "../../../../models/Agent.model";

interface IProps {
	isAgent: boolean;
	agent: IAgentModel;
	profile: IAgentModel;
	page: number;
	getAll: (body: any) => void;
	setPage: (value: number) => void;
}

interface IState {
	filterID: string;
	filterName: string;
	filterLastName: string;
	filterSecondLastName: string;
	filterCURP: string;
	filterPhone: string;
	filterEmail: string;
	filterStatus: string;
}

const Filters: React.FC<IProps> = ({
	isAgent,
	agent,
	profile,
	page,
	getAll,
	setPage,
}) => {
	const { id } = useParams();
	const [active, setActive] = useState(true);
	const [state, setState] = useState<IState>({
		filterID: "",
		filterName: "",
		filterLastName: "",
		filterSecondLastName: "",
		filterCURP: "",
		filterPhone: "",
		filterEmail: "",
		filterStatus: "",
	});

	useEffect(() => {
		console.log("EXPEDIENTES USEFFECT", isAgent, agent);
		if (profile.id) {
			getEntities(page);
		}
	}, [, profile, agent, page]);

	const getEntities = (page: number) => {
		console.log("GET ENTITIES", isAgent, agent);

		const {
			filterID,
			filterName,
			filterLastName,
			filterSecondLastName,
			filterCURP,
			filterPhone,
			filterEmail,
			filterStatus,
		} = state;

		let isfilterID = filterID.trim() !== "";
		let isFilterName = filterName.trim() !== "";
		let isFilterLastName = filterLastName.trim() !== "";
		let isFilterSecondLastName = filterSecondLastName.trim() !== "";
		let isFilterPhone = filterPhone.trim() !== "";
		let isFilterCURP = filterCURP.trim() !== "";
		let isfilterEmail = filterEmail.trim() !== "";
		let isFilterStatus = filterStatus.trim() !== "";

		let filters: any = {
			id: filterID.trim(),
			agentId: isAgent ? profile.id : agent.id,
			form: {
				nombres: { value: filterName.trim() },
				apellido_paterno: { value: filterLastName.trim() },
				apellido_materno: { value: filterSecondLastName.trim() },
				curp: { value: filterCURP.trim() },
				telefono: { value: filterPhone.trim() },
				email: { value: filterEmail.trim() },
				status: { value: filterStatus.trim() },
			},
		};

		if (!isfilterID) delete filters.id;
		if (!isFilterName) delete filters.form.nombres;
		if (!isFilterLastName) delete filters.form.apellido_paterno;
		if (!isFilterSecondLastName) delete filters.form.apellido_materno;
		if (!isFilterCURP) delete filters.form.curp;
		if (!isFilterPhone) delete filters.form.telefono;
		if (!isfilterEmail) delete filters.form.email;
		if (!isFilterStatus) delete filters.form.status;
		if (!isAgent) delete filters.agentId;

		if (
			!isFilterName &&
			!isFilterLastName &&
			!isFilterSecondLastName &&
			!isFilterCURP &&
			!isFilterPhone &&
			!isfilterEmail &&
			!isFilterStatus
		) {
			delete filters.form;
		}

		getAll({
			filters: {
				...filters,
			},
			page,
			limit: 15,
			order: {
				createdAt: "desc",
				_id: "desc",
			},
		});
	};

	const cleanFilters = () => {
		setState({
			...state,
			filterID: "",
			filterName: "",
			filterLastName: "",
			filterSecondLastName: "",
			filterPhone: "",
			filterEmail: "",
			filterStatus: "",
		});

		getAll({
			filters: {},
			page,
			limit: 15,
			order: {
				createdAt: "desc",
				_id: "desc",
			},
		});

		setPage(1);
	};

	const STATUS = ["NUEVO", "EN REVISION", "OBSERVACIONES", "COMPLETO"];

	return (
		<Accordion fluid styled>
			<Accordion.Title
				active={active}
				onClick={() => {
					setActive(!active);
				}}
			>
				<Icon name="dropdown" />
				Búsqueda
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Grid>
					<Grid.Row columns={4}>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="ID"
								value={state.filterID}
								onChange={(e) => {
									setState({
										...state,
										filterID: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Nombre"
								value={state.filterName}
								onChange={(e) => {
									setState({
										...state,
										filterName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Apellido Paterno"
								value={state.filterLastName}
								onChange={(e) => {
									setState({
										...state,
										filterLastName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Apellido Materno"
								value={state.filterSecondLastName}
								onChange={(e) => {
									setState({
										...state,
										filterSecondLastName:
											e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Teléfono"
								value={state.filterPhone}
								onChange={(e) => {
									setState({
										...state,
										filterPhone: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="CURP"
								value={state.filterCURP}
								onChange={(e) => {
									setState({
										...state,
										filterCURP: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Correo"
								value={state.filterEmail}
								onChange={(e) => {
									setState({
										...state,
										filterEmail: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Dropdown
								clearable
								placeholder="Estatus"
								className="user-filter-field"
								selection
								search
								value={state.filterStatus}
								options={STATUS.map((status) => {
									return {
										id: status,
										text: status,
										value: status,
									};
								})}
								onChange={(e, data) => {
									setState({
										...state,
										filterStatus: data.value!.toString(),
									});
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<div style={{ float: "right" }}>
							<Button
								style={{ width: "200px" }}
								color="grey"
								onClick={cleanFilters}
							>
								Limpiar
							</Button>
							<Button
								style={{ width: "200px" }}
								color="green"
								onClick={() => {
									getEntities(1);
									setPage(1);
								}}
							>
								Buscar
							</Button>
						</div>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
};

export default Filters;
