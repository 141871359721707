import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const ImageMessage = ({
  selectedImage,
  setSelectedImage,
  setMessage,
  message,
  emitMessage,
  profile,
  media,
}: any) => {
  const { id } = useParams();

  const imageSubmit = (e: any) => {
    e.preventDefault();
    emitMessage(
      message
        ? {
            phone: id,
            caption: { image: media, message: message },
            agent_id: profile.id,
            agent_phone: profile.phone,
          }
        : {
            phone: id,
            image: media,
            agent_id: profile.id,
            agent_phone: profile.phone,
          }
    );
    setSelectedImage("");
    setMessage("");
  };

  return (
    <form>
      {selectedImage && (
        <div className="chatcrm-image-form">
          <img src={URL.createObjectURL(selectedImage)} />
          <h1 style={{ color: "white" }}>Imagen: {selectedImage.name}</h1>
          <br />
          <input
            className="chatcrm-imgtext-input"
            value={message}
            type="text"
            placeholder="Escribe un mensaje"
            onChange={(e) => setMessage(e.target.value)}
            autoFocus
          />
          <button onClick={imageSubmit} className="chatcrm-message-input-send">
            <Icon name="send" size="large" />
          </button>
          <button
            onClick={() => setSelectedImage("")}
            className="chatcrm-message-input-delete"
          >
            <Icon name="trash alternate outline" size="large" />
          </button>
        </div>
      )}
    </form>
  );
};
