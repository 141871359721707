import { namespace_1, namespace_2 } from "../enviroment";

export const types = [
	{
		key: "IS_TOP",
		text: "TOP 10",
		value: "IS_TOP",
	},
	{
		key: "IS_AGENT",
		text: "AGENTE",
		value: "IS_AGENT",
	},
	{
		key: "IS_COORDINATOR",
		text: "COORDINADOR",
		value: "IS_COORDINATOR",
	},
	{
		key: "IS_GESTIONADOR",
		text: "MESA DE CONTROL",
		value: "IS_GESTIONADOR",
	},
	{
		key: "IS_DISTRIBUIDOR",
		text: "DISTRIBUIDOR",
		value: "IS_DISTRIBUIDOR",
	},
	{
		key: "IS_FRANQUICIADO",
		text: "FRANQUICIADO",
		value: "IS_FRANQUICIADO",
	},
	{
		key: "IS_MENTOR",
		text: "MENTOR",
		value: "IS_MENTOR",
	},
	{
		key: "IS_CONTADOR",
		text: "CONTADOR",
		value: "IS_CONTADOR",
	},
];

export const services = [
	{
		key: "CRÉDITOS",
		text: " CRÉDITOS",
		value: namespace_1,
	},
	{
		key: "DISTRIBUCIONES",
		text: " DISTRIBUCIONES",
		value: namespace_2,
	},
];
