import axios from "axios";
import {
    SET_SERVICE,
    SET_SERVICES,
    SET_LOADING_SERVICE,
    SET_LOADING_SERVICES,
} from "../constants/services.constants";
import { paymentsApiUrl } from "../constants/enviroment";

export const getAllServices = (services: any) => async (dispatch: any) => {
    dispatch(setLoading());
    try {
        const response = await axios.get(
            `${paymentsApiUrl}/api/services/getService`
        );
        const services = response.data.services;
        dispatch(setServices(services));
    } catch (error) {
        console.log("Error al cargar los servicios");
    }
};

export const setServices = (services: any) => async (dispatch: any) => {
    await dispatch({
        type: SET_SERVICES,
        payload: services,
    });
};

export const setLoading = () => (dispath: any) => {
    dispath({
        type: SET_LOADING_SERVICES,
    });
};
