import Swal from "sweetalert2";
import {
    Button,
    Header,
    Icon,
    Label,
    Modal,
    Popup,
    Segment,
    Table,
} from "semantic-ui-react";
import { updateDiscount } from "./services/updateDiscount";
import { MXN } from "../../helper/MXN";
import "./ModalServicesLead.css";

export const ModalServicesLead = ({
    setOpenServicesModal,
    openServicesModal,
    selectedLead,
    discountsFiltered,
    profile,
    getAllDiscounts,
}: any) => {
    const filterServices = discountsFiltered.filter(
        (discount: any) =>
            !discount.deleted && discount.lead?._id === selectedLead.lead?._id
    );

    const totalCost = filterServices
        .filter((service: any) => service.status !== "CANCELADO")
        .reduce(
            (accumulator: any, discount: any) =>
                accumulator + discount.service.cost,
            0
        );

    const handleDeleteDiscount = async (service: any) => {
        try {
            Swal.fire({
                title: `Cancelar el servicio ${service.service.type}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cancelar Servicio",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await updateDiscount(service._id, {
                        discount: { status: "CANCELADO" },
                    });
                    if (response.ok) {
                        Swal.fire({
                            title: "Servicio Cancelado",
                            text: "El servicio ha sido cancelado.",
                            icon: "success",
                        });
                        getAllDiscounts(profile.id);
                    }
                }
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudo cancelar este servicio",
            });
        }
    };

    return (
        <Modal
            onOpen={() => setOpenServicesModal(true)}
            onClose={() => setOpenServicesModal(false)}
            open={openServicesModal}
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
                justifyContent: "center",
            }}
            size="large"
        >
            <Modal.Content scrolling>
                <Header as="h2">
                    <Icon name="file alternate" />
                    <Header.Content>Servicios</Header.Content>
                    <Header.Subheader>
                        Cancela los servicios solicitados (Solo puedes cancelar
                        los servicios con status "PENDIENTE")
                    </Header.Subheader>
                </Header>
                <div className="table-services-lead">
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Tipo</Table.HeaderCell>
                                <Table.HeaderCell>Costo</Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Estatus
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    Acciones
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {filterServices &&
                            filterServices.map((service: any) => {
                                return (
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header as="h4" image>
                                                    <Icon name="file" />
                                                    <Header.Content>
                                                        {service.service.type}
                                                    </Header.Content>
                                                </Header>
                                            </Table.Cell>
                                            <Table.Cell
                                                disabled={
                                                    service.status ===
                                                    "CANCELADO"
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {MXN.format(
                                                    service.service.cost
                                                )}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                {service.status === "PAGADO" ? (
                                                    <Label color="green">
                                                        PAGADO
                                                    </Label>
                                                ) : service.status ===
                                                  "GENERADO" ? (
                                                    <Label color="blue">
                                                        GENERADO
                                                    </Label>
                                                ) : service.status ===
                                                  "PENDIENTE" ? (
                                                    <Label color="orange">
                                                        PENDIENTE
                                                    </Label>
                                                ) : (
                                                    <Label color="red">
                                                        CANCELADO
                                                    </Label>
                                                )}
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                {service.status !==
                                                "PENDIENTE" ? null : (
                                                    <Popup
                                                        trigger={
                                                            <Button
                                                                icon="ban"
                                                                color="red"
                                                                onClick={() => {
                                                                    setOpenServicesModal(
                                                                        true
                                                                    );
                                                                    handleDeleteDiscount(
                                                                        service
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        content="Cancelar"
                                                        basic
                                                    />
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                );
                            })}
                    </Table>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Segment color="blue">
                    <Header as="h5" floated="right">
                        Costos: {MXN.format(totalCost)}
                    </Header>
                    <Header floated="right">|</Header>
                    <Header as="h5" floated="right">
                        Servicios: {filterServices.length}
                    </Header>
                    <br />
                </Segment>
                <Button
                    color="black"
                    onClick={() => setOpenServicesModal(false)}
                >
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
