import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useParams } from 'react-router-dom';
import { Segment, Grid, Loader } from 'semantic-ui-react';
import { getFilteredUsers } from '../../../../actions/user-admin.actions';
import { IUserModel } from '../../../../models/User.model';

interface IState {
	entities: IUserModel[];
	activeIndex: number;
	page: number;
	filterName: string;
	filterLastName: string;
	filterPhone: string;
	filterDate: string;
	filterStatus: string;
	filterNSS: string;
	filterCURP: string;
}

const Assignation: React.FC<Props> = ({
    leads,
    loadingLeads,
    getFilteredUsers
}) => {

    const { id } = useParams();

    useEffect(() => {
        getFilteredUsers(1, true);
    }, []);

    useEffect(() => {
        getEntities();
    }, [leads]);

    const [state, setState] = useState<IState>({
		activeIndex: 1,
		page: 1,
		entities: leads ? leads.map((m) => m) : [],
		filterName: "",
		filterLastName: "",
		filterPhone: "",
		filterDate: "",
		filterStatus: "",
		filterNSS: "",
		filterCURP: ""
	});

    const cleanFilters = () => {

		setState({
			...state,
			page: 1,
			filterName: "",
			filterLastName: "",
			filterPhone: "",
			filterDate: "",
			filterStatus: "",
			filterNSS: "",
			filterCURP: "",
			entities: leads.map((m) => m),
		});
	};

	const parseDate = (date: string) => {
		let dateParsed: Date = new Date(date);
		return dateParsed.toLocaleDateString();
	};

    const getEntities = () => {
		let filteredList: IUserModel[] = [];
		const { 
			filterName,
			filterLastName,
			filterPhone,
			filterDate,
			filterStatus,
			filterNSS,
			filterCURP,
		} = state;

		let isFilterName = filterName.trim() !== "";
		let isFilterLastName = filterLastName.trim() !== "";
		let isFilterPhone = filterPhone.trim() !== "";
		let isFilterDate = filterDate.trim() !== "";
		let isFilterStatus = filterStatus.trim() !== "";
		let isFilterNSS = filterNSS.trim() !== "";
		let isFilterCURP = filterCURP.trim() !== "";

		if (isFilterName) {
			filteredList = filteredList ? filteredList.filter((f) =>
				f.name.toLowerCase().includes(filterName.toLowerCase())
			) : leads.filter((f) =>
				f.name.toLowerCase().includes(filterName.toLowerCase())
			);
		}
		if (isFilterLastName) {
			filteredList = filteredList.concat(
				leads.filter((f) =>
					f.lastName?.toLowerCase().includes(filterLastName.toLowerCase())
				)
			);
		}
		if (isFilterPhone) {
			let phone = filterPhone.toLowerCase();
			if (phone.includes("no")) {
				filteredList = filteredList.concat(
					leads.filter(
					(f) =>
						f.phone.toLowerCase() === "+52" || f.phone.toLowerCase() === ""
					)
				);
			} else if (phone.includes("si")) {
				filteredList = filteredList.concat(
					leads.filter(
					(f) =>
						f.phone.toLowerCase() !== "+52" && f.phone.toLowerCase() !== ""
					)
				);
			} else {
				filteredList = filteredList.concat(
					leads.filter((f) =>
					f.phone.toLowerCase().includes(filterPhone.toLowerCase())
					)
				);
			}
		}

		if (isFilterDate) {
			filteredList = filteredList.concat(
				leads.filter((f) =>
					parseDate(f.created_at!).includes(filterDate.toLowerCase())
				)
			);
		}
		if (isFilterNSS) {
			filteredList = filteredList.concat(
				leads.filter((f) =>
					f.NSS ? f.NSS.toLowerCase().includes(filterNSS.toLowerCase()) : false
				)
			);
		}
		if (isFilterCURP) {
			filteredList = filteredList.concat(
				leads.filter((f) =>
					f.CURP ? f.CURP.toLowerCase().includes(filterCURP.toLowerCase()) : false
				)
			);
		}
		if (isFilterStatus) {
			filteredList = filteredList.concat(
				leads.filter((f) =>
					f.agentStatus.toLowerCase().includes(filterStatus.toLowerCase())
				)
			);
		}
		if (!isFilterName && !isFilterLastName && !isFilterPhone && !isFilterDate && !isFilterStatus && !isFilterNSS && !isFilterCURP) {
			setState({
				...state,
				entities: leads.map((m) => m),
			});
		} else {
			setState({
				...state,
				page: 1,
				entities: filteredList,
			});
		}
	};

    return (
        <Grid as={Segment}>
            <Grid.Row columns={2}>
                {!loadingLeads ? 
                <Grid.Column>
                    {state.entities ? state.entities.map((lead: IUserModel, index: number) => {
                        if(index > (state.page * 10)) {
                            return
                        }
                        if(state.page > 1 && index < ((state.page - 1) * 10)) {
                            return
                        }
                        if(lead.phone === "+52") {
                            return
                        }
                        return (
                            <div>
                                {lead.wa_id ?? lead.Fid}
                            </div>
                        );
                    }) : null}
                </Grid.Column>
                :<Loader active={loadingLeads} color="blue">Cargando...</Loader>}
                <Grid.Column>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        userId: state.users.user.id,
        leads: state.adminUsers.users,
        loadingLeads: state.adminUsers.loading
    }
}

const mapActionsToProps = {
    getFilteredUsers
}

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector>

export default connector(Assignation);