import {
	SET_AGENTS,
	SET_AGENT_BY_ID,
	LOADING_ADMIN_AGENT,
	SET_LOADING_ERRORS_ADMIN_AGENT,
	CLEAR_LOADING_ERRORS_ADMIN_AGENT,
	LOADING_UPDATE_ADMIN_AGENT,
} from "../constants/agent-admin.constants";

import axios from "axios";
import { IAgentModel } from "../models/Agent.model";
import { apiUrl } from "../constants/enviroment";
import FormData from "form-data";
import { setProfileData } from "./users.actions";

export const getAgents = () => async (dispatch: any) => {
	let loaded = false;
	await dispatch({ type: LOADING_ADMIN_AGENT });
	axios
		.get(`${apiUrl}/agent/`)
		.then((res) => {
			if (res.status !== 200) {
				console.warn("Error al cargar agentes");
				dispatch(setErrors(res.data.message));
			}
			const agents = res.data.agents;
			dispatch(setAgentsData(agents));
			loaded = true;
		})
		.catch((err) => {
			console.log(err);
			dispatch(setErrors("Error al cargar coordinadores"));
		});
	return loaded;
};

export const getCoordinators = () => async (dispatch: any) => {
	try {
		dispatch({ type: LOADING_ADMIN_AGENT });
		const res = await axios.get(`${apiUrl}/coordinator/`);

		if (res.status !== 200) {
			dispatch(setErrors(res.data.message));
		}
		dispatch(setAgentsData(res.data.coordinators));
	} catch (error) {
		dispatch(setErrors("Error al cargar coordinadores"));
	}
};

export const getByCoordinatorId = (id: string) => async (dispatch: any) => {
	try {
		dispatch({ type: LOADING_ADMIN_AGENT });
		const res = await axios.get(`${apiUrl}/coordinator/agents/${id}`);

		if (res.status !== 200) {
			dispatch(setErrors(res.data.message));
		}
		dispatch(setAgentsData(res.data.agents));
	} catch (error) {
		dispatch(setErrors("Error al cargar coordinadores"));
	}
};

export const getAgent =
	(id: string, isLogin?: boolean) => async (dispatch: any) => {
		let loaded = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });
		await axios
			.get(`${apiUrl}/agent/${id}`)
			.then((res) => {
				if (res.status !== 200) {
					console.warn("Error al cargar agente", res.data.message);
					dispatch(setErrors(res.data.message));
				}
				const agent = res.data.agent;
				console.log("AGENT", agent, isLogin);
				if (isLogin) {
					dispatch(setProfileData(agent));
				}
				dispatch(setAgentData(agent));
				loaded = true;
			})
			.catch((err) => {
				console.error(
					"Error al cargar al agente",
					err.response.data.message
				);
				dispatch(setErrors(err.response.data.message));
			});
		return loaded;
	};

export const createAgent = (agent: IAgentModel) => async (dispatch: any) => {
	let created = false;

	try {
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

		const formData = new FormData();

		formData.append("name", agent.name);

		formData.append("lastName", agent.lastName);

		formData.append("phone", agent.phone);

		formData.append("email", agent.email);

		formData.append("type", agent.type!);

		formData.append("bot_id", agent.bot_id!);

		formData.append("password", agent.password!);

		formData.append("username", agent.username!);

		formData.append("status", agent.status!);

		formData.append("coordinatorId", agent.coordinatorId!);

		// Update the formData object
		for (let i: number = 0; i < agent.images!.length; i++) {
			// Details of the uploaded file
			formData.append(
				"images",
				agent.images![i],
				`${(i + 1).toString()}.png`
			);
		}

		const config = {
			headers: {
				Accept: "multipart/form-data",
				"Content-Type": "multipart/form-data;",
			},
		};

		// Request made to the backend api
		// Send formData object
		const response = await axios.post(`${apiUrl}/agent/`, formData, config);

		if (response.status !== 200) {
			console.warn("NO SE HA CREADO EL AGENTE", response);
			dispatch(setErrors(response.data.message));
			return created;
		}

		// eslint-disable-next-line array-callback-return
		const agente = response.data.agent;

		dispatch(setAgentData(agente));

		created = true;
	} catch (e) {
		console.error("ERROR AL CREAR", e);
		dispatch(setErrors("Error al crear agente"));
		created = false;
	}

	return created;
};

export const updateImage =
	(agent: IAgentModel, image: File, position: number) =>
	async (dispatch: any) => {
		let updated = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

		const formData = new FormData();

		formData.append("name", agent.name);

		formData.append("lastName", agent.lastName);

		formData.append("position", position);

		formData.append("image", image, `${position + 1}.png`);

		const config = {
			headers: {
				Accept: "multipart/form-data",
				"Content-Type": "multipart/form-data;",
			},
		};

		await axios
			.put(`${apiUrl}/agent/updateImage/${agent.id}`, formData, config)
			.then(async (res) => {
				if (res.data.status !== "success") {
					dispatch({
						type: SET_LOADING_ERRORS_ADMIN_AGENT,
						payload: res.data.message,
					});
				}
				getAgent(agent.id!);
				dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_AGENT });
				updated = true;
			})
			.catch((err) => {
				console.error("ERROR AL MODIFICAR IMAGEN");
				console.error(err);
				dispatch({
					type: SET_LOADING_ERRORS_ADMIN_AGENT,
					payload: err.response.data.message,
				});
				updated = false;
			});

		return updated;
	};

export const updateAgent = (agent: IAgentModel) => async (dispatch: any) => {
	let updated = false;
	dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

	await axios
		.put(`${apiUrl}/agent/${agent.id}`, agent)
		.then((res) => {
			dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_AGENT });
			if (res.status !== 200) {
				dispatch(setErrors(res.data.message));
				return updated;
			}
			const agente = res.data.agent;
			dispatch(setAgentData(agente));
			updated = true;
		})
		.catch((err) => {
			console.error(err.response.data.message);
			dispatch(setErrors(err.response.data.message));
		});
	return updated;
};

export const deleteAgent =
	(agent: IAgentModel, adminPassword: string) => async (dispatch: any) => {
		let deleted = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

		const agentRequest = {
			id: agent.id,
			adminPassword: adminPassword,
		};

		await axios
			.post(`${apiUrl}/api/v1/admin/deleteAgent`, agentRequest)
			.then((res) => {
				dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_AGENT });
				deleted = true;
			})
			.catch((err) => {
				dispatch({
					type: SET_LOADING_ERRORS_ADMIN_AGENT,
					payload: err.response.data.message,
				});
				deleted = false;
			});
		return deleted;
	};

export const updateAgentPassword =
	(newPassword: string, agentId: string) => async (dispatch: any) => {
		let updated = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });
		try {
			const request = {
				newPassword: newPassword,
			};

			const response = await axios.put(
				`${apiUrl}/agent/updatePassword/${agentId}`,
				request
			);

			if (response.status !== 200) {
				console.warn("Error al actualizar contraseña", response.data);
				dispatch(setErrors(response.data.message));
			}

			dispatch({
				type: CLEAR_LOADING_ERRORS_ADMIN_AGENT,
			});
			updated = true;
		} catch (e) {
			console.error("Error al actualizar contraseña");
			dispatch(setErrors("Error al actualizar contraseña"));
		}
		return updated;
	};

export const setAgentsData = (agents: IAgentModel[]) => (dispatch: any) => {
	dispatch({ type: LOADING_ADMIN_AGENT });
	dispatch({
		type: SET_AGENTS,
		payload: agents,
	});
};

export const setAgentData = (agent: IAgentModel) => (dispatch: any) => {
	dispatch({
		type: SET_AGENT_BY_ID,
		payload: agent,
	});
};

export const setErrors = (errors: string) => (dispatch: any) => {
	dispatch({
		type: SET_LOADING_ERRORS_ADMIN_AGENT,
		payload: errors,
	});
};
