import React, { useEffect, useState } from "react";
import {
	Segment,
	Grid,
	Input,
	Dropdown,
	Step,
	Button,
} from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { ConnectedProps, connect } from "react-redux";
import { getAll } from "../../../actions/reports.actions";
import { getAgents } from "../../../actions/agent-admin.actions";
import PRODUCTS from "../../../constants/dashboard/products.constants";
import CAMPAIGNS, {
	DISTRIBUTION_CAMPAIGNS,
	CREDITOS_ORIGINS,
	DIST_ORIGINS,
	TYPE_PRODUCTS,
} from "../../../constants/dashboard/campaigns.constants";
import { IAgentModel } from "../../../models/Agent.model";
import DataShow from "./components/DataShow.component";
import * as xlsx from "xlsx";
import { IUserModel } from "../../../models/User.model";
import { namespace_1, namespace_2 } from "../../../constants/enviroment";
import "../../../css/reports.css";

interface IState {
	selectedNameSpace: string;
	filterStartDate: string;
	filterEndDate: string;
	filterAgentId: string;
	filterType: string | string[];
	filterCampaign: string;
	filterOrigin: string;
	filterTypeProduct: string;
}

const Reports: React.FC<IProps> = ({
	agents,
	loadingAgents,
	authenticated,
	loading,
	leads,
	counter,
	getAgents,
	getAll,
}) => {
	useEffect(() => {
		getAgents();
	}, [, authenticated]);

	useEffect(() => {}, [leads, counter]);

	const [state, setState] = useState<IState>({
		selectedNameSpace: namespace_1,
		filterStartDate: "",
		filterEndDate: "",
		filterAgentId: "",
		filterCampaign: "",
		filterType: PRODUCTS[0].value,
		filterOrigin: "",
		filterTypeProduct: "",
	});

	useEffect(() => {
		clear();
	}, [state.selectedNameSpace]);

	const find = async (toDownload: boolean) => {
		let filters: any = {
			startDate: state.filterStartDate,
			endDate: state.filterEndDate,
			agentId: state.filterAgentId,
			campaign: state.filterCampaign,
			pageId: state.filterType,
			type_macro: "blaster",
			type_producto: state.filterTypeProduct,
			order: {
				createdAt: -1,
			},
			toDownload: toDownload,
			source: "5214499401720",
		};

		if (state.filterOrigin !== "blaster") delete filters.type_macro;
		if (state.filterCampaign !== "amf") delete filters.source;

		console.log(
			"FILTERS",
			state.filterCampaign,
			state.filterCampaign !== "amf",
			filters
		);

		await getAll(filters);
	};

	const clear = () => {
		setState({
			...state,
			filterStartDate: "",
			filterEndDate: "",
			filterAgentId: "",
			filterCampaign: "",
			filterOrigin: "",
			filterTypeProduct: "",
		});
	};

	const getAgentName = (id: string) => {
		let name = "";
		let agent = agents.filter((f) => f.id! === id)[0];
		name = agent ? `${agent.name} ${agent.lastName}` : "";
		return name;
	};

	const convertAllClientJsonToExcel = async (leads: IUserModel[]) => {
		const new_Users_format: any = [];

		leads.forEach((user) => {
			new_Users_format.push({
				id: user.wa_id ? user.wa_id : user.Fid,
				Nombre: user.name,
				Teléfono: user.phone,
				Email: user.email,
				"Capacidad Crédito": user.capacidad_credito,
				"Creditos Activos": user.creditos_activos,
				"Tipo de Cliente": user.type_producto,
				Agente: getAgentName(user.agent),
				"status agente": user.agentStatus,
				"fecha creado": new Date(user.createdAt!)
					.toLocaleString("en-GB")
					.split(",")[0],
				NSS: user.NSS,
				CURP: user.CURP,
				Delegación: user.delegacion,
				Matrícula: user.matricula,
			});
		});

		const workSheet = xlsx.utils.json_to_sheet(new_Users_format);
		const workBook = xlsx.utils.book_new();
		workSheet["!cols"] = [
			{ width: 20 },
			{ width: 30 },
			{ width: 30 },
			{ width: 15 },
			{ width: 15 },
			{ width: 15 },
		];

		xlsx.utils.book_append_sheet(workBook, workSheet, "Clientes");
		xlsx.write(workBook, { bookType: "xlsx", type: "buffer" });
		xlsx.write(workBook, { bookType: "xlsx", type: "binary" });
		xlsx.writeFile(
			workBook,
			`Clientes${state.filterStartDate}_${state.filterEndDate}.xlsx`
		);
	};

	return (
		<Segment>
			<Grid padded>
				<Grid.Row className="margin-bottom-40">
					<Grid.Column>
						<h1>CAMPAÑAS</h1>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="margin-bottom-20" stretched>
					<Grid.Column>
						<Step.Group>
							<Step
								active={state.selectedNameSpace === namespace_1}
								icon={PRODUCTS[0].icon}
								onClick={() => {
									setState({
										...state,
										selectedNameSpace: namespace_1,
										filterType: PRODUCTS[0].value,
									});
								}}
								title={PRODUCTS[0].text}
								description={PRODUCTS[0].description}
							/>
							<Step
								active={state.selectedNameSpace === namespace_2}
								icon={PRODUCTS[1].icon}
								onClick={() => {
									setState({
										...state,
										selectedNameSpace: namespace_2,
										filterType: PRODUCTS[1].value,
									});
								}}
								title={PRODUCTS[1].text}
								description={PRODUCTS[1].description}
							/>
						</Step.Group>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={2} className="margin-bottom-20" stretched>
					<Grid.Column textAlign="right">
						<Input
							action={{
								color: "green",
								content: "Fecha inicio",
							}}
							actionPosition="left"
							value={state.filterStartDate}
							onChange={(e, data) => {
								setState({
									...state,
									filterStartDate: data.value,
								});
							}}
							type="date"
						/>
					</Grid.Column>
					<Grid.Column textAlign="left">
						<Input
							action={{
								color: "blue",
								content: "Fecha fin",
							}}
							actionPosition="left"
							value={state.filterEndDate}
							min={state.filterStartDate}
							onChange={(e, data) => {
								setState({
									...state,
									filterEndDate: data.value,
								});
							}}
							type="date"
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="margin-bottom-20" stretched>
					<Grid.Column computer={4} mobile={16} textAlign="right">
						<Dropdown
							button
							className="icon"
							placeholder="Seleccione el origen"
							floating
							labeled
							clearable
							value={state.filterOrigin}
							onChange={(e, data) => {
								const option = data.options!.filter(
									(f) => f.value === data.value
								)[0];
								setState({
									...state,
									filterType: option?.values,
									filterOrigin: data.value?.toString() ?? "",
								});
							}}
							icon="world"
							options={
								state.selectedNameSpace === namespace_1
									? CREDITOS_ORIGINS
									: DIST_ORIGINS
							}
						/>
					</Grid.Column>
					<Grid.Column computer={4} mobile={16} textAlign="right">
						<Dropdown
							button
							className="icon"
							placeholder="Seleccione el tipo de campaña"
							floating
							labeled
							clearable
							value={state.filterCampaign}
							onChange={(e, data) => {
								setState({
									...state,
									filterCampaign: data.value!.toString(),
								});
							}}
							icon="world"
							options={
								state.selectedNameSpace === namespace_1
									? CAMPAIGNS
									: DISTRIBUTION_CAMPAIGNS
							}
						/>
					</Grid.Column>
					<Grid.Column computer={4} mobile={16} textAlign="left">
						<Dropdown
							button
							className="icon"
							placeholder="Seleccione un agente"
							floating
							labeled
							scrolling
							fluid
							clearable
							loading={loadingAgents}
							disabled={loadingAgents}
							value={state.filterAgentId}
							onChange={(e, data) => {
								setState({
									...state,
									filterAgentId: data.value!.toString(),
								});
							}}
							icon="user"
							options={agents.map((agent: IAgentModel) => {
								if (agent.bot_id !== state.selectedNameSpace) {
									return {
										hidden: true,
									};
								}

								return {
									value: agent.id,
									text: `${agent.name} ${agent.lastName}`,
								};
							})}
						/>
					</Grid.Column>
					{state.selectedNameSpace === namespace_1 ? (
						<Grid.Column computer={4} mobile={16} textAlign="right">
							<Dropdown
								button
								className="icon"
								placeholder="Seleccione el tipo de cliente"
								floating
								labeled
								clearable
								value={state.filterTypeProduct}
								onChange={(e, data) => {
									setState({
										...state,
										filterTypeProduct:
											data.value!.toString(),
									});
								}}
								icon="world"
								options={TYPE_PRODUCTS}
							/>
						</Grid.Column>
					) : null}
				</Grid.Row>
				<Grid.Row columns={2}>
					<Grid.Column textAlign="right">
						<Button
							className="w-300"
							onClick={clear}
							disabled={loading}
							loading={loading}
						>
							Limpiar
						</Button>
					</Grid.Column>
					<Grid.Column textAlign="left">
						<Button
							className="w-300"
							color="green"
							onClick={() => find(false)}
							disabled={loading}
							loading={loading}
						>
							Buscar
						</Button>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						<DataShow
							jsonToExcel={() =>
								convertAllClientJsonToExcel(leads)
							}
							getAgentName={getAgentName}
							title={"Leads totales:"}
							find={find}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		agents: state.adminAgents.agents,
		loadingAgents: state.adminAgents.loading,
		user: state.users.user,
		authenticated: state.users.authenticated,
		loading: state.reports.loading,
		leads: state.reports.leads,
		counter: state.reports.counter,
	};
};

const mapActionsToProps = {
	getAgents,
	getAll,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Reports);
