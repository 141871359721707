import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Modal } from 'semantic-ui-react';
import { IUserModel } from '../../../../models/User.model';
import { RootState } from '../../../../store/store';

const UserList:React.FC<IUserListProps> = ({
    users,
    pagination,
    jsonToExcel,
    getAgentName,
    page,
    next,
    loading,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const parseDate = (date: string) => {
    let dateParsed: Date = new Date(date);
    return dateParsed.toLocaleDateString()
  }

  return (
    <Modal
      onClose={() => {setOpen(false); pagination(0);}}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
            color="green"
            onClick={() => {setOpen(true)}}    
        >
            Ver Usuarios
        </Button>
      }
      size="fullscreen"
      dimmer={'blurring'}
      style={{ 
        left: 'auto',
        right: 'auto',
        top: 'auto',
        height: 'fit-content'
      }}
    >
      <Modal.Header>Usuarios</Modal.Header>
      <Modal.Content image>
        <Table striped bordered hover>
            <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Teléfono</th>
                  <th>CURP</th>
                  <th>NSS</th>
                  <th>Matrícula</th>
                  <th>Delegación</th>
                  <th>Fecha de registro</th>
                  <th>ID Agente</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => {
                    return(
                        <tr key={user.Fid ? user.Fid : user.wa_id}>
                            <td>{user.Fid ? user.Fid : user.wa_id}</td>
                            <td>{user.name}</td>
                            <td>{user.lastName}</td>
                            <td>{user.phone !== "+52" ? user.phone : "NO REGISTRO"}</td>
                            <td>{user.CURP}</td>
                            <td>{user.NSS}</td>
                            <td>{user.matricula}</td>
                            <td>{user.delegacion}</td>
                            <td>{parseDate(user.createdAt!)}</td>
                            <td><h5 className='link pointer' onClick={() => navigate(`/agent/${user.agent}`)}>{getAgentName(user.agent)}</h5></td>
                        </tr>
                    )
                })}
            </tbody>
          </Table>
      </Modal.Content>
      <Modal.Actions >
        <Grid>
              <Grid.Row computer={16}>
                <Grid.Column computer={5}>
                  <Button positive disabled={users.length <= 0 || loading} onClick={() => jsonToExcel(false, users)}>Descargar Página</Button>
                  <Button positive disabled={users.length <= 0 || loading} onClick={() => jsonToExcel(true)} >Descargar Todo</Button>
                </Grid.Column>
                <Grid.Column computer={11}>
                  <Button positive loading={loading} disabled={page <= 0 || loading} onClick={() => {page--; pagination(page);}}>
                    Atras
                  </Button>
                  <Button positive loading={loading} disabled={!next || loading} onClick={() => {page++; pagination(page);}}>
                    Siguiente
                  </Button>
                  <Button color='black' onClick={() => {setOpen(false); pagination(0);}}>
                    Cerrar
                  </Button>
                </Grid.Column>
              </Grid.Row>

          </Grid>
        </Modal.Actions>
    </Modal>
  )
}

export interface IProps {
  users: IUserModel[]
  pagination: Function,
  jsonToExcel: Function,
  getAgentName: Function,
  page: number
  next: boolean,
  loading: boolean
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState, props: IProps) => {
    return {
      users: props.users,
      pagination: props.pagination,
      jsonToExcel: props.jsonToExcel,
      getAgentName: props.getAgentName,
      page: props.page,
      next: props.next,
      loading: props.loading
    }
}

const mapActionsToProps = {
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IUserListProps = ConnectedProps<typeof connector>;

export default connector(UserList);