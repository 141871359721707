import {
	SET_EXPEDIENTE,
	SET_EXPEDIENTES,
	SET_EXPEDIENTES_LOADING,
	SET_EXPEDIENTE_COUNTER,
	SET_EXPEDIENTE_ERRORS,
	SET_EXPEDIENTE_PAGE,
} from "../constants/expedientesCRM.constansts";
import axios from "axios";
import { apiUrl } from "../constants/enviroment";
import IExpediente from "../models/ExpedienteCRM.model";
import IExpedienteCRM from "../models/ExpedienteCRM.model";

export const getAll = (body: any) => async (dispatch: any) => {
	console.log("GET ALL EXPEDIENTES");
	let loaded = false;
	await dispatch({ type: SET_EXPEDIENTES_LOADING });

	await axios
		.post(`${apiUrl}/expediente/`, body)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(
					"No se han podido cargar los expedientes",
					res.data.message
				);
				dispatch(setErrors(res.data.message));
				return loaded;
			}

			let data = res.data.paginationData;

			console.log("DATA", data);

			dispatch(setCounter(data.totalDocs));
			dispatch(setExpedientes(data.docs));
			loaded = true;
		})
		.catch((err) => {
			console.error(
				"Error al cargar los expedientes",
				err.response.data.message
			);
			setErrors(err.response.data.message);
		});

	return loaded;
};

export const getByLeadByAgent =
	(leadId: string, agentId: string) => async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: SET_EXPEDIENTES_LOADING });
		try {
			let url = `${apiUrl}/expediente/${agentId}/${leadId}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.warn("No se ha podido obtener el expediente", response);
				dispatch(setErrors("No se han podido cargar el expediente"));
				return loaded;
			}

			let data = response.data;

			dispatch(setCounter(data.count));
			dispatch(setExpediente(data.expediente));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar el expediente", e);
			setErrors("Error al cargar el expediente");
		}
		return loaded;
	};

export const update =
	(id: string, expediente: IExpedienteCRM) => async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: SET_EXPEDIENTES_LOADING });
		try {
			const body = {
				form: expediente.form,
				referencias: expediente.referencias,
				documents: expediente.documents,
			};

			let url = `${apiUrl}/expediente/${id}`;

			const response: any = await axios.put(url, body);

			if (response.status !== 200) {
				console.warn("No se ha podido editar el expediente", response);
				dispatch(setErrors("No se han podido editar el expediente"));
				return loaded;
			}

			let data = response.data;

			dispatch(setExpediente(data.expediente));
			loaded = true;
		} catch (e) {
			console.error("Error al editar el expediente", e);
			setErrors("Error al editar el expediente");
		}
		return loaded;
	};

export const uploadFile =
	(id: string, documento: string, file: File) => async (dispatch: any) => {
		let uploaded = false;
		dispatch({ type: SET_EXPEDIENTES_LOADING });

		const formData = new FormData();

		formData.append("documento", documento);

		formData.append("name", file.name);

		const file_extension = file.name.split(".").pop();

		formData.append("file", file, `${documento}.${file_extension}`);

		const config = {
			headers: {
				Accept: "multipart/form-data",
				"Content-Type": "multipart/form-data;",
			},
		};

		const response = await axios.post(
			`${apiUrl}/expediente/file/${id}`,
			formData,
			config
		);

		if (response.status !== 200) {
		}

		dispatch(setExpediente(response.data.expediente));

		return uploaded;
	};

export const uploadFileStatus =
	(id: string, documento: string, isValid: boolean) =>
	async (dispatch: any) => {
		try {
			let uploaded = false;
			dispatch({ type: SET_EXPEDIENTES_LOADING });

			const response = await axios.put(
				`${apiUrl}/expediente/file/${id}`,
				{
					isValid,
				}
			);

			if (response.status !== 200) {
			}

			return uploaded;
		} catch (e) {}
	};

export const setCounter = (counter: number) => async (dispatch: any) => {
	await dispatch({
		type: SET_EXPEDIENTE_COUNTER,
		payload: counter,
	});
};

export const setPage = (page: number) => async (dispatch: any) => {
	await dispatch({
		type: SET_EXPEDIENTE_PAGE,
		payload: page,
	});
};

export const setExpediente =
	(expediente: IExpediente) => async (dispatch: any) => {
		await dispatch({
			type: SET_EXPEDIENTE,
			payload: expediente,
		});
	};

export const setExpedientes =
	(expedientes: IExpediente[]) => async (dispatch: any) => {
		await dispatch({
			type: SET_EXPEDIENTES,
			payload: expedientes,
		});
	};

export const setErrors = (errors: string) => async (dispatch: any) => {
	await dispatch({
		type: SET_EXPEDIENTE_ERRORS,
		payload: errors,
	});
};
