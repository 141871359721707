import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import { setAgentsData, setAgentsTotal, setErrors } from "./utils.actions";
import { LOADING_ADMIN_AGENT } from "../../constants/agent-admin.constants";
import IPaginate from "../../models/Paginate.model";

export const getAll = async (
	filters: any,
	page: number,
	limit: number,
	sort: any
) => {
	let agents: IPaginate = null!;
	try {
		const response = await axios.post(`${apiUrl}/agent/getAll`, {
			filters,
			page,
			limit,
			sort,
		});

		if (response.status !== 200) {
			return agents;
		}

		agents = response.data.data;
	} catch (e) {
		console.error("Error at getAll agents");
	}
	return agents;
};

export const getByType =
	(filters: any, page: number, limit: number, sort: any, role: string) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: LOADING_ADMIN_AGENT });
			const res = await getAll(filters, page, limit, sort);

			if (!res) {
				dispatch(setErrors("Error al cargar"));
			}

			const { docs, totalDocs } = res;
			dispatch(setAgentsTotal(totalDocs));
			dispatch(setAgentsData(role, docs));
		} catch (error) {
			dispatch(setErrors("Error al cargar"));
			return false;
		}
	};
