//user reducer types
export const SET_AGENTS = "SET_AGENTS";
export const GET_AGENTS = "GET_AGENTS";
export const SET_AGENT_BY_ID = "SET_AGENT_BY_ID";
export const GET_AGENT_BY_ID = "GET_AGENT_BY_ID";
export const LOADING_ADMIN_AGENT = "LOADING_ADMIN_AGENT";
export const LOADING_UPDATE_ADMIN_AGENT = "LOADING_UPDATE_ADMIN_AGENT";
export const SET_LOADING_ERRORS_ADMIN_AGENT = "SET_LOADING_ERRORS_ADMIN_AGENT";
export const CLEAR_LOADING_ERRORS_ADMIN_AGENT =
    "CLEAR_LOADING_ERRORS_ADMIN_AGENT";
export const SET_COORDINATORS = "SET_COORDINATORS";
export const SET_DISTRIBUIDORES = "SET_DISTRIBUIDORES";
export const SET_FRANQUICIADOS = "SET_FRANQUICIADOS";
export const SET_MENTORES = "SET_MENTORES";
export const SET_GESTIONADORES = "SET_GESTIONADORES";
export const SET_CONTADORES = "SET_CONTADORES";
export const SET_ADMIN_AGENTS_PAGE = "SET_ADMIN_AGENTS_PAGE";
export const SET_ADMINS = "SET_ADMINS";
export const SET_ADMIN_AGENTS_TOTAL = "SET_ADMIN_AGENTS_TOTAL";
