import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../store/store";
import {
    Header,
    Table,
    Segment,
    Grid,
    Icon,
    Button,
    Message,
} from "semantic-ui-react";
import { getAllDiscounts } from "../../../actions/discounts.actions";
import { useEffect, useState } from "react";
import { ModalServicesLead } from "./components/ModalServices/ModalServicesLead";
import { MXN } from "./helper/MXN";
import "./RequestedServices.css";

const RequestedServices: React.FC<IProps> = ({
    profile,
    getAllDiscounts,
    discounts,
    discountsFiltered,
}) => {
    const [openServicesModal, setOpenServicesModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState(false);
    const uniqueLeads: any[] = Array.from(
        new Set(discounts.map((discount: any) => discount.lead._id))
    ).map(
        (leadId) =>
            discounts.find(
                (discount: any) => discount.lead._id === leadId
            ) as any
    );

    const totalCost = discounts
        .filter((discount: any) => discount.status !== "CANCELADO")
        .reduce(
            (accumulator: any, discount: any) =>
                accumulator + discount.service.cost,
            0
        );

    useEffect(() => {
        if (profile.id) {
            getAllDiscounts(profile.id);
        }
    }, [, profile.id]);

    console.log(uniqueLeads);
    return (
        <Grid centered>
            <Grid.Column width={10}>
                <Segment>
                    <Header as="h2">
                        <Icon name="file alternate" />
                        <Header.Content>
                            Servicios Solicitados
                            <Header.Subheader>
                                Verifica el estatus y administra los servicios
                                solicitados por cliente
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <div className="services-leads-table">
                        {uniqueLeads.length === 0 ? (
                            <Message info>
                                <Message.Header>
                                    No hay registros se servicios solicitados
                                    para algun lead!
                                </Message.Header>
                                <p>
                                    Dirígete a la sección de tus clientes en el
                                    apartado 'acciones' para realizar una
                                    solicitud.
                                </p>
                            </Message>
                        ) : (
                            <Table basic="very" celled selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Nombre
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Telefono
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            CURP
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>NSS</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                {uniqueLeads &&
                                    uniqueLeads.map((discount: any) => {
                                        return (
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        <Header as="h4" image>
                                                            <Icon
                                                                name="user"
                                                                circular
                                                            />
                                                            <Header.Content>
                                                                {
                                                                    discount
                                                                        .lead
                                                                        .name
                                                                }{" "}
                                                                {
                                                                    discount
                                                                        .lead
                                                                        .lastName
                                                                }
                                                                <Header.Subheader>
                                                                    {
                                                                        discount
                                                                            .lead
                                                                            .email
                                                                    }
                                                                </Header.Subheader>
                                                            </Header.Content>
                                                        </Header>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {discount.lead.phone}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {discount.lead.CURP}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {discount.lead.NSS}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign="center">
                                                        <Button
                                                            color="green"
                                                            onClick={() => {
                                                                setOpenServicesModal(
                                                                    true
                                                                );
                                                                setSelectedLead(
                                                                    discount
                                                                );
                                                            }}
                                                        >
                                                            <Icon name="file" />
                                                            Ver Servicios
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        );
                                    })}
                            </Table>
                        )}
                    </div>
                    <ModalServicesLead
                        setOpenServicesModal={setOpenServicesModal}
                        openServicesModal={openServicesModal}
                        selectedLead={selectedLead}
                        discountsFiltered={discountsFiltered}
                        profile={profile}
                        getAllDiscounts={getAllDiscounts}
                    />
                </Segment>
                <Segment color="blue">
                    <Header as="h5" floated="right">
                        Total de Costos: {MXN.format(totalCost)}
                    </Header>
                    <Header floated="right">|</Header>
                    <Header as="h5" floated="right">
                        Total de Servicios: {discounts.length}
                    </Header>
                    <br />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        profile: state.users.profile,
        lead: state.adminUsers.user,
        agent: state.adminAgents.agent,
        loading: state.servicesReducer.loading,
        discounts: state.discountsReducer.discounts,
        discountsFiltered: state.discountsReducer.discountsFiltered,
    };
};

const mapActionsToProps = {
    getAllDiscounts,
};

const connector = connect(mapStateToProps, mapActionsToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(RequestedServices);
