import React, { useState, useEffect } from "react";
import {
	Accordion,
	Grid,
	Icon,
	Input,
	Dropdown,
	Button,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";

interface IProps {
	role: string;
	namespace: string;
	page: number;
	getByType: (
		filters: any,
		page: number,
		limit: number,
		order: any,
		type: string
	) => void;
	setPage: (value: number) => void;
}

interface IState {
	filterID: string;
	filterName: string;
	filterLastName: string;
	filterPhone: string;
	filterEmail: string;
	filterStatus: string;
	filterType: string;
	filterIsDeleted: boolean;
}

const AgentsFilters: React.FC<IProps> = ({
	role,
	namespace,
	page,
	getByType,
	setPage,
}) => {
	const [active, setActive] = useState(true);
	const [state, setState] = useState<IState>({
		filterID: "",
		filterName: "",
		filterLastName: "",
		filterPhone: "",
		filterEmail: "",
		filterStatus: "",
		filterType: "",
		filterIsDeleted: false,
	});
	const { type, coordinatorId } = useParams();

	useEffect(() => {
		console.log("USEFECT", type, page);
		getEntities(page);
	}, [, type, page]);

	const types: any = {
		agentes: { $or: [{ type: "IS_AGENT" }, { type: "IS_TOP" }] },
		coordinadores: { type: "IS_COORDINATOR" },
		mentores: { type: "IS_MENTOR" },
		distribuidores: { type: "IS_DISTRIBUIDOR" },
		franquiciados: { type: "IS_FRANQUICIADO" },
		contadores: { type: "IS_CONTADOR" },
		gestionadores: { type: "IS_GESTIONADOR" },
		disenadores: { type: "IS_DISENADOR" },
	};

	const getEntities = (page: number) => {
		const {
			filterID,
			filterName,
			filterLastName,
			filterPhone,
			filterEmail,
			filterStatus,
			filterType,
		} = state;

		let isfilterID = filterID.trim() !== "";
		let isFilterName = filterName.trim() !== "";
		let isFilterLastName = filterLastName.trim() !== "";
		let isFilterPhone = filterPhone.trim() !== "";
		let isfilterEmail = filterEmail.trim() !== "";
		let isFilterStatus = filterStatus.trim() !== "";
		let isFilterType = filterType.trim() !== "";

		let filters: any = {
			id: filterID.trim(),
			name: filterName.trim(),
			lastName: filterLastName.trim(),
			phone: filterPhone.trim(),
			email: filterEmail.trim(),
			status: filterStatus.trim(),
			type: filterType.trim(),
			verified: true,
			deleted: { $nin: [true] },
			bot_id: namespace,
			coordinatorId: coordinatorId,
		};

		if (!isfilterID) delete filters.id;
		if (!isFilterName) delete filters.name;
		if (!isFilterLastName) delete filters.lastName;
		if (!isFilterPhone) delete filters.phone;
		if (!isfilterEmail) delete filters.email;
		if (!isFilterStatus) delete filters.status;
		if (type !== "agentes") delete filters.verified;
		if (type !== "agentes") delete filters.deleted;
		if (!coordinatorId) delete filters.coordinatorId;
		if (!isFilterType) {
			delete filters.type;
			filters = {
				...filters,
				...types[type ?? "agentes"],
			};
		}
		if (
			isFilterName ||
			isFilterLastName ||
			isFilterPhone ||
			isfilterEmail ||
			isFilterStatus
		) {
			getByType(
				{
					...filters,
				},
				page,
				12,
				{
					createdAt: "desc",
					_id: "desc",
				},
				"IS_AGENT"
			);
		} else {
			getByType(
				{
					...filters,
				},
				page,
				12,
				{
					createdAt: "desc",
					_id: "desc",
				},
				"IS_AGENT"
			);
		}
	};

	const cleanFilters = () => {
		setPage(1);

		setState({
			...state,
			filterName: "",
			filterLastName: "",
			filterPhone: "",
			filterEmail: "",
			filterStatus: "",
			filterType: "",
			filterIsDeleted: false,
		});

		const filters = {
			...types[type ?? "agentes"],
			bot_id: namespace,
			verified: true,
			deleted: { $nin: [true] },
		};

		if (type !== "agentes") delete filters.verified;
		if (type !== "agentes") delete filters.deleted;

		getByType(
			filters,
			1,
			12,
			{
				createdAt: "desc",
				_id: "desc",
			},
			"IS_AGENT"
		);
		setPage(1);
	};

	const status = [
		{
			key: "Active",
			text: "ACTIVO",
			value: "Active",
		},
		{
			key: "Inactive",
			text: "INACTIVO",
			value: "Inactive",
		},
	];

	const typeOptions = [
		{
			key: "IS_AGENT",
			text: "AGENTES",
			value: "IS_AGENT",
		},
		{
			key: "IS_TOP",
			text: "AGENTES TOP",
			value: "IS_TOP",
		},
	];

	return (
		<Accordion fluid styled>
			<Accordion.Title
				active={active}
				onClick={() => {
					setActive(!active);
				}}
			>
				<Icon name="dropdown" />
				Búsqueda
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Grid>
					<Grid.Row columns={4}>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="ID"
								value={state.filterID}
								onChange={(e) => {
									setState({
										...state,
										filterID: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Nombre"
								value={state.filterName}
								onChange={(e) => {
									setState({
										...state,
										filterName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Apellido"
								value={state.filterLastName}
								onChange={(e) => {
									setState({
										...state,
										filterLastName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Teléfono"
								value={state.filterPhone}
								onChange={(e) => {
									setState({
										...state,
										filterPhone: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Correo"
								value={state.filterEmail}
								onChange={(e) => {
									setState({
										...state,
										filterEmail: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						{type === "agentes" ? (
							<Grid.Column computer={4} mobile={16} tablet={8}>
								<Dropdown
									clearable
									placeholder="Estatus"
									className="user-filter-field"
									selection
									search
									options={status}
									fluid
									value={state.filterStatus}
									onChange={(e, { value }) => {
										setState({
											...state,
											filterStatus: value as string,
										});
									}}
								/>
							</Grid.Column>
						) : null}
						{type === "agentes" ? (
							<Grid.Column computer={4} mobile={16} tablet={8}>
								<Dropdown
									clearable
									placeholder="Tipo de agente"
									className="user-filter-field"
									selection
									search
									options={typeOptions}
									fluid
									value={state.filterType!}
									onChange={(e, { value }) => {
										setState({
											...state,
											filterType: value as string,
										});
									}}
								/>
							</Grid.Column>
						) : null}
					</Grid.Row>
					<Grid.Row>
						<div style={{ float: "right" }}>
							<Button
								style={{ width: "200px" }}
								color="grey"
								onClick={cleanFilters}
							>
								Limpiar
							</Button>
							<Button
								style={{ width: "200px" }}
								color="green"
								onClick={() => {
									getEntities(1);
									setPage(1);
								}}
							>
								Buscar
							</Button>
						</div>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
};

export default AgentsFilters;
