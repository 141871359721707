import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

interface IFooterProps {}

const Footer = (props: IFooterProps) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <footer id="footer" className="text-sm-left text-center">
        <div className="container py-4 py-sm-5">
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8}>
                <ul className="list-agileits">
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      onClick={() => navigate("/terms_and_conditions")}
                      className="nav-link pointer"
                    >
                      Términos y Condiciones
                    </a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      onClick={() => navigate("/privacy")}
                      className="nav-link pointer"
                    >
                      Aviso de privacidad
                    </a>
                  </li>
                </ul>
              </Grid.Column>
              <Grid.Column computer={8}>
                <div className="fv3-contact">
                  <p className="d-inline">
                    <a href="https://api.whatsapp.com/send/?phone=5214494383720&text=Más%20información%20">
                      <Icon name="whatsapp" color="green" size="big" />
                      449-438-3720
                    </a>
                  </p>
                </div>
                <div className="fv3-contact">
                  <p className="d-inline">
                    <a href="mailto:contacto@manali.com.mx">
                      <Icon name="mail" size="big" />
                      w.contreras@cwvlconsultoria.com
                    </a>
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
