import {
	SET_PROFILE_ERRORS,
	SET_UNAUTHENTICATED,
	SET_USER_PROFILE,
	LOADING_USER_PROFILE,
	SET_USER_AGENT_PROFILE,
} from "../constants/profile.constants";
import axios from "axios";
import { decodeToken } from "react-jwt";
import { apiUrl } from "../constants/enviroment";
import IUserProfile from "../models/IAgentProfile.model";
import { IAgentModel } from "../models/Agent.model";

export const loginUser = (userData: any) => async (dispatch: any) => {
	let loggedIn = false;

	dispatch({ type: LOADING_USER_PROFILE });

	await axios
		.post(`${apiUrl}/login`, userData)
		.then((res) => {
			if (res.status !== 202) {
				dispatch(setErrors(res.data.message));
				return loggedIn;
			}
			const { token, profile } = res.data;

			localStorage.setItem("token", `${token}`);

			axios.defaults.headers.common["Authorization"] = token;
			axios.defaults.headers.common["Access-Control-Allow-Headers"] =
				"Origin, X-Requested-With, Content-Type, Accept";
			axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
			axios.defaults.headers.common["Access-Control-Allow-Methods"] =
				"OPTIONS,POST,GET,PUT,DELETE";

			const tokenDecoded: any = decodeToken(token);

			const user = tokenDecoded.user;

			dispatch(setUserData(user));
			dispatch(setProfileData(profile));

			loggedIn = true;
		})
		.catch((e) => {
			console.error("LOGIN_ERROR", e.response.data.message);
			dispatch(setErrors(e.response.data.message));
		});

	return loggedIn;
};

export const getSession = () => async (dispatch: any) => {
	console.log("GET SESSION");

	let sessionExists = false;

	try {
		const token = localStorage.getItem("token");

		dispatch({ type: LOADING_USER_PROFILE });

		const response = await axios.get(`${apiUrl}/account`, {
			headers: {
				Authorization: token!,
			},
		});

		if (response.status !== 200) {
			dispatch(logoutUser());
			return sessionExists;
		}

		axios.defaults.headers.common["Authorization"] = token!;
		axios.defaults.headers.common["Access-Control-Allow-Headers"] =
			"Origin, X-Requested-With, Content-Type, Accept";
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Access-Control-Allow-Methods"] =
			"OPTIONS,POST,GET,PUT,DELETE";

		const { user, agent } = response.data;

		dispatch(setUserData(user));

		dispatch(setProfileData(agent));

		sessionExists = true;
	} catch (e) {
		dispatch(unaunthenticate());
	}
	return sessionExists;
};

export const setProfileData = (agent: IAgentModel) => async (dispatch: any) => {
	await dispatch({
		type: SET_USER_AGENT_PROFILE,
		payload: agent,
	});
};

export const setUserData = (user: IUserProfile) => async (dispatch: any) => {
	await dispatch({
		type: SET_USER_PROFILE,
		payload: user,
	});
};

export const setErrors = (errors: any) => (dispatch: any) => {
	dispatch({
		type: SET_PROFILE_ERRORS,
		payload: errors,
	});
};

export const unaunthenticate = () => (dispatch: any) => {
	dispatch({
		type: SET_UNAUTHENTICATED,
	});
};

export const logoutUser = () => (dispatch: any) => {
	localStorage.removeItem("token");
	delete axios.defaults.headers.common["Authorization"];
	dispatch({
		type: SET_UNAUTHENTICATED,
	});
	window.location.href = "/login";
};
