import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link, useNavigate } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
//redux stuff
import { connect, ConnectedProps } from 'react-redux';
import { loginUser } from '../../../actions/users.actions';
import { RootState } from '../../../store/store';
import { Image, Segment } from 'semantic-ui-react';
import "../../../css/login.css";

export interface ILoginState {
    username: string;
    usernameValid: boolean;
    password: string;
    passwordValid: boolean;
    loading_page: boolean
}

const Login: React.FC<ILoginProps> = ({
    authenticated,
    loading,
    user,
    errors,
    loginUser
}) => {

    const [state, setState] = useState<ILoginState>({
        username: "",
        usernameValid: true,
        password: "",
        passwordValid: true,
        loading_page: true
    });

    const navigate = useNavigate();

    useEffect(() => {
        if(authenticated) {
            switch(user.type) {
                case "IS_ADMIN":
                    navigate("/dashboard");
                    break;
                case "IS_COORDINADOR":
                    navigate("/coordinator/dashboard");
                    break;
                default : 
                    navigate("/agent/dashboard");
                    break;
            }
        }
    }, [authenticated, user]);

    const handleSubmit = async (
        username:string = state.username, 
        password: string =state.password
    ) => {
        let { usernameValid, passwordValid } = state;
        const userData = {
            username: username,
            password: password
        };
        usernameValid = username !== "";
        passwordValid = password !== "";

        console.log("submit", state.username, password);

        if(usernameValid && passwordValid) {
            let isLogged = await loginUser(userData);

            console.log("isLogged", isLogged);
        }
        setState({
            ...state,
            usernameValid,
            passwordValid
        })
    }

    return (
        <div className={`bg-login ${state.loading_page ? 'realtived' : ''}`}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={8}>
                    <Image
                        src='/assets/images/logo.png'
                        className='logo-login'
                        centered
                    />
                </Grid>
                <Grid item xs={12}>
                    <Segment raised className='login-container'>
                        <h2>Bienvenido!</h2>
                        <Container style={{ marginTop: '20px' }}
                            component='main'
                            maxWidth='md'>
                            <Grid
                                container
                                alignContent='center'
                                alignItems='center'
                                justifyContent='center'
                                spacing={3}
                            >
                                <Grid item md={9}>
                                        <Box>
                                            <form>
                                                <TextField
                                                    autoComplete='on'
                                                    variant='filled'
                                                    value={state.username}
                                                    id='standard-basic'
                                                    label='Nombre de Usuario'
                                                    name='username'
                                                    type='text'
                                                    className='input-login'
                                                    onChange={e => setState({
                                                        ...state,
                                                        username: e.currentTarget.value.toLowerCase().trim(),
                                                        usernameValid: e.currentTarget.value !== ""
                                                    })}
                                                    error={!state.usernameValid}
                                                />
                                                <TextField
                                                    autoComplete='on'
                                                    className='input-login'
                                                    variant='filled'
                                                    margin='normal'
                                                    value={state.password}
                                                    fullWidth
                                                    name='password'
                                                    label='Contraseña'
                                                    type='password'
                                                    onKeyDown={e => {
                                                        if(e.key === "Enter") {
                                                            handleSubmit(state.username, state.password);
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        setState({
                                                            ...state,
                                                            password: e.currentTarget.value.trim(),
                                                            passwordValid: e.currentTarget.value !== ""
                                                        })}
                                                        
                                                    }
                                                    error={!state.passwordValid}
                                                />
                                            </form>
                                            {errors !== ""  && (
                                                <Typography variant='body2' style={{ color: "red" }}>
                                                    {errors.toUpperCase()}
                                                </Typography>
                                            )}
                                            <Grid container>
                                                <Grid item sm={12} md={12}>
                                                    <Link style={{fontSize: "1.2rem"}} to='#'>
                                                        Olvidaste tu contraseña?
                                                    </Link>
                                                </Grid>
                                            
                                            </Grid>
                                            <Button 
                                                type='submit' 
                                                variant='contained'
                                                disabled={loading}
                                                onClick={() => handleSubmit(state.username, state.password)}
                                                style={{ margin: '20px', backgroundColor: "#0d6efd", color: "white" }}
                                            >
                                                Entrar
                                                {loading ? (<CircularProgress size={30} color='secondary' />) : null}
                                            </Button>
                                        </Box >
                                </Grid >
                            </Grid >
                        </Container >
                    </Segment>
                    <p style={{color: "white"}}>© 2022  All rights reserved | Design by CWVL Consultoría.</p>
                </Grid>
            </Grid>
        </div>
    )
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        authenticated: state.users.authenticated,
        userRoles: state.users.user.roles,
        userType: state.users.user.type,
        user: state.users.user,
        loading: state.users.loading,
        errors: state.users.errors
    }
}

const mapActionsToProps = {
    loginUser
}

const connector = connect(mapStateToProps, mapActionsToProps);

type ILoginProps = ConnectedProps<typeof connector>;

export default connector(Login);
