import React, { useEffect, useState } from "react";
import { Grid, Input, Image, Icon, Button } from "semantic-ui-react";
import { IoLogoWhatsapp } from "react-icons/io";
import "../credentials.css";

const SeguimientoAgente = ({ isAgent, profile, agent }: any) => {
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);
    const [imageData, setImageData] = useState("");

    const handleProfileImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setSelectedProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const imageUrl = "https://i.imgur.com/sNFK20r.png";

        const imageToBase64 = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    setImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir la imagen:", error);
            }
        };
        imageToBase64();
    }, []);

    return (
        <>
            <div>
                <input
                    style={{ display: "none" }}
                    id="btn-upload"
                    type="file"
                    onChange={handleProfileImageChange}
                    multiple
                    name="btn-foto"
                    title="Subir fotos"
                />
                <h3>Carga tu foto en la credencial</h3>
                <Button
                    onClick={() => {
                        document.getElementById("btn-upload")!.click();
                    }}
                    color="green"
                >
                    <Icon name="upload" />
                    Cargar Foto
                </Button>
            </div>
            <div className="cotizador-component">
                <Grid
                    id="seguimiento-agente"
                    className="cotizador-container padding-0"
                >
                    <div className="header_seguimiento">
                        <div className="seguimiento_logo">
                            {imageData && (
                                <img
                                    src={imageData}
                                    alt="Logo"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                        </div>

                        <div className="seguimiento_text">
                            <p>
                                Recuerde que por ser{" "}
                                <b>pensionado y jubilado del IMSS</b> usted
                                tiene
                                <br />
                                derecho a este beneficio.
                                <br />
                                ☑️Apoyo financiero que va desde <b>
                                    $15,000
                                </b>{" "}
                                hasta <b>$600,000</b>
                                <br />
                                ☑️Con tasa desde <b>2% mensual.</b>
                                <br />
                                ☑️Pre-cotización en <b>15 minutos.</b>
                                <br />
                                ☑️El trámite puede tardar hasta 72hrs en su
                                autorización, se
                                <br />
                                recomienda enviar todos los docuentos para
                                iniciar el trámite
                                <br />a la brevedad.
                                <br />
                                <b>
                                    Aquí le proporciono mis datos de contacto
                                    teléfono/WhatsApp.
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="seguimiento_contact_container">
                        <div className="seguimiento_contact_email">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "21px",
                                    }}
                                >
                                    CORREO:
                                </b>
                                <br />
                                {isAgent ? profile.email : agent.email}
                            </p>
                        </div>
                        <div className="seguimiento_contact_phone">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "21px",
                                    }}
                                >
                                    TELÉFONO:
                                </b>
                                <br />
                                <IoLogoWhatsapp className="seguimiento_contact_icon" />{" "}
                                {isAgent ? profile.phone : agent.phone}
                            </p>
                        </div>
                    </div>
                    <div className="seguimiento_image_profile">
                        {selectedProfileImage ? (
                            <Image
                                src={selectedProfileImage}
                                alt="Selected"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                }}
                            />
                        ) : (
                            <div className="seguimiento_icon_select_image">
                                <Icon name="user circle" color="orange" />
                            </div>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default SeguimientoAgente;
