import {
    idsCreditosFBPages,
    idsCreditosMacro,
    idsCreditosOrigins,
    idsCreditosWhatsapp,
    idsDistFBPages,
    idsDistMacro,
    idsDistOrigins,
    idsDistWhatsapp
} from "../origins/origins.constants";

const CAMPAIGNS = [
    {
        id: 0,
        value: 'all',
        text: 'TODOS',
    },
    {
        id: 1,
        value: 'tradicional',
        text: 'TRADICIONAL',
    },
    {
        id: 2,
        value: 'renovacion',
        text: 'RENOVACIÓN',
    },
    {
        id: 3,
        value: 'compra',
        text: 'COMPRA DE DEUDA',
    },
    {
        id: 4,
        value: 'jubilados',
        text: 'JUBILADOS',
    },
    {
        id: 5,
        value: 'unam',
        text: 'UNAM',
    },
    {
        id: 6,
        value: 'nl',
        text: 'NUEVO LEÓN',
    },
    {
        id: 7,
        value: 'gem',
        text: 'GEM',
    },
    {
        id: 8,
        value: 'gemCompra',
        text: 'GEM COMPRA',
    },
    {
        id: 9,
        value: 'cdmx',
        text: 'CDMX',
    },
    {
        id: 10,
        value: 'cdmxCompra',
        text: 'CDMX COMPRA',
    },
    {
        id: 11,
        value: 'jubiladoCompra',
        text: 'JUBILADO COMPRA',
    },
    {
        id: 12,
        value: 'jubiladoRenovacion',
        text: 'JUBILADO RENOVACIÓN',
    },
    {
        id: 13,
        value: 'exitusIMSS',
        text: 'EXITUS IMSS',
    },
    {
        id: 14,
        value: 'exitusISSSTE',
        text: 'EXITUS ISSSTE',
    },
]

export const DISTRIBUTION_CAMPAIGNS = [
    {
        id: 5,
        value: 'all',
        text: 'TODOS',
    },
    {
        id: 6,
        value: 'distribucion',
        text: 'CLIENTES GENERAL',
    },
    {
        id: 7,
        value: 'distribucionMultiva',
        text: 'CLIENTES MULTIVA',
    },
    {
        id: 8,
        value: 'franquicias',
        text: 'CLIENTES FRANQUICIAS',
    },
    {
        id: 9,
        value: 'amf',
        text: 'CLIENTES AMF',
    },
];

export const CREDITOS_ORIGINS = [
    {
        id: 8,
        value: 'all',
        values: idsCreditosOrigins,
        text: 'TODOS'
    },
    {
        id: 9,
        value: 'facebook',
        values: idsCreditosFBPages,
        text: 'FACEBOOK'
    },
    {
        id: 10,
        value: 'whatsapp',
        values: idsCreditosWhatsapp,
        text: 'WHATSAPP'
    },
    {
        id: 11,
        value: 'macro',
        values: idsCreditosMacro,
        text: 'MACRO WHATSAPP'
    },
    {
        id: 12,
        value: 'blaster',
        values: idsCreditosOrigins,
        text: 'BLASTER'
    }
]

export const DIST_ORIGINS = [
    {
        id: 13,
        value: 'all',
        values: idsDistOrigins,
        text: 'TODOS'
    },
    {
        id: 14,
        value: 'facebook',
        values: idsDistFBPages,
        text: 'FACEBOOK'
    },
    {
        id: 15,
        value: 'whatsapp',
        values: idsDistWhatsapp,
        text: 'WHATSAPP'
    },
    {
        id: 16,
        value: 'macro',
        values: idsDistMacro,
        text: 'MACRO WHATSAPP'
    },
    {
        id: 17,
        value: 'blaster',
        values: idsDistOrigins,
        text: 'BLASTER'
    },

]

export const TYPE_PRODUCTS = [
    {
        id: 18,
        value: 'Tradicional',
        text: 'TRADICIONAL'
    },
    {
        id: 19,
        value: 'Renovación',
        text: 'RENOVACIÓN'
    },
    {
        id: 20,
        value: 'Compra',
        text: 'COMPRA DE CARTERA'
    },
    {
        id: 21,
        value: 'Mixto',
        text: 'MIXTO'
    },
    {
        id: 22,
        value: 'Capacidad negativa',
        text: 'SIN CAPACIDAD'
    },
    {
        id: 22,
        value: 'Poca capacidad',
        text: 'POCA CAPACIDAD'
    },
    {
        id: 22,
        value: 'SIN_VALIDAR',
        text: 'SIN VALIDAR'
    }
]

export default CAMPAIGNS;