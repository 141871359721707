import {
    SET_AGENTS,
    SET_COORDINATORS,
    SET_DISTRIBUIDORES,
    SET_FRANQUICIADOS,
    SET_MENTORES,
    SET_AGENT_BY_ID,
    LOADING_ADMIN_AGENT,
    SET_LOADING_ERRORS_ADMIN_AGENT,
    LOADING_UPDATE_ADMIN_AGENT,
    CLEAR_LOADING_ERRORS_ADMIN_AGENT,
    SET_ADMIN_AGENTS_PAGE,
    SET_ADMIN_AGENTS_TOTAL,
} from "../constants/agent-admin.constants";

import { IAgentModel } from "../models/Agent.model";

const initialState = {
    loading: false,
    loadingUpdate: false,
    page: 1,
    total: 0,
    agents: [] as ReadonlyArray<IAgentModel>,
    cooridnators: [] as ReadonlyArray<IAgentModel>,
    distribuidores: [] as ReadonlyArray<IAgentModel>,
    franquiciados: [] as ReadonlyArray<IAgentModel>,
    mentores: [] as ReadonlyArray<IAgentModel>,
    agent: {} as Readonly<IAgentModel>,
    errors: "",
};

export type State = Readonly<typeof initialState>;

const agentAdminReducer = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case SET_AGENTS:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                agents: action.payload,
            };
        case SET_COORDINATORS:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                cooridnators: action.payload,
            };
        case SET_DISTRIBUIDORES:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                distribuidores: action.payload,
            };
        case SET_FRANQUICIADOS:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                franquiciados: action.payload,
            };
        case SET_MENTORES:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                mentores: action.payload,
            };
        case SET_ADMIN_AGENTS_PAGE:
            return {
                ...state,
                page: action.payload,
            };
        case SET_ADMIN_AGENTS_TOTAL:
            return {
                ...state,
                total: action.payload,
            };
        case SET_AGENT_BY_ID:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
                agent: action.payload,
            };
        case LOADING_ADMIN_AGENT:
            return {
                ...state,
                errors: "",
                loading: true,
            };
        case LOADING_UPDATE_ADMIN_AGENT:
            return {
                ...state,
                errors: "",
                loadingUpdate: true,
            };
        case SET_LOADING_ERRORS_ADMIN_AGENT:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: action.payload,
            };
        case CLEAR_LOADING_ERRORS_ADMIN_AGENT:
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errors: "",
            };
        default:
            return state;
    }
};

export default agentAdminReducer;
