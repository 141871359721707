import {
	SET_BIOMETRICO,
	SET_BIOMETRICOS,
	SET_BIOMETRICO_COUNTER,
	SET_BIOMETRICO_PAGE,
	SET_BIOMETRICO_ERRORS,
	SET_BIOMETRICO_LOADING,
	SET_BIOMETRICOS_LOADING,
} from "../../constants/biometricos.constants";
import IBiometrico from "../../models/Biometrico.model";

export const setLoading = () => (dispatch: any) => {
	dispatch({
		type: SET_BIOMETRICO_LOADING,
	});
};

export const setAllLoading = () => (dispatch: any) => {
	dispatch({
		type: SET_BIOMETRICOS_LOADING,
	});
};

export const setCounter = (total: number) => (dispatch: any) => {
	dispatch({
		type: SET_BIOMETRICO_COUNTER,
		payload: total,
	});
};

export const setPage = (page: number) => (dispatch: any) => {
	dispatch({
		type: SET_BIOMETRICO_PAGE,
		payload: page,
	});
};

export const setBiometricosData =
	(biometrico: IBiometrico[]) => (dispatch: any) => {
		dispatch({
			type: SET_BIOMETRICOS,
			payload: biometrico,
		});
	};

export const setBiometricoData =
	(biometrico: IBiometrico) => (dispatch: any) => {
		dispatch({
			type: SET_BIOMETRICO,
			payload: biometrico,
		});
	};

export const setErrors = (errors: string) => (dispatch: any) => {
	dispatch({
		type: SET_BIOMETRICO_ERRORS,
		payload: errors,
	});
};
