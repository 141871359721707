import axios from "axios";
import { chatCRMUrl } from "../../../../../../constants/enviroment";

export const deleteTemplates = async (template: any) => {
    return await axios.post(
        `${chatCRMUrl}/api/deleteTemplate/${template.name}/${template._id}`
    );
};

export const disabledTemplates = async (template: any) => {
    return await axios.get(`${chatCRMUrl}/api/deleteTemplate/${template._id}`);
};
