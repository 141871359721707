import React, { useState, useEffect } from 'react';
import { RootState } from '../../../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Grid,
	Accordion,
	Image,
	Segment,
	Icon,
	Input,
	Loader,
	Table
} from 'semantic-ui-react';

interface IDetailAgentState {
	activeIndex: number,
	filterName: string,
	filterLastName: string,
	filterPhone: string,
	filterDate: string,
}

const EditCotization: React.FC<IProps> = ({
	
}) => {

	const navigate = useNavigate();
	const { id } = useParams();

	const [state, setState] = useState<IDetailAgentState>({
		activeIndex: 1,
		filterName: "",
		filterLastName: "",
		filterPhone: "",
		filterDate: ""
	});

	useEffect(() => {
		if (id) {

		}
	}, [id])

	const cleanFilters = () => {
		console.log("CLEAN FILTERS")
		setState({
			...state,
			filterName: "",
			filterLastName: "",
			filterPhone: "",
			filterDate: "",
		})

	}

	const parseDate = (date: string) => {
		let dateParsed: Date = new Date(date);
		return dateParsed.toLocaleDateString()
	}

	return (
			<Segment>
				<Grid>
					<Grid.Row>
						<Grid.Column computer={13}></Grid.Column>
						<Grid.Column computer={2}>
						</Grid.Column>
						<Grid.Column computer={1}>
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Segment>
					<Accordion fluid styled>
						<Accordion.Title
							active={state.activeIndex === 1}
							onClick={
								() => {
									setState({
										...state,
										activeIndex: state.activeIndex === 0 ? 1 : 0
									})
								}
							}
						>
							<Icon name='dropdown' />
							Búsqueda
						</Accordion.Title>
						<Accordion.Content active={state.activeIndex === 1}>
							<Grid stretched>
								<Grid.Row>
									<Grid.Column computer={4} mobile={16} tablet={8}>
										<Input
											label="Nombre"
											value={state.filterName}
											onChange={(e) => {
												setState({
													...state,
													filterName: e.currentTarget.value
												})
											}}
										/>
									</Grid.Column>
									<Grid.Column computer={4} mobile={16} tablet={8}>
										<Input
											label="Apellido"
											value={state.filterLastName}
											onChange={(e) => {
												setState({
													...state,
													filterLastName: e.currentTarget.value
												})
											}}
										/>
									</Grid.Column>
									<Grid.Column computer={4} mobile={16} tablet={8}>
										<Input
											label="Teléfono"
											value={state.filterPhone}
											onChange={(e) => {
												setState({
													...state,
													filterPhone: e.currentTarget.value
												})
											}}
										/>
									</Grid.Column>
									<Grid.Column computer={4} mobile={16} tablet={8}>
										<Input
											label="Fecha"
											value={state.filterDate}
											onChange={(e) => {
												setState({
													...state,
													filterDate: e.currentTarget.value
												})
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<div style={{ float: "right" }}>
										<Button
											style={{ width: "200px" }}
											color="grey"
											onClick={() => cleanFilters()}
										>
											Limpiar
										</Button>
										<Button
											style={{ width: "200px" }}
											color="green"
											onClick={() => {}}
										>
											Buscar
										</Button>
									</div>
								</Grid.Row>
							</Grid>
						</Accordion.Content>
					</Accordion>
				</Segment>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>ID</Table.HeaderCell>
								<Table.HeaderCell>Nombre</Table.HeaderCell>
								<Table.HeaderCell>CURP</Table.HeaderCell>
								<Table.HeaderCell>NSS</Table.HeaderCell>
								<Table.HeaderCell>Teléfono</Table.HeaderCell>
								<Table.HeaderCell>Fecha de registro</Table.HeaderCell>
								<Table.HeaderCell>Estatus</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							
						</Table.Body>
					</Table>
			</Segment>
	)
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		
	}
}

const mapActionsToProps = {
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(EditCotization);