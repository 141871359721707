import axios from "axios";
import { apiUrl } from "../constants/enviroment";
import {
	LOADING_AGENT_DASHBOARD,
	SET_AGENT_DASHBOARD,
	SET_AGENT_DASHBOARD_ERRORS,
	SET_AGENT_DASHBOARD_TIME,
} from "../constants/dashboard.agent.constants";
import IAgentDashboard from "../models/IDashboard.agent.model";

export const getData =
	(agentId: string, range: string) => async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: LOADING_AGENT_DASHBOARD });
		try {
			let url = `${apiUrl}/dashboard/${agentId}/${range}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.warn("No se ha podido obtener el dashboard", response);
				dispatch(setErrors("No se ha podido obtener el dashboard"));
				return loaded;
			}

			let data = response.data;

			dispatch(setData(data));

			loaded = true;
		} catch (e) {
			console.error("Error al cargar el dashboard", e);
			dispatch(setErrors("No se ha podido obtener el dashboard"));
		}
		return loaded;
	};

export const setTime = (time: string) => async (dispatch: any) => {
	await dispatch({
		type: SET_AGENT_DASHBOARD_TIME,
		payload: time,
	});
};

export const setData = (data: IAgentDashboard) => async (dispatch: any) => {
	await dispatch({
		type: SET_AGENT_DASHBOARD,
		payload: data,
	});
};

export const setErrors = (errors: string) => async (dispatch: any) => {
	await dispatch({
		type: SET_AGENT_DASHBOARD_ERRORS,
		payload: errors,
	});
};
