import {
    SET_MESSAGES,
    SET_MESSAGE_BY_ID,
    LOADING_MESSAGE,
    SET_LOADING_ERRORS_MESSAGE,
    CLEAR_LOADING_ERRORS_MESSAGE
} from '../constants/message.constants';

import axios from 'axios';
import { apiUrl } from '../constants/enviroment';
import IMessageResponse from '../models/IMessage.model';

export const getMessagesByConversationId = (conversationId: string, agentId: string) => async (dispatch: any) => {
    await dispatch({ type: LOADING_MESSAGE })
    await axios.post(
        `${apiUrl}/message/conversation/${conversationId}`,
        {
            agentId: agentId
        }
        )
        .then((res) => {
            let messageListResponse = res.data.response;
            dispatch(setMessagesData(messageListResponse));
            dispatch({ type: CLEAR_LOADING_ERRORS_MESSAGE });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_LOADING_ERRORS_MESSAGE,
                payload: err.response.data.errors ? err.response.data.errors[0].message : "error al cargar conversaciones"
            });
        });
}

export const setMessagesData = (messages: IMessageResponse[]) => (dispatch: any) => {
    dispatch({
        type: SET_MESSAGES,
        payload: messages
    });
}

export const setMessageData = (message: IMessageResponse) => (dispatch: any) => {
    dispatch({
        type: SET_MESSAGE_BY_ID,
        payload: message
    });
}