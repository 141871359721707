import {
	SET_BIOMETRICO,
	SET_BIOMETRICOS,
	SET_BIOMETRICO_COUNTER,
	SET_BIOMETRICO_PAGE,
	SET_BIOMETRICO_LOADING,
	SET_BIOMETRICOS_LOADING,
	SET_BIOMETRICO_ERRORS,
} from "../constants/biometricos.constants";

import IBiometrico from "../models/Biometrico.model";

const initialState = {
	loading: false,
	loadingBiometrico: false,
	biometricos: [] as ReadonlyArray<IBiometrico>,
	biometrico: null as unknown as Readonly<IBiometrico>,
	counter: 10,
	page: 1,
	errors: "",
};

export type State = Readonly<typeof initialState>;

const reducer = (state: State = initialState, action: any): State => {
	switch (action.type) {
		case SET_BIOMETRICO:
			return {
				...state,
				loading: false,
				loadingBiometrico: false,
				biometrico: action.payload,
				errors: "",
			};
		case SET_BIOMETRICOS:
			return {
				...state,
				loading: false,
				loadingBiometrico: false,
				biometricos: action.payload,
				errors: "",
			};
		case SET_BIOMETRICO_COUNTER:
			return {
				...state,
				counter: action.payload,
			};
		case SET_BIOMETRICO_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case SET_BIOMETRICO_LOADING:
			return {
				...state,
				loadingBiometrico: true,
				errors: "",
			};
		case SET_BIOMETRICOS_LOADING:
			return {
				...state,
				loading: true,
				errors: "",
			};
		case SET_BIOMETRICO_ERRORS:
			return {
				...state,
				loading: false,
				loadingBiometrico: false,
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
