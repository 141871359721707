import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/store";
import { read, utils, writeFile } from "xlsx";
import {
	Button,
	Dropdown,
	Grid,
	Header,
	Label,
	Table,
} from "semantic-ui-react";
import { fillListUsers } from "../../../../actions/user-admin.actions";
import IRegistro from "../../../../models/registros.leads.model";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../css/users.css";
import CAMPAIGNS, {
	DISTRIBUTION_CAMPAIGNS,
} from "../../../../constants/dashboard/campaigns.constants";
import { namespace_1 } from "../../../../constants/enviroment";
import { getType } from "../helpers/leads.utils";

interface IState {
	tipoBase: string;
	tipoBaseValido: boolean;
	registros: IRegistro[];
	loading: boolean;
	errors: string[];
	max: number;
}

const LoadXLSZ: React.FC<IProps> = ({
	agent,
	profile,
	loading,
	errors,
	isAgent,
	isCoordinator,
	isAdmin,
	fillListUsers,
}) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const bot_id = isAgent ? profile.bot_id : agent?.bot_id;

	const [state, setState] = useState<IState>({
		tipoBase:
			bot_id === namespace_1
				? CAMPAIGNS[0].value
				: DISTRIBUTION_CAMPAIGNS[0].value,
		tipoBaseValido: true,
		registros: [],
		loading: false,
		errors: [],
		max: 50,
	});

	const reader = new FileReader();

	const validate = (registro: IRegistro, index: number) => {
		let { errors } = state;

		let { name, phone } = registro;

		name = name?.trim().replaceAll(" ", "");
		phone = phone ? phone.toString().trim().replaceAll(" ", "") : "";

		let isValid = false;
		if (!name || name === "") {
			errors.push(`Cliente ${index}: Nombre no puede estar vacío`);
			return isValid;
		}
		if (!phone || phone === "") {
			errors.push(`Cliente ${index}: Teléfono no puede estar vacío`);
			return isValid;
		}
		if (
			!Number.isInteger(Number(registro.phone)) ||
			registro.phone?.toString().length !== 10
		) {
			errors.push(
				`Cliente ${index}: Teléfono invalido: ${phone} (Debe ser un número a 10 dígitos y sin espacios)`
			);
			return isValid;
		}

		isValid = true;
		return isValid;
	};

	const handleChangeType = (e: any, data: any) => {
		let { registros } = state;
		registros.map((registro: IRegistro, index: number) => {
			registros[index] = {
				...registro,
				isMacro: false,
				isMultiva: false,
				isCompra: false,
				isGEM: false,
				isGEMCompra: false,
				isJubilado: false,
				isTradicional: false,
				isUNAM: false,
				isNL: false,
				isRenovation: false,
				isMultivaDistribucion: false,
				...getType(data.value),
			};
		});
		setState({
			...state,
			tipoBase: data.value,
			tipoBaseValido: data.value !== null && data.value !== "all",
			registros,
		});
	};

	const handleImport = ($event: any) => {
		setState({ ...state, loading: true, registros: [], errors: [] });
		const files = $event.target.files;
		if (files.length) {
			const file = files[0];
			reader.onload = (event) => {
				const wb = read(event.target!.result);
				const sheets = wb.SheetNames;
				if (sheets.length) {
					const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
					let registros: any[] = [];
					rows.map((row: any, index: number) => {
						const filters = registros.filter(
							(r: any) => r.phone === row.phone
						);
						if (filters.length === 0) {
							const isValid = validate(row, index + 1);
							if (isValid) {
								console.log(
									"TIPO",
									state.tipoBase,
									getType(state.tipoBase)
								);
								row = {
									...row,
									isMacro: false,
									isMultiva: false,
									isCompra: false,
									isGEM: false,
									isGEMCompra: false,
									isJubilado: false,
									isTradicional: false,
									isMultivaDistribucion: false,
									isUNAM: false,
									isNL: false,
									...getType(state.tipoBase),
								};
								registros.push(row);
							}
						}
						return row;
					});
					setState({
						...state,
						registros: registros.slice(0, state.max),
					});
				}
			};
			reader.readAsArrayBuffer(file);
		}
		setState({ ...state, loading: false });
	};

	const handleExport = (isPlantilla: boolean) => {
		const headings = [
			[
				"name",
				"lastName",
				"phone",
				"email",
				"curp",
				"nss",
				"capacidad_credito",
				"matricula",
				"delegacion",
				"salario",
				"claveIssemym",
			],
		];
		const wb = utils.book_new();
		const ws = utils.json_to_sheet([]);
		utils.sheet_add_aoa(ws, headings);
		utils.sheet_add_json(ws, isPlantilla ? [] : state.registros, {
			origin: "A2",
			skipHeader: true,
		});
		utils.book_append_sheet(wb, ws, "Report");
		writeFile(wb, "Plantilla.xlsx");
	};

	const save = async () => {
		const { registros, tipoBase } = state;
		let { tipoBaseValido } = state;

		tipoBaseValido = tipoBase !== "" && tipoBase !== "all";

		if (id && registros.length > 0 && tipoBaseValido) {
			console.log("TIPO BASE VALIDO", tipoBase, tipoBaseValido);
			const filled = await fillListUsers(id, registros);
			if (filled) {
				if (isAgent) {
					navigate(`/customers`);
				} else {
					navigate(`/agent/${id}`);
				}
			}
		}

		setState({
			...state,
			tipoBaseValido,
		});
	};

	const clean = () => {
		document.getElementById("uploadLeadsButton")!.setAttribute("value", "");
		reader.abort();
		setState({
			...state,
			registros: [],
			errors: [],
		});
	};

	const getClassName = (registro: IRegistro) => {
		let className = "";
		if (registro.matricula) {
			className = "jubilado";
		}
		return className;
	};

	return (
		<>
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<Header>Carga de Clientes</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1}>
					<Grid.Column>
						<Dropdown
							value={state.tipoBase}
							placeholder="Tipo de lead"
							search
							options={
								bot_id === namespace_1
									? CAMPAIGNS
									: DISTRIBUTION_CAMPAIGNS
							}
							label="Tipo de lead"
							onChange={handleChangeType}
						/>
					</Grid.Column>
					<Grid.Column>
						{!state.tipoBaseValido ? (
							<Label color="red">
								Seleccione un tipo de base específico.
							</Label>
						) : null}
					</Grid.Column>
				</Grid.Row>
				{errors ? <Label color="red">{errors}</Label> : null}
				<Grid.Row>
					<Grid.Column>
						<input
							id="uploadLeadsButton"
							type="file"
							name="file"
							onChange={handleImport}
							style={{ display: "none" }}
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
						<Button
							color="green"
							floated="right"
							onClick={() => {
								document
									.getElementById("uploadLeadsButton")!
									.click();
							}}
						>
							Subir Archivo
						</Button>
						<Button
							color="blue"
							floated="right"
							onClick={() => handleExport(true)}
						>
							Descargar plantilla
						</Button>
					</Grid.Column>
				</Grid.Row>
				{state.errors.length > 0 ? (
					<Grid.Row>
						<Grid.Column>
							<Header color="red">
								<Header.Content>
									Algúnos clientes no se pudieron cargar
								</Header.Content>
								<Header.Subheader>
									{state.errors.map((e) => {
										return (
											<>
												<h6 style={{ color: "red" }}>
													{e}
												</h6>
												<br />
											</>
										);
									})}
								</Header.Subheader>
							</Header>
						</Grid.Column>
					</Grid.Row>
				) : null}
				<Grid.Row>
					<Grid.Column>
						<Table attached celled selectable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>ID</Table.HeaderCell>
									<Table.HeaderCell>Nombre</Table.HeaderCell>
									<Table.HeaderCell>
										Apellido
									</Table.HeaderCell>
									<Table.HeaderCell>
										Teléfono
									</Table.HeaderCell>
									<Table.HeaderCell>Email</Table.HeaderCell>
									<Table.HeaderCell>CURP</Table.HeaderCell>
									<Table.HeaderCell>NSS</Table.HeaderCell>
									<Table.HeaderCell>CC</Table.HeaderCell>
									<Table.HeaderCell>
										Matrícula
									</Table.HeaderCell>
									<Table.HeaderCell>
										Delegación
									</Table.HeaderCell>
									<Table.HeaderCell>Salario</Table.HeaderCell>
									<Table.HeaderCell>
										Clave Issemym
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{!state.loading && state.registros
									? state.registros.map(
											(registro: IRegistro, index) => {
												return (
													<Table.Row
														key={index}
														className={getClassName(
															registro
														)}
													>
														<Table.Cell>
															{index + 1}
														</Table.Cell>
														<Table.Cell>
															{registro.name}
														</Table.Cell>
														<Table.Cell>
															{registro.lastName}
														</Table.Cell>
														<Table.Cell>
															{registro.phone}
														</Table.Cell>
														<Table.Cell>
															{registro.email}
														</Table.Cell>
														<Table.Cell>
															{registro.curp}
														</Table.Cell>
														<Table.Cell>
															{registro.nss}
														</Table.Cell>
														<Table.Cell>
															{
																registro.capacidad_credito
															}
														</Table.Cell>
														<Table.Cell>
															{registro.matricula}
														</Table.Cell>
														<Table.Cell>
															{
																registro.delegacion
															}
														</Table.Cell>
														<Table.Cell>
															{registro.salario}
														</Table.Cell>
														<Table.Cell>
															{
																registro.claveIssemym
															}
														</Table.Cell>
													</Table.Row>
												);
											}
									  )
									: null}
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Button
							color="green"
							floated="right"
							onClick={save}
							disabled={loading}
							loading={loading}
						>
							Registrar
						</Button>
						<Button
							color="grey"
							floated="right"
							onClick={() => navigate(-1)}
						>
							Cancelar
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	profile: state.users.profile,
	agent: state.adminAgents.agent,
	errors: state.adminUsers.errors,
	loading: state.adminUsers.loading,
	isAdmin: state.users.user.type === "IS_ADMIN",
	isCoordinator: state.users.user.type === "IS_COORDINATOR",
	isAgent:
		state.users.user.type === "IS_AGENT" ||
		state.users.user.type === "IS_TOP",
});

const mapActionsToProps = {
	fillListUsers,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(LoadXLSZ);
