import { Input, Popup, Button, Icon } from "semantic-ui-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AudioMessage } from "./AudioMessage/AudioMessage";
import { DocumentMessage } from "./DocumentMessage/DocumentMessage";
import { ImageMessage } from "./ImageMessage/ImageMessage";
import TemplateContainer from "../../../Templates/components/TemplateContainer";

export const ChatContainer = ({
    chatPermission,
    generalPermission,
    emitMessage,
    setMessage,
    message,
    isAgent,
    profile,
    agent,
}: any) => {
    const [selectedImage, setSelectedImage] = useState<any>();
    const [selectedDocument, setSelectedDocument] = useState<any>();
    const [media, setMedia] = useState<any>();
    const { id } = useParams();

    const handleDocument = (e: any) => {
        if (!e.target.files[0]) return;
        setSelectedDocument(e.target.files[0]);
        const data = new FileReader();
        data.addEventListener("load", () => {
            setMedia(data.result);
        });
        data.readAsDataURL(e.target.files[0]);
    };

    const handleImage = (e: any) => {
        if (!e.target.files[0]) return;
        setSelectedImage(e.target.files[0]);
        const data = new FileReader();
        data.addEventListener("load", () => {
            setMedia(data.result);
        });
        data.readAsDataURL(e.target.files[0]);
    };

    const messageSubmit = () => {
        if (!message) {
            console.log("mensaje vacio");
            return;
        }
        emitMessage({
            phone: id,
            message,
            agent_id: profile.id,
            agent_phone: profile.phone,
        });
        setMessage("");
    };

    return (
        <>
            {!selectedDocument && !selectedImage ? (
                <>
                    <div className="chatcrm-message-input">
                        {generalPermission ? (
                            <>
                                {/* <TemplateContainer
                                    agent={isAgent ? profile : agent}
                                    emit={emitMessage}
                                /> */}
                                {chatPermission ? (
                                    <>
                                        <Popup
                                            content={
                                                <div className="chatcrm-message-popup">
                                                    <Input
                                                        label="Subir Imagenes"
                                                        id="upload"
                                                        type="file"
                                                        name="upload"
                                                        title="Subir Imagenes"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        onChange={handleImage}
                                                        accept="image/*"
                                                    />
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor:
                                                                "white",
                                                        }}
                                                        onClick={() => {
                                                            document
                                                                .getElementById(
                                                                    "upload"
                                                                )!
                                                                .click();
                                                        }}
                                                    >
                                                        <Icon
                                                            name="images"
                                                            color="black"
                                                            size="large"
                                                        />
                                                    </button>
                                                    <Input
                                                        label="Subir Archivos"
                                                        id="uploadDocuments"
                                                        type="file"
                                                        name="upload"
                                                        title="Subir Archivos"
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        onChange={
                                                            handleDocument
                                                        }
                                                        accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
                                                    />
                                                    <button
                                                        type="button"
                                                        style={{
                                                            backgroundColor:
                                                                "white",
                                                        }}
                                                        onClick={() => {
                                                            document
                                                                .getElementById(
                                                                    "uploadDocuments"
                                                                )!
                                                                .click();
                                                        }}
                                                    >
                                                        <Icon
                                                            name="paperclip"
                                                            color="black"
                                                            size="large"
                                                        />
                                                    </button>
                                                </div>
                                            }
                                            on="click"
                                            position="top center"
                                            trigger={
                                                <Button
                                                    icon="add"
                                                    size="large"
                                                    circular
                                                />
                                            }
                                        />
                                        <input
                                            value={message}
                                            type="text"
                                            placeholder="Escribe un mensaje"
                                            autoFocus
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            onKeyDown={(e) =>
                                                e.key === "Enter"
                                                    ? messageSubmit()
                                                    : null
                                            }
                                        />
                                        <AudioMessage
                                            emitMessage={emitMessage}
                                            message={message}
                                            messageSubmit={messageSubmit}
                                            profile
                                        />
                                    </>
                                ) : (
                                    <input
                                        type="text"
                                        placeholder="No puedes enviar mensajes aun...."
                                        disabled
                                    />
                                )}
                            </>
                        ) : (
                            <input
                                type="text"
                                placeholder="El cliente no quiere recibir mensajes en este momento"
                                disabled
                            />
                        )}
                    </div>
                </>
            ) : !selectedImage && selectedDocument ? (
                <DocumentMessage
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                    emitMessage={emitMessage}
                    media={media}
                    profile
                />
            ) : (
                <ImageMessage
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    setMessage={setMessage}
                    message={message}
                    emitMessage={emitMessage}
                    media={media}
                    profile
                />
            )}
        </>
    );
};
