import { initialFormField, initialDocument } from "./utils.init";

export const initialState = {
    form: {
        folio: {
            ...initialFormField,
            rule: new RegExp(/^.*$/).source,
        },
        fecha_llamada: {
            ...initialFormField,
            value: Date.now(),
            type: 'datetime-local',
            rule: new RegExp(/^.*$/).source,
        },
        observaciones: initialFormField,
        status: {
            ...initialFormField,
            type: 'dropdown',
            value: 'NUEVO',
            options: [
                'NUEVO',
                'EN REVISION',
                'OBSERVACIONES',
                'COMPLETO'
            ]
        },
    },

    documents: {
        evidencia_folio: {
            ...initialDocument,
            extensions: ['image/*']
        },
        evidencia_firma: {
            ...initialDocument,
            extensions: ['image/*']
        }
    }
}