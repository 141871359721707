import { useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const DocumentMessage = ({
    selectedDocument,
    setSelectedDocument,
    emitMessage,
    profile,
    media,
}: any) => {
    const { id } = useParams();

    const documentSubmit = (e: any) => {
        e.preventDefault();
        emitMessage({
            phone: id,
            document: media,
            fileName: selectedDocument.name,
            agent_id: profile.id,
            agent_phone: profile.phone,
        });
        setSelectedDocument("");
    };

    return (
        <form>
            {selectedDocument && (
                <div className="chatcrm-document-form">
                    <h1 style={{ color: "white" }}>
                        Documento: {selectedDocument.name}
                    </h1>
                    <br />
                    <button
                        onClick={documentSubmit}
                        className="chatcrm-message-input-send"
                    >
                        <Icon name="send" size="large" />
                    </button>
                    <button
                        onClick={() => setSelectedDocument("")}
                        className="chatcrm-message-input-delete"
                    >
                        <Icon name="trash alternate outline" size="large" />
                    </button>
                </div>
            )}
        </form>
    );
};
