import { Icon, Message } from "semantic-ui-react";

export const ValidRecipient = () => {
    return (
        <div className="chatcrm-not-valid-recipient">
            <Message warning attached="bottom">
                <h3>
                    <Icon name="warning" />
                    Este lead no tiene cuenta de WhatsApp
                </h3>
                Es posible que el lead haya bloqueado nuestra cuenta
            </Message>
        </div>
    );
};
