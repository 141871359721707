import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Header, Image, Modal, Icon, Input, Grid, TextArea } from 'semantic-ui-react';
import { IUserModel } from '../../../../models/User.model';
import { RootState } from '../../../../store/store';
import "../../../../css/events.css";

interface IState {
  date: string;
  dateValid: boolean;
  note: string;
  noteValid: boolean;
}

const CreateEventModal:React.FC<IDetailUserProps> = ({
    user,
}) => {
  const [state, setState] = useState<IState>({
    date: "",
    dateValid: true,
    note: "",
    noteValid: true
  });
  const [open, setOpen] = React.useState(false)
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          basic
          color="red"
          onClick={() => {
              setOpen(true);
          }}
        >
          Agendar
        </Button>
      }
      style={{ 
        left: 'auto',
        right: 'auto',
        top: '20%',
        height: 'fit-content'
      }}
    >
      <Modal.Header>Agenda un evento</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header textAlign='center'>{user.name} {user.lastName}</Header>
          <p>
            Teléfono: {user.phone}
          </p>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign='center' computer={16} mobile={16}>
                <Input
                  
                  type='datetime-local'
                  value={state.date}
                  className='w-300'
                  
                  onChange={(e)=>{
                    console.log(`FECHA ${e.currentTarget.value}`)
                    setState({
                      ...state,
                      date: e.currentTarget.value,
                      dateValid: e.currentTarget.value !== ""
                    })
                  }}
                  
                />
              </Grid.Column>
              <Grid.Column textAlign='center' computer={16} mobile={16}>
                <TextArea
                  className="w-300"
                  type='textarea'
                  rows={3}
                  value={state.note}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions >
        <Button color='black' onClick={() => setOpen(false)}>
          Cerrar
        </Button>
        <Button
          content="Editar"
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export interface IProps {
  user: IUserModel
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState, props: IProps) => {
    return {
        user: props.user
    }
}

const mapActionsToProps = {

}

const connector = connect(mapStateToProps, mapActionsToProps);

type IDetailUserProps = ConnectedProps<typeof connector>;

export default connector(CreateEventModal);