import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import {
	setCounter,
	setErrors,
	setAllLoading,
	setSolicitudesData,
	setSolicitudData,
} from "./utils.actions";

export const getAll = (body: any) => async (dispatch: any) => {
	console.log("GET ALL EXPEDIENTES");
	let loaded = false;
	await dispatch(setAllLoading());

	await axios
		.post(`${apiUrl}/solicitudes/getAll`, body)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(
					"No se han podido cargar los expedientes",
					res.data.message
				);
				dispatch(setErrors(res.data.message));
				return loaded;
			}

			let data = res.data.paginationData;

			console.log("DATA", data);

			dispatch(setCounter(data.totalDocs));
			dispatch(setSolicitudesData(data.docs));
			loaded = true;
		})
		.catch((err) => {
			console.error(
				"Error al cargar los expedientes",
				err.response.data.message
			);
			setErrors(err.response.data.message);
		});

	return loaded;
};

export const getByExpedienteId = (id: string) => async (dispatch: any) => {
	dispatch(setAllLoading());
	await axios
		.get(`${apiUrl}/solicitudes/expediente/${id}`)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			dispatch(setSolicitudesData(res.data.solicitudes));
		})
		.catch((err) => {
			console.error(err);
			dispatch(setErrors(err.response.data.message));
		});
};

export const getById = (id: string) => async (dispatch: any) => {
	dispatch(setAllLoading());
	await axios
		.get(`${apiUrl}/solicitudes/${id}`)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			dispatch(setSolicitudData(res.data.solicitud));
		})
		.catch((err) => {
			console.error(err);
			dispatch(setErrors(err.response.data.message));
		});
};
