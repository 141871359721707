import {
	SET_LOADING_ERRORS_ADMIN_AGENT,
	CLEAR_LOADING_ERRORS_ADMIN_AGENT,
	LOADING_UPDATE_ADMIN_AGENT,
} from "../../constants/agent-admin.constants";

import axios from "axios";
import { IAgentModel } from "../../models/Agent.model";
import { apiUrl } from "../../constants/enviroment";
import { setErrors, setAgentData } from "./utils.actions";
import FormData from "form-data";

export const updateImage =
	(agent: IAgentModel, image: File, position: number) =>
	async (dispatch: any) => {
		let updated = false;
		dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });

		const formData = new FormData();

		formData.append("name", agent.name);

		formData.append("lastName", agent.lastName);

		formData.append("position", position);

		formData.append(
			"image",
			image,
			position === -1 ? `profile_image.jpg` : `${position + 1}.png`
		);

		const config = {
			headers: {
				Accept: "multipart/form-data",
				"Content-Type": "multipart/form-data;",
			},
		};

		await axios
			.put(`${apiUrl}/agent/updateImage/${agent.id}`, formData, config)
			.then(async (res) => {
				if (res.status !== 200) {
					dispatch(setErrors(res.data.message));
					return updated;
				}
				dispatch(setAgentData(res.data.agent));
				updated = true;
			})
			.catch((err) => {
				console.error(err);
				dispatch(setErrors(err.response.data.message));
				updated = false;
			});

		return updated;
	};

export const update = (agent: IAgentModel) => async (dispatch: any) => {
	let updated = false;
	dispatch({ type: LOADING_UPDATE_ADMIN_AGENT });
	delete agent.password;

	await axios
		.put(`${apiUrl}/agent/${agent.id}`, agent)
		.then((res) => {
			dispatch({ type: CLEAR_LOADING_ERRORS_ADMIN_AGENT });
			if (res.status !== 200) {
				dispatch(setErrors(res.data.message));
				return updated;
			}
			const agente = res.data.agent;
			dispatch(setAgentData(agente));
			updated = true;
		})
		.catch((err) => {
			console.error(err.response.data.message);
			dispatch(setErrors(err.response.data.message));
		});
	return updated;
};
