import {
    SET_DISCOUNT,
    SET_DISCOUNTS,
    SET_LOADING_DISCOUNT,
    SET_LOADING_DISCOUNTS,
} from "../constants/discounts.constants";

const initialState = {
    loading: false,
    loadingDiscount: false,
    discount: {},
    discounts: [],
    discountsFiltered: [],
    errors: "",
};

export type State = Readonly<typeof initialState>;

const discountsReducer = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case SET_DISCOUNT:
            return {
                ...state,
                loading: false,
                loadingDiscount: false,
                discount: action.payload,
                errors: "",
            };
        case SET_DISCOUNTS:
            return {
                ...state,
                loading: false,
                loadingDiscount: false,
                discounts: action.payload,
                discountsFiltered: action.payload,
                errors: "",
            };
        case SET_LOADING_DISCOUNTS:
            return {
                ...state,
                loading: true,
                errors: "",
            };
        case SET_LOADING_DISCOUNT:
            return {
                ...state,
                loadingDiscount: true,
                errors: "",
            };
        default:
            return state;
    }
};

export default discountsReducer;
