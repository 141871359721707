import React, { useState } from "react";
import { RootState } from "../../../store/store";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Modal,
    Grid,
    Button,
    Form,
    TextArea,
    Input,
    Icon,
    Label,
} from "semantic-ui-react";
import { create, update } from "../../../actions/group_templates.actions";
import { CategoryTemplates } from "./interfaces/categoryTemplates.interface";

interface IState {
    isNew: boolean;
    category_name: string;
    titleValid: boolean;
    description: string;
    descriptionValid: boolean;
}

const GroupTemplateEdit: React.FC<IGroupTemplateEditProps> = ({
    user,
    group,
    create,
    update,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState<IState>({
        isNew: id ? false : true,
        category_name: id ? group?.category_name ?? "" : "",
        titleValid: true,
        description: id ? group?.description ?? "" : "",
        descriptionValid: true,
    });

    const onClose = () => {
        navigate(-1);
    };

    const save = async () => {
        const { category_name, description } = state;
        let { titleValid, descriptionValid } = state;

        titleValid = category_name !== "";
        descriptionValid = description !== "";

        let group: CategoryTemplates = {
            agentId: user.id,
            category_name: category_name,
            description: description,
        };

        if (titleValid && descriptionValid) {
            if (state.isNew) {
                let created = (await create(group))!;
                console.log("created 2", created);
                if (created) {
                    onClose();
                }
            } else {
                group._id = group._id;
                let updated = await update(group);
                if (updated) {
                    onClose();
                }
            }
        }

        setState({
            ...state,
            titleValid,
            descriptionValid,
        });
    };

    return (
        <Modal open={true} onClose={onClose} className="default-modal">
            <Modal.Header>{`${
                state.isNew ? "Crear" : "Editar"
            } Categoría/Campaña`}</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Form>
                                <Form.Field>
                                    <label>Nombre</label>
                                    <Input
                                        placeholder="Nombre de la categoría o campaña"
                                        value={state.category_name}
                                        error={!state.titleValid}
                                        onChange={(e, { value }) => {
                                            setState({
                                                ...state,
                                                category_name: value,
                                                titleValid: value !== "",
                                            });
                                        }}
                                    />
                                    {!state.titleValid ? (
                                        <Label pointing prompt>
                                            Se requiere un nombre de categoría o
                                            campaña
                                        </Label>
                                    ) : null}
                                </Form.Field>
                                <Form.Field>
                                    <label>Descripción</label>
                                    <TextArea
                                        placeholder="Descripción de la categoría o campaña"
                                        value={state.description}
                                        onChange={(e, { value }) => {
                                            setState({
                                                ...state,
                                                description: value!.toString(),
                                                descriptionValid: value !== "",
                                            });
                                        }}
                                    />
                                    {!state.descriptionValid ? (
                                        <Label pointing prompt>
                                            Se requiere una descripción para la
                                            categoría o campaña
                                        </Label>
                                    ) : null}
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="red" onClick={onClose}>
                    <Icon name="remove" /> Cancelar
                </Button>
                <Button color="green" onClick={save}>
                    <Icon name="checkmark" /> Crear
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.users.user,
        group: state.groupTemplates.group_template,
        loading: state.groupTemplates.loadingTemplate,
        errors: state.groupTemplates.errors,
    };
};

const mapActionsToProps = {
    create,
    update,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IGroupTemplateEditProps = ConnectedProps<typeof connector>;

export default connector(GroupTemplateEdit);
