import Swal from "sweetalert2";
import { useState } from "react";
import { postTemplates } from "./services/postTemplate";
import {
    Grid,
    GridColumn,
    Label,
    Segment,
    Form,
    Button,
    Icon,
    Input,
    TextArea,
    Divider,
    Dimmer,
    Loader,
    Checkbox,
} from "semantic-ui-react";
import "./CreateTemplate.css";

export const CreateTemplate = ({
    setCategory,
    setModalCreateOpen,
    getByGroupId,
    groupTemplate,
}: any) => {
    const [loading, setLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [variable, setVariable] = useState<any>([]);
    const [button, setButton] = useState<any>([]);
    const [template, setTemplate] = useState<any>({
        alias: "",
        text: "",
        variables: [],
        botones: [],
    });

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleAlias = (e: any) => {
        setTemplate({
            ...template,
            alias: e.target.value,
        });
    };

    const handleText = (e: any) => {
        setTemplate({
            ...template,
            text: e.target.value,
        });
    };

    const handleAddVariable = () => {
        setVariable([...variable, { value: "" }]);
        let { text, variables } = template;
        variables.push({});
        text = `${text} {{${variables.length}}} `;
        setTemplate({
            ...template,
            text,
            variables,
        });
    };

    const handleChangeVariable = (e: any, i: any) => {
        const { value } = e.target;
        const onChangeVal = [...variable];
        onChangeVal[i] = value;
        setVariable(onChangeVal);
    };

    const handleDeleteBody = () => {
        setVariable([]);
        setTemplate({
            ...template,
            text: "",
            variables: [],
        });
    };

    const handleAddButton = () => {
        setButton([...button, { text: "" }]);
        let { text, botones } = template;
        botones.push({});
        setTemplate({
            ...template,
            text,
            botones,
        });
    };

    const handleChangeButton = (e: any, i: any) => {
        const { value } = e.target;
        const onChangeVal = [...button];
        onChangeVal[i] = value;
        setButton(onChangeVal);
    };

    const handleDeleteButtons = () => {
        setButton([]);
        setTemplate({
            ...template,
            botones: [],
        });
    };

    const BD_MODEL = () => {
        if (template.variables.length <= 0 && template.botones.length <= 0)
            return {
                alias: template.alias,
                type: "BD",
                category_id: setCategory._id,
                editable: false,
                buttons: [],
                body: template.text,
                variables: [],
            };
        else if (template.variables.length <= 0 && template.botones.length > 0)
            return {
                alias: template.alias,
                type: "BD",
                category_id: setCategory._id,
                editable: false,
                buttons: Object.values(button),
                body: template.text,
                variables: [],
            };
        else if (template.variables.length > 0 && template.botones.length <= 0)
            return {
                alias: template.alias,
                type: "BD",
                category_id: setCategory._id,
                editable: true,
                buttons: [],
                body: template.text,
                variables: Object.values(variable),
            };
        else
            return {
                alias: template.alias,
                type: "BD",
                category_id: setCategory._id,
                editable: true,
                buttons: Object.values(button),
                body: template.text,
                variables: Object.values(variable),
            };
    };

    const submitTemplate = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const postData = BD_MODEL();
        console.log("DATA TEMPLATE", postData);
        await postTemplates(postData)
            .then((res) => {
                setLoading(false);
                setModalCreateOpen(false);
                getByGroupId(groupTemplate._id!);
                console.log("TEMPLATE CREADO", res);
                Swal.fire(
                    "Template Creado",
                    "Template creado en Meta y Base de Datos",
                    "success"
                );
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                Swal.fire({
                    title: "ERROR",
                    text: `ERROR, ${error.error} / ${error.message}`,
                    icon: "error",
                    showCancelButton: true,
                    confirmButtonText: "Reenviar",
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        submitTemplate(e);
                    }
                });
            });
    };

    return (
        <>
            <Grid padded centered>
                <Grid.Row columns={2}>
                    <GridColumn>
                        <Segment
                            color="blue"
                            style={{
                                height: "650px",
                                backgroundColor: "#FAFAD2",
                            }}
                        >
                            <Label attached="top">
                                <h3 style={{ textAlign: "center" }}>
                                    Visualización
                                </h3>
                            </Label>
                            <br />
                            <br />
                            <br />
                            <div className="preview-template-container">
                                <div className="preview_template_text">
                                    <p>{template.text}</p>
                                </div>
                            </div>
                        </Segment>
                    </GridColumn>
                    <GridColumn>
                        <Segment color="green" className="container_form">
                            {loading === false ? (
                                <>
                                    <Label attached="top">
                                        <h3 style={{ textAlign: "center" }}>
                                            Contenido
                                        </h3>
                                    </Label>
                                    <Form className="form_create_template">
                                        <Form.Field
                                            control={Input}
                                            label="Nombre del template :"
                                            placeholder="Nombre"
                                            value={template.alias}
                                            onChange={handleAlias}
                                        />
                                        <div>
                                            <Segment compact>
                                                <Checkbox
                                                    toggle
                                                    label="Botones"
                                                    checked={isChecked}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                            </Segment>
                                        </div>
                                        <Divider horizontal>Body</Divider>
                                        <Segment>
                                            <Button
                                                floated="right"
                                                inverted
                                                color="red"
                                                onClick={() =>
                                                    handleDeleteBody()
                                                }
                                            >
                                                <Icon name="trash" /> Vaciar
                                            </Button>
                                            <Button
                                                floated="right"
                                                inverted
                                                color="green"
                                                onClick={() =>
                                                    handleAddVariable()
                                                }
                                            >
                                                <Icon name="plus" /> Variable
                                            </Button>
                                            {variable &&
                                                variable.map(
                                                    (val: any, i: any) => {
                                                        return (
                                                            <>
                                                                <Form.Field
                                                                    control={
                                                                        Input
                                                                    }
                                                                    label="Variable :"
                                                                    name="value"
                                                                    value={
                                                                        val.value
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) =>
                                                                        handleChangeVariable(
                                                                            e,
                                                                            i
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        );
                                                    }
                                                )}
                                            <Form.Field
                                                control={TextArea}
                                                label="Mensaje :"
                                                placeholder="Escribe el contenido del template..."
                                                value={template.text}
                                                onChange={handleText}
                                            />
                                        </Segment>
                                        {isChecked ? (
                                            <>
                                                <Divider horizontal>
                                                    Botones
                                                </Divider>
                                                <Segment clearing>
                                                    <Button
                                                        floated="right"
                                                        inverted
                                                        color="red"
                                                        onClick={() =>
                                                            handleDeleteButtons()
                                                        }
                                                    >
                                                        <Icon name="trash" />{" "}
                                                        Vaciar
                                                    </Button>
                                                    <Button
                                                        floated="right"
                                                        inverted
                                                        color="green"
                                                        onClick={() =>
                                                            handleAddButton()
                                                        }
                                                    >
                                                        <Icon name="plus" />{" "}
                                                        Boton
                                                    </Button>
                                                    {button &&
                                                        button.map(
                                                            (
                                                                val: any,
                                                                i: any
                                                            ) => {
                                                                return (
                                                                    <>
                                                                        <Form.Field
                                                                            control={
                                                                                Input
                                                                            }
                                                                            label="Texto del Boton :"
                                                                            name="value"
                                                                            value={
                                                                                val.value
                                                                            }
                                                                            onChange={(
                                                                                e: any
                                                                            ) =>
                                                                                handleChangeButton(
                                                                                    e,
                                                                                    i
                                                                                )
                                                                            }
                                                                        />
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                </Segment>
                                            </>
                                        ) : null}

                                        <Button
                                            floated="right"
                                            color="green"
                                            onClick={(e: any) =>
                                                submitTemplate(e)
                                            }
                                        >
                                            <Icon name="check" /> Guardar
                                        </Button>
                                    </Form>
                                </>
                            ) : (
                                <Dimmer active inverted>
                                    <Loader
                                        indeterminate
                                        content="Creando Template"
                                    />
                                </Dimmer>
                            )}
                        </Segment>
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </>
    );
};
