import {
    SET_AGENT_DASHBOARD,
    SET_AGENT_DASHBOARD_TIME,
    LOADING_AGENT_DASHBOARD,
    SET_AGENT_DASHBOARD_ERRORS,
    CLEAR_AGENT_DASHBOARD_ERRORS
  } from '../constants/dashboard.agent.constants'
  import DashboardAgent from '../models/IDashboard.agent.model';
  
  const initialState = {
    loading: false,
    data: {} as Readonly<DashboardAgent>,
    time: 'week' as Readonly<String>,
    errors: "",
  }
  
  export type InitialState = Readonly<typeof initialState>;
  
  const reducer = (state: InitialState = initialState, action: any): InitialState  => {
    switch (action.type) {
      case SET_AGENT_DASHBOARD:
        return {
          ...state,
          loading: false,
          errors: "",
          data: action.payload
        };
      case SET_AGENT_DASHBOARD_TIME:
        return {
          ...state,
          time: action.payload
        };
      case LOADING_AGENT_DASHBOARD:
        return {
          ...state,
          loading: true,
          errors: "",
        };
      case SET_AGENT_DASHBOARD_ERRORS:
        return {
          ...state,
          loading: false,
          errors: action.payload
        };
      case CLEAR_AGENT_DASHBOARD_ERRORS:
        return {
          ...state,
          loading: false,
          errors: ""
        };
      default:
        return state;
    }
  }
  
  export default reducer;