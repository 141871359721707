import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Paper, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link, useNavigate } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
//redux stuff
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/store';
import { Checkbox, Image, Label } from 'semantic-ui-react';
import { IUserModel } from '../../../models/User.model';

export interface ISignupState {
    email: string;
    emailValid: boolean;
    name: string;
    nameValid: boolean;
    lastName: string;
    lastNameValid: boolean;
    phone: string;
    phoneValid: boolean;
    password: string;
    passwordValid: boolean;
    confirmPassword: string;
    confirmPasswordValid: boolean;
    terms: boolean;
    termsValid: boolean;
}

const SignUp: React.FC<ILoginProps> = ({
    userState,
}) => {
    const [state, setState] = useState<ISignupState>({
        email: "",
        emailValid: true,
        name: "",
        nameValid: true,
        lastName: "",
        lastNameValid: true,
        phone: "",
        phoneValid: true,
        password: "",
        passwordValid: true,
        confirmPassword: "",
        confirmPasswordValid: true,
        terms: false,
        termsValid: true,
    })
    const history = useNavigate();

    const [errors, setErrors] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(userState !== undefined ? userState.loading : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState])

    useEffect(() => {
        setErrors(userState.errors)
    }, [userState.errors])

    useEffect(() => {
        if(userState !== undefined && !userState.loading) {
            if(userState.authenticated) {
                /* switch(userState.user.role.role){
                    case"admin":
                    case"localadmin":
                        history("/admin")
                        break;
                    default:
                        history("/")
                        break;
                } */
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState.authenticated])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let { 
            emailValid, 
            passwordValid, 
            confirmPasswordValid,
            nameValid,
            lastNameValid,
            phoneValid,
            termsValid
        } = state;
        const { 
            email, 
            password, 
            confirmPassword,
            name,
            lastName,
            phone,
            terms
        } = state;
       
        const user: IUserModel = {
            name: state.name,
            lastName: state.lastName,
            phone: '+52'+state.phone,
            amount: 0,
            mensualidades: 0,
            agent: "",
            Fid: "",
            status: "",
            lastMessage: "",
            created_at: "",
            updated_at: "",
            agentStatus: "",
            pageId: ""
        };
        
        emailValid = email !== "";
        passwordValid = password !== "";
        confirmPasswordValid = confirmPassword !== "";
        nameValid = name !== "";
        lastNameValid = lastName !== "";
        phoneValid = phone !== "" && phone.length === 10;
        termsValid = terms;

        if(emailValid && 
            passwordValid && 
            confirmPasswordValid &&
            nameValid &&
            lastNameValid &&
            phoneValid &&
            termsValid
        ) {
            setLoading(true);
        }
        setState({
            ...state,
            emailValid,
            passwordValid,
            confirmPasswordValid,
            nameValid,
            lastNameValid,
            phoneValid,
            termsValid
        })
    }

    return (
        <Box style={{ margin: "40px" }}>
            <Image 
                src='/assets/images/logo.png' 
                style={{ left: "auto", right: "auto", textAlign: "center", maxWidth: "30%" }}
                wrapped ui={true} 
            />
            <Box >
                <Typography variant='h4'>
                    <Box fontWeight={600} letterSpacing={3}>
                        Inicia Sesión
                    </Box>
                </Typography>
            </Box>
            <Container style={{ marginTop: '20px' }}
                component='main'
                maxWidth='md'>
                <CssBaseline />
                <Grid
                    container
                    alignContent='center'
                    alignItems='center'
                    justifyContent='center'
                    spacing={3}>
                    <Grid item md={9}>
                        <Paper>
                            <Box>
                                <TextField
                                    variant='outlined'
                                    margin='none'
                                    value={state.email}
                                    fullWidth
                                    id='email'
                                    label='Correo'
                                    name='email'
                                    type='email'
                                    onChange={e => setState({
                                        ...state,
                                        email: e.currentTarget.value,
                                        emailValid: e.currentTarget.value !== ""
                                    })}
                                    error={!state.emailValid}
                                />
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    value={state.name}
                                    fullWidth
                                    id='name'
                                    label='Nombre'
                                    name='name'
                                    type='text'
                                    onChange={e => setState({
                                        ...state,
                                        name: e.currentTarget.value,
                                        nameValid: e.currentTarget.value !== ""
                                    })}
                                    error={!state.nameValid}
                                />
                                
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    value={state.lastName}
                                    fullWidth
                                    id='lastName'
                                    label='Apellido'
                                    name='lastName'
                                    type='text'
                                    onChange={e => setState({
                                        ...state,
                                        lastName: e.currentTarget.value,
                                        lastNameValid: e.currentTarget.value !== ""
                                    })}
                                    error={!state.lastNameValid}
                                />
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    value={state.phone}
                                    fullWidth
                                    id='phone'
                                    label='Teléfono'
                                    name='phone'
                                    type='text'
                                    onChange={e => setState({
                                        ...state,
                                        phone: e.currentTarget.value,
                                        phoneValid: e.currentTarget.value !== ""
                                    })}
                                    error={!state.phoneValid}
                                />
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    value={state.password}
                                    fullWidth
                                    name='password'
                                    label='Contraseña'
                                    type='password'
                                    error={!state.passwordValid}
                                    onChange={e => setState({
                                        ...state,
                                        password: e.currentTarget.value,
                                        passwordValid: e.currentTarget.value !== ""
                                    })}
                                />
                                <TextField
                                    variant='outlined'
                                    margin='normal'
                                    value={state.confirmPassword}
                                    fullWidth
                                    name='password'
                                    label='Confirmar contraseña'
                                    type='password'
                                    error={!state.confirmPasswordValid}
                                    onChange={e => setState({
                                        ...state,
                                        confirmPassword: e.currentTarget.value,
                                        confirmPasswordValid: e.currentTarget.value === state.password
                                    })}
                                />
                                
                                {errors !== ""  && (
                                    <Typography variant='body2' style={{ color: "red" }}>
                                        {errors.toUpperCase()}
                                    </Typography>
                                )}
                                <Grid container>
                                    <Grid item sm={6} md={6} style={{ marginBottom: "10px" }}>
                                        <Checkbox
                                            checked={state.terms}
                                            onChange={(e, {checked}) => {
                                                setState({
                                                    ...state,
                                                    terms: checked!,
                                                    termsValid: checked!
                                                })
                                            }}
                                            value="accept"
                                            style={{ padding: "5px" }}
                                        />
                                        &nbsp;&nbsp;&nbsp;Acepto los 
                                        <Link to='#'>
                                        &nbsp;Términos y condiciones
                                        </Link>
                                    </Grid>
                                    <Grid item sm={6} md={6} style={{ marginBottom: "10px" }}>
                                        {!state.termsValid ?
                                            <Label color="red">Debe aceptar los términos y condiciones</Label>
                                        : null}
                                    </Grid>
                                    <Grid item sm={6} md={6}>
                                        <Link to='#'>
                                            Olvidaste tu contraseña?
                                        </Link>
                                    </Grid>
                                    <Grid item sm={6} md={6}>
                                        <Link to='/login'>
                                            Ya tienes una cuenta? Inicia Sesión!
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Button 
                                    type='submit' 
                                    variant='contained'
                                    disabled={loading}
                                    onClick={handleSubmit}
                                    style={{ margin: '20px', backgroundColor: "#0d6efd", color: "white" }}
                                >
                                    Registrarse
                                    {loading && (<CircularProgress size={30} color='secondary' />)}
                                </Button>
                            </Box >
                        </Paper >
                    </Grid >
                </Grid >
            </Container >
        </Box >
    )
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        userState: state.users,
    }
}

const mapActionsToProps = {
}

const connector = connect(mapStateToProps, mapActionsToProps);

type ILoginProps = ConnectedProps<typeof connector>;

export default connector(SignUp);
