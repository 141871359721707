import { useEffect, useState } from "react";
import {io as socketIOClient, Socket} from "socket.io-client";
import { apiUrl } from "../constants/enviroment";
import { NEW_MESSAGE_CRM } from '../constants/chat.constants';
import { decodeToken } from "react-jwt";

const useChat = (agentId?: String) => {
  const [socketRef, setSocketRef] = useState<Socket>(socketIOClient)
  
  let token: any = "";
  let decodedToken: any = {};
  let isToken: boolean = false;

  useEffect(() => {  
    if(agentId === "" && localStorage.getItem('token')) {
      token = localStorage.getItem('token')
      decodedToken = decodeToken(token)
      isToken = true;
    } else {
      isToken = false
    }
  }, [localStorage])

  useEffect(() => {

    decodedToken ?
      setSocketRef(socketIOClient(apiUrl, {
        query: {roomId: agentId === "" && isToken ? decodedToken.user.id : agentId}
      }))
    : null

    return () => {
      socketRef.disconnect()
    } 
  },[isToken])

  const sendMessage = (message: any) => {
    socketRef.emit(NEW_MESSAGE_CRM, {
      conversationId: message.conversation,
      message: `${message.body}`,
      type: "text",
      agentId: agentId,
      userId: message.destination,
      file: message.file? message.file: undefined,
      typeFile: message.file ? message.file.type : undefined,
      fileName: message.file ? message.file.name : undefined
    });
  };

  return { sendMessage, socketRef };
};

export default useChat;