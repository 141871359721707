import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button, Header, Modal, Grid, Label, Loader } from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { useParams } from "react-router-dom";
import { getById } from "../../../actions/validations.actions";
import { useNavigate } from "react-router-dom";
import CreditosVigentes from "../creditos/creditos";
import "../../../css/validation.modal.css";

export interface IState {
    id?: string;
}

const DetailValidation: React.FC<Props> = ({
    validation,
    errors,
    loading,
    getById,
}) => {
    const { id } = useParams();

    const navigate = useNavigate();

    const initialState = {
        id: id,
    };

    const [open, setOpen] = useState(true);
    const [state, setState] = useState<IState>(initialState);

    useEffect(() => {
        setState(initialState);
    }, [open]);

    useEffect(() => {
        if (id) {
            getById(id);
        }
    }, [id]);

    const onClose = () => {
        setOpen(false);
        navigate(-1);
    };

    return (
        <Modal
            onClose={() => setOpen(false)}
            className="validation_modal"
            onOpen={() => setOpen(true)}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeOnPortalMouseLeave={false}
            closeOnTriggerBlur={false}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={false}
            closeOnTriggerClick={false}
            open={open}
            trigger={
                <Button
                    color="blue"
                    floated="right"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    Ver
                </Button>
            }
            size="large"
        >
            <Modal.Header>
                <Label color="blue" ribbon>
                    Validación SIPRE
                </Label>
                <Modal.Description className="validation_header">
                    <Header as={"h2"}>ID: {state.id}</Header>
                </Modal.Description>
            </Modal.Header>
            <Modal.Content className="validation_content">
                {!loading && validation ? (
                    <Grid centered>
                        <Grid.Row columns={2}>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    CURP
                                </Label>
                                <h2>{validation?.curp ?? ""}</h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    {validation.isJubilado
                                        ? "Matrícula"
                                        : "NSS"}
                                </Label>
                                <h2>
                                    {validation.isJubilado
                                        ? validation.matricula ?? validation.nss
                                        : validation.nss ?? ""}
                                </h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    TELÉFONO
                                </Label>
                                <h2>{validation?.phone ?? ""}</h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    Delegación
                                </Label>
                                <h2>{validation?.delegacion ?? ""}</h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    FECHA DE VALIDACIÓN
                                </Label>
                                <h2>
                                    {new Date(
                                        validation.validationDate!
                                    ).toLocaleDateString()}
                                </h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    CAPACIDAD DE CRÉDITO
                                </Label>
                                <h2>
                                    {validation?.cc
                                        ? `$${Number(validation.cc).toFixed(2)}`
                                        : "$0.0"}
                                </h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    EMAIL
                                </Label>
                                <h2>{validation?.email}</h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    ESTATUS
                                </Label>
                                <h2>
                                    {validation.isValidated
                                        ? "VALIDADO"
                                        : "PENDIENTE"}
                                </h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    CRÉDITOS ACTIVOS
                                </Label>
                                <h2>
                                    {
                                        validation.creditosRenovacion!.concat(
                                            validation.creditosCompra!
                                        ).length
                                    }
                                </h2>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="green" ribbon>
                                    VALIDACIÓN SIPRE
                                </Label>
                                <a
                                    href={`${validation.urlImage}`}
                                    target="_blank"
                                >
                                    <h2>{validation.urlImage}</h2>
                                </a>
                            </Grid.Column>
                            <Grid.Column computer={8} mobile={16}>
                                <Label color="red" ribbon>
                                    NOTAS
                                </Label>
                                <h2>{validation.comments}</h2>
                            </Grid.Column>
                            <Grid.Column computer={16} mobile={16}>
                                <CreditosVigentes
                                    creditos={validation.creditosRenovacion!.concat(
                                        validation.creditosCompra!
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <Loader active inline="centered">
                        Cargando...
                    </Loader>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        userId: state.users.user.id,
        validation: state.validations.validation,
        errors: state.validations.errors,
        loading: state.validations.loadingValidation,
    };
};

const mapActionsToProps = {
    getById,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector>;

export default connector(DetailValidation);
