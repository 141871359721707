import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
    Button,
    Pagination,
    Segment,
    Table,
    SemanticCOLORS,
    Loader,
    Label,
    Accordion,
    Grid,
    Icon,
    Input,
} from "semantic-ui-react";
import {
    getByFilter,
    getAllByCustomerId,
    create,
    setPage,
} from "../../../actions/validations.actions";
import { RootState } from "../../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import IValidation from "./../../../models/validation.model";
import moment from "moment";
import "../../../css/users.css";

interface IState {
    activeIndex: number;
    filterCURP: string;
    filterNSS: string;
    filterPhone: string;
    filterDate: string;
    filterUpdateDate: string;
    filterCC?: boolean | null;
    limit: number;
}

const Validaciones: React.FC<IPensionadosProps> = ({
    lead,
    validations,
    validation,
    loading,
    loadingValidation,
    agentId,
    isAdmin,
    isCoordinator,
    isMentor,
    errors,
    page,
    counter,
    authenticated,
    create,
    setPage,
    getByFilter,
}) => {
    const { id } = useParams();

    const [state, setState] = useState<IState>({
        activeIndex: 1,
        filterCURP: "",
        filterNSS: "",
        filterPhone: "",
        filterDate: "",
        filterUpdateDate: "",
        limit: 10,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) {
            loadEntities(agentId, page);
        }
    }, [authenticated]);

    useEffect(() => {
        if (!loading && page) {
            loadEntities(agentId, page);
        }
    }, [page]);

    useEffect(() => {}, [validations]);

    useEffect(() => {
        loadEntities(agentId, page);
    }, [validation]);

    const loadEntities = (agent: string, page: number) => {
        const {
            filterPhone,
            filterDate,
            filterUpdateDate,
            filterNSS,
            filterCURP,
            filterCC,
        } = state;

        let isFilterPhone = filterPhone.trim() !== "";
        let isFilterDate = filterDate.trim() !== "";
        let isFilterUpdateDate = filterUpdateDate.trim() !== "";
        let isFilterNSS = filterNSS.trim() !== "";
        let isFilterCURP = filterCURP.trim() !== "";
        let isFilterCC = filterCC !== null;

        let filters: any = {
            phone: filterPhone.trim(),
            createdAt: filterDate.trim(),
            updatedAt: filterUpdateDate.trim(),
            nss: filterNSS.trim(),
            curp: filterCURP.trim(),
            cc: filterCC,
            userId: id,
        };

        if (!isFilterPhone) delete filters.phone;
        if (!isFilterDate) delete filters.createdAt;
        if (!isFilterUpdateDate) delete filters.updatedAt;
        if (!isFilterNSS) delete filters.nss;
        if (!isFilterCURP) delete filters.curp;
        if (!isFilterCC) delete filters.cc;
        if (!id) delete filters.userId;
        if (!lead.CURP) delete filters.like;
        if (id && (lead.wa_id ?? lead.Fid) === id) {
            filters = {
                like: [{ curp: lead.CURP }],
            };
        }
        getByFilter(page, state.limit, filters, {
            createdAt: -1,
        });
    };

    const cleanFilters = () => {
        setPage(1);

        setState({
            ...state,
            filterCURP: "",
            filterNSS: "",
            filterPhone: "",
            filterDate: "",
            filterUpdateDate: "",
        });

        getByFilter(
            1,
            state.limit,
            {},
            {
                createdAt: -1,
            }
        );
    };

    const getColorCC = (amount: string) => {
        let color: SemanticCOLORS = "red";
        amount = amount.replace("$", "");
        amount = amount.replaceAll(",", "");

        if (Number(amount)) {
            let numberAmount = Number(amount);
            if (numberAmount > 0 && numberAmount < 3000) {
                color = "black";
            } else if (numberAmount >= 3000 && numberAmount < 8000) {
                color = "orange";
            } else if (numberAmount >= 8000) {
                color = "green";
            }
        }
        return color;
    };

    const revalidate = (validation: IValidation) => {
        create({ ...validation, isValidated: false });
    };

    const getClassName = (validation: IValidation) => {
        let className = "";
        if (validation.isJubilado === true) {
            className = "jubilado";
        }
        return className;
    };

    return (
        <Segment style={{ width: "100%", overflowX: "auto" }}>
            <Accordion fluid styled>
                <Accordion.Title
                    active={id ? state.activeIndex === 1 : false}
                    onClick={() => {
                        setState({
                            ...state,
                            activeIndex: state.activeIndex === 0 ? 1 : 0,
                        });
                    }}
                >
                    <Icon name="dropdown" />
                    Búsqueda
                </Accordion.Title>
                <Accordion.Content active={state.activeIndex === 1}>
                    <Grid>
                        <Grid.Row columns={4}>
                            <Grid.Column computer={4} mobile={16} tablet={8}>
                                <Input
                                    className="user-filter-field"
                                    label="CURP"
                                    value={state.filterCURP}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            filterCURP: e.currentTarget.value,
                                        });
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16} tablet={8}>
                                <Input
                                    className="user-filter-field"
                                    label="NSS"
                                    value={state.filterNSS}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            filterNSS: e.currentTarget.value,
                                        });
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16} tablet={8}>
                                <Input
                                    className="user-filter-field"
                                    label="Teléfono"
                                    value={state.filterPhone}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            filterPhone: e.currentTarget.value,
                                        });
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16} tablet={8}>
                                <Input
                                    className="user-filter-field"
                                    label="Fecha"
                                    type="date"
                                    value={state.filterDate}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            filterDate: e.currentTarget.value,
                                        });
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={16} tablet={8}>
                                <Input
                                    className="user-filter-field"
                                    label="Fecha actualización"
                                    type="date"
                                    value={state.filterUpdateDate}
                                    onChange={(e) => {
                                        setState({
                                            ...state,
                                            filterUpdateDate:
                                                e.currentTarget.value,
                                        });
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <div style={{ float: "right" }}>
                                <Button
                                    style={{ width: "200px" }}
                                    color="grey"
                                    onClick={() => cleanFilters()}
                                >
                                    Limpiar
                                </Button>
                                <Button
                                    style={{ width: "200px" }}
                                    color="green"
                                    onClick={() => loadEntities(agentId, 1)}
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Grid.Row>
                    </Grid>
                </Accordion.Content>
            </Accordion>
            {!loading ? (
                <Table
                    attached
                    celled
                    selectable
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center",
                    }}
                >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="7">
                                <Pagination
                                    activePage={page}
                                    totalPages={Math.trunc(counter / 10) + 1}
                                    onPageChange={(event, data) => {
                                        setPage(Number(data.activePage));
                                    }}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="1">
                                <Button
                                    color="blue"
                                    onClick={() => loadEntities(agentId, page)}
                                >
                                    Actualizar
                                </Button>
                                {/* <Button
									color="green"
									onClick={() => {
										if (id) {
											navigate(
												`/validation/create/${id}`
											);
										} else {
											navigate("/validation/create");
										}
									}}
								>
									Crear
								</Button> */}
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>CURP</Table.HeaderCell>
                            <Table.HeaderCell>NSS/Matrícula</Table.HeaderCell>
                            <Table.HeaderCell>CC</Table.HeaderCell>
                            <Table.HeaderCell>Teléfono</Table.HeaderCell>
                            {isAdmin ? (
                                <Table.HeaderCell>Agente</Table.HeaderCell>
                            ) : null}
                            <Table.HeaderCell>Estatus</Table.HeaderCell>
                            <Table.HeaderCell>Fecha</Table.HeaderCell>
                            <Table.HeaderCell>
                                Última actualización
                            </Table.HeaderCell>
                            <Table.HeaderCell>Acciones</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {validations
                            ? validations.map(
                                  (validation: IValidation, index: number) => {
                                      return (
                                          <Table.Row
                                              className={getClassName(
                                                  validation
                                              )}
                                              key={`val-${
                                                  validation.id ??
                                                  validation._id
                                              }`}
                                          >
                                              <Table.Cell>
                                                  {validation.id ??
                                                      validation._id}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {validation.curp}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {validation.isJubilado
                                                      ? validation.matricula ??
                                                        validation.nss
                                                      : validation.nss}
                                              </Table.Cell>
                                              <Table.Cell
                                                  style={{
                                                      color: getColorCC(
                                                          String(validation.cc)
                                                      ),
                                                  }}
                                              >
                                                  {validation.cc}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  <a
                                                      href={`https://wa.me/${validation.phone}`}
                                                      target="_blank"
                                                      rel="noopener"
                                                  >
                                                      {validation.phone}
                                                  </a>
                                              </Table.Cell>
                                              {isAdmin ? (
                                                  <Table.Cell>
                                                      {validation.agentId}
                                                  </Table.Cell>
                                              ) : null}
                                              <Table.Cell>
                                                  {validation.isValidated
                                                      ? "VALIDADO"
                                                      : "PENDIENTE"}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {moment(
                                                      validation.createdAt
                                                  ).format("DD/MM/YYYY")}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  {moment(
                                                      validation.updatedAt
                                                  ).format("DD/MM/YYYY")}
                                              </Table.Cell>
                                              <Table.Cell>
                                                  <Button.Group>
                                                      <Button
                                                          color="blue"
                                                          floated="right"
                                                          onClick={() => {
                                                              navigate(
                                                                  `/validation/detail/${
                                                                      validation.id ??
                                                                      validation._id
                                                                  }`
                                                              );
                                                          }}
                                                      >
                                                          Ver
                                                      </Button>
                                                      <Button
                                                          color="yellow"
                                                          floated="right"
                                                          disabled={
                                                              loadingValidation ||
                                                              !validation.isValidated
                                                          }
                                                          loading={
                                                              loadingValidation
                                                          }
                                                          onClick={() =>
                                                              revalidate(
                                                                  validation
                                                              )
                                                          }
                                                      >
                                                          Revalidar
                                                      </Button>
                                                  </Button.Group>
                                              </Table.Cell>
                                          </Table.Row>
                                      );
                                  }
                              )
                            : null}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell colSpan="12">
                                <Pagination
                                    activePage={page}
                                    totalPages={
                                        Math.trunc(counter / state.limit) + 1
                                    }
                                    onPageChange={(event, data) => {
                                        setPage(Number(data.activePage));
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            ) : (
                <Loader active={loading} size="big">
                    Cargando ...
                </Loader>
            )}
            {errors ? <Label color="red">{errors}</Label> : null}
        </Segment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        lead: state.adminUsers.user,
        validations: state.validations.validations,
        validation: state.validations.validation,
        counter: state.validations.counter,
        page: state.validations.page,
        loading: state.validations.loading,
        loadingValidation: state.validations.loadingValidation,
        errors: state.validations.errors,
        agentId: state.users.user.id,
        isAdmin: state.users.user.type === "IS_ADMIN",
        isCoordinator: state.users.user.type === "IS_COORDINATOR",
        isMentor: state.users.user.type === "IS_MENTOR",
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        authenticated: state.users.authenticated,
    };
};

const mapActionsToProps = {
    getByFilter,
    getAllByCustomerId,
    setPage,
    create,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IPensionadosProps = ConnectedProps<typeof connector>;

export default connector(Validaciones);
