import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router';
import { Segment, Header, Button, Grid, Checkbox, Dropdown, Input, Label } from 'semantic-ui-react';
import { AiOutlineUser } from 'react-icons/ai'
import { RootState } from '../../../store/store';
import { getUsers, getTotalUsers } from '../../../actions/user-admin.actions';
import DataShow from './components/DataShow.component';
import { IUserModel } from '../../../models/User.model';
import DatePicker from 'react-date-picker';
import botTypes from '../../../constants/catalogues/bots.types';
import { DateTime } from "luxon";
import { namespace_1, namespace_2 } from '../../../constants/enviroment';
import * as xlsx from 'xlsx';
import { getAgents } from  '../../../actions/agent-admin.actions';
import { IconType } from 'react-icons/lib';
import macroTypes from '../../../constants/catalogues/macro.types';

interface IDashboardState {
    filterStartDate: string | null,
    filterEndDate: string | null,
    filterType: string,
    total_entities: number
    total_potential_entities: number;
    success_rate: number;
    entities: IUserModel[],
    potentialEntities: IUserModel[],
    followMentEntities: IUserModel[],
    isRango: boolean,
    isDateFilter: boolean,
    actualDate: Date | null,
    isAgentFilter: boolean,
    agentFilter: string,
    isFilter: boolean,
    secondary_filter: string,
    page: number,
    loading: boolean,
    errorMessage: string,
    firstLoad?: boolean,
    macroType?: string;
}

const Dashboard: React.FC<IDashboardProps> = ({
    getAgents,
    agents,
    user,
    authenticated,
    userType
}) => {
    const navigate = useNavigate()

    const [state, setState] = useState<IDashboardState>({
        filterStartDate: null,
        filterEndDate: null,
        filterType: "ALL",
        total_entities: 0,
        total_potential_entities: 0,
        success_rate: 0,
        entities:[],
        potentialEntities: [],
        followMentEntities: [],
        isRango: false,
        isDateFilter: false,
        actualDate: null,
        isAgentFilter: false,
        agentFilter: "",
        isFilter: false,
        secondary_filter: "",
        page: 0,
        loading: false,
        errorMessage: "",
        macroType: ""
    });

    useEffect(() => {
        if(!authenticated) {
            navigate("/login");
        }
        if(userType === "IS_AGENT") {
            navigate("/crm")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
    }, []);

    useEffect(() => {
        if(userType === "IS_COORDINADOR" && !state.firstLoad){
            setState({...state, firstLoad: true});
            return;
        };
        
        setState({... state, loading: true})
        getTotalUsers({
            isFilter: state.isFilter, filter: state.filterType, 
            isDateFilter: state.isDateFilter, 
            isRangeFilter: state.isRango, 
            Date: {
                initial_date: state.filterStartDate, final_date: state.filterEndDate
            },
            secondary_filter: state.secondary_filter,
            page: state.page,
            agentFilter: state.agentFilter,
            macroType: state.macroType
        }).then(res => {
            setState({...state, 
                total_entities: res.Total_Users, 
                total_potential_entities: res.Total_phones, 
                success_rate: res.success_rate, 
                potentialEntities: res.users,
                loading: false
            });
        })
    }, [state.page]);

    useEffect(() => {
        getAgents();
      }, [])
    useEffect(() => {
        setState({...state, agentFilter: state.isAgentFilter ? state.agentFilter : ""})
    }, [state.isAgentFilter]);

    useEffect(() => {
        if(!state.isDateFilter)
            setState({...state, isFilter: state.filterType !== "ALL"});

        if(!state.isDateFilter && (state.filterEndDate || state.filterStartDate))
            setState({...state, isDateFilter: true, isFilter: true});

    }, [state.filterType, state.filterEndDate, state.filterStartDate]);

    useEffect(() => {
        if(state.secondary_filter !== "")
            setState({...state, isFilter: true})
        else
            setState({...state, isFilter: false})
    }, [state.secondary_filter]);

    useEffect(() => {
        if(state.errorMessage !== "")
            setTimeout(() => {
                setState({...state,
                    errorMessage: ""
                });
            }, 1500);
    }, [state.errorMessage]);

    const handleSubmit = () => {
        if(userType === "IS_COORDINADOR") {
            if(state.secondary_filter === ""){
                setState({...state, errorMessage: "ID de campaña es necesario"})
                return;
            }
        }

        setState({... state, loading: true});
        getTotalUsers({
            isFilter: state.isFilter, filter: state.filterType, 
            isDateFilter: state.isDateFilter, 
            isRangeFilter: state.isRango, 
            Date: {
                initial_date: state.filterStartDate, final_date: state.filterEndDate
            },
            secondary_filter: state.secondary_filter,
            page: state.page,
            agentFilter: state.agentFilter,
            macroType: state.macroType
        }).then(users => {
            setState({...state, 
                total_entities: users.Total_Users, 
                total_potential_entities: users.Total_phones, 
                success_rate: users.success_rate,
                potentialEntities: users.users,
                loading: false,
                errorMessage: users.Total_Users <= 0 ? "Sin resultados" : ""
            });
        })
    }

    const cleanFilters = () => {
        setState({
            filterStartDate: null,
            filterEndDate: null,
            filterType: "ALL",
            total_entities: 0,
            total_potential_entities: 0,
            success_rate: 0,
            entities:[],
            potentialEntities: [],
            followMentEntities: [],
            isRango: false,
            isDateFilter: false,
            actualDate: null,
            isAgentFilter: false,
            agentFilter: "",
            isFilter: false,
            secondary_filter: "",
            page: 0,
            loading: false,
            errorMessage: "",
            macroType: ""
        });
    }

    const createAgentesOptions = () => {
        const options: { key: string | undefined; text: string; value: string | undefined; image: IconType; }[] = []
        agents.forEach(agent => {
            if(agent.status === "DISPERSED")
                return;
            options.push({
                key: agent.id,
                text: `${agent.name} ${agent.lastName} ${agent.bot_id! === namespace_1 ? "CREDITOS" : "DISTRIBUCION"}`,
                value: agent.id,
                image: AiOutlineUser
            });
        });

        return options;
    }

    const getAgentName = (id: string) => {
        let name = "";
        let agent = agents.filter(f => f.id! === id)[0];
        name = agent ? `${agent.name} ${agent.lastName}` : "";
        return name;
    }

    const pagination = (pagination: number) => { 
        setState({... state, page: pagination})
    };

    const onChangeType = (type: string) => {
        setState({
            ...state,
            filterType: type,
            macroType: state.macroType !== "" ? "" : state.macroType 
        });
    };

    const convertAllClientJsonToExcel = async (allPage?: boolean, users_page?: IUserModel[]) => {
        let users = users_page;

        if(allPage){
            setState({... state, loading: true});
            await getTotalUsers({            
                isFilter: state.isFilter, filter: state.filterType, 
                isDateFilter: state.isDateFilter, 
                isRangeFilter: state.isRango, 
                Date: {
                    initial_date: state.filterStartDate, final_date: state.filterEndDate
                },
                secondary_filter: state.secondary_filter,
                page: state.page,
                allUsers: true,
                agentFilter: state.agentFilter,
                macroType: state.macroType
                }).then(response => {
                    setState({... state, loading: false});
                    users = response.users;
                })
            }

        const new_Users_format: any = [];

        users!.forEach(user => {
            if(user.NSS && user.CURP) {
                new_Users_format.push({
                    id: user.wa_id ? user.wa_id : user.Fid,
                    agente: getAgentName(user.agent),
                    nombre: user.name,
                    tel: user.phone.replace("521", ""),
                    "status agente": user.agentStatus,
                    "fecha creado": new Date(user.createdAt!).toLocaleString("en-GB").split(",")[0],
                    NSS: user.NSS,
                    CURP: user.CURP,
                    "Delegación": user.delegacion,
                    "Matrícula": user.matricula
                });
            } else {
                new_Users_format.push({
                    id: user.wa_id ? user.wa_id : user.Fid,
                    agente: getAgentName(user.agent),
                    nombre: user.name,
                    tel: user.phone.replace("521", ""),
                    "status agente": user.agentStatus,
                    "fecha creado": new Date(user.createdAt!).toLocaleString("en-GB").split(",")[0],
                    "Delegación": user.delegacion,
                    "Matrícula": user.matricula
                });  
            }
        });

        const workSheet = xlsx.utils.json_to_sheet(new_Users_format);
        const workBook =  xlsx.utils.book_new();
        workSheet['!cols'] = [{width: 20}, {width: 30}, {width: 30}, {width: 15}, {width: 15}, {width: 15}];

        xlsx.utils.book_append_sheet(workBook, workSheet, "Clientes");
        xlsx.write(workBook, {bookType: "xlsx", type: "buffer"});
        xlsx.write(workBook, {bookType: "xlsx", type: "binary"});
        xlsx.writeFile(workBook, `Clientes_${state.isFilter ? state.filterType === namespace_1 ? "Creditos" : state.secondary_filter ? `filtro_${state.secondary_filter}` : state.filterType === namespace_2 ? "Distribucion" : "Comentarios" : "Registros_Completos"}${state.agentFilter !== "" ? "_" + getAgentName(state.agentFilter): ""}_${state.isDateFilter ? state.isRango ? new Date(state.filterStartDate!).toLocaleString("en-GB").split(',')[0] + "_" + new Date(state.filterEndDate!).toLocaleString("en-GB").split(',')[0] : new Date(state.filterEndDate!).toLocaleString("en-GB").split(',')[0] : "todas_fechas"}_${allPage ? "Todos_registros" : "Pagina"}.xlsx`);
    };

    return(
        <div style={{ minHeight: '600px' }}>
        <Segment>
            <Header>
                Registros de Clientes
                <Header.Subheader>
                Clientes Totales: {state.total_entities}
                </Header.Subheader>
            </Header>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Checkbox 
                            toggle
                            checked={state.isRango}
                            onChange={() => {
                                setState({
                                    ...state,
                                    isRango: !state.isRango
                                })
                            }}
                            disabled={state.loading || state.errorMessage !== ""}
                        />
                        <h6>Rango de fechas</h6>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={state.isRango ? 3 : 2}>
                    {state.isRango ?
                    <Grid.Column>
                        <DatePicker
                            maxDate={state.filterEndDate ? new Date(state.filterEndDate) : undefined}
                            onClickDay={(value: Date) => {
                                if(!state.isDateFilter)
                                    setState({...state, isDateFilter: true});
                                    
                                setState({
                                    ...state,
                                    filterStartDate: value.toUTCString()
                                })
                            }}
                            disabled={state.loading || state.errorMessage !== ""}
                            value={ state.filterStartDate ? new Date(state.filterStartDate!) : undefined}
                        />
                    </Grid.Column>
                    : null}
                    <Grid.Column>
                        <DatePicker 
                            minDate={state.filterStartDate ? new Date(state.filterStartDate) : undefined}
                            onClickDay={(value: Date) => {
                                setState({
                                    ...state,
                                    filterEndDate: value.toUTCString(),
                                });
                            }}
                            disabled={state.loading || state.errorMessage !== ""}
                            value={state.filterEndDate ? new Date(state.filterEndDate!) : undefined}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            label='Busqueda por agente'
                            onChange={() => {
                                setState({
                                    ...state,
                                    isAgentFilter: !state.isAgentFilter,
                                    isFilter: state.isFilter ? state.isFilter : !state.isFilter
                                });
                            }}
                            disabled={state.loading || state.errorMessage !== ""}
                            checked={state.isAgentFilter}
                            />
                        {state.isAgentFilter ?
                        <Dropdown
                            style={{marginBottom: "10px"}}
                            search
                            placeholder={getAgentName(state.agentFilter)}
                            fluid
                            selection
                            labeled
                            options={createAgentesOptions()}
                            onChange={(e, data) => setState({... state, agentFilter: data.value!.toString()})}
                            disabled={state.loading || state.errorMessage !== ""}
                        /> : null
                        }
                        <Input
                            label="Filtro"
                            placeholder="ID Campaña"
                            fluid
                            style={{marginBottom: '10px'}}
                            onChange={(e, data) => setState({...state, secondary_filter: data.value, filterType: "ALL"})}
                            value={state.secondary_filter}
                            disabled={state.loading || state.errorMessage !== ""}
                        />

                        {state.filterType === "MACRO" ? 
                        <>
                            <Dropdown
                                style={{marginBottom: "10px"}}
                                disabled={state.secondary_filter !== "" || state.loading || state.errorMessage !== ""}
                                value={state.macroType}
                                placeholder="Tipo de Macro"
                                fluid
                                selection
                                options={macroTypes}
                                onChange={(e, data) => setState({...state, macroType: data.value!.toString()})}
                                />
                        </>
                        : null}
                        {userType !== "IS_COORDINADOR" ?
                            <Dropdown
                            disabled={state.secondary_filter !== "" || state.loading || state.errorMessage !== ""}
                            value={state.filterType}
                            placeholder="Estatus de usuario"
                            fluid
                            selection
                            options={botTypes}
                            onChange={(e, data) => onChangeType(data.value!.toString())}
                            /> : null
                        }
                        {state.errorMessage !== "" ?
                            <Label color='red'>
                                {state.errorMessage}
                            </Label> : null
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={8}>
                        <Button
                            style={{ width: "200px" }}
                            color="grey"
                            onClick={() => cleanFilters()}
                            floated="right"
                        >
                            Limpiar
                        </Button>
                    </Grid.Column>
                    <Grid.Column computer={8}>
                        <Button
                            style={{ width: "200px" }}
                            color="green"
                            floated="left"
                            onClick={() => {handleSubmit()}}
                            loading={state.loading}
                        >
                            Buscar
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    { state.macroType !== "SMS" && state.macroType !== 'blaster' ?
                        <Grid.Column computer={8}>
                            <DataShow
                                next_button={state.potentialEntities.length === 10}
                                pagination={pagination}
                                actual_page={state.page}
                                total={state.total_entities}
                                title={state.filterType !== "MACRO" ? "Usuarios registrados" : "Mensajes Enviados"}
                                users={state.entities? state.entities: []}
                                show_modal_button={false}
                            />
                        </Grid.Column>
                        : null
                    }

                    <Grid.Column computer={state.macroType !== 'blaster' && state.macroType !== 'SMS' ?  8 : 16 }>
                        <DataShow
                            next_button={state.potentialEntities.length === 10}
                            pagination={pagination}
                            jsonToExcel={convertAllClientJsonToExcel}
                            getAgentName={getAgentName}
                            actual_page={state.page}
                            total={state.total_potential_entities}
                            title={state.filterType !== "MACRO" ? "Teléfonos obtenidos" : state.macroType !== 'blaster' ? "Mensajes Respondidos" : 'Llamadas exitosas'}
                            users={
                                state.potentialEntities ?
                                    state.potentialEntities
                                : []
                            }
                            show_modal_button={true}
                            loading={state.loading}
                        />
                    </Grid.Column>
{/*                     <Grid.Column computer={16}>
                        <DataShow
                            next_button={state.potentialEntities.length === 10}
                            pagination={pagination}
                            actual_page={state.page}
                            total={
                                state.followMentEntities ?
                                    state.followMentEntities.length 
                                : 0
                            }
                            title={"Teléfonos obtenidos en seguimiento"}
                            users={
                                state.followMentEntities ?
                                    state.followMentEntities
                                : []
                            }
                        />
                    </Grid.Column> */}
                </Grid.Row>
                { state.macroType !== "SMS" && state.macroType !== 'blaster' ?
                        <Grid.Row>
                            <Grid.Column computer={16}>
                                <h1>
                                    Eficiencia
                                </h1>
                                <h1>
                                    {state.success_rate ? state.success_rate.toFixed(2) : 0.0} %
                                </h1>
                            </Grid.Column>
                         {/*<Grid.Column computer={8}>
                                <h1>
                                    Eficiencia de Seguimiento
                                </h1>
                                <h1>
                                    {state.success_rate ? state.success_rate.toFixed(2) : 0.0} %
                                </h1>
                            </Grid.Column> */}
                        </Grid.Row>
                        : null
                    }
            </Grid>
        </Segment>
        </div>
    );
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        agents: state.adminAgents.agents,
        user: state.users.user,
        authenticated: state.users.authenticated,
        users: state.adminUsers.users,
        userRole: state.users.user.roles,
        userType: state.users.user.type,
        loading: state.adminUsers.loading
    }
}

const mapActionsToProps = {
    getUsers,
    getAgents
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IDashboardProps = ConnectedProps<typeof connector>;

export default connector(Dashboard);