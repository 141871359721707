import React from "react";
import {
	Grid,
	Input,
	Dropdown,
	Label,
	SemanticWIDTHS,
} from "semantic-ui-react";
import "../../../../css/expedientes.css";

interface IProps {
	field: string;
	title: string;
	value: string | number | boolean | null;
	isValid: boolean;
	options: string[];
	type: string;
	error_message: string;
	computer?: SemanticWIDTHS;
	mobile?: SemanticWIDTHS;
	index?: number;
	disabled?: boolean;
	validateFields: (field: string, value: any, index?: number) => void;
}

const FieldInput: React.FC<IProps> = ({
	field,
	title,
	value,
	isValid,
	options,
	type,
	error_message,
	computer,
	mobile,
	index,
	disabled,
	validateFields,
}) => {
	return type !== "dropdown" ? (
		<Grid.Column computer={computer} mobile={mobile} stretched>
			<Input
				className="field-input"
				type={type}
				value={value}
				disabled={disabled}
				onChange={(e) =>
					validateFields(field, e.currentTarget.value, index)
				}
				label={{
					content: title.toUpperCase(),
					color: "green",
					className: "label-field-input",
				}}
				placeholder={title}
				error={!isValid}
			/>
			{!isValid ? <Label color="red">{error_message}</Label> : null}
		</Grid.Column>
	) : (
		<Grid.Column computer={computer} mobile={mobile}>
			<Dropdown
				fluid
				selection
				value={value!}
				disabled={disabled}
				onChange={(e, data) => {
					validateFields(field, data.value, index);
				}}
				options={options.map((option) => {
					return {
						key: option,
						text: option,
						value: option,
					};
				})}
				label={{ content: title.toUpperCase(), color: "green" }}
				placeholder={title}
				error={!isValid}
			/>
			{!isValid ? <Label color="red">{error_message}</Label> : null}
		</Grid.Column>
	);
};

export default FieldInput;
