import axios, { AxiosError, AxiosResponse } from "axios";
import {
    SET_GROUP_TEMPLATE,
    SET_GROUP_TEMPLATES,
    SET_LOADING_GROUP_TEMPLATE,
    SET_LOADING_GROUP_TEMPLATES,
    SET_LOADING_ERRORS_GROUP_TEMPLATE,
} from "../constants/group_template.constants";
import IGroupTemplate from "../models/template.model";
import { chatCRMUrl } from "../constants/enviroment";
import { CategoryTemplates } from "../layout/components/templates/interfaces/categoryTemplates.interface";

export const getAll = () => async (dispatch: any) => {
    let loaded = false;
    await dispatch({ type: SET_LOADING_GROUP_TEMPLATES });
    let categoryTemplates: CategoryTemplates[] = [];
    try {
        let url = `${chatCRMUrl}/api/getCategories`;

        const response = await axios.get(url);

        if (response.status !== 200) {
            console.warn("No se han podido obtener las categorias", response);
            setLoadingErrors("No se han podido obtener las categorias");
            return loaded;
        }

        categoryTemplates = response.data.categories;
        console.log(categoryTemplates);
        dispatch(setTemplates(categoryTemplates));
        loaded = true;
    } catch (e) {
        console.error("Error al cargar las categorias", e);
        setLoadingErrors("Error al cargar las categorias");
    }
    return loaded;
};

export const create =
    (groupTepmlate: CategoryTemplates) => async (dispatch: any) => {
        let created = false;
        try {
            await dispatch({ type: SET_LOADING_GROUP_TEMPLATE });

            const body = {
                agentId: groupTepmlate.agentId,
                category_name: groupTepmlate.category_name,
                description: groupTepmlate.description,
            };

            const response = await axios.post(
                `${chatCRMUrl}/api/createCategory/`,
                body
            );

            if (response.status !== 201) {
                dispatch(setLoadingErrors("No se puedo crear la Categoria"));
                return;
            }

            dispatch(setTemplate(response.data.categories));
            created = true;
        } catch (error) {
            console.error(error);
            dispatch(setLoadingErrors("Error al crear la Categoria"));
        }

        console.log("created", created);
        return created;
    };

export const update =
    (groupTepmlate: CategoryTemplates) => async (dispatch: any) => {
        let updated = false;
        await dispatch({ type: SET_LOADING_GROUP_TEMPLATE });

        await axios
            .put(`${chatCRMUrl}/group_template/${groupTepmlate._id}`, {
                data: {
                    agentId: groupTepmlate.agentId,
                    title: groupTepmlate.category_name,
                    description: groupTepmlate.description,
                },
            })
            .then((response: AxiosResponse) => {
                if (response.status !== 200) {
                    console.warn(
                        "No se puedo actualizar la categoria",
                        response.data.message
                    );
                    setLoadingErrors("No se puedo actualizar la categoria");
                    return;
                }
                dispatch(setTemplate(response.data.group_template));
                updated = true;
            })
            .catch((error: AxiosError) => {
                console.error(error.response!.data);
                dispatch(setLoadingErrors(error.response!.data.message));
            });
        return updated;
    };

export const setTemplates =
    (group_templates: CategoryTemplates[]) => async (dispatch: any) => {
        await dispatch({
            type: SET_GROUP_TEMPLATES,
            payload: group_templates,
        });
    };

export const setTemplate =
    (group_template: IGroupTemplate) => async (dispatch: any) => {
        await dispatch({
            type: SET_GROUP_TEMPLATE,
            payload: group_template,
        });
    };

export const setLoadingErrors = (error: string) => async (dispatch: any) => {
    console.error("setLoadingErrors: ", error);
    await dispatch({
        type: SET_LOADING_ERRORS_GROUP_TEMPLATE,
        payload: error,
    });
};
