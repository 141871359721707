import React, { useEffect } from "react";
import { getSession } from "../actions/users.actions";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import Routes from "./Routes";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import "../css/body.css";

const Body: React.FC<IBodyProps> = ({ authenticated, getSession }) => {
  useEffect(() => {
    redirect();
  }, []);

  const navigate = useNavigate();

  const redirect = async () => {
    const session = await getSession();
    if (!session) {
      navigate("/login");
    }
  };

  return (
    <div className={authenticated ? "body" : ""}>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes />
    </div>
  );
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
  return {
    authenticated: state.users.authenticated,
  };
};

const mapActionsToProps = {
  getSession,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IBodyProps = ConnectedProps<typeof connector>;

export default connector(Body);
