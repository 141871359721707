import axios from "axios";
import { paymentsApiUrl } from "../../../../../../constants/enviroment";

export const updateDiscount = async (agentId: any, discountData: any) => {
    try {
        const response = await axios.put(
            `${paymentsApiUrl}/api/discounts/${agentId}`,
            discountData
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
