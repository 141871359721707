import {
    Button,
    Grid,
    GridColumn,
    Header,
    Icon,
    Label,
    Modal,
    Segment,
} from "semantic-ui-react";

export const PreviewTemplate = ({
    setOpenPreviewModal,
    openPreviewModal,
    preview,
}: any) => {
    return (
        <Modal
            onOpen={() => setOpenPreviewModal(true)}
            onClose={() => setOpenPreviewModal(false)}
            open={openPreviewModal}
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
                justifyContent: "center",
            }}
            size="small"
        >
            <Modal.Content scrolling>
                <Grid padded verticalAlign="middle" container centered>
                    <GridColumn>
                        <Segment raised>
                            <Header as="h2">
                                <Icon name="comment alternate outline" />
                                <Header.Content>
                                    {preview?.alias}
                                    <Header.Subheader>
                                        {preview?.name}
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                            <Label color="green" ribbon>
                                ESTATUS: {preview?.status}
                            </Label>
                            <div className="preview-message-template">
                                <div className="preview-message-template-body">
                                    {preview?.text}
                                </div>
                                <div>
                                    {preview &&
                                        preview?.buttons.map(
                                            (button: any, index: any) => {
                                                return (
                                                    <div
                                                        className="preview-messages-template-buttons"
                                                        key={index}
                                                    >
                                                        <Icon name="reply" />
                                                        <p>{button}</p>
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                        </Segment>
                    </GridColumn>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="black"
                    onClick={() => setOpenPreviewModal(false)}
                >
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
