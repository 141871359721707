import React, { useEffect } from "react";
import { IUserModel } from "../../../../models/User.model";
import { Segment, Grid, Label } from 'semantic-ui-react';

interface IProps {
    customer: IUserModel
}

export const CustomerDetailComponent: React.FC<IProps> = ({
    customer
}) => {

    useEffect(() => {
        
    }, [customer]);

    return (
        <Segment>
            <Label
                color='green'
                size="massive"
                attached="top left"
            >
                ID: {customer.wa_id ?? customer.Fid}
            </Label>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <h1>Cliente</h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Nombre:
                        </Label>
                        <h2>{customer?.name ?? ""}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Apellido:
                        </Label>
                        <h2>{customer?.name ?? ""}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Teléfono:
                        </Label>
                        <h2>{customer?.phone ?? ""}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Cliente preferente para:
                        </Label>
                        <h2>{customer?.type_producto}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            CURP:
                        </Label>
                        <h2>{customer?.CURP ?? ""}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            {customer.isJubilado ? "Matrícula" : "   NSS   "}:
                        </Label>
                        <h2>{customer?.NSS ?? ""}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Capacidad de crédito:
                        </Label>
                        <h2>{customer?.capacidad_credito ?? "$0.00"}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Créditos activos:
                        </Label>
                        <h2>{customer?.creditos_activos ?? "0"}</h2>
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16}>
                        <Label  color='green' ribbon size="massive">
                            Descuentos:
                        </Label>
                        <h2>{customer?.total_descuentos ?? "$0.00"}</h2>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default CustomerDetailComponent;