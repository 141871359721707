//user reducer types
export const SET_USERS = "SET_USERS";
export const SET_FOLLOWMENT_USERS = "SET_FOLLOWMENT_USERS";
export const GET_USERS = "GET_USERS";
export const SET_USER_BY_ID = "SET_USER_BY_ID";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const LOADING_ADMIN_USER = "LOADING_ADMIN_USER";
export const LOADING_ADMIN_USERS = "LOADING_ADMIN_USERS";
export const LOADING_UPDATE_ADMIN_USER = "LOADING_UPDATE_ADMIN_USER";
export const SET_LOADING_ERRORS_ADMIN_USER = "SET_LOADING_ERRORS_ADMIN_USER";
export const CLEAR_LOADING_ERRORS_ADMIN_USER =
	"CLEAR_LOADING_ERRORS_ADMIN_USER";
export const SET_EXPEDIENT_ADMIN_USER = "SET_EXPEDIENT_ADMIN_USER";
export const LOADING_EXPEDIENT_ADMIN_USER = "LOADING_EXPEDIENT_ADMIN_USER";
export const SET_COUNTER_ADMIN_USER = "SET_COUNTER_ADMIN_USER";
export const SET_FOLLOWMENT_COUNTER_ADMIN_USER =
	"SET_FOLLOWMENT_COUNTER_ADMIN_USER";
export const SET_PAGE_ADMIN_USER = "SET_PAGE_ADMIN_USER";
export const SET_FOLLOWMENT_PAGE_ADMIN_USER = "SET_FOLLOWMENT_PAGE_ADMIN_USER";
export const SET_ACTIONS_OPEN = "SET_ACTIONS_OPEN";
export const LOADING_FOLLOWMENT_ADMIN_USER = "LOADING_FOLLOWMENT_ADMIN_USER";
export const SET_FOLLOWMENT_USERS_CONVERSATION =
	"SET_FOLLOWMENT_USERS_CONVERSATION";
export const SET_USERS_CONVERSATION = "SET_USERS_CONVERSATION";
export const SET_USER_FILTERS = "SET_USER_FILTERS";
