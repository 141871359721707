import React from "react";
import { Grid, Table, Icon, SemanticICONS } from "semantic-ui-react";
import "../../../../css/users.css";

interface IOrigen {
	origen: string;
	icono: SemanticICONS | undefined;
}

const origenData: IOrigen[] = [
	{
		origen: "Blaster fijo",
		icono: "phone",
	},
	{
		origen: "Blaster movil",
		icono: "mobile",
	},
	{
		origen: "Whatsapp",
		icono: "whatsapp",
	},
	{
		origen: "Facebook",
		icono: "facebook",
	},
	{
		origen: "Excel",
		icono: "file excel",
	},
	{
		origen: "Registro Manual",
		icono: "user",
	},
	{
		origen: "Facebook",
		icono: "facebook",
	},
	{
		origen: "Asignación Automática",
		icono: "fire",
	},
];

const tipoData = [
	{
		tipo: "Pensionado Tradicional",
		className: "tradicional",
		color: "verde claro",
	},
	{
		tipo: "Pensionado Renovacion",
		className: "renovation",
		color: "azul claro",
	},
	{
		tipo: "Pensionado Compra",
		className: "compra",
		color: "rosa",
	},
	{
		tipo: "Jubilado",
		className: "jubilado",
		color: "amarillo",
	},
	{
		tipo: "Nuevo León",
		className: "nuevo-leon",
		color: "naranja",
	},
	{
		tipo: "UNAM",
		className: "unam",
		color: "arena",
	},
	{
		tipo: "GEM Tradicional",
		className: "gem-tradicional",
		color: "azul fuerte",
	},
	{
		tipo: "GEM Compra",
		className: "gem-compra",
		color: "rojo",
	},
	{
		tipo: "Oaxaca",
		className: "oaxaca",
		color: "café",
	},
];

const InfoLeads = () => {
	return (
		<Grid>
			<Grid.Row columns={2}>
				<Grid.Column computer={8} mobile={16}>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Origen</Table.HeaderCell>
								<Table.HeaderCell>Icono</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{origenData.map((origen, index) => {
								return (
									<Table.Row key={`ilo-${index}`}>
										<Table.Cell>{origen.origen}</Table.Cell>
										<Table.Cell>
											<Icon name={origen.icono} />
										</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				</Grid.Column>
				<Grid.Column computer={8} mobile={16}>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Origen</Table.HeaderCell>
								<Table.HeaderCell>Color</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{tipoData.map((tipo, index) => {
								return (
									<Table.Row
										key={`ilo-${index}`}
										className={tipo.className}
									>
										<Table.Cell>{tipo.tipo}</Table.Cell>
										<Table.Cell>{tipo.color}</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default InfoLeads;
