import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router';
import { Segment, Header, Grid, Card, Image, Icon } from 'semantic-ui-react';
import { RootState } from '../../../store/store';


const Admin: React.FC<IAdminProps> = ({
    user
}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if(!user.authenticated) {
            navigate("/login")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    
    return(
        <Segment>
            <Header>Bienvenido! Administrador</Header>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column width={8} >
                    <Card centered onClick={() => navigate("/admin/users")}>
                        <Image src='/assets/images/admin_Usuarios.png' wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Administrar Usuarios</Card.Header>
                            <Card.Description>
                                Busca, Crea, Edita y Elimina Usuarios de ManaliApp 
                            </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                            <div>
                                <Icon name='user' />
                                22 Usuarios
                            </div>
                        </Card.Content>
                    </Card>
                    </Grid.Column>
                    <Grid.Column width={8}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        user: state.users,
    }
}

const mapActionsToProps = {
    
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IAdminProps = ConnectedProps<typeof connector>;

export default connector(Admin);