import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, Button, Modal, Header, Loader } from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { getById, create } from "../../../actions/Biometricos";
import { BiometricoComponent } from "./components/biometrico.component";
import { useNavigate, useParams } from "react-router-dom";

const Edit: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	biometrico,
	loadingBiometrico,
	errors,
	getById,
}) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [open, setOpen] = useState(true);

	useEffect(() => {
		console.log("BIO ID", id);
		if (id && profile.id) {
			getById(id);
		}
	}, [id, profile]);

	useEffect(() => {}, [biometrico]);

	const onOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		navigate(-1);
	};

	return (
		<Modal
			onClose={onClose}
			open={open}
			size="large"
			dimmer={"blurring"}
			style={{
				left: "auto",
				right: "auto",
				top: "auto",
				height: "fit-content",
			}}
			trigger={
				<Button onClick={onOpen} color="blue">
					Administrar
				</Button>
			}
		>
			<Modal.Header>
				<Header>
					<Header.Content>Biométrico:</Header.Content>
				</Header>
			</Modal.Header>
			<Modal.Content style={{ minHeight: "600px" }}>
				<Grid>
					{!loadingBiometrico && biometrico ? (
						<Grid.Row columns={1}>
							<Grid.Column key={biometrico.id}>
								<BiometricoComponent
									biometrico={biometrico}
									isAgent={isAgent}
									loading={loadingBiometrico}
								/>
							</Grid.Column>
						</Grid.Row>
					) : (
						<Loader active={loadingBiometrico}>Cargando...</Loader>
					)}
					{errors ? (
						<Grid.Row>
							<Grid.Column>{errors}</Grid.Column>
						</Grid.Row>
					) : null}
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={onClose}>
					Cerrar
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		user: state.users.user,
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		biometrico: state.biometricos.biometrico,
		errors: state.biometricos.errors,
		loadingBiometrico: state.biometricos.loadingBiometrico,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getById,
	create,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Edit);
