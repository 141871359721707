import {
    SET_VALIDATIONS,
    SET_VALIDATION,
    SET_LOADING_VALIDATION,
    SET_LOADING_VALIDATIONS,
    SET_LOADING_ERRORS_VALIDATION,
    CLEAR_LOADING_ERRORS_VALIDATION,
    SET_VALIDATION_BY_ID,
    SET_COUNTER_VALIDATION,
    SET_PAGE_VALIDATION,
  } from '../constants/validation.constants';
  
  import IValidationModel from '../models/validation.model';
  
  const initialState = {
    loading: false,
    loadingValidation: false,
    validations: [] as ReadonlyArray<IValidationModel>,
    validation: null as unknown as Readonly<IValidationModel>,
    counter: 10,
    page: 1,
    errors: ""
  }
  
  export type UserState = Readonly<typeof initialState>;
  
  const validationsReducer = (state: UserState = initialState, action: any): UserState  => {
    switch (action.type) {
      case SET_VALIDATION:
        return {
          ...state,
          loading: false,
          loadingValidation: false,
          validation: action.payload,
          errors: ""
        }
      case SET_VALIDATIONS:
        return {
          ...state,
          loading: false,
          loadingValidation: false,
          validations: action.payload,
          errors: ""
        }
      case SET_COUNTER_VALIDATION:
        return {
          ...state,
          counter: action.payload
        }
      case SET_PAGE_VALIDATION:
        return {
          ...state,
          page: action.payload
        }
      case SET_VALIDATION_BY_ID:
        return {
          ...state,
          loading: false,
          loadingValidation: false,
          validation: action.payload,
          errors: ""
        };
      case SET_LOADING_VALIDATIONS:
        return {
          ...state,
          loading: true,
          errors: ""
        };
      case SET_LOADING_VALIDATION:
        return {
          ...state,
          loadingValidation: true,
          errors: ""
        };
      case SET_LOADING_ERRORS_VALIDATION:
        return {
          ...state,
          loading: false,
          loadingValidation: false,
          errors: action.payload
        };
      case CLEAR_LOADING_ERRORS_VALIDATION:
        return {
          ...state,
          loading: false,
          loadingValidation: false,
          errors: ""
        };
      default:
        return state;
    }
  }
  
  export default validationsReducer;