export const states = [
	{ id: 1, text: "Aguascalientes", value: "MX-AGU" },
	{ id: 2, text: "Baja California", value: "MX-BCN" },
	{ id: 3, text: "Baja California Sur", value: "MX-BCS" },
	{ id: 4, text: "Campeche", value: "MX-CAM" },
	{ id: 5, text: "Chiapas", value: "MX-CHP" },
	{ id: 6, text: "Chihuahua", value: "MX-CHH" },
	{ id: 7, text: "Coahuila", value: "MX-COA" },
	{ id: 8, text: "Colima", value: "MX-COL" },
	{ id: 9, text: "CDMX", value: "MX-CDMX" },
	{ id: 10, text: "Durango", value: "MX-DUR" },
	{ id: 11, text: "Guanajuato", value: "MX-GUA" },
	{ id: 12, text: "Guerrero", value: "MX-GRO" },
	{ id: 13, text: "Hidalgo", value: "MX-HID" },
	{ id: 14, text: "Jalisco", value: "MX-JAL" },
	{ id: 15, text: "EDO. de México", value: "MX-MEX" },
	{ id: 16, text: "Michoacán", value: "MX-MIC" },
	{ id: 17, text: "Morelos", value: "MX-MOR" },
	{ id: 18, text: "Nayarit", value: "MX-NAY" },
	{ id: 19, text: "Nuevo León", value: "MX-NEL" },
	{ id: 20, text: "Oaxaca", value: "MX-OAX" },
	{ id: 21, text: "Puebla", value: "MX-PUE" },
	{ id: 22, text: "Querétaro", value: "MX-QUE" },
	{ id: 23, text: "Quintana Roo", value: "MX-ROO" },
	{ id: 24, text: "San Luis Potosí", value: "MX-SLP" },
	{ id: 25, text: "Sinaloa", value: "MX-SIN" },
	{ id: 26, text: "Sonora", value: "MX-SON" },
	{ id: 27, text: "Tabasco", value: "MX-TAB" },
	{ id: 28, text: "Tamaulipas", value: "MX-TAM" },
	{ id: 29, text: "Tlaxcala", value: "MX-TLA" },
	{ id: 30, text: "Veracruz", value: "MX-VER" },
	{ id: 31, text: "Yucatán", value: "MX-YUC" },
	{ id: 32, text: "Zacatecas", value: "MX-ZAC" },
];
