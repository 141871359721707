import React, { useEffect, useState } from "react";
import { Grid, Header, Pagination, Button, Icon } from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { ConnectedProps, connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getByType } from "../../../actions/Agent/get.actions";
import { setAgentsPage } from "../../../actions/Agent/utils.actions";
import AgentsBox from "./components/agents.box";
import AgentsFilters from "./components/agents.filters";
import { namespace_1 } from "../../../constants/enviroment";

const Agents: React.FC<IProps> = ({
	profile,
	agents,
	loading,
	bot_id,
	page,
	total,
	getByType,
	setAgentsPage,
}) => {
	const { type } = useParams();
	const navigate = useNavigate();
	const [pagina, setPagina] = useState(page);

	useEffect(() => {
		setPagina(1);
	}, [type]);

	useEffect(() => {
		setPagina(page);
	}, [page]);

	const paginate = () => {
		return (
			<Pagination
				activePage={pagina}
				totalPages={Math.trunc(total / 12) + 1}
				onPageChange={(event, data) => {
					console.log("PAGE", data.activePage);
					setAgentsPage(Number(data.activePage));
				}}
			/>
		);
	};

	return (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header>
						{total} - {type?.toUpperCase()}
					</Header>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<AgentsFilters
						role={profile.type!}
						namespace={bot_id}
						page={page}
						getByType={getByType}
						setPage={setAgentsPage}
					/>
				</Grid.Column>
				<Grid.Column>
					<Button
						color="green"
						floated="right"
						onClick={() =>
							navigate(
								`/${
									bot_id === namespace_1
										? "creditos"
										: "distribuciones"
								}/crear/`
							)
						}
					>
						<Icon name="plus" /> Crear
					</Button>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
			<Grid.Row style={{ minHeight: "600px" }}>
				{!loading ? (
					<Grid.Column>
						{agents.length > 0 ? (
							<AgentsBox agents={agents.map((agent) => agent)} />
						) : (
							<h2>NO TIENES {type?.toUpperCase()} REGISTRADOS</h2>
						)}
					</Grid.Column>
				) : null}
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

interface IComponentProps {
	namespace: string;
}

const mapStateToProps = (state: RootState, props: IComponentProps) => {
	return {
		profile: state.users.profile,
		agents: state.adminAgents.agents,
		loading: state.adminAgents.loading,
		role: state.users.user.type,
		bot_id: props.namespace,
		page: state.adminAgents.page,
		total: state.adminAgents.total,
	};
};

const mapActionsToProps = {
	getByType,
	setAgentsPage,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Agents);
