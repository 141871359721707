import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, Button, Modal, Header, Loader } from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { getBySolicitudId, create } from "../../../actions/Biometricos";
import { useParams } from "react-router-dom";
import IBiometrico from "../../../models/Biometrico.model";
import { biometricoInit } from "../../../initialStates";
import { PropmtCreate } from "./components/prompt.create";
import { BiometricoComponent } from "./components/biometrico.component";
import { getById } from "../../../actions/SolicitudCredito";

interface IState {
	biometricos: IBiometrico[];
}

const ModalExpediente: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	solicitudId,
	biometricos,
	biometrico,
	loading,
	loadingBiometrico,
	getBySolicitudId,
	create,
}) => {
	const { id } = useParams();
	const [open, setOpen] = useState(false);
	const [state, setState] = useState<IState>({
		biometricos: [],
	});

	const onOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (open && solicitudId) {
			console.log("BIOMETRICOS CAMBIO", solicitudId, biometrico);
			getBySolicitudId(solicitudId!);
		}
	}, [open, solicitudId, biometrico]);

	const createSolicitud = () => {
		const biometrico: IBiometrico = {
			...biometricoInit,
			leadId: id!,
			agentId: isAgent ? profile.id! : agent.id!,
			solicitudId: solicitudId!,
		};

		const created = create(biometrico);

		if (!created) return;
	};

	return (
		<Modal
			onClose={onClose}
			open={open}
			size="large"
			dimmer={"blurring"}
			style={{
				left: "auto",
				right: "auto",
				top: "auto",
				height: "fit-content",
			}}
			trigger={
				<Button onClick={onOpen} color="blue" floated="right">
					Biométricos
				</Button>
			}
		>
			<Modal.Header>
				<Header>
					<Header.Content>Biométricos</Header.Content>
				</Header>
			</Modal.Header>
			<Modal.Content style={{ minHeight: "600px" }}>
				<Grid>
					<Grid.Row columns={1}>
						<Grid.Column>
							<PropmtCreate create={createSolicitud} />
						</Grid.Column>
					</Grid.Row>
					{!loading ? (
						<Grid.Row columns={1}>
							{biometricos?.map((biometrico) => {
								return (
									<Grid.Column key={biometrico.id}>
										<BiometricoComponent
											biometrico={biometrico}
											isAgent={isAgent}
											loading={loadingBiometrico}
										/>
									</Grid.Column>
								);
							})}
						</Grid.Row>
					) : (
						<Loader active={loading}>Cargando...</Loader>
					)}
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={onClose}>
					Cerrar
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

interface IComponentProps {
	solicitudId: string;
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState, props: IComponentProps) => {
	return {
		user: state.users.user,
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		lead: state.adminUsers.user,
		solicitudId: props.solicitudId,
		biometricos: state.biometricos.biometricos,
		biometrico: state.biometricos.biometrico,
		errors: state.expedientesReducer.errors,
		loading: state.biometricos.loading,
		loadingBiometrico: state.biometricos.loadingBiometrico,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getBySolicitudId,
	create,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(ModalExpediente);
