import {
    SET_CONVERSATION,
    SET_CONVERSATIONS,
    SET_LOADING_CONVERSATION,
    SET_LOADING_CONVERSATIONS,
} from "../constants/conversationsCRM.constants";
import { IConversationCRM } from "../models/IConversationCRM.model";

const initialState = {
    loading: false,
    loadingConversation: false,
    conversation: {} as Readonly<IConversationCRM>,
    conversations: [] as ReadonlyArray<IConversationCRM>,
    errors: "",
};

export type State = Readonly<typeof initialState>;

const conversationsCrmReducer = (
    state: State = initialState,
    action: any
): State => {
    switch (action.type) {
        case SET_CONVERSATION:
            return {
                ...state,
                loading: false,
                loadingConversation: false,
                conversation: action.payload,
                errors: "",
            };
        case SET_CONVERSATIONS:
            return {
                ...state,
                loading: false,
                loadingConversation: false,
                conversations: action.payload,
                errors: "",
            };
        case SET_LOADING_CONVERSATION:
            return {
                ...state,
                loadingConversation: true,
                errors: "",
            };
        case SET_LOADING_CONVERSATIONS:
            return {
                ...state,
                loading: true,
                errors: "",
            };
        default:
            return state;
    }
};

export default conversationsCrmReducer;
