import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Button, Header, Modal, Input, Grid, Label } from 'semantic-ui-react';
import { RootState } from '../../../store/store';
import { create, update, getAll, getById } from '../../../actions/validations.actions';
import { useNavigate } from 'react-router-dom';
import Validations from './validations';
import '../../../css/validation.modal.css';

const ValidationsModal:React.FC<Props> = ({
	
}) => {

	const navigate = useNavigate();

	const [open, setOpen] = useState(true);

	const onClose = () => {
		setOpen(false);
		navigate(-1);
	}

  return (
    <Modal
		onClose={() => onClose()}
		className='validation_modal'
		onOpen={() => setOpen(true)}
		open={open}
		size="fullscreen"
        dimmer={'blurring'}
	>
		<Modal.Header>{"Validaciones"}</Modal.Header>
		<Modal.Content>
			<Validations/>
		</Modal.Content>
			<Modal.Actions >
				<Button
					color='black'
					onClick={onClose}
				>
					Cerrar
				</Button>
			</Modal.Actions>
    	</Modal>
	)
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
		userId: state.users.user.id,
        validation: state.validations.validation,
        errors: state.validations.errors,
        loading: state.validations.loadingValidation
    }
}

const mapActionsToProps = {
	create,
	update,
	getAll,
	getById
}

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector>;

export default connector(ValidationsModal);