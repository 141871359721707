import React from "react";
import { Grid, Segment, Image, Button } from "semantic-ui-react";

const imageBuilder = (
	galery: any[],
	isNew: boolean,
	onDeleteImage: (index: number) => void,
	onEditImage: (index: number, file: File) => void
) => {
	const getNameImage = (position: number) => {
		let title: string = "";
		switch (position) {
			case 0:
				title = "1.- Presentación";
				break;
			case 1:
				title = "2.- Teléfono";
				break;
			case 2:
				title = "3.- Credencial";
				break;
			case 3:
				title = "4.- Tabla";
				break;
			case 4:
				title = "5.- Seguimiento 1";
				break;
			case 5:
				title = "6.- Seguimiento 2";
				break;
		}
		return title;
	};

	let imagesComponents: any[] = [];
	if (galery) {
		if (galery.length <= 0) {
			imagesComponents.push(
				<Grid.Column key={`no_image-0`}>
					<Image src={"/assets/images/no_image.jpg"} />
				</Grid.Column>
			);
		}
		for (let position = 0; position < galery.length; position++) {
			let path;
			if (galery[position]) {
				path = galery[position].Location
					? galery[position].Location
					: URL.createObjectURL(galery[position]);
			} else {
				path = "/assets/images/no_image.jpg";
			}
			imagesComponents.push(
				<Grid.Column
					id={`${position}-img-agent`}
					key={`${position}-img-agent`}
				>
					<Segment key={position} textAlign="center">
						<h5>{getNameImage(position)}</h5>
						<Grid
							style={{
								margin: "5px",
								left: "auto",
								right: "auto",
								textAlign: "center",
							}}
						>
							<Grid.Row centered>
								<Grid.Column
									key={position}
									computer={16}
									mobile={16}
								>
									<Image
										id={`image-${path}`}
										key={position}
										src={path}
										style={{
											minHeight: "100px",
											maxHeight: "100px",
											left: "auto",
											right: "auto",
										}}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column
									key={position}
									computer={16}
									mobile={16}
								>
									{!isNew ? (
										<Button
											circular
											color="red"
											icon="delete"
											onClick={() =>
												onDeleteImage(position)
											}
										/>
									) : null}
									<input
										style={{ visibility: "hidden" }}
										id={`btn-edit-image-${position}`}
										type="file"
										onChange={(event) =>
											onEditImage(
												position,
												event.target.files![0]
											)
										}
									/>
									{isNew ? (
										<Button
											key={position}
											circular
											color="blue"
											icon="edit"
											onClick={() =>
												document
													.getElementById(
														`btn-edit-image-${position}`
													)!
													.click()
											}
										/>
									) : null}
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Grid.Column>
			);
		}
		return imagesComponents;
	}
};

interface IImageSliderProps {
	isNew: boolean;
	galery: File[];
	onEditImage: (index: number, file: File) => void;
	onDeleteImage: (index: number) => void;
}

const ImageSlider = (props: IImageSliderProps) => {
	const { galery, isNew, onDeleteImage, onEditImage } = props;
	return (
		<Segment style={{ width: "100%" }} textAlign="center">
			<Grid columns={5}>
				<Grid.Row>
					{imageBuilder(galery, isNew, onDeleteImage, onEditImage)}
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default ImageSlider;
