import React, { useEffect, useState } from "react";
import { Modal, Grid, Button, Icon, Label, Card } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { getById } from "../../../../actions/user-admin.actions";
import { useParams } from "react-router-dom";
import AddEventModal from "./add_event.modal";
import "../../../../css/actions.modal.css";

const ActionsModal: React.FC<IActionModalProps> = ({
    lead,
    agent,
    profile,
    isAgent,
    getById,
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        console.log("ID", profile.id, lead.wa_id, id);

        if (profile.id && !lead.wa_id && id) {
            getById(id, isAgent ? profile.id! : agent.id!);
        }
    }, [, profile]);

    const whaButton = () => {
        const chatEnabled = isAgent
            ? profile.isChatEnabled
            : agent.isChatEnabled;

        return chatEnabled ? (
            <Button
                basic
                color="green"
                onClick={() => navigate(`/chatCRM/${lead.phone}`)}
            >
                ChatCRM
            </Button>
        ) : (
            <Button
                basic
                as="a"
                color="green"
                href={`https://wa.me/${
                    lead.phone?.length === 10
                        ? `52${lead.phone}`
                        : lead.phone.replace("521", "52")
                }`}
                target="_blank"
            >
                Whatsapp
            </Button>
        );
    };

    return (
        <Modal
            onOpen={() => setOpen(true)}
            onClose={() => navigate(-1)}
            open={open}
            trigger={
                <Button
                    color="green"
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    ACCIONES
                </Button>
            }
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
            }}
            size="large"
        >
            <Modal.Content scrolling>
                <Label
                    attached="top left"
                    color="green"
                    size="huge"
                    pointing
                    ribbon
                >
                    ID: {id}
                </Label>
                <Grid padded verticalAlign="middle" container centered>
                    <Grid.Row columns={1} textAlign="center">
                        <Grid.Column>
                            <h2 className="action-title">
                                {lead?.name?.toUpperCase()}{" "}
                                {lead?.lastName?.toUpperCase()} -{" "}
                                {lead.CURP?.toUpperCase()}
                            </h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Card.Group>
                        <Card>
                            <Card.Content>
                                <Card.Header>Whatsapp</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="whatsapp"
                                        color="green"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Envía Whatsapp a tu cliente.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    {whaButton()}
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Validación</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="check"
                                        color="yellow"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Valida o revisa las validaciones de tu
                                    cliente.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    <Button
                                        basic
                                        color="yellow"
                                        onClick={() => {
                                            navigate(
                                                `/validations/customer/${
                                                    lead.Fid ?? lead.wa_id
                                                }`
                                            );
                                        }}
                                    >
                                        Validaciones
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Cotización</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="calculator"
                                        color="blue"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Cotiza a tu cliente según el producto.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div>
                                    <Button
                                        basic
                                        color="black"
                                        onClick={() =>
                                            navigate("/cotizador/tradicional")
                                        }
                                    >
                                        Tradicional
                                    </Button>
                                    <Button
                                        basic
                                        color="blue"
                                        onClick={() =>
                                            navigate("/cotizador/renovacion")
                                        }
                                    >
                                        Renovación
                                    </Button>
                                    <Button
                                        basic
                                        color="red"
                                        onClick={() =>
                                            navigate("/cotizador/compra")
                                        }
                                    >
                                        Compra
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Editar</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="edit"
                                        color="brown"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Revisa el expediente de tu cliente.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    <Button
                                        basic
                                        color="brown"
                                        onClick={() =>
                                            navigate(
                                                `/customer/edit/${
                                                    lead.Fid ?? lead.wa_id
                                                }`
                                            )
                                        }
                                    >
                                        Editar
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Agenda</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="calendar"
                                        color="red"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Agenda a tu cliente.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    <AddEventModal user={lead} />
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Expedientes</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="file pdf"
                                        color="purple"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Revisa el expediente de tu cliente.
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    <Button
                                        basic
                                        color="purple"
                                        onClick={() =>
                                            navigate(`/expediente/${lead._id}`)
                                        }
                                    >
                                        Expediente
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                        <Card>
                            <Card.Content>
                                <Card.Header>Servicios</Card.Header>
                                <Card.Meta>
                                    <Icon
                                        name="tasks"
                                        color="teal"
                                        size="big"
                                    />
                                </Card.Meta>
                                <Card.Description>
                                    Registra los descuentos por cliente
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <div className="ui two buttons">
                                    <Button
                                        basic
                                        color="teal"
                                        onClick={() =>
                                            navigate(`/services/${lead._id}`)
                                        }
                                    >
                                        Servicios
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                    </Card.Group>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => navigate(-1)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        lead: state.adminUsers.user,
        profile: state.users.profile,
        agent: state.adminAgents.agent,
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
    };
};

const mapActionstoProps = {
    getById,
};

const connector = connect(mapStateToProps, mapActionstoProps);

type IActionModalProps = ConnectedProps<typeof connector>;

export default connector(ActionsModal);
