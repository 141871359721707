import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import IBiometrico from "../../models/Biometrico.model";
import { setErrors, setLoading, setBiometricoData } from "./utils.actions";

export const create = (biometrico: IBiometrico) => async (dispatch: any) => {
	dispatch(setLoading());
	let created = false;

	await axios
		.post(`${apiUrl}/biometricos/`, biometrico)
		.then((res) => {
			if (res.status !== 200) {
				console.warn("CREATE", res.data.message);
				dispatch(setErrors(res.data.message));
				return created;
			}
			dispatch(setBiometricoData(res.data.biometrico));

			created = true;
		})
		.catch((err) => {
			console.error("CREATE", err.response.data.message);
			dispatch(setErrors(err.response.data.message));
			return created;
		});
	return created;
};
