import {
  SET_GROUP_TEMPLATES,
  SET_GROUP_TEMPLATE,
  SET_LOADING_GROUP_TEMPLATE,
  SET_LOADING_GROUP_TEMPLATES,
  SET_LOADING_ERRORS_GROUP_TEMPLATE,
  CLEAR_LOADING_ERRORS_GROUP_TEMPLATE,
  SET_GROUP_TEMPLATE_BY_ID,
} from "../constants/group_template.constants";

import IGroupTemplate from "../models/template.model";
import { CategoryTemplates } from "../layout/components/templates/interfaces/categoryTemplates.interface";

const initialState = {
  loading: false,
  loadingTemplate: false,
  group_templates: [] as ReadonlyArray<CategoryTemplates>,
  group_template: null as unknown as Readonly<CategoryTemplates>,
  errors: "",
};

export type UserState = Readonly<typeof initialState>;

const groupTemplatesReducer = (
  state: UserState = initialState,
  action: any
): UserState => {
  switch (action.type) {
    case SET_GROUP_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        group_template: action.payload,
        errors: "",
      };
    case SET_GROUP_TEMPLATES:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        group_templates: action.payload,
        errors: "",
      };
    case SET_GROUP_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        group_template: action.payload,
        errors: "",
      };
    case SET_LOADING_GROUP_TEMPLATES:
      return {
        ...state,
        loading: true,
        errors: "",
      };
    case SET_LOADING_GROUP_TEMPLATE:
      return {
        ...state,
        loadingTemplate: true,
        errors: "",
      };
    case SET_LOADING_ERRORS_GROUP_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        errors: action.payload,
      };
    case CLEAR_LOADING_ERRORS_GROUP_TEMPLATE:
      return {
        ...state,
        loading: false,
        loadingTemplate: false,
        errors: "",
      };
    default:
      return state;
  }
};

export default groupTemplatesReducer;
