import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Segment, Loader } from "semantic-ui-react";
import { RootState } from "../../../store/store";
import { getAgent, getAgents } from "../../../actions/agent-admin.actions";
import {
	update,
	setUserData,
	getAll,
	getAllByFollowment,
	setPage,
	setFollowmentPage,
	updateUltimoSeguimiento,
	setFilters,
} from "../../../actions/user-admin.actions";
import { IUserModel } from "../../../models/User.model";
import LeadsCreditos from "./components/leads.table";
import LeadsFilters from "./components/leads.filters";
import FillLeadsModal from "./components/assign.leads";

const Users: React.FC<IDetailAgentProps> = ({
	profile,
	agent,
	agents,
	leads,
	lead,
	loadingLead,
	loadingLeads,
	leadsSeguimiento,
	pageSeguimiento,
	counterSeguimiento,
	isAgent,
	isAdmin,
	isCoordinator,
	counter,
	page,
	filters,
	getAll,
	setUserData,
	setPage,
	update,
	updateUltimoSeguimiento,
	getAgents,
	getAllByFollowment,
	setFollowmentPage,
	getAgent,
	setFilters,
}) => {
	const navigate = useNavigate();

	const { id } = useParams();

	useEffect(() => {
		if (!isAgent && id) {
			getAgent(id);
		}
	}, [id]);

	useEffect(() => {
		console.log("PROFILE", profile, loadingLead);

		if ((profile.id || agent.id) && !loadingLead) {
			getEntities();
		}
	}, [, agent, profile, loadingLead, page, pageSeguimiento]);

	const getEntities = () => {
		console.log("GET ENTITIES", filters, page);

		const order = {
			createdAt: "desc",
			_id: "desc",
		};

		const filter = {
			agent: id ?? profile.id,
			...filters,
		};

		if (!isAgent && !id) delete filter.agent;

		getAll(page, 10, filter, order);

		getAllByFollowment(
			pageSeguimiento,
			10,
			{
				...{
					agent: id ?? profile.id,
					hide_users: true,
				},
				proximoSeguimiento: new Date().toJSON().slice(0, 10),
			},
			order
		);

		if (!isAgent) {
			getAgents();
		}
	};

	const getAgentName = (id: string) => {
		let name = "";
		let agent = agents.filter((f) => f.id! === id)[0];
		name = agent ? `${agent.name} ${agent.lastName}` : "";
		return name;
	};

	const updateStatus = async (customer: IUserModel, status: string) => {
		let updated = false;
		let customerUpdated = { ...customer, agentStatus: status };
		updated = await update(customerUpdated)!;
		return updated;
	};

	const updateAmount = async (customer: IUserModel, amount: number) => {
		let updated = false;
		let customerUpdated = { ...customer, amount: amount };
		updated = await update(customerUpdated)!;
		return updated;
	};

	const updateLead = async (customer: IUserModel, data: any) => {
		let updated = false;
		let customerUpdated = { ...customer, ...data };
		updated = await update(customerUpdated)!;
		return updated;
	};

	const updateSeguimiento = async (
		customerId: string,
		agentId: string,
		ultimoSeguimiento: string
	) => {
		await updateUltimoSeguimiento(customerId, agentId, ultimoSeguimiento);
	};

	const updateProximoSeguimiento = async (
		customer: IUserModel,
		proximoSeguimiento: string
	) => {
		let updated = false;
		let customerUpdated = {
			...customer,
			proximoSeguimiento: proximoSeguimiento,
		};
		updated = await update(customerUpdated)!;
		return updated;
	};

	return (
		<Segment textAlign="center">
			<LeadsCreditos
				agent={isAgent ? profile : agent}
				counter={counterSeguimiento}
				isAgent={isAgent}
				leads={leadsSeguimiento.map((lead) => lead)}
				loadingLead={loadingLead}
				page={pageSeguimiento}
				setPage={setFollowmentPage}
				setUser={setUserData}
				updateProximoSeguimiento={updateProximoSeguimiento}
				updateSeguimiento={updateSeguimiento}
				updateStatus={updateStatus}
				updateAmount={updateAmount}
				update={updateLead}
				getAgentName={getAgentName}
				selectedLead={lead}
			/>
			<Segment>
				<h1>Clientes Asignados: {counter}</h1>
				<LeadsFilters
					agentId={isAgent ? profile.id! : id ?? agent.id!}
					filters={filters}
					getAll={getAll}
					setPage={setPage}
					isAgent={isAgent}
					setFilters={setFilters}
				/>
			</Segment>
			<Button
				size="large"
				color="blue"
				floated="right"
				style={{ margin: "5px" }}
				onClick={() => navigate("/customer/create")}
			>
				<Icon name="plus" />
				Registrar
			</Button>
			<Button
				size="large"
				color="green"
				floated="right"
				style={{ margin: "5px" }}
				onClick={() =>
					navigate(
						`/leads/load/${isAgent ? profile.id : id ?? agent.id}`
					)
				}
			>
				<Icon name="plus" />
				Cargar Excel
			</Button>
			{isAdmin ? (
				<FillLeadsModal agent={isAgent ? profile : agent} />
			) : null}
			{!loadingLeads ? (
				<LeadsCreditos
					agent={isAgent ? profile : agent}
					counter={counter}
					isAgent={isAgent}
					leads={leads.map((lead) => lead)}
					loadingLead={loadingLead}
					page={page}
					setPage={setPage}
					setUser={setUserData}
					updateProximoSeguimiento={updateProximoSeguimiento}
					updateSeguimiento={updateSeguimiento}
					updateStatus={updateStatus}
					updateAmount={updateAmount}
					update={updateLead}
					getAgentName={getAgentName}
					selectedLead={lead}
				/>
			) : (
				<Loader active={loadingLeads} size="huge">
					Cargando Usuarios
				</Loader>
			)}
		</Segment>
	);
};

const mapStateToProps = (state: RootState) => {
	return {
		profile: state.users.profile,

		agent: state.adminAgents.agent,
		agents: state.adminAgents.agents,
		lead: state.adminUsers.user,

		leads: state.adminUsers.usersConversation,
		counter: state.adminUsers.counter,
		page: state.adminUsers.page,
		filters: state.adminUsers.filters,

		leadsSeguimiento: state.adminUsers.followumentUsersConversation,
		counterSeguimiento: state.adminUsers.followmentCounter,
		pageSeguimiento: state.adminUsers.followmentPage,

		loadingLeads: state.adminUsers.loading,
		loadingLead: state.adminUsers.loadingUpdate,

		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isMentor: state.users.user.type === "IS_MENTOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getAll,
	getAgent,
	setUserData,
	setPage,
	update,
	updateUltimoSeguimiento,
	getAgents,
	getAllByFollowment,
	setFollowmentPage,
	setFilters,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IDetailAgentProps = ConnectedProps<typeof connector>;

export default connector(Users);
