import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Icon, Button } from "semantic-ui-react";
import { RootState } from "../../../../store/store";
import { ConnectedProps, connect } from "react-redux";
import { chatCRMUrl } from "../../../../constants/enviroment";
import { io as socketIOClient, Socket } from "socket.io-client";
import { useParams } from "react-router";
import { ImagePreview } from "./components/ImagePreview/ImagePreview";
import { ChatContainer } from "./components/ChatContainer/ChatContainer";
import { getByPhone } from "../../../../actions/user-admin.actions";
import { ClientMessage } from "./components/ConversationContainer/ClientMessage";
import { OwnMessage } from "./components/ConversationContainer/OwnMessage";
import { PromptAgent } from "./components/Modals/promptAgent";
import { ValidRecipient } from "./components/Modals/validRecipient";
import { generateWaLink } from "../../users/helpers/leads.utils";
import "./chatCrm.css";

const ChatCrm: React.FC<IProps> = ({
    isAgent,
    profile,
    agent,
    lead,
    getByPhone,
}) => {
    const navigate = useNavigate();
    const [socketRef, setSocketRef] = useState<Socket>(socketIOClient);
    const [modalPreview, setModalPreview] = useState(false);
    const [imagePreview, setImagePreview] = useState({});
    const [message, setMessage] = useState("");
    const [conversation, setConversation] = useState<any>([]);
    const [chatPermission, setChatPermission] = useState(false);
    const [generalPermission, setGeneralPermission] = useState(false);
    const [notifications, setNotifications] = useState("");
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [validRecipient, setValidRecipient] = useState();
    const [statusMessage, setStatusMessage] = useState({});
    const [enable, setEnable] = useState(1);
    const [messages, setMessages] = useState({
        own_message: "",
        client_message: "",
    });
    const { id } = useParams();
    const leadPhone: any = id;

    const getMessages = async () => {
        try {
            const messages: any = await axios({
                method: "POST",
                url: `${chatCRMUrl}/api/getConversations`,
                data: {
                    conversation_id: id,
                    agent_id: isAgent ? profile.id : agent.id,
                    agent_phone: isAgent ? profile.phone : agent.phone,
                },
            });
            setConversation(messages?.data?.messages);
            setChatPermission(messages?.data?.permissions.chat_permission);
            setNotifications(messages?.data?.notifications);
            setValidRecipient(messages?.data?.conversation?.valid_recipient);
            setGeneralPermission(
                messages?.data?.permissions.general_permission
            );
        } catch (error) {
            console.log("ERROR AL OBTENER LA CONVERSACION", error);
        }
    };

    const comparedId = async () => {
        try {
            const data: any = await axios({
                method: "POST",
                url: `${chatCRMUrl}/api/compareConversationId`,
                data: {
                    conversation_id: id,
                    agent_id: isAgent ? profile.id : agent.id,
                },
            });
            setEnable(data?.data?.access);
        } catch (error) {
            console.log("ERROR AL COMPARAR LA CONVERSACION POR ID", error);
        }
    };

    const handleChangeNotifications = async () => {
        const response: any = await axios({
            method: "PUT",
            url: `${chatCRMUrl}/api/switchNotifications/${id}`,
        }).catch((error) => console.log(error));
        console.log("NotificationSwitch", response);
    };

    const handleCheckboxChange = async () => {
        setIsChecked(!isChecked);
    };

    const emitMessage = (message: any) => {
        socketRef.emit("message", {
            ...message,
            agent_id: profile.id,
            agent_phone: profile.phone,
        });
    };

    const pushMessage = () => {
        socketRef.on("own-message", (message) => {
            messages.own_message = message;
            if (!message || typeof message === "string") return;
            setConversation([...conversation, messages.own_message]);
            setMessages({
                own_message: "",
                client_message: "",
            });
        });
    };

    const receiveMessage = () => {
        socketRef.on("client-message", (message) => {
            messages.client_message = message;
            if (!message || typeof message === "string") return;
            setConversation([...conversation, messages.client_message]);
            setMessages({
                own_message: "",
                client_message: "",
            });
        });
    };

    const handlePermission = () => {
        socketRef.on("permission", (permission) => {
            setChatPermission(permission.chatPermission);
            setGeneralPermission(permission.generalPermission);
            setValidRecipient(permission.valid_recipient);
        });
    };

    const handleStatusMessage = () => {
        socketRef.on("status", (status) => {
            console.log("STATUS", status);
            setStatusMessage({ id: status._id, status: status.status });
        });
    };

    const scrollToBottom = () => {
        let scroll: any = document.querySelector(
            ".chatcrm-conversation-container"
        );
        scroll.scrollTop = scroll.scrollHeight;
    };

    useEffect(() => {
        setIsChecked(notifications === "ON" ? true : false);
    }, [notifications]);

    useEffect(() => {
        if (profile.id || agent.id) {
            const idAgent = isAgent ? profile.id : agent.id;
            getByPhone(idAgent!, id);
        }
    }, [, profile, agent, id]);

    useEffect(() => {
        if (socketRef.active) {
            handlePermission();
            handleStatusMessage();
        }
    }, [, conversation, messages, socketRef.active]);

    useEffect(() => {
        if (profile.id) {
            if (isAgent && !profile.isChatEnabled) {
                navigate(-1);
            }
            comparedId();
            getMessages();
            setSocketRef(
                socketIOClient(chatCRMUrl, {
                    query: { id: id },
                })
            );
        }
    }, [, profile, id, isAgent]);

    useEffect(() => {
        if (profile.id && !socketRef.connected) {
            socketRef.connect();
        }
        if (socketRef.active) {
            pushMessage();
            receiveMessage();
            scrollToBottom();
        }
    }, [conversation, profile, socketRef.connected, socketRef.active]);

    return (
        <div>
            {enable !== 0 ? null : <PromptAgent />}
            <ImagePreview
                modalPreview={modalPreview}
                imagePreview={imagePreview}
                setModalPreview={setModalPreview}
            />
            <div className="chatcrm-container">
                <div className="chatcrm-contact">
                    <Icon name="user circle" size="big" />
                    <div className="chatcrm-contact-info">
                        <p className="chatcrm-contact-info-name">
                            LEAD: {lead?.name?.toUpperCase()}{" "}
                            {lead?.lastName?.toUpperCase()}
                        </p>
                        <p className="chatcrm-contact-info-phone">{id}</p>
                    </div>
                    <div className="chatcrm-contact-info-whatsapp">
                        <Button
                            color="green"
                            href={generateWaLink(leadPhone)}
                            target="_blank"
                        >
                            <Icon name="external" />
                            WhatsApp Web
                        </Button>
                    </div>
                    <div className="chatcrm-notifications-toggle">
                        <Checkbox
                            toggle
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            onClick={handleChangeNotifications}
                        />
                        <div className="chatcrm-notifications-icon">
                            {isChecked ? (
                                <Icon name="bell" color="green" />
                            ) : (
                                <Icon name="bell slash" color="red" />
                            )}
                        </div>
                    </div>
                </div>
                <div className="chatcrm-conversation-container">
                    {validRecipient || validRecipient === undefined ? (
                        <>
                            {conversation &&
                                conversation.map((item: any) => {
                                    if (item.isOwn) {
                                        return (
                                            <OwnMessage
                                                key={item._id}
                                                item={item}
                                                setModalPreview={
                                                    setModalPreview
                                                }
                                                setImagePreview={
                                                    setImagePreview
                                                }
                                                statusMessage={statusMessage}
                                            />
                                        );
                                    } else {
                                        return (
                                            <ClientMessage
                                                key={item._id}
                                                item={item}
                                                setModalPreview={
                                                    setModalPreview
                                                }
                                                setImagePreview={
                                                    setImagePreview
                                                }
                                            />
                                        );
                                    }
                                })}
                        </>
                    ) : (
                        <ValidRecipient />
                    )}
                </div>
                <ChatContainer
                    chatPermission={chatPermission}
                    generalPermission={generalPermission}
                    emitMessage={emitMessage}
                    setMessage={setMessage}
                    message={message}
                    isAgent
                    profile
                    agent
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        profile: state.users.profile,
        lead: state.adminUsers.user,
        agent: state.adminAgents.agent,
    };
};

const mapActionsToProps = {
    getByPhone,
};
const connector = connect(mapStateToProps, mapActionsToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(ChatCrm);
