import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
    Button,
    Dimmer,
    Grid,
    GridColumn,
    Header,
    Icon,
    Loader,
    Segment,
} from "semantic-ui-react";
import { RootState } from "../../../../../store/store";
import { getAllServices } from "../../../../../actions/services.actions";
import { useNavigate } from "react-router";
import { createDiscounts } from "../../services/createDiscounts";
import { MXN } from "../../helper/MXN";
import "./Services.css";

const Services: React.FC<IProps> = ({
    profile,
    getAllServices,
    services,
    loading,
    lead,
}) => {
    const navigate = useNavigate();
    const [selectedServices, setSelectedServices] = useState<any>([]);
    const [discounts, setDiscounts] = useState<any>([]);

    const totalCost = selectedServices.reduce(
        (accumulator: any, service: any) => accumulator + service.cost,
        0
    );

    const addService = (service: any) => {
        if (!selectedServices.some((s: any) => s.serviceId === service._id))
            setSelectedServices([...selectedServices, service]);

        if (!discounts.some((s: any) => s.serviceId === service._id))
            setDiscounts([
                ...discounts,
                {
                    serviceId: service._id,
                    agentId: profile.id,
                    leadId: lead._id,
                },
            ]);
    };

    const removeService = (serviceId: any) => {
        const updatedServices = selectedServices.filter(
            (service: any) => service._id !== serviceId
        );
        setSelectedServices(updatedServices);
        const updatedServicesData = discounts.filter(
            (service: any) => service.serviceId !== serviceId
        );
        setDiscounts(updatedServicesData);
    };

    const handleCreateDiscount = () => {
        try {
            if (lead.CURP === "" && lead.NSS === "") {
                Swal.fire({
                    icon: "error",
                    title: "No se puede solicitar servicios",
                    text: "El cliente seleccionado no cuenta con una CURP y NSS registrados en el CRM",
                });
                navigate(-1);
            } else {
                createDiscounts({ discounts });
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Servicios Solicitados",
                    showConfirmButton: false,
                    timer: 1500,
                });
                navigate(-1);
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "No se pudieron solicitar los servicios",
            });
        }
    };

    useEffect(() => {
        if (profile.id) {
            getAllServices(services);
        }
    }, [, profile.id]);

    return (
        <Grid centered padded>
            <Grid.Column width={10}>
                <Segment>
                    <Header as="h2">
                        <Icon name="file" />
                        <Header.Content>
                            Solicitar Servicios
                            <Header.Subheader>
                                Lead: {lead.name} {lead.lastName} | Telefono:{" "}
                                {lead.phone} | CURP: {lead.CURP} | NSS:{" "}
                                {lead.NSS}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <br />
                    <Grid centered>
                        <Grid.Row columns={3}>
                            <Grid.Column width={7}>
                                <Segment color="orange">
                                    {loading ? (
                                        <Dimmer active>
                                            <Loader>Cargando Servicios</Loader>
                                        </Dimmer>
                                    ) : (
                                        <>
                                            <Header as="h">
                                                <Icon name="copy" />
                                                <Header.Content>
                                                    Servicios Disponibles
                                                </Header.Content>
                                            </Header>
                                            <div className="services-available-container">
                                                {services &&
                                                    services.map(
                                                        (service: any) => (
                                                            <div
                                                                className={
                                                                    selectedServices.includes(
                                                                        service
                                                                    )
                                                                        ? "services-available-items-select"
                                                                        : "services-available-items"
                                                                }
                                                                key={
                                                                    service._id
                                                                }
                                                            >
                                                                <div className="services-available-info">
                                                                    <h5>
                                                                        {
                                                                            service.type
                                                                        }
                                                                    </h5>
                                                                    <p>
                                                                        Costo:{" "}
                                                                        {MXN.format(
                                                                            service.cost
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <Button
                                                                    icon="plus"
                                                                    color="green"
                                                                    onClick={() =>
                                                                        addService(
                                                                            service
                                                                        )
                                                                    }
                                                                    disabled={selectedServices.includes(
                                                                        service
                                                                    )}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </>
                                    )}
                                </Segment>
                            </Grid.Column>
                            <GridColumn
                                width={1}
                                style={{ textAlign: "center" }}
                            >
                                <Icon
                                    name="arrow alternate circle right"
                                    color="green"
                                    size="big"
                                />
                            </GridColumn>
                            <Grid.Column width={7}>
                                <Segment color="green">
                                    <Header as="h3">
                                        <Icon name="check" />
                                        <Header.Content>
                                            Servicios listos para solicitar
                                        </Header.Content>
                                    </Header>
                                    <div className="services-selected-container">
                                        {selectedServices &&
                                            selectedServices.map(
                                                (service: any) => (
                                                    <div
                                                        className="services-selected-items"
                                                        key={service._id}
                                                    >
                                                        <div className="services-selected-info">
                                                            <h5>
                                                                {service.type}
                                                            </h5>
                                                            <p>
                                                                Costo:{" "}
                                                                {MXN.format(
                                                                    service.cost
                                                                )}
                                                            </p>
                                                        </div>
                                                        <Button
                                                            icon="times circle"
                                                            color="red"
                                                            onClick={() =>
                                                                removeService(
                                                                    service._id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </Segment>
                                <Segment color="blue">
                                    <Header as="h5" floated="left">
                                        Servicios: {selectedServices.length}
                                    </Header>
                                    <Header as="h5" floated="right">
                                        Total: {MXN.format(totalCost)}
                                    </Header>
                                    <br />
                                </Segment>
                                <Button
                                    floated="right"
                                    color="black"
                                    onClick={() => navigate(-1)}
                                >
                                    Volver
                                </Button>
                                {selectedServices.length > 0 ? (
                                    <Button
                                        floated="right"
                                        color="green"
                                        onClick={() => handleCreateDiscount()}
                                    >
                                        <Icon name="check" />
                                        Solicitar Servicios
                                    </Button>
                                ) : null}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        profile: state.users.profile,
        lead: state.adminUsers.user,
        agent: state.adminAgents.agent,
        services: state.servicesReducer.services,
        loading: state.servicesReducer.loading,
    };
};

const mapActionsToProps = {
    getAllServices,
};

const connector = connect(mapStateToProps, mapActionsToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(Services);
