import React, { useEffect, useState } from "react";
import { Grid, Input, Image, Icon, Button, Label } from "semantic-ui-react";
import "../credentials.css";
import { IoLogoWhatsapp } from "react-icons/io";

const CredencialAgente = ({ isAgent, profile, agent }: any) => {
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);
    const [imageData, setImageData] = useState("");
    const [qrData, qrImageData] = useState("");

    const handleProfileImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setSelectedProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const imageUrl = "https://i.imgur.com/sNFK20r.png";
        const imageToBase64 = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    setImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir la imagen:", error);
            }
        };
        imageToBase64();
    }, []);

    useEffect(() => {
        const qrUrl = "https://i.postimg.cc/DwKm1wn1/QrCWVL.jpg";
        const qrToBase64 = async () => {
            try {
                const response = await fetch(qrUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    qrImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir el QR:", error);
            }
        };
        qrToBase64();
    }, []);

    return (
        <>
            <div>
                <input
                    style={{ display: "none" }}
                    id="btn-upload"
                    type="file"
                    onChange={handleProfileImageChange}
                    multiple
                    name="btn-foto"
                    title="Subir fotos"
                />
                <h3>Carga tu foto en la credencial</h3>
                <Button
                    onClick={() => {
                        document.getElementById("btn-upload")!.click();
                    }}
                    color="green"
                >
                    <Icon name="upload" />
                    Cargar Foto
                </Button>
            </div>
            <div className="cotizador-component">
                <Grid
                    id="credencial-agente"
                    className="cotizador-container padding-0"
                >
                    <div className="header_credential">
                        <div className="header_title">
                            <p>ASESOR CERTIFICADO</p>
                        </div>
                        <div className="header_name">
                            <p>
                                {isAgent
                                    ? `${profile.name} ${profile.lastName}`
                                    : `${agent.name} ${agent.lastName}`}
                            </p>
                        </div>
                        <div className="header_phone_title">
                            <p>Teléfono:</p>
                        </div>
                        <div className="header_phone">
                            <p>
                                <IoLogoWhatsapp className="seguimiento_contact_icon" />{" "}
                                {isAgent ? profile.phone : agent.phone}
                            </p>
                        </div>
                    </div>
                    <div className="info_container">
                        <div className="info_logo">
                            {imageData && (
                                <img
                                    src={imageData}
                                    alt="Logo"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                        </div>
                        <div className="info_perfil">
                            {selectedProfileImage ? (
                                <Image
                                    src={selectedProfileImage}
                                    alt="Selected"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "500px",
                                    }}
                                />
                            ) : (
                                <div className="credential_icon_select_image">
                                    <Icon name="user circle" color="orange" />
                                </div>
                            )}
                        </div>
                        <div className="info_qr">
                            {qrData && (
                                <img
                                    src={qrData}
                                    alt="QR"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                            <p>
                                ESCANEAR CÓDIGO QR
                                <br />
                                PARA VALIDAR LA IDENTIDAD
                                <br />
                                DE ASESOR CERTIFICADO
                            </p>
                        </div>
                    </div>
                    <div className="contact_container">
                        <div className="contact_email">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontWeight: "bold",
                                    }}
                                >
                                    CORREO:
                                </b>
                                <br />
                                <b>{isAgent ? profile.email : agent.email}</b>
                            </p>
                        </div>
                        <div className="contact_phone">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontWeight: "bold",
                                    }}
                                >
                                    QUEJAS O CAMBIO DE ASESOR:
                                </b>
                                <br />
                                <b>
                                    (449) 9967297, (449) 6882899 <br />
                                    (449) 6887301, (449) 6887302
                                </b>
                                <br />
                                <IoLogoWhatsapp className="seguimiento_contact_icon" />{" "}
                                <b style={{ fontSize: "20px" }}>449 584 3856</b>
                            </p>
                        </div>
                    </div>
                    <div className="info_text">
                        <p>
                            <b
                                style={{
                                    color: "orangered",
                                    fontWeight: "bold",
                                }}
                            >
                                ¡ATENCIÓN!
                            </b>
                            <b>
                                NUNCA SE SOLICITA ANTICIPOS O DINERO PARA NINGÚN
                                TRÁMITE
                                <br />
                                Toda la información está protegida por la ley
                                federal de protección de datos; Visite nuestro
                                aviso de
                                <br />
                                privacidad:
                                http://www.elprestamodelmes.com.mx/aviso_privacidad/
                            </b>
                        </p>
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default CredencialAgente;
