import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { io as socketIOClient, Socket } from "socket.io-client";
import { useEffect, useState } from "react";
import { RootState } from "../../../store/store";
import { connect, ConnectedProps } from "react-redux";
import { Button, Icon, Radio } from "semantic-ui-react";
import { notificationCRM } from "../../../constants/enviroment";
import { INotification } from "../../../models/INotificationCRM";
import {
    getNotificationsById,
    setNotifications,
    handleNotification,
    setPage,
} from "../../../actions/notifications.actions";
import { LoaderNotification } from "./components/loaderNotification";
import "./Notification.css";

const Notification: React.FC<NotificationProps> = ({
    authenticated,
    profile,
    notifications,
    page,
    totalPages,
    count,
    getNotificationsById,
    setNotifications,
    setPage,
    loading,
}) => {
    const [socketRef, setSocketRef] = useState<Socket>(socketIOClient);
    const [openBox, setOpenBox] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<any>();
    const [showReadNotifications, setShowReadNotifications] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const body: any = document.body;
    const box: any = document.getElementById("box");
    const item: any = document.getElementById("item");
    const header: any = document.querySelector("header");
    const info: any = document.getElementById("info");

    body.addEventListener("click", function (event: any) {
        if (
            header &&
            event.target &&
            !header.contains(event.target) &&
            openBox
        ) {
            toggleNotificationBox();
        }
    });

    const toggleNotificationBox = () => {
        if (openBox) {
            box.style.width = "0px";
            box.style.opacity = "0";
            item.style.transition = "0s";
            item.style.opacity = "0";
            info.style.transition = "0s";
            info.style.opacity = "0";
            setOpenBox(false);
        } else {
            box.style.width = "620px";
            box.style.opacity = "1";
            item.style.transition = "2s";
            item.style.opacity = "1";
            info.style.transition = "2s";
            info.style.opacity = "1";
            setOpenBox(true);
        }
    };

    const reciveNotifications = () => {
        socketRef.on("notification", (data: any) => {
            console.log("DATA N", data);
            const dataRoute = data.url.split("/");
            const locationArray = document.location.pathname.split("/");
            if (
                dataRoute[dataRoute.length - 1] ===
                locationArray[locationArray.length - 1]
            ) {
                return;
            }
            const notifs: INotification[] = Object.assign([], notifications);
            notifs.splice(0, 0, data);
            setNotifications(notifs);
            toast.info(`Tienes un nuevo mensaje de ${data.leadName}`);
        });
    };

    const getCurrentLocation = () => {
        return location.pathname.split("/");
    };

    const toggleShowReadNotifications = () => {
        setShowReadNotifications(!showReadNotifications);
    };

    const filteredNotifications = showReadNotifications
        ? notifications
        : notifications.filter((notification) => !notification.isOpened);

    const loadNotifications = async () => {
        return await getNotificationsById(
            profile.id,
            page,
            Object.assign([], notifications)
        );
    };

    const openNotification = (id: any, url: string) => {
        const slug = url.split("/");
        handleNotification(id);
        toggleNotificationBox();
        setNotifications([]);
        setPage(1);
        navigate(`/chatCRM/${slug[slug.length - 1]}`);
        loadNotifications();
    };

    const notificationsContainer = document.querySelector(
        ".notification_items_box"
    );
    notificationsContainer?.addEventListener("scroll", (event: any) => {
        setScrollPosition(event.srcElement.scrollTop);

        const diff =
            event.srcElement.scrollHeight - event.srcElement.clientHeight;
        setContainerMaxHeight(diff);
    });

    const [scrollPosition, setScrollPosition] = useState<any>(
        notificationsContainer?.scrollTop
    );
    const [containerMaxHeight, setContainerMaxHeight] = useState<any>(
        notificationsContainer?.clientHeight
    );

    useEffect(() => {
        setCurrentLocation(getCurrentLocation());
    }, [, location.pathname]);

    useEffect(() => {
        if (profile.id) {
            getNotificationsById(
                profile.id,
                page,
                Object.assign([], notifications)
            );
            setSocketRef(
                socketIOClient(notificationCRM, {
                    query: { id: profile.id },
                })
            );
        }
    }, [, profile.id]);

    useEffect(() => {
        if (profile.id && !socketRef.connected) {
            socketRef.connect();
        }
        if (socketRef.active) {
            reciveNotifications();
        }
    }, [profile.id, socketRef.connected, socketRef.active]);

    useEffect(() => {
        if (profile.id) {
            if (containerMaxHeight === scrollPosition) {
                if (totalPages >= page) {
                    loadNotifications().finally(() => setPage(page + 1));
                }
            }
        }
    }, [page, profile.id, containerMaxHeight, scrollPosition]);

    return (
        <>
            {authenticated ? (
                <>
                    <div
                        className="notification_icon"
                        onClick={() => toggleNotificationBox()}
                    >
                        <Button
                            icon="bell"
                            color="green"
                            size="huge"
                            circular
                        />
                        <span>{count}</span>
                    </div>
                    <div className="main_container">
                        <div className="notification_box" id="box">
                            <div id="info">
                                <div className="notification_header_info">
                                    <h2>Notificaciones</h2>
                                    <div className="notification_header_visible">
                                        <p>Mostrar notificaciones leídas</p>
                                        <Radio
                                            style={{
                                                backgroundColor: "grey",
                                                borderRadius: "10px",
                                            }}
                                            toggle
                                            onClick={
                                                toggleShowReadNotifications
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="notification_info">
                                    <h2>
                                        <span>{count}</span> Mensajes sin leer
                                    </h2>
                                </div>
                            </div>
                            <div className="notification_items_box" id="item">
                                {filteredNotifications.map(
                                    (notification: any, index: number) => (
                                        <div
                                            onClick={() => {
                                                openNotification(
                                                    notification._id,
                                                    notification.url
                                                );
                                            }}
                                            className={`notification_item ${
                                                notification.isOpened
                                                    ? "notification_read"
                                                    : "notification_not_read"
                                            }`}
                                            key={`Notification-${index}-${notification._id}`}
                                        >
                                            <div className="notification_icon">
                                                <Icon
                                                    name="user circle"
                                                    size="big"
                                                />
                                            </div>
                                            <div className="notification_content">
                                                <p>
                                                    <b>
                                                        {notification.leadName}
                                                    </b>{" "}
                                                    te ha enviado un mensaje
                                                </p>

                                                <p>
                                                    <Icon
                                                        name="whatsapp"
                                                        color="green"
                                                    />
                                                    {notification.message.substring(
                                                        0,
                                                        100
                                                    )}
                                                </p>
                                                <p>{notification.curp}</p>
                                            </div>
                                            <div className="notification_content_secondary">
                                                <div className="notification_date">
                                                    <p>
                                                        {moment(
                                                            notification.createdAt
                                                        ).format(
                                                            "DD-MM-YYYY / h:mm A"
                                                        )}
                                                    </p>
                                                </div>
                                                {notification.isOpened ? (
                                                    ""
                                                ) : (
                                                    <div className="notification_circle" />
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                                <div>
                                    {loading ? <LoaderNotification /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        authenticated: state.users.authenticated,
        profile: state.users.profile,
        type: state.users.user.type,
        notifications: state.notificationReducer.notifications,
        page: state.notificationReducer.page,
        totalPages: state.notificationReducer.totalPages,
        count: state.notificationReducer.count,
        loading: state.notificationReducer.loading,
    };
};

const mapActionsToProps = {
    getNotificationsById,
    setNotifications,
    setPage,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type NotificationProps = ConnectedProps<typeof connector>;

export default connector(Notification);
