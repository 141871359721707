import {
    SET_SERVICE,
    SET_SERVICES,
    SET_LOADING_SERVICE,
    SET_LOADING_SERVICES,
} from "../constants/services.constants";

const initialState = {
    loading: false,
    loadingService: false,
    service: {},
    services: [],
    errors: "",
};

export type State = Readonly<typeof initialState>;

const servicesReducer = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case SET_SERVICE:
            return {
                ...state,
                loading: false,
                loadingService: false,
                service: action.payload,
                errors: "",
            };
        case SET_SERVICES:
            return {
                ...state,
                loading: false,
                loadingService: false,
                services: action.payload,
                errors: "",
            };
        case SET_LOADING_SERVICES:
            return {
                ...state,
                loading: true,
                errors: "",
            };
        case SET_LOADING_SERVICE:
            return {
                ...state,
                loadingService: true,
                errors: "",
            };
        default:
            return state;
    }
};

export default servicesReducer;
