import Swal from "sweetalert2";
import { useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../store/store";
import { Link } from "react-router-dom";
import { generateWaLink } from "../users/helpers/leads.utils";
import {
    Button,
    Dimmer,
    Grid,
    Header,
    Icon,
    Label,
    Loader,
    Message,
    Segment,
    Table,
} from "semantic-ui-react";
import {
    getConversationsByAgentId,
    updateStatusNotifications,
} from "../../../actions/conversationsCRM.actions";

const PendingChat: React.FC<IProps> = ({
    getConversationsByAgentId,
    updateStatusNotifications,
    profile,
    conversations,
    isAgent,
    agent,
    loading,
}) => {
    const switchStatusNotifications = async (item: any) => {
        await updateStatusNotifications(item._id)
            .then((res) => {
                console.log(res);
                getConversationsByAgentId(isAgent ? profile.id : agent.id);
                Swal.fire({
                    position: "top-end",
                    icon: "info",
                    title: `Notificaciones ${
                        item.notifications === "OFF"
                            ? "Activadas"
                            : "Desactivadas"
                    }`,
                    showConfirmButton: false,
                    timer: 3000,
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire(
                    "Error",
                    "Hubo un error al cambiar el estatus",
                    "error"
                );
            });
    };

    useEffect(() => {
        if (profile.id) {
            getConversationsByAgentId(isAgent ? profile.id : agent.id);
        }
    }, [profile.id]);
    console.log(loading);

    return (
        <Grid centered>
            <Grid.Column width={12}>
                <Segment raised color="green">
                    <Header as="h2">
                        <Icon name="comment alternate outline" />
                        <Header.Content>
                            {isAgent ? (
                                <>Chats Pendientes</>
                            ) : (
                                <>
                                    Chats Pendientes del Agente: {agent.name}{" "}
                                    {agent.lastName}
                                </>
                            )}
                            <Header.Subheader>
                                Verifica el estado de tus conversaciones
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    {loading ? (
                        <Dimmer active inverted>
                            <Loader inverted>Cargando...</Loader>
                        </Dimmer>
                    ) : (
                        <>
                            {conversations.length === 0 ? (
                                <Message info>
                                    <Message.Header>
                                        {isAgent ? (
                                            <>No tienes chats pendientes.</>
                                        ) : (
                                            <>
                                                El agente no tiene chats
                                                pendientes.
                                            </>
                                        )}
                                    </Message.Header>
                                </Message>
                            ) : (
                                <Table compact celled selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                LEAD
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                TELEFONO - WhatsApp
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                ULTIMO MENSAJE
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                NOTIFICACIONES
                                            </Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>
                                    {conversations &&
                                        conversations.map(
                                            (
                                                conversation: any,
                                                index: number
                                            ) => (
                                                <Table.Body
                                                    key={`${conversation._id}-${index}`}
                                                >
                                                    <Table.Row>
                                                        <Table.Cell>
                                                            <Icon
                                                                name="user"
                                                                circular
                                                            />
                                                            {
                                                                conversation
                                                                    .lead.name
                                                            }{" "}
                                                            {
                                                                conversation
                                                                    .lead
                                                                    .lastName
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Icon
                                                                name="whatsapp"
                                                                color="green"
                                                            />
                                                            <a
                                                                href={generateWaLink(
                                                                    conversation._id.substring(
                                                                        3
                                                                    )
                                                                )}
                                                                target="_blank"
                                                            >
                                                                {conversation._id.substring(
                                                                    3
                                                                )}
                                                            </a>
                                                            {/* <Link
                                                        to={`/chatCRM/${conversation._id.substring(
                                                            3
                                                        )}`}
                                                    >
                                                        {conversation._id.substring(
                                                            3
                                                        )}
                                                    </Link> */}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {
                                                                conversation
                                                                    .last_message
                                                                    .body.text
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            collapsing
                                                            textAlign="center"
                                                        >
                                                            {conversation.notifications ===
                                                            "ON" ? (
                                                                <Label
                                                                    color="green"
                                                                    horizontal
                                                                >
                                                                    Activadas
                                                                </Label>
                                                            ) : (
                                                                <Label
                                                                    color="red"
                                                                    horizontal
                                                                >
                                                                    Desactivadas
                                                                </Label>
                                                            )}
                                                        </Table.Cell>
                                                        <Table.Cell
                                                            collapsing
                                                            textAlign="center"
                                                        >
                                                            <Button
                                                                basic
                                                                color={
                                                                    conversation.notifications ===
                                                                    "OFF"
                                                                        ? "green"
                                                                        : "red"
                                                                }
                                                                onClick={() =>
                                                                    switchStatusNotifications(
                                                                        conversation
                                                                    )
                                                                }
                                                            >
                                                                {conversation.notifications ===
                                                                "OFF"
                                                                    ? "ACTIVAR"
                                                                    : "DESACTIVAR"}
                                                            </Button>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            )
                                        )}
                                </Table>
                            )}
                        </>
                    )}
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        profile: state.users.profile,
        conversations: state.conversationsCrmReducer.conversations,
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        agent: state.adminAgents.agent,
        loading: state.conversationsCrmReducer.loading,
    };
};

const mapActionsToProps = {
    getConversationsByAgentId,
    updateStatusNotifications,
};

const connector = connect(mapStateToProps, mapActionsToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(PendingChat);
