const botTypes = [
    {
        key: "ALL",
        text: "TODOS",
        value: "ALL",
        icon: "list"
    },
    {
        key: "CREDITOS",
        text: "CRÉDITOS",
        value: "e9a9ebd6_6ae8_461f_8586_3f409d472e41",
        icon: "credit card"
    },
    {
        key: "DISTRIBUTION",
        text: "DISTRIBUCIONES",
        value: "cde31b48_79aa_40f4_a8c0_b6ab37cf68f6",
        icon: "building"
    },
    {
        key: 'COMMENTS',
        text: 'COMENTARIOS',
        value: 'isComments',
        icon: 'comment'
    },
    {
        key: 'MACRO',
        text: 'MACRO',
        value: 'MACRO',
        icon: 'whatsapp'
    },
    {
        key: 'JUBILADOS',
        text: 'JUBILADOS',
        value: 'isJubilados',
        icon: 'whatsapp'
    },
    {
        key: 'RENOVATION',
        text: 'RENOVACIÓN',
        value: 'isRenovations',
        icon: 'whatsapp'
    }
];

export default botTypes;