import React, { useState } from 'react';
import { Table, Pagination } from 'semantic-ui-react';
import { ICreditoActivo } from '../../../models/validation.model';
import '../../../css/validation.modal.css';

interface IState {
    page: number;
}

interface IProps {
    creditos: ICreditoActivo[];
}

const CreditosVigentes = (props: IProps) => {

    const { creditos } = props;

    const [state, setState] = useState<IState>({
        page: 1
    });

    return (
        <Table celled selectable className='table_credits'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='6'>
                        <Pagination
                            activePage={state.page}
                            totalPages={Math.trunc(creditos.length / 10) + 1}
                            onPageChange={(event, data) => {
                                setState({
                                    ...state,
                                    page: Number(data.activePage)
                                })
                            }}
                        />
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell className='table_header'>FINANCIERA</Table.HeaderCell>
                    <Table.HeaderCell className='table_header'>CAT</Table.HeaderCell>
                    <Table.HeaderCell className='table_header'>MONTO</Table.HeaderCell>
                    <Table.HeaderCell className='table_header'>DESCUENTO</Table.HeaderCell>
                    <Table.HeaderCell className='table_header'>PLAZOS</Table.HeaderCell>
                    <Table.HeaderCell className='table_header'>MONTO CAPITAL</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {creditos.map((credito: ICreditoActivo, index: number) => {
                    if(index > (state.page * 10)) {
                        return
                    }
                    if(state.page > 1 && index < ((state.page - 1) * 10)) {
                        return
                    }
                    return(
                        <Table.Row
                            key={`creditos-${index}`}
                        >
                            <Table.Cell>{credito.bank_name}</Table.Cell>
                            <Table.Cell>{credito.cat}</Table.Cell>
                            <Table.Cell>{credito.amount}</Table.Cell>
                            <Table.Cell>{credito.discount}</Table.Cell>
                            <Table.Cell>{credito.plazes}</Table.Cell>
                            <Table.Cell>{credito.capitalAmount}</Table.Cell>
                           
                        </Table.Row>
                    )
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colSpan='12'>
                        <Pagination
                            activePage={state.page}
                            totalPages={Math.trunc(creditos.length / 10) + 1}
                            onPageChange={(event, data) => {
                                setState({
                                    ...state,
                                    page: Number(data.activePage)
                                })
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default CreditosVigentes;