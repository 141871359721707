import { useState } from "react";
import { CreateTemplate } from "./CreateTemplate";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

export const ModalCreateTemplate = ({
    selectCategory,
    agentData,
    getByGroupId,
    groupTemplate,
}: any) => {
    const [modalCreateOpen, setModalCreateOpen] = useState(false);

    return (
        <Modal
            onOpen={() => setModalCreateOpen(true)}
            onClose={() => setModalCreateOpen(false)}
            open={modalCreateOpen}
            trigger={
                <Button floated="right" color="green">
                    <Icon name="plus" /> Añadir Template
                </Button>
            }
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
                justifyContent: "center",
            }}
            size="large"
        >
            <Modal.Content>
                <Header as="h1">
                    <Icon name="file text" />
                    <Header.Content>
                        Categoria: {selectCategory.category_name}
                        <Header.Subheader>
                            Edita y guarda tu template
                        </Header.Subheader>
                    </Header.Content>
                </Header>
                <CreateTemplate
                    setCategory={selectCategory}
                    agentData={agentData}
                    setModalCreateOpen={setModalCreateOpen}
                    getByGroupId={getByGroupId}
                    groupTemplate={groupTemplate}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setModalCreateOpen(false)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
