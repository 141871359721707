import React, { useState } from "react";
import {
	Accordion,
	Grid,
	Icon,
	Input,
	Dropdown,
	Button,
} from "semantic-ui-react";
import { status } from "../../../../constants/users/status.user";

interface IProps {
	isAgent: boolean;
	agentId: string;
	filters: any;
	getAll: (page: number, limit: number, filters: any, order: any) => void;
	setPage: (value: number) => void;
	setFilters: (filters: any) => void;
}

interface IState {
	filterName: string;
	filterLastName: string;
	filterPhone: string;
	filterDate: string;
	filterUpdateDate: string;
	filterStatus: string;
	filterNSS: string;
	filterCURP: string;
	filterCC?: boolean | null;
	filterHide: boolean;
}

const LeadsFilters: React.FC<IProps> = ({
	isAgent,
	agentId,
	filters,
	getAll,
	setPage,
	setFilters,
}) => {
	const [active, setActive] = useState(true);
	const [state, setState] = useState<IState>({
		filterName: filters.name ?? "",
		filterLastName: filters.lastName ?? "",
		filterPhone: filters.phone ?? "",
		filterDate: filters.createdAt ?? "",
		filterUpdateDate: filters.updatedAt ?? "",
		filterStatus: filters.agentStatus ?? "",
		filterNSS: filters.NSS ?? "",
		filterCURP: filters.CURP ?? "",
		filterCC: filters.capacidad_credito ?? null,
		filterHide: filters.hide_users === false ? false : true,
	});

	const loadEntities = (page: number, agent?: string) => {
		const {
			filterName,
			filterLastName,
			filterPhone,
			filterDate,
			filterUpdateDate,
			filterStatus,
			filterNSS,
			filterCURP,
			filterCC,
			filterHide,
		} = state;

		let isFilterName = filterName.trim() !== "";
		let isFilterLastName = filterLastName.trim() !== "";
		let isFilterPhone = filterPhone.trim() !== "";
		let isFilterDate = filterDate.trim() !== "";
		let isFilterUpdateDate = filterUpdateDate.trim() !== "";
		let isFilterStatus = filterStatus.trim() !== "";
		let isFilterNSS = filterNSS.trim() !== "";
		let isFilterCURP = filterCURP.trim() !== "";
		let isFilterCC = filterCC !== null;
		let isFilterHide = filterHide !== null;

		let filters: any = {
			name: filterName.trim(),
			lastName: filterLastName.trim(),
			phone: filterPhone.trim(),
			createdAt: filterDate.trim(),
			updatedAt: filterUpdateDate.trim(),
			agentStatus: filterStatus.trim(),
			NSS: filterNSS.trim(),
			CURP: filterCURP.trim(),
			capacidad_credito: filterCC,
			hide_users: filterHide,
		};

		if (!isFilterName) delete filters.name;
		if (!isFilterLastName) delete filters.lastName;
		if (!isFilterPhone) delete filters.phone;
		if (!isFilterDate) delete filters.createdAt;
		if (!isFilterUpdateDate) delete filters.updatedAt;
		if (!isFilterStatus) delete filters.agentStatus;
		if (!isFilterNSS) delete filters.NSS;
		if (!isFilterCURP) delete filters.CURP;
		if (!isFilterCC) delete filters.capacidad_credito;
		if (!isFilterHide) delete filters.hide_users;
		if (filterHide === false) delete filters.hide_users;

		if (
			isFilterName ||
			isFilterLastName ||
			isFilterPhone ||
			isFilterDate ||
			isFilterUpdateDate ||
			isFilterStatus ||
			isFilterNSS ||
			isFilterCURP ||
			isFilterCC ||
			isFilterHide
		) {
			getAll(
				page,
				10,
				isAgent || agent
					? {
							...filters,
							agent: agent,
					  }
					: {
							...filters,
					  },
				{
					createdAt: "desc",
					_id: "desc",
				}
			);
		} else {
			getAll(
				page,
				10,
				isAgent || agent
					? {
							...filters,
							agent: agent,
					  }
					: {
							...filters,
					  },
				{
					createdAt: "desc",
					_id: "desc",
				}
			);
		}
		setFilters(filters);
	};

	const cleanFilters = () => {
		setPage(1);

		setState({
			...state,
			filterName: "",
			filterLastName: "",
			filterPhone: "",
			filterDate: "",
			filterUpdateDate: "",
			filterStatus: "",
			filterNSS: "",
			filterCURP: "",
			filterCC: null,
			filterHide: true,
		});

		getAll(
			1,
			10,
			isAgent
				? {
						agent: agentId,
						hide_users: true,
				  }
				: {},
			{
				createdAt: "desc",
				_id: "desc",
			}
		);

		setFilters({
			hide_users: true,
		});
	};

	const ccOptions = [
		{ key: "siCC", icon: "plus", text: "SI", value: true },
		{ key: "noCC", icon: "delete", text: "NO", value: false },
	];

	const hideOptions = [
		{
			key: "siHide",
			icon: "hide",
			text: "OCULTAR NO APLICA/NO INTERESA",
			value: true,
		},
		{
			key: "noHide",
			icon: "eye",
			text: "MOSTRAR NO APLICA/NO INTERESA",
			value: false,
		},
	];

	return (
		<Accordion fluid styled>
			<Accordion.Title
				active={active}
				onClick={() => {
					setActive(!active);
				}}
			>
				<Icon name="dropdown" />
				Búsqueda
			</Accordion.Title>
			<Accordion.Content active={active}>
				<Grid>
					<Grid.Row columns={4}>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Nombre"
								value={state.filterName}
								onChange={(e) => {
									setState({
										...state,
										filterName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Apellido"
								value={state.filterLastName}
								onChange={(e) => {
									setState({
										...state,
										filterLastName: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="Teléfono"
								value={state.filterPhone}
								onChange={(e) => {
									setState({
										...state,
										filterPhone: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								type="date"
								className="user-filter-field"
								label="Fecha"
								value={state.filterDate}
								onChange={(e) => {
									setState({
										...state,
										filterDate: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="CURP"
								value={state.filterCURP}
								onChange={(e) => {
									setState({
										...state,
										filterCURP: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								className="user-filter-field"
								label="NSS"
								value={state.filterNSS}
								onChange={(e) => {
									setState({
										...state,
										filterNSS: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Dropdown
								placeholder="Estatus"
								className="user-filter-field"
								selection
								search
								options={status}
								fluid
								value={state.filterStatus}
								onChange={(e, { value }) => {
									setState({
										...state,
										filterStatus: value as string,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Input
								type="date"
								className="user-filter-field"
								label="Fecha Actualización"
								value={state.filterUpdateDate}
								onChange={(e) => {
									setState({
										...state,
										filterUpdateDate: e.currentTarget.value,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Dropdown
								placeholder="Capacidad Crédito"
								className="user-filter-field"
								selection
								search
								options={ccOptions}
								fluid
								value={state.filterCC!}
								onChange={(e, { value }) => {
									setState({
										...state,
										filterCC: value as boolean,
									});
								}}
							/>
						</Grid.Column>
						<Grid.Column computer={4} mobile={16} tablet={8}>
							<Dropdown
								placeholder="Ocultar NO APLICA Y NO INTERESA"
								className="user-filter-field"
								selection
								search
								options={hideOptions}
								fluid
								value={state.filterHide!}
								onChange={(e, { value }) => {
									setState({
										...state,
										filterHide: value as boolean,
									});
								}}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<div style={{ float: "right" }}>
							<Button
								style={{ width: "200px" }}
								color="grey"
								onClick={cleanFilters}
							>
								Limpiar
							</Button>
							<Button
								style={{ width: "200px" }}
								color="green"
								onClick={() => loadEntities(1, agentId)}
							>
								Buscar
							</Button>
						</div>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
};

export default LeadsFilters;
