import axios from "axios";
import { paymentsApiUrl } from "../../../../constants/enviroment";

export const createDiscounts = async (discountData: any) => {
    console.log(discountData);
    try {
        const response = await axios.post(
            `${paymentsApiUrl}/api/discounts/`,
            discountData
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
