import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import {
	setErrors,
	setAllLoading,
	setBiometricosData,
	setBiometricoData,
	setCounter,
} from "./utils.actions";

export const getAll = (body: any) => async (dispatch: any) => {
	console.log("GET ALL EXPEDIENTES");
	let loaded = false;
	await dispatch(setAllLoading());

	await axios
		.post(`${apiUrl}/biometricos/getAll`, body)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(
					"No se han podido cargar los expedientes",
					res.data.message
				);
				dispatch(setErrors(res.data.message));
				return loaded;
			}

			let data = res.data.paginationData;

			console.log("DATA", data);

			dispatch(setCounter(data.totalDocs));
			dispatch(setBiometricosData(data.docs));
			loaded = true;
		})
		.catch((err) => {
			console.error(
				"Error al cargar los expedientes",
				err.response.data.message
			);
			setErrors(err.response.data.message);
		});

	return loaded;
};

export const getById = (id: string) => async (dispatch: any) => {
	dispatch(setAllLoading());
	await axios
		.get(`${apiUrl}/biometricos/${id}`)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			console.log("BIO DATA", res.data);
			dispatch(setBiometricoData(res.data.biometrico));
		})
		.catch((err) => {
			console.error(err);
			dispatch(setErrors(err.response.data.message));
		});
};

export const getBySolicitudId = (id: string) => async (dispatch: any) => {
	dispatch(setAllLoading());
	await axios
		.get(`${apiUrl}/biometricos/solicitud/${id}`)
		.then((res) => {
			if (res.status !== 200) {
				console.warn(res.data.message);
				dispatch(setErrors(res.data.message));
			}
			console.log("BIO DATA", res.data);
			dispatch(setBiometricosData(res.data.biometricos));
		})
		.catch((err) => {
			console.error(err);
			dispatch(setErrors(err.response.data.message));
		});
};
