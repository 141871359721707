import { RootState } from "../../../store/store";
import { ConnectedProps, connect } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Grid, Button, Segment, Header, Icon } from "semantic-ui-react";
import { agent_data } from "./data";
import html2canvas from "html2canvas";
import CredencialAgente from "./components/credencialAgente";
import ServicioAgente from "./components/servicioAgente";
import LlamadaAgente from "./components/llamadaAgente";
import SeguimientoAgente from "./components/seguimientoAgente";
import Seguimiento2Agente from "./components/seguimiento2Agente";
import "./credentials.css";

const Credentials: React.FC<IProps> = ({ isAgent, profile, agent }) => {
    const canvasRef = useRef(null);
    const [selectedCanvas, setSelectedCanvas] = useState("");

    const elementToCanvas = async (isCopy: boolean) => {
        let id = "";
        let fileName = "";

        switch (selectedCanvas) {
            case "credencial":
                id = "credencial-agente";
                fileName = "CredencialAgente.png";
                break;
            case "servicio":
                id = "servicio-agente";
                fileName = "ServicioAgente.png";
                break;
            case "llamada":
                id = "llamada-agente";
                fileName = "LLamadaAgente.png";
                break;
            case "seguimiento":
                id = "seguimiento-agente";
                fileName = "SeguimientoAgente.png";
                break;
            case "seguimiento2":
                id = "seguimiento2-agente";
                fileName = "Seguimiento-2-Agente.png";
                break;
            default:
                return;
        }

        let element: any = document.getElementById(id);

        console.log("ELEMENT", element, id, fileName);

        const canvas = await html2canvas(element, {
            foreignObjectRendering: true,
            allowTaint: true,
            useCORS: true,
            logging: true,
            width: 900,
            height: 900,
            scrollX: 0,
            scrollY: 0,
            scale: 1,
        });

        console.log("CANVAS", canvas);

        canvas.style.width = "900px";
        canvas.style.height = "900px";

        let ctx = canvas.getContext("2d");

        let image = canvas.toDataURL("png", 1);

        if (isCopy) {
            await navigator.clipboard.write([
                new ClipboardItem({
                    "image/png": await fetch(image).then((r) => r.blob()),
                }),
            ]);
            return;
        }

        console.log("image", image);
        let link = document.createElement("a");
        link.download = fileName;
        link.href = image;
        link.click();
    };

    const buildCredential = () => {
        switch (selectedCanvas) {
            case "credencial":
                return (
                    <CredencialAgente
                        isAgent={isAgent}
                        profile={profile}
                        agent={agent}
                    />
                );
            case "servicio":
                return (
                    <ServicioAgente
                        isAgent={isAgent}
                        profile={profile}
                        agent={agent}
                    />
                );
            case "llamada":
                return (
                    <LlamadaAgente
                        isAgent={isAgent}
                        profile={profile}
                        agent={agent}
                    />
                );
            case "seguimiento":
                return (
                    <SeguimientoAgente
                        isAgent={isAgent}
                        profile={profile}
                        agent={agent}
                    />
                );
            case "seguimiento2":
                return (
                    <Seguimiento2Agente
                        isAgent={isAgent}
                        profile={profile}
                        agent={agent}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            <Segment color="orange">
                <Header as="h2" icon textAlign="center">
                    <Header.Content>
                        CREDENCIALES - {selectedCanvas.toLocaleUpperCase()}
                    </Header.Content>
                </Header>
                <Grid columns={2} padded container>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Segment color="blue" style={{ height: "auto" }}>
                                <h4>Tipo Credencial</h4>
                                <div className="container_credentials">
                                    <div
                                        className="item_type_canvas"
                                        onClick={() =>
                                            setSelectedCanvas("credencial")
                                        }
                                    >
                                        <Segment className="item_type">
                                            <Header>
                                                <Header.Content>
                                                    CREDENCIAL
                                                </Header.Content>
                                                <Header.Subheader>
                                                    Canvas Credencial
                                                </Header.Subheader>
                                            </Header>
                                        </Segment>
                                    </div>
                                    <div
                                        className="item_type_canvas"
                                        onClick={() =>
                                            setSelectedCanvas("servicio")
                                        }
                                    >
                                        <Segment className="item_type">
                                            <Header>
                                                <Header.Content>
                                                    SERVICIO
                                                </Header.Content>
                                                <Header.Subheader>
                                                    Canvas Servicio
                                                </Header.Subheader>
                                            </Header>
                                        </Segment>
                                    </div>
                                    <div
                                        className="item_type_canvas"
                                        onClick={() =>
                                            setSelectedCanvas("llamada")
                                        }
                                    >
                                        <Segment className="item_type">
                                            <Header>
                                                <Header.Content>
                                                    LLAMADA
                                                </Header.Content>
                                                <Header.Subheader>
                                                    Canvas Llamada
                                                </Header.Subheader>
                                            </Header>
                                        </Segment>
                                    </div>
                                    <div
                                        className="item_type_canvas"
                                        onClick={() =>
                                            setSelectedCanvas("seguimiento")
                                        }
                                    >
                                        <Segment className="item_type">
                                            <Header>
                                                <Header.Content>
                                                    SEGUIMIENTO
                                                </Header.Content>
                                                <Header.Subheader>
                                                    Canvas Seguimiento
                                                </Header.Subheader>
                                            </Header>
                                        </Segment>
                                    </div>
                                    <div
                                        className="item_type_canvas"
                                        onClick={() =>
                                            setSelectedCanvas("seguimiento2")
                                        }
                                    >
                                        <Segment className="item_type">
                                            <Header>
                                                <Header.Content>
                                                    SEGUIMIENTO 2
                                                </Header.Content>
                                                <Header.Subheader>
                                                    Canvas Seguimiento 2
                                                </Header.Subheader>
                                            </Header>
                                        </Segment>
                                    </div>
                                </div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <div className="credentials_canvas_container">
                                <Segment color="black">
                                    <Grid.Row columns={1}>
                                        <Grid.Column
                                            verticalAlign="middle"
                                            textAlign="center"
                                        >
                                            <Button
                                                color="blue"
                                                ref={canvasRef}
                                                onClick={() =>
                                                    elementToCanvas(true)
                                                }
                                                style={{ marginBottom: "10px" }}
                                            >
                                                <Icon name="clipboard" />
                                                Copiar al portapapeles
                                            </Button>
                                            <Button
                                                color="green"
                                                ref={canvasRef}
                                                style={{ marginBottom: "10px" }}
                                                onClick={() =>
                                                    elementToCanvas(false)
                                                }
                                            >
                                                <Icon name="download" />
                                                Descargar Archivo
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {buildCredential()}
                                </Segment>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        profile: state.users.profile,
        lead: state.adminUsers.user,
        agent: state.adminAgents.agent,
        customer: state.adminUsers.user,
        loading: state.adminUsers.loading,
    };
};

const connector = connect(mapStateToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(Credentials);
