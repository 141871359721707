import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Grid, Modal, Table, Pagination, Loader } from 'semantic-ui-react';
import { IUserModel } from '../../../../models/User.model';
import { RootState } from '../../../../store/store';
import { setUsersData } from '../../../../actions/user-admin.actions';

interface IState {
	page: number;
}

const UserList: React.FC<IUserListProps> = ({
	leads,
	loading,
	errors,
	find,
	jsonToExcel,
	getAgentName,
	setUsersData
}) => {
	const [open, setOpen] = useState(false);
	const [state, setState] = useState<IState>({
		page: 1
	});

	useEffect(() => {
		if(open) {
			setState({
				...state,
				page: 1
			});
			find(true)
		}
	}, [open]);

	useEffect(()=> {
		setState({
			...state,
			page: 1
		});
	}, []);

	useEffect(()=> {
	}, [errors]);
	
	const parseDate = (date: string) => {
		let dateParsed: Date = new Date(date);
		return dateParsed.toLocaleDateString()
	}

	const onClose = () => {
		setUsersData([])
		setOpen(false)
	}

	return (
		<Modal
			onClose={onClose}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={
				<Button
					color="green"
					onClick={() => { setOpen(true) }}
				>
					Ver Usuarios
				</Button>
			}
			size="fullscreen"
			dimmer={'blurring'}
			style={{
				left: 'auto',
				right: 'auto',
				top: 'auto',
				height: 'fit-content'
			}}
		>
			<Modal.Header>Usuarios</Modal.Header>
			<Modal.Content style={{ minHeight: '300px' }} >
			
			{loading ? (
				errors ? <h1>Error al cargar leads, seleccione un rango de fechas menor</h1> 
				: 
				<Loader 
					active={loading} 
					color="blue" 
					size='huge' 
				>
					<h2 style={{ 
						color: 'blue', 
						margin: '150px',
					}}>
						Cargando...
					</h2>
				</Loader>
			) 
			:
			<Table selectable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							<Pagination
								activePage={state.page}
								totalPages={Math.trunc(leads.length / 10) + 1}
								onPageChange={(event, data) => {
									setState({
										...state,
										page: Number(data.activePage)
									})
								}}
							/>
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell >ID</Table.HeaderCell>
						<Table.HeaderCell >NOMBRE</Table.HeaderCell>
						<Table.HeaderCell >APELLIDO</Table.HeaderCell>
						<Table.HeaderCell >TELÉFONO</Table.HeaderCell>
						<Table.HeaderCell >CURP</Table.HeaderCell>
						<Table.HeaderCell >NSS/MATRÍCULA</Table.HeaderCell>
						<Table.HeaderCell >DELEGACIÓN</Table.HeaderCell>
						<Table.HeaderCell >C.C.</Table.HeaderCell>
						<Table.HeaderCell >Fecha Registro</Table.HeaderCell>
						<Table.HeaderCell >AGENTE</Table.HeaderCell>
						<Table.HeaderCell >ESTATUS</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{leads.map((user: IUserModel, index: number) => {
						if(index > (state.page * 10)) {
							return
						}
						if(state.page > 1 && index < ((state.page - 1) * 10)) {
							return
						}
						return(
							<Table.Row
								key={`report-users-${index}`}
							>
								<Table.Cell>{user.wa_id ?? user.Fid}</Table.Cell>
								<Table.Cell>{user.name}</Table.Cell>
								<Table.Cell>{user.lastName}</Table.Cell>
								<Table.Cell>{user.phone}</Table.Cell>
								<Table.Cell>{user.CURP}</Table.Cell>
								<Table.Cell>{user.isJubilado ? user.matricula : user.NSS}</Table.Cell>
								<Table.Cell>{user.delegacion}</Table.Cell>
								<Table.Cell>{user.capacidad_credito}</Table.Cell>
								<Table.Cell>{parseDate(user.createdAt!)}</Table.Cell>
								<Table.Cell>{getAgentName(user.agent)}</Table.Cell>
								<Table.Cell>{user.agentStatus}</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
				<Table.Footer>
					<Table.Row>
						<Table.Cell colSpan='12'>
							<Pagination
								activePage={state.page}
								totalPages={Math.trunc(leads.length / 10) + 1}
								onPageChange={(event, data) => {
									setState({
										...state,
										page: Number(data.activePage)
									})
								}}
							/>
						</Table.Cell>
					</Table.Row>
				</Table.Footer>
			</Table>}
			</Modal.Content>
			<Modal.Actions >
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column textAlign='right'>
							<Button positive onClick={() => jsonToExcel(true)} >Descargar</Button>
						</Grid.Column>
						<Grid.Column textAlign='left'>
							<Button color='black' onClick={() => { setOpen(false); }}>
								Cerrar
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Actions>
		</Modal>
	)
}

export interface IProps {
	users: IUserModel[]
	jsonToExcel: Function,
	getAgentName: Function,
	find: Function
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState, props: IProps) => {
	return {
		leads: state.reports.leads,
		loading: state.reports.loading,
		errors: state.reports.errors,
		jsonToExcel: props.jsonToExcel,
		getAgentName: props.getAgentName,
		find: props.find
	}
}

const mapActionsToProps = {
	setUsersData
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IUserListProps = ConnectedProps<typeof connector>;

export default connector(UserList);