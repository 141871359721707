import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../store/store";
import {
    Button,
    Header,
    Icon,
    Modal,
    Image,
    Divider,
    Input,
    Grid,
    Card,
} from "semantic-ui-react";
import { useEffect, useState } from "react";
import { IAgentModel } from "../../../models/Agent.model";
import {
    getFranchiseById,
    updateFranchise,
} from "../../../actions/franchise.actions";

const Franchise: React.FC<IProps> = ({
    getFranchiseById,
    updateFranchise,
    profile,
    agent,
    franchise,
}: any) => {
    const [open, setOpen] = useState(false);
    console.log(profile);
    console.log(agent);
    console.log("FRANCHISE", franchise);

    const [first, setfirst] = useState();

    useEffect(() => {
        if (open) {
            getFranchiseById(agent.id);
        }
    }, [open]);

    const handleUpdateFranchise = () => {
        try {
            updateFranchise();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            open={open}
            trigger={
                <Button color="orange">
                    <Icon name="building" />
                    Datos Franquicia
                </Button>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
                justifyContent: "center",
            }}
            size="large"
            dimmer={"blurring"}
        >
            <Header as="h2">
                <Image
                    circular
                    src={agent.profile_image}
                    style={{ width: "100px", height: "100px" }}
                />
                <Header.Content>
                    {agent.name}
                    {""}
                    {agent.lastName}
                    <Header.Subheader>
                        Administra los datos de la Franquicia
                    </Header.Subheader>
                </Header.Content>
            </Header>
            <Modal.Content scrolling>
                <Grid columns={2}>
                    <Grid.Column>
                        <Input
                            label="Nombre de la Franquicia"
                            value={franchise?.franchiseName}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input label="Eslogan" value={franchise?.slogan} />
                    </Grid.Column>
                </Grid>
                <br />
                <br />
                <br />
                <Divider horizontal>
                    <Header as="h4">
                        <Icon name="fork" />
                        Rama
                    </Header>
                </Divider>
                <Grid columns="equal">
                    <Grid.Column>
                        <Input
                            label="Nombre Completo"
                            placeholder="ej: (Aguascalientes)"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Input label="Abreviacion" placeholder="ej: (AGS)" />
                    </Grid.Column>
                </Grid>
                <br />
                <br />
                <br />
                <Divider horizontal>
                    <Header as="h4">
                        <Icon name="address card outline" />
                        Datos
                    </Header>
                </Divider>
                <Grid padded>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Input
                                label="Dirección"
                                value={franchise?.info?.address}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                label="Correo"
                                value={franchise?.info?.email}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                label="Telefono"
                                value={franchise?.info?.phone}
                            />
                        </Grid.Column>
                        <br />
                        <br />
                        <br />
                        <Grid.Column>
                            <Input
                                label="Cronograma"
                                value={franchise?.info?.schedule}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                label="Calendario"
                                value={franchise?.info?.timetable}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <br />
                <br />
                <Divider horizontal>
                    <Header as="h4">
                        <Icon name="globe" />
                        Redes Sociales
                    </Header>
                </Divider>
                <Grid padded>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Icon name="facebook" color="blue" size="big" />
                            <Input
                                label="https://"
                                value={franchise?.socials?.facebook}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name="instagram" color="pink" size="big" />
                            <Input
                                label="https://"
                                value={franchise?.socials?.instagram}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name="linkedin" color="blue" size="big" />
                            <Input
                                label="https://"
                                value={franchise?.socials?.linkedIn}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <br />
                <br />
                <Divider horizontal>
                    <Header as="h4">
                        <Icon name="users" />
                        Equipo
                    </Header>
                </Divider>
                <Grid centered>
                    <Grid columns={4}>
                        {franchise &&
                            franchise?.team?.map((user: any) => {
                                return (
                                    <Grid.Column>
                                        <Card raised>
                                            <Card.Content>
                                                <Image
                                                    floated="left"
                                                    size="tiny"
                                                    src={user.icon}
                                                />
                                                <Card.Header>
                                                    {user.name}
                                                </Card.Header>
                                                <Card.Meta>
                                                    Miembro de la Franquicia
                                                </Card.Meta>
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                );
                            })}
                    </Grid>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    <Icon name="remove" /> Cerrar
                </Button>
                <Button color="green" onClick={() => setOpen(false)}>
                    <Icon name="checkmark" /> Registrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

interface IPropsComponent {
    agent: IAgentModel;
}

const mapStateToProps = (state: RootState, props: IPropsComponent) => {
    return {
        profile: state.users.profile,
        isAgent:
            state.users.user.type === "IS_AGENT" ||
            state.users.user.type === "IS_TOP",
        agent: props.agent,
        franchise: state.franchiseReducer.franchise,
    };
};

const mapActionsToProps = {
    getFranchiseById,
    updateFranchise,
};

const connector = connect(mapStateToProps, mapActionsToProps);
type IProps = ConnectedProps<typeof connector>;
export default connector(Franchise);
