import axios from "axios";
import {
    SET_DISCOUNT,
    SET_DISCOUNTS,
    SET_LOADING_DISCOUNT,
    SET_LOADING_DISCOUNTS,
} from "../constants/discounts.constants";
import { paymentsApiUrl } from "../constants/enviroment";

export const getAllDiscounts = (agentId: any) => async (dispatch: any) => {
    dispatch(setLoading());
    try {
        const response = await axios.get(
            `${paymentsApiUrl}/api/discounts/agent/${agentId}`
        );
        const discounts = response.data.discounts;
        dispatch(setDiscounts(discounts));
        dispatch(setDiscountsFiltered(discounts));
    } catch (error) {
        console.log("Error al cargar los descuentos");
    }
};

export const setDiscounts = (discounts: any) => async (dispatch: any) => {
    await dispatch({
        type: SET_DISCOUNTS,
        payload: discounts,
    });
};

export const setDiscountsFiltered =
    (discountsFiltered: any) => async (dispatch: any) => {
        await dispatch({
            type: SET_DISCOUNTS,
            payload: discountsFiltered,
        });
    };

export const setLoading = () => (dispatch: any) => {
    dispatch({
        type: SET_LOADING_DISCOUNTS,
    });
};
