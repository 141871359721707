import React, { useEffect, useState } from "react";
import { Grid, Input, Image, Icon, Button } from "semantic-ui-react";
import { IoLogoWhatsapp } from "react-icons/io";
import "../credentials.css";

const Seguimiento2Agente = ({ isAgent, profile, agent }: any) => {
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);
    const [imageData, setImageData] = useState("");

    const handleProfileImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setSelectedProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const imageUrl = "https://i.imgur.com/sNFK20r.png";

        const imageToBase64 = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    setImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir la imagen:", error);
            }
        };
        imageToBase64();
    }, []);

    return (
        <>
            <div>
                <input
                    style={{ display: "none" }}
                    id="btn-upload"
                    type="file"
                    onChange={handleProfileImageChange}
                    multiple
                    name="btn-foto"
                    title="Subir fotos"
                />
                <h3>Carga tu foto en la credencial</h3>
                <Button
                    onClick={() => {
                        document.getElementById("btn-upload")!.click();
                    }}
                    color="green"
                >
                    <Icon name="upload" />
                    Cargar Foto
                </Button>
            </div>
            <div className="cotizador-component">
                <Grid
                    id="seguimiento2-agente"
                    className="cotizador-container padding-0"
                >
                    <div className="header_seguimiento2">
                        <div className="seguimiento_logo2">
                            {imageData && (
                                <img
                                    src={imageData}
                                    alt="Logo"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                        </div>

                        <div className="seguimiento_text2">
                            <p>
                                Recuerde que por ser cliente <b>Multiva</b>,
                                usted puede obtener un beneficio
                                <br />
                                adicional al realizar{" "}
                                <b>
                                    renovación de su crédito actual y/o compra
                                    de deuda
                                    <br />
                                    de créditos que tenga con otras financieras.
                                </b>
                                <br />
                                <ul>
                                    <li>✅ La mejor tasa del mercado</li>
                                    <li>✅ Sin salir de casa</li>
                                    <li>
                                        ✅ Con la confianza de ya haber
                                        realizado trámites con nosotros
                                    </li>
                                </ul>
                                <b>
                                    Si desea le actualice las opciones que
                                    podemos ofrecerle, sólo
                                    <br />
                                    mande mensaje.
                                </b>
                                <br />
                                <b style={{ marginLeft: "220px" }}>
                                    ¡Gracias por su preferencia!
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="seguimiento_contact_container2">
                        <div className="seguimiento_contact_email2">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "21px",
                                    }}
                                >
                                    CORREO:
                                </b>
                                <br />
                                {isAgent ? profile.email : agent.email}
                            </p>
                        </div>
                        <div className="seguimiento_contact_phone2">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "21px",
                                    }}
                                >
                                    TELÉFONO:
                                </b>
                                <br />
                                <IoLogoWhatsapp className="seguimiento_contact_icon2" />{" "}
                                {isAgent ? profile.phone : agent.phone}
                            </p>
                        </div>
                    </div>
                    <div className="seguimiento_image_profile">
                        {selectedProfileImage ? (
                            <Image
                                src={selectedProfileImage}
                                alt="Selected"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                }}
                            />
                        ) : (
                            <div className="seguimiento_icon_select_image2">
                                <Icon name="user circle" color="orange" />
                            </div>
                        )}
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default Seguimiento2Agente;
