import { idsCreditosOrigins, idsDistOrigins } from '../origins/origins.constants';

const PRODUCTS = [
    {
        id: 1,
        value: idsCreditosOrigins,
        text: 'CRÉDITOS',
        icon: 'credit card',
        description: 'Leads campañas de créditos'
    },
    {
        id: 2,
        value: idsDistOrigins,
        text: 'DISTRIBUCIONES',
        icon: 'users',
        description: 'Leads campañas de distribuciones'
    }
]

export default PRODUCTS;