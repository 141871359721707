import { initialFormField, initialDocument } from "./utils.init";

export const initialState = {
    form: {
        folio: {
            ...initialFormField,
            rule: new RegExp(/^.*$/).source,
        },
        monto_credito: {
            ...initialFormField,
            value: 0.0,
            type: 'number',
            rule: new RegExp(/^(?:\d+(?:\.\d{1,2})?)?$/).source,
        },
        numero_pagos: {
            ...initialFormField,
            value: 0.0,
            type: 'number',
            rule: new RegExp(/^(?:\d+(?:\.\d{1,2})?)?$/).source,
        },
        descuento_mensual: {
            ...initialFormField,
            value: 0.0,
            type: 'number',
            rule: new RegExp(/^(?:\d+(?:\.\d{1,2})?)?$/).source,
        },
        nombre_del_banco: initialFormField,
        clabe_interbancaria: {
            ...initialFormField,
            error_message: 'No es una clabe valida',
            rule: new RegExp(/^(?:\d{18})?$/).source,
        },
        observaciones: initialFormField,
        status: {
            ...initialFormField,
            type: 'dropdown',
            value: 'NUEVO',
            options: [
                'NUEVO',
                'EN REVISION',
                'OBSERVACIONES',
                'COMPLETO'
            ]
        },
    },

    documents: {
        solicitud_foliada: {
            ...initialDocument,
            extensions: [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ]
        },
        solicitud_firmada: {
            ...initialDocument,
            extensions: ['application/pdf']
        },
        selfie: {
            ...initialDocument,
            extensions: ['image/*']
        }
    }
}