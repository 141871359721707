import { Grid } from "semantic-ui-react";
import { generateWaLink } from "../helpers/leads.utils";

interface IProps {
	lead: any;
}

const keys = [
	"CURP",
	"NSS",
	"matricula",
	"phone",
	"email",
	"salario",
	"capacidad_credito",
];

export const LeadCard: React.FC<IProps> = ({ lead }) => {
	return (
		<div className="lead-card">
			<Grid>
				<Grid.Row>
					<Grid.Column>
						<h6>
							{lead.name} {lead.lastName}
						</h6>
					</Grid.Column>
				</Grid.Row>
				{keys.map((key: any, index) => {
					return (
						<Grid.Row columns={2}>
							<Grid.Column textAlign="right">
								<h6>
									{key.replaceAll("_", " ").toUpperCase()}:
								</h6>
							</Grid.Column>
							<Grid.Column textAlign="left">
								{key === "phone" ? (
									<a
										href={generateWaLink(lead[key])}
										target="_blank"
									>
										{lead[key]}
									</a>
								) : (
									<h6>{lead[key]}</h6>
								)}
							</Grid.Column>
						</Grid.Row>
					);
				})}
				<Grid.Row>
					<Grid.Column></Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};
