import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../store/store";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../actions/users.actions";
import { setOpenMenu } from "../../../actions/profile.actions";
import { namespace_1, namespace_2 } from "../../../constants/enviroment";

import "react-pro-sidebar/dist/css/styles.css";
import "../../../css/side.menu.css";

interface IMainMenuState {
	opened: boolean;
	sideMenuClassName: string;
}

const MainMenu: React.FC<IBodyProps> = ({
	authenticated,
	isAdmin,
	isCoordinator,
	isGestionador,
	isDistribuidor,
	isMentor,
	isAgent,
	user,
	isOpen,
	logoutUser,
	setOpenMenu,
}) => {
	const getMenuClassName = () => {
		if (!authenticated) {
			return "no-side-menu";
		}
		if (!state.opened) {
			return "side-menu";
		}
		return "side_menu_mobile";
	};

	useEffect(() => {
		getMenuClassName();
	}, [isOpen]);

	const navigate = useNavigate();

	const [state, setState] = useState<IMainMenuState>({
		opened: false,
		sideMenuClassName: "side-menu",
	});

	const logOut = async () => {
		await logoutUser();
	};

	const getProducto = () => {
		return user.bot_id === namespace_1 ? "creditos" : "distribuciones";
	};

	const getMisAgentesLink = () => {
		const links: any = {
			IS_COORDINATOR: `${getProducto()}/coordinador/${user.id}`,
			IS_MENTOR: `${getProducto()}/mentor/${user.id}`,
			IS_DISTRIBUIDOR: `${getProducto()}/distribuidor/${user.id}`,
			IS_FRANQUICIADO: `${getProducto()}/franquiciado/${user.id}`,
		};

		return links[user.type ?? "IS_COORDINDATOR"];
	};

	return (
		<ProSidebar
			className={
				authenticated
					? isOpen
						? "side-menu-mobile"
						: "side-menu"
					: "no-side-menu"
			}
			collapsed={!state.opened && !isOpen}
			onMouseEnter={() => {
				setState({
					...state,
					opened: true,
				});
			}}
			onMouseLeave={() => {
				setOpenMenu(false);
				setState({
					...state,
					opened: false,
				});
			}}
		>
			<Menu iconShape="round">
				<MenuItem
					icon={
						<Icon
							name={state.opened ? "arrow right" : "arrow down"}
						/>
					}
					onClick={() => {
						setState({
							...state,
							opened: !state.opened,
						});
					}}
				>
					Menú
				</MenuItem>
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="home" />}
						onClick={() => navigate("/")}
					>
						Inicio
					</MenuItem>
				) : null}
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="dashboard" />}
						onClick={() => navigate("/dashboard")}
					>
						Dashboard
					</MenuItem>
				) : null}
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="dashboard" />}
						onClick={() => navigate("/reportes")}
					>
						Reportes
					</MenuItem>
				) : null}
				{isAgent ? (
					<MenuItem
						icon={<Icon name="home" />}
						onClick={() => navigate("/agent/dashboard")}
					>
						Dashboard
					</MenuItem>
				) : null}
				{isAdmin ? (
					<SubMenu title={"Team"} icon={<Icon name="money" />}>
						<MenuItem
							icon={<Icon name="users" />}
							onClick={() => navigate("/team/distribuidores")}
						>
							Distribuidores
						</MenuItem>
						<MenuItem
							icon={<Icon name="users" />}
							onClick={() => navigate("/team/franquiciados")}
						>
							Franquiciados
						</MenuItem>
						<MenuItem
							icon={<Icon name="users" />}
							onClick={() => navigate("/team/gestionadores")}
						>
							Mesa de control
						</MenuItem>
						<MenuItem
							icon={<Icon name="users" />}
							onClick={() => navigate("/team/contadores")}
						>
							Contadores
						</MenuItem>
						<MenuItem
							icon={<Icon name="users" />}
							onClick={() => navigate("/team/disenadores")}
						>
							Diseñadores
						</MenuItem>
					</SubMenu>
				) : null}
				{isAdmin ? (
					<SubMenu title={"Créditos"} icon={<Icon name="money" />}>
						{isAdmin ? (
							<MenuItem
								icon={<Icon name="user" />}
								onClick={() =>
									navigate("/creditos/coordinadores")
								}
							>
								Coordinadores
							</MenuItem>
						) : null}
						{isAdmin || isCoordinator ? (
							<MenuItem
								icon={<Icon name="user" />}
								onClick={() => navigate("/creditos/mentores")}
							>
								Mentores
							</MenuItem>
						) : null}
						<MenuItem
							icon={<Icon name="user" />}
							onClick={() => navigate("/creditos/agentes")}
						>
							Agentes
						</MenuItem>
					</SubMenu>
				) : null}
				{isAdmin ? (
					<SubMenu
						title={"Distribuciones"}
						icon={<Icon name="building" />}
					>
						{isAdmin ? (
							<MenuItem
								icon={<Icon name="user" />}
								onClick={() =>
									navigate("/distribuciones/coordinadores")
								}
							>
								Coordinadores
							</MenuItem>
						) : null}
						{isAdmin || isCoordinator ? (
							<MenuItem
								icon={<Icon name="user" />}
								onClick={() =>
									navigate("/distribuciones/mentores")
								}
							>
								Mentores
							</MenuItem>
						) : null}
						<MenuItem
							icon={<Icon name="user" />}
							onClick={() => navigate("/distribuciones/agentes")}
						>
							Agentes
						</MenuItem>
					</SubMenu>
				) : null}
				{isCoordinator || isMentor ? (
					<MenuItem
						icon={<Icon name="user" />}
						onClick={() => navigate(`${getMisAgentesLink()}`)}
					>
						Mis Agentes
					</MenuItem>
				) : null}
				{!isMentor && !isCoordinator ? (
					<MenuItem
						icon={<Icon name="user" />}
						onClick={() =>
							isAdmin
								? navigate("/admin/customers")
								: navigate("/customers")
						}
					>
						Mis Clientes
					</MenuItem>
				) : null}
				{isAdmin || isCoordinator ? (
					<MenuItem
						icon={<Icon name="id card" />}
						onClick={() => navigate("/p_validations")}
					>
						Validaciones
					</MenuItem>
				) : null}
				{isAgent ? (
					<MenuItem
						icon={<Icon name="file" />}
						onClick={() => navigate("/RequestedServices")}
					>
						Servicios Solicitados
					</MenuItem>
				) : null}
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="comment alternate" />}
						onClick={() => navigate("/pendingChat")}
					>
						Chats Pendientes
					</MenuItem>
				) : null}
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="vcard" />}
						onClick={() => navigate("/credentials")}
					>
						Credenciales
					</MenuItem>
				) : null}
				{isAdmin ? (
					<MenuItem
						icon={<Icon name="conversation" />}
						onClick={() => navigate("/templates")}
					>
						Templates
					</MenuItem>
				) : null}
				{isAdmin || isCoordinator || isGestionador ? (
					<MenuItem
						icon={<Icon name="folder" />}
						onClick={() => navigate("/expedientes")}
					>
						Expedientes
					</MenuItem>
				) : null}
				{isAdmin || isCoordinator || isGestionador ? (
					<MenuItem
						icon={<Icon name="book" />}
						onClick={() => navigate("/solicitudes")}
					>
						Solicitudes
					</MenuItem>
				) : null}
				{isAdmin || isCoordinator || isGestionador ? (
					<MenuItem
						icon={<Icon name="eye" />}
						onClick={() => navigate("/biometricos")}
					>
						Biométricos
					</MenuItem>
				) : null}
				<SubMenu title={"Info"} icon={<Icon name="help" />}>
					<MenuItem
						icon={<Icon name="user" />}
						onClick={() => navigate("/info/leads")}
					>
						Identificación de leads
					</MenuItem>
				</SubMenu>
				<MenuItem icon={<Icon name="log out" />} onClick={logOut}>
					Cerrar Sesión
				</MenuItem>
			</Menu>
		</ProSidebar>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		authenticated: state.users.authenticated,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isMentor: state.users.user.type === "IS_MENTOR",
		isGestionador: state.users.user.type === "IS_GESTIONADOR",
		isDistribuidor: state.users.user.type === "IS_DISTRIBUIDOR",
		isContador: state.users.user.type === "IS_CONTADOR",
		isFranquiciado: state.users.user.type === "IS_FRANQUICIADO",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
		isOpen: state.users.openMenu,
		user: state.users.user,
		bot_id: state.users.user.bot_id,
	};
};

const mapActionsToProps = {
	logoutUser,
	setOpenMenu,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IBodyProps = ConnectedProps<typeof connector>;

export default connector(MainMenu);
