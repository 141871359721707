import {
	SET_SOLICITUD,
	SET_SOLICITUDES,
	SET_SOLICITUD_COUNTER,
	SET_SOLICITUD_PAGE,
	SET_SOLICITUD_LOADING,
	SET_SOLICITUDES_LOADING,
	SET_SOLICITUD_ERRORS,
} from "../constants/solicitudes_credito.constants";

import ISolicitud from "../models/solicitud_credito.model";

const initialState = {
	loading: false,
	loadingSolicitud: false,
	solicitudes: [] as ReadonlyArray<ISolicitud>,
	solicitud: null as unknown as Readonly<ISolicitud>,
	counter: 10,
	page: 1,
	errors: "",
};

export type State = Readonly<typeof initialState>;

const reducer = (state: State = initialState, action: any): State => {
	switch (action.type) {
		case SET_SOLICITUD:
			return {
				...state,
				loading: false,
				loadingSolicitud: false,
				solicitud: action.payload,
				errors: "",
			};
		case SET_SOLICITUDES:
			return {
				...state,
				loading: false,
				loadingSolicitud: false,
				solicitudes: action.payload,
				errors: "",
			};
		case SET_SOLICITUD_COUNTER:
			return {
				...state,
				counter: action.payload,
			};
		case SET_SOLICITUD_PAGE:
			return {
				...state,
				page: action.payload,
			};
		case SET_SOLICITUD_LOADING:
			return {
				...state,
				loadingSolicitud: true,
				errors: "",
			};
		case SET_SOLICITUDES_LOADING:
			return {
				...state,
				loading: true,
				errors: "",
			};
		case SET_SOLICITUD_ERRORS:
			return {
				...state,
				loading: false,
				loadingSolicitud: false,
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
