import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Modal, Grid, Input, Button, Label } from 'semantic-ui-react';
import { updateAgentPassword } from '../../../../actions/agent-admin.actions';
import { IAgentModel } from '../../../../models/Agent.model';
import { RootState } from '../../../../store/store';

interface IState {
    confirmPassword: string;
    confirmPasswordValid: boolean;
    password: string;
    passwordValid: boolean;
}

const ChangePasswordModal:React.FC<IChangePasswordProps> = ({
    agent,
    loading,
    errors,
    updateAgentPassword
}) => {

    const [open, setOpen] = useState(false);
    const [state, setState] = useState<IState>({
        confirmPassword: "",
        confirmPasswordValid: true,
        password: "",
        passwordValid: true
    });

    const udpatePassword = async () => {
        const {password, confirmPassword} = state;
        let passwordValid = password !== "";
        let confirmPasswordValid = confirmPassword !== "";
        if(passwordValid && confirmPasswordValid) {
            const updated = await updateAgentPassword(password, agent.id!);
            console.log("PASSWORD UPDATED", updated);
            if(updated === true) {
                console.log("CLOSSING", updated);
                setOpen(false);
            }
        }
        setState({
            ...state,
            passwordValid,
            confirmPasswordValid
        })
    }

    return (
        <Modal 
            onClose={() => setOpen(false)}
            open={open}
            trigger={
				<Button
                    color="blue"
                    floated='right'
                    onClick={() => setOpen(true)}
				>
					Actualizar contraseña
				</Button>
			}
            className="default-modal"
            dimmer={'blurring'}
            size="large"
        >
            <Modal.Header>Cambiar contraseña</Modal.Header>
            <Modal.Content>
                <Grid columns={2} centered>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                fluid
                                label="Contraseña"
                                placeholder="Contraseña"
                                value={state.password}
                                error={!state.passwordValid}
                                onChange={(e) => setState({
                                    ...state, 
                                    password: e.target.value.trim(),
                                    passwordValid: e.target.value.trim() !== ''
                                })}
                            /> 
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                fluid
                                label="Confirmar contraseña"
                                placeholder="Contraseña"
                                value={state.confirmPassword}
                                error={!state.confirmPasswordValid}
                                onChange={(e) => setState({
                                    ...state,
                                    confirmPassword: e.target.value.trim(),
                                    confirmPasswordValid: e.target.value.trim() === state.password
                                })}
                            />
                            {!state.confirmPasswordValid ?
                                <Label color='red'>Contraseñas no son iguales</Label>
                            : null}
                        </Grid.Column>
                    </Grid.Row>
                    {errors ? 
                        <Grid.Row>
                            <Grid.Column>
                                <Label>{errors}</Label>
                            </Grid.Column>
                        </Grid.Row>
                        : null
                    }
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='black'
                    onClick={() => setOpen(false)}
                >
                    Cerrar
                </Button>
                <Button
                    content={"Actualizar"}
                    onClick={udpatePassword}
                    positive
                    disabled={loading}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
}

interface IProps {
    agent: IAgentModel;
}

const mapStateToProps = (state: RootState, props: IProps) => {
    return {
        loading: state.adminAgents.loadingUpdate,
        errors: state.adminAgents.errors,
        agent: props.agent
    }
}

const mapActionsToProps = {
    updateAgentPassword
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IChangePasswordProps = ConnectedProps<typeof connector>;

export default connector(ChangePasswordModal);