import axios from "axios";
import {
    SET_TEMPLATES,
    SET_LOADING_TEMPLATE,
    SET_LOADING_ERRORS_TEMPLATE,
} from "../constants/template.constants";
import { ITemplate } from "../models/template.model";
import { chatCRMUrl } from "../constants/enviroment";

export const getByGroupId = (id: string) => async (dispatch: any) => {
    await dispatch({ type: SET_LOADING_TEMPLATE });
    let loaded = false;
    try {
        const response = await axios.get(
            `${chatCRMUrl}/api/getTemplates/${id}`
        );

        if (response.status !== 200) {
            console.warn(
                `No se han podido cargar los templates con ID de Categoria: ${id}`
            );
            setLoadingErrors(
                `No se han podido cargar los templates con ID de Categoria: ${id}`
            );
            return loaded;
        }

        const templates = response.data.templates;
        console.log("template", templates);

        dispatch(setTemplates(templates));
        loaded = true;
    } catch (e) {
        console.error(
            `Error al cargar los templates con ID de Categoria: ${id}`,
            e
        );
        dispatch(
            setLoadingErrors(
                `Error al cargar los templates con ID de Categoria: ${id}`
            )
        );
    }
    return loaded;
};

export const setTemplates =
    (templates: ITemplate[]) => async (dispatch: any) => {
        await dispatch({
            type: SET_TEMPLATES,
            payload: templates,
        });
    };

export const setLoadingErrors = (error: string) => async (dispatch: any) => {
    console.error("setLoadingErrors: ", error);
    await dispatch({
        type: SET_LOADING_ERRORS_TEMPLATE,
        payload: error,
    });
};
