export const DELEGACIONES = [
    "AGUASCALIENTES",
    "BAJA CALIFORNIA NOR",
    "BAJA CALIFORNIA SUR",
    "CAMPECHE",
    "CHIAPAS",
    "CHIHUAHUA",
    "COAHUILA",
    "COLIMA",
    "DURANGO",
    "EDO DE MEX ORIENTE",
    "EDO DE MEX PONIENTE",
    "GUANAJUATO",
    "GUERRERO",
    "HIDALGO",
    "JALISCO",
    "MICHOACAN",
    "MORELOS",
    "NAYARIT",
    "NIVEL CENTRAL",
    "NORESTE",
    "NOROESTE",
    "NUEVO LEON",
    "OAXACA",
    "PUEBLA",
    "QUERETARO",
    "QUINTANA ROO",
    "SAN LUIS POTOSI",
    "SINALOA",
    "SONORA",
    "SURESTE",
    "SUROESTE",
    "TABASCO",
    "TAMAULIPAS",
    "TLAXCALA",
    "VERACRUZ NORTE",
    "VERACRUZ SUR",
    "YUCATAN",
    "ZACATECAS",
    "MANDO"
]