import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import ISolicitudCredito from "../../models/solicitud_credito.model";
import { setErrors, setLoading, setSolicitudData } from "./utils.actions";

export const create =
	(solicitud: ISolicitudCredito) => async (dispatch: any) => {
		dispatch(setLoading());
		let created = false;

		await axios
			.post(`${apiUrl}/solicitudes/`, solicitud)
			.then((res) => {
				if (res.status !== 200) {
					console.warn("CREATE", res.data.message);
					dispatch(setErrors(res.data.message));
					return created;
				}
				dispatch(setSolicitudData(res.data.solicitud));

				created = true;
			})
			.catch((err) => {
				console.error("CREATE", err.response.data.message);
				dispatch(setErrors(err.response.data.message));
				return created;
			});
		return created;
	};
