import {
	SET_SOLICITUD,
	SET_SOLICITUDES,
	SET_SOLICITUD_COUNTER,
	SET_SOLICITUD_PAGE,
	SET_SOLICITUD_ERRORS,
	SET_SOLICITUD_LOADING,
	SET_SOLICITUDES_LOADING,
} from "../../constants/solicitudes_credito.constants";
import ISolicitudCredito from "../../models/solicitud_credito.model";

export const setLoading = () => (dispatch: any) => {
	dispatch({
		type: SET_SOLICITUD_LOADING,
	});
};

export const setAllLoading = () => (dispatch: any) => {
	dispatch({
		type: SET_SOLICITUDES_LOADING,
	});
};

export const setCounter = (total: number) => (dispatch: any) => {
	dispatch({
		type: SET_SOLICITUD_COUNTER,
		payload: total,
	});
};

export const setPage = (page: number) => (dispatch: any) => {
	dispatch({
		type: SET_SOLICITUD_PAGE,
		payload: page,
	});
};

export const setSolicitudesData =
	(solicitudes: ISolicitudCredito[]) => (dispatch: any) => {
		dispatch({
			type: SET_SOLICITUDES,
			payload: solicitudes,
		});
	};

export const setSolicitudData =
	(solicitud: ISolicitudCredito) => (dispatch: any) => {
		dispatch({
			type: SET_SOLICITUD,
			payload: solicitud,
		});
	};

export const setErrors = (errors: string) => (dispatch: any) => {
	dispatch({
		type: SET_SOLICITUD_ERRORS,
		payload: errors,
	});
};
