import React from "react";
import { Grid, Input, Image, Label } from "semantic-ui-react";
import "../../../../css/cotizador.css";

interface IProps {
    capitalAmount: number;
    taza: number;
    oldTaza: number;
    handleField: (field: string, value: number) => void;
}

const CotizadorCompra: React.FC<IProps> = ({
    capitalAmount,
    taza,
    oldTaza,
    handleField,
}) => {
    return (
        <div>
            <Grid
                id="cotizador-compra-01"
                className="cotizador-container padding-0"
            >
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Image
                            floated="left"
                            src={`https://i.imgur.com/sNFK20r.png`}
                            className={"img-logo"}
                            alt="LOGO"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Image
                            floated="right"
                            src={"https://i.imgur.com/zyo9nDq.png"}
                            className={"img-logo"}
                            alt="LOGO MULTIVA"
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="margin-10">
                    <Grid.Column textAlign="left">
                        <h3 className="base-text">COTIZACIÓN PRELIMINAR</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="margin-10">
                    <Grid.Column textAlign="center">
                        <span className="text-blue">
                            Firme su compra de deuda
                            <br />
                            y reciba su beneficio en efectivo
                            <br />
                            por {"    "}
                        </span>
                        <Input
                            className="input-field"
                            iconPosition="left"
                            icon="dollar"
                            type="number"
                            value={capitalAmount}
                            onChange={(e) =>
                                handleField(
                                    "capitalAmount",
                                    Number(e.currentTarget.value)
                                )
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} className="margin-10">
                    <Grid.Column>
                        <Label className="label-field base-field bg-blue">
                            Su taza de interés es:
                        </Label>
                        <Input
                            className="input-field base-field"
                            iconPosition="left"
                            icon="dollar"
                            type="number"
                            value={oldTaza}
                            onChange={(e) =>
                                handleField(
                                    "oldTaza",
                                    Number(e.currentTarget.value)
                                )
                            }
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Label className="label-field base-field bg-lsblue">
                            Su taza de interés, pasará a:
                        </Label>
                        <Input
                            className="input-field base-field"
                            iconPosition="left"
                            icon="dollar"
                            type="number"
                            value={taza}
                            onChange={(e) =>
                                handleField(
                                    "taza",
                                    Number(e.currentTarget.value)
                                )
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="margin-10">
                    <Grid.Column textAlign="left">
                        <ul>
                            <li>Pago anticipado sin penalización.</li>
                            <li>Seguro de vida gratis.</li>
                            <li>Sin comisión por apertura.</li>
                            <li>Pre-autorización en 15 minutos.</li>
                            <li>
                                Trámite en línea y depósito en 72 hrs, en la
                                cuenta donde usted recibe su pensión.
                            </li>
                        </ul>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className="margin-10">
                    <Grid.Column textAlign="left">
                        <h6 className="txt-blue">
                            Somos Distribuidor Autorizado{" "}
                            <span className="txt-orange">CWVL Consultoría</span>
                        </h6>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default CotizadorCompra;
