import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import { Segment, Header, Grid, Button, Icon, Label } from "semantic-ui-react";
import { getAll } from "../../../actions/group_templates.actions";
import { CategoryTemplates } from "./interfaces/categoryTemplates.interface";
import { refreshTemplates } from "./components/CreateTemplate/services/refreshTemplates";
import Templates from "./components/templates";
import "./group_templates.css";

interface IState {
    group: CategoryTemplates;
}

const GroupTemplates: React.FC<IGroupTemplatesProps> = ({
    user,
    groups,
    loading,
    getAll,
}) => {
    const [activeCategory, setActiveCategory] = useState(null);
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [state, setState] = useState<IState>({
        group: {
            agentId: user.id,
            category_name: "",
            description: "",
            deleted: false,
        },
    });
    const navigate = useNavigate();

    const refreshTemplate = async () => {
        await refreshTemplates()
            .then((res) => {
                console.log(res);
                setRefreshLoader(false);
                getAll();
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Templates sincronizados con Meta",
                    showConfirmButton: false,
                    timer: 3000,
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire(
                    "Error",
                    "No se pudo hacer la sincronización con Meta",
                    "error"
                );
            });
    };

    const handleCategoryClick = (group: any) => {
        setActiveCategory(group);
    };

    useEffect(() => {
        if (user.id) {
            getAll();
        }
    }, [, user]);

    return (
        <Segment>
            <Header as="h2" icon>
                <Header.Content>
                    Administrador de Templates ChatCRM
                </Header.Content>
                <Header.Subheader>
                    Gestor de Categorías/Campañas para creación de templates en
                    ChatCRM"
                </Header.Subheader>
            </Header>
            <Grid>
                <Grid.Column>
                    {refreshLoader ? (
                        <Button loading primary floated="right">
                            Loading
                        </Button>
                    ) : (
                        <Button
                            floated="right"
                            color="blue"
                            onClick={() => {
                                refreshTemplate();
                                setRefreshLoader(true);
                            }}
                        >
                            <Icon name="sync" /> Sincronizar Templates
                        </Button>
                    )}
                </Grid.Column>
            </Grid>
            <Grid stackable columns={2}>
                <Grid.Column width={4}>
                    <Segment color="blue">
                        <Button
                            attached="top"
                            color="green"
                            onClick={() => navigate("/templates/create")}
                        >
                            <Icon name="plus" /> Crear Categoría
                        </Button>
                        <div className="group-template-container">
                            {!loading && groups
                                ? groups.map((group, index) => {
                                      return (
                                          <Segment
                                              key={`${group._id}-${index}`}
                                              className="group-template-item pointer"
                                              raised
                                              color={
                                                  group === activeCategory
                                                      ? "green"
                                                      : "yellow"
                                              }
                                              onClick={() => {
                                                  setState({
                                                      ...state,
                                                      group: group,
                                                  });
                                                  handleCategoryClick(group);
                                              }}
                                          >
                                              <Header>
                                                  <Header.Content>
                                                      {group.category_name}
                                                  </Header.Content>
                                                  <Header.Subheader>
                                                      {group.description}
                                                  </Header.Subheader>
                                              </Header>
                                              {group.deleted ? (
                                                  <Label color="red">
                                                      Categoría inhabilitada en
                                                      ChatCRM
                                                  </Label>
                                              ) : null}
                                          </Segment>
                                      );
                                  })
                                : null}
                        </div>
                    </Segment>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Segment color="green">
                        <div>
                            <Label attached="top">
                                <h2 style={{ textAlign: "center" }}>
                                    {state.group.category_name}
                                </h2>
                                <h5>{state.group.description}</h5>
                            </Label>
                        </div>
                        <br />
                        <br />
                        <div className="card-templates-container">
                            <Templates groupTemplate={state.group} />
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        user: state.users.user,
        groups: state.groupTemplates.group_templates,
        loading: state.groupTemplates.loading,
        errors: state.groupTemplates.errors,
    };
};

const mapActionsToProps = {
    getAll,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IGroupTemplatesProps = ConnectedProps<typeof connector>;

export default connector(GroupTemplates);
