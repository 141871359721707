import React, { useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";

interface IProps {
	create: () => void;
}

export const PropmtCreate: React.FC<IProps> = ({ create }) => {
	const [open, setOpen] = useState(false);

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			size="mini"
			dimmer={"blurring"}
			style={{
				left: "auto",
				right: "auto",
				top: "auto",
				height: "fit-content",
			}}
			trigger={
				<Button
					floated="right"
					color="green"
					circular
					icon={<Icon name="plus" />}
					onClick={() => setOpen(true)}
				/>
			}
		>
			<Modal.Header>Solicitud de Biométrico</Modal.Header>
			<Modal.Content>
				Se registrará una nueva solicitud de biométrico para este
				expediente.
			</Modal.Content>
			<Modal.Actions>
				<Button color="red" onClick={() => setOpen(false)}>
					Cancelar
				</Button>
				<Button
					color="green"
					icon={<Icon name="plus" />}
					onClick={() => {
						create();
						setOpen(false);
					}}
				>
					Registrar
				</Button>
			</Modal.Actions>
		</Modal>
	);
};
