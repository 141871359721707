import React, { useEffect, useState } from "react";
import { Grid, Image } from "semantic-ui-react";
import "../credentials.css";

const ServicioAgente = ({ isAgent, profile, agent }: any) => {
    const [imageData, setImageData] = useState("");

    useEffect(() => {
        const imageUrl = "https://i.imgur.com/sNFK20r.png";

        const imageToBase64 = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    setImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir la imagen:", error);
            }
        };
        imageToBase64();
    }, []);

    return (
        <>
            <div className="cotizador-component">
                <Grid
                    id="servicio-agente"
                    className="cotizador-container padding-0"
                >
                    <div className="service_container">
                        <div className="service_logo">
                            {imageData && (
                                <img
                                    src={imageData}
                                    alt="Logo"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                        </div>

                        <div className="service_text_main">
                            <p>
                                Este beneficio financiero va dirigido para{" "}
                                <b>pensionados y jubilados del IMSS;</b>
                                <br />
                                Son financiados por Bancos autorizados por
                                Banxico (solo los mejores
                                <br />
                                bancos participan), los montos autorizados
                                parten desde <b>$15,000</b> hasta <br />
                                <b>$600,000</b> con tasa de interés desde el{" "}
                                <b>2%.</b>
                            </p>
                        </div>
                        <div className="service_text_info">
                            <p>
                                Mi nombre es:{" "}
                                <u>
                                    <b
                                        style={{
                                            color: "orangered",
                                        }}
                                    >
                                        {isAgent
                                            ? `${profile.name} ${profile.lastName}`
                                            : `${agent.name} ${agent.lastName}`}
                                    </b>
                                </u>
                                .<br />
                                Asesor autorizado de{" "}
                                <b>CWVL CONSULTORÍA "EL PRÉSTAMO DEL MES",</b>
                                <br />
                                distribuidor autorizado por la banca comercial.
                                <br />
                                Mi telefono/Whatsapp es :{" "}
                                <u>
                                    <b style={{ color: "orangered" }}>
                                        {isAgent ? profile.phone : agent.phone}
                                    </b>
                                </u>
                                .<br />
                                por si gusta comunicarse conmigo.
                            </p>
                        </div>
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default ServicioAgente;
