import { Button, Modal } from "semantic-ui-react";

export const ImagePreview = ({
    modalPreview,
    imagePreview,
    setModalPreview,
}: any) => {
    return (
        <Modal
            open={modalPreview}
            style={{
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                height: "fit-content",
            }}
            size="large"
            dimmer={"blurring"}
        >
            <Modal.Content>
                <div className="preview-image-chatcrm">
                    <img
                        src={imagePreview.body?.image.uri}
                        style={{
                            maxWidth: "80%",
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setModalPreview(false)}>
                    Cerrar
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
