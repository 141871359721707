import userReducer from "../reducers/users.reducer";
import userAdminReducer from "../reducers/admin-users.reducer";
import agentAdminReducer from "../reducers/admin-agents.reducer";
import conversationReducer from "../reducers/conversations.reducer";
import messageReducer from "../reducers/messages.reducer";
import validationsReducer from "../reducers/validations.reducer";
import templatesReducer from "../reducers/templates.reducer";
import groupTemplatesReducer from "../reducers/group_templates.reducer";
import reportsReducer from "../reducers/reports.reducer";
import agentDashboardReducer from "../reducers/dashboard.agent.reducer";
import expedientesReducer from "../reducers/expedientesCRM.reducer";
import categoriesTemplateReducer from "../reducers/categories.templates.reducer";
import notificationReducer from "../reducers/notificationCRM.reducer";
import solicitudesReducer from "../reducers/solicitudes_credito.reducer";
import biometricosReducer from "../reducers/biometricos.reducer";
import conversationsCrmReducer from "../reducers/conversationsCRM.reducer";
import servicesReducer from "../reducers/services.reducer";
import discountsReducer from "../reducers/discounts.reducer";
import franchiseReducer from "../reducers/franchise.reducer";

import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

const middleware =
    process.env.NODE_ENV !== "production"
        ? [require("redux-immutable-state-invariant").default(), thunk]
        : [thunk];

const store = configureStore({
    reducer: {
        users: userReducer,
        adminUsers: userAdminReducer,
        adminAgents: agentAdminReducer,
        conversation: conversationReducer,
        message: messageReducer,
        validations: validationsReducer,
        groupTemplates: groupTemplatesReducer,
        templates: templatesReducer,
        reports: reportsReducer,
        agentDashboard: agentDashboardReducer,
        expedientesReducer: expedientesReducer,
        categoriesTemplate: categoriesTemplateReducer,
        notificationReducer: notificationReducer,
        solicitudes: solicitudesReducer,
        biometricos: biometricosReducer,
        conversationsCrmReducer: conversationsCrmReducer,
        servicesReducer: servicesReducer,
        discountsReducer: discountsReducer,
        franchiseReducer: franchiseReducer,
    },
    middleware: middleware,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
