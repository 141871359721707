import {
    SET_CONVERSATIONS,
    SET_CONVERSATION_BY_ID,
    LOADING_CONVERSATION,
    SET_LOADING_ERRORS_CONVERSATION,
    CLEAR_LOADING_ERRORS_CONVERSATION
} from '../constants/conversations.constants';

import axios from 'axios';
import IConversationModel from '../models/IConversation.model';
import { apiUrl } from '../constants/enviroment';

const sorter = (a: IConversationModel, b: IConversationModel) => {
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
}

export const getConversations = () => async (dispatch: any) => {

    await dispatch({ type: LOADING_CONVERSATION })
    await axios.get(`${apiUrl}/conversation`)
        .then((res) => {
            let conversationsList = res.data.response;
            dispatch(setConversationsData(conversationsList.sort(sorter).reverse()));
            dispatch({ type: CLEAR_LOADING_ERRORS_CONVERSATION });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: SET_LOADING_ERRORS_CONVERSATION,
                payload: err.response.data.errors ? err.response.data.errors[0].message : "error al cargar conversaciones"
            });
        });
}

export const getConversationsByAgentId = (id: string) => async (dispatch: any) => {
    await dispatch({ type: LOADING_CONVERSATION })
    await axios.get(`${apiUrl}/conversation/agent/${id}`,{
        headers: {
            "Authorization": localStorage.getItem("token")!
        }
    }).then((response) => {
        let conversationList = response.data.response;
        dispatch(setConversationsData(conversationList.sort(sorter).reverse()));
        dispatch({type: CLEAR_LOADING_ERRORS_CONVERSATION})
    }).catch((error) => {
        dispatch({type: SET_LOADING_ERRORS_CONVERSATION,
        payload: "error al cargar conversaciones"
    });
    })                                       
}

export const setConversationsData = (conversations: IConversationModel[]) => (dispatch: any) => {
    dispatch({ type: LOADING_CONVERSATION });
    dispatch({
        type: SET_CONVERSATIONS,
        payload: conversations 
    });
}

export const setConversationData = (conversation: IConversationModel) => (dispatch: any) => {
    dispatch({ type: LOADING_CONVERSATION });
    dispatch({
        type: SET_CONVERSATION_BY_ID,
        payload: conversation
    });
}