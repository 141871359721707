import {
  BrowserRouter as Router,
} from "react-router-dom";
import Header from '../src/layout/Header';
import Body from '../src/layout/Body';
import Footer from '../src/layout/Footer';
import SideMenu from './layout/components/side_menu/SideMenu';
import './App.css';

//redux stuffS
import { Provider } from 'react-redux';
import store from './store/store';

const App = () => {

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Header/>
          <SideMenu/>
          <Body/>
          <Footer/>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
