import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/store';
import '../../../css/crm.css';
import { Grid, Segment } from 'semantic-ui-react';
import ConversationList from './components/conversationList.component';
import Conversation from './components/conversation.component';

const CRM: React.FC<ICRMProps> = ({
}) => {

    return (
        <Segment>
            <Grid style={{ height: '700px'}}>
                <Grid.Row style={{ margin: '0', padding: '0'}} divided stretched>
                    <Grid.Column 
                        style={{ margin: '0', padding: '0'}} 
                        className='conversations-list-container' 
                        stretched 
                        computer={4}
                    >
                        <ConversationList/>
                    </Grid.Column>
                    <Grid.Column computer={12}>
                        <Conversation/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
        
    }
}

const mapActionsToProps = {
}

const connector = connect(mapStateToProps, mapActionsToProps);

type ICRMProps = ConnectedProps<typeof connector>;

export default connector(CRM);