import {
    SET_NOTIFICATION,
    SET_NOTIFICATIONS,
    SET_LOADING_NOTIFICATION,
    SET_LOADING_NOTIFICATIONS,
    SET_PAGE_NOTIFICATIONS,
    SET_TOTALPAGES_NOTIFICATIONS,
    SET_OPEN_NOTIFICATION,
    SET_COUNT_NOTIFICATIONS,
} from "../constants/notifications.constants";
import { INotification } from "../models/INotificationCRM";

const initialState = {
    loading: false,
    loadingNotification: false,
    page: 1,
    totalPages: 1 as Readonly<number>,
    count: 0 as Readonly<number>,
    notifications: [] as ReadonlyArray<INotification>,
    notification: {} as Readonly<INotification>,
    errors: "",
};

export type State = Readonly<typeof initialState>;

const notificationReducer = (
    state: State = initialState,
    action: any
): State => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                notification: action.payload,
                errors: "",
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                notifications: action.payload,
                errors: "",
            };
        case SET_PAGE_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                page: action.payload,
                errors: "",
            };
        case SET_TOTALPAGES_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                totalPages: action.payload,
                errors: "",
            };
        case SET_COUNT_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                count: action.payload,
                errors: "",
            };
        case SET_OPEN_NOTIFICATION:
            return {
                ...state,
                loading: false,
                loadingNotification: false,
                notification: action.payload,
                errors: "",
            };
        case SET_LOADING_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
                errors: "",
            };
        case SET_LOADING_NOTIFICATION:
            return {
                ...state,
                loadingNotification: true,
                errors: "",
            };
        default:
            return state;
    }
};

export default notificationReducer;
