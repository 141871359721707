import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Button, Header, Modal, Input, Grid, Label, Checkbox, Segment, Dropdown } from 'semantic-ui-react';
import { RootState } from '../../../store/store';
import { CLEAR_LOADING_ERRORS_VALIDATION } from '../../../constants/validation.constants';
import { useParams } from 'react-router-dom';
import IValidation  from '../../../models/validation.model';
import { create, update, getAll, getById } from '../../../actions/validations.actions';
import { useNavigate } from 'react-router-dom';
import { DELEGACIONES } from '../../../constants/validaciones/delegaciones.constants';
import '../../../css/validation.modal.css';

export interface IState {
	isNew: boolean;
	id?: string;
	isJubilado: boolean;

	curp: string;
	curpValido: boolean;
	errorCurp: string;
	
	nss: string;
	nssValido: boolean;
	errorNss: string;
	
	phone: string;
	phoneValido: boolean;
	errorPhone: string;
	
	matricula: string;
	matriculaValido: boolean;
	errorMatricula: string;
	
	delegacion: string;
	delegacionValido: boolean;
	errorDelegacion: string;
}

const EditValidation:React.FC<Props> = ({
	userId,
	isAdmin,
	validation,
	errors,
	loading,
	agent,
	customer,
	create,
	update,
}) => {

	const { id, user_id } = useParams();

	const navigate = useNavigate();

	const initialState = {
		isNew: id ? false : true,
		id: id,
		isJubilado: id || user_id ? customer ? customer.isJubilado ?? false : (validation ? validation.isJubilado ?? false : false) : false ,

		curp: id || user_id ? customer ? customer.CURP ?? "" : (validation ? validation.curp : "") : "" ,
		curpValido: true,
		errorCurp: "",

		nss: id || user_id ? customer ? customer.NSS ?? "" : (validation ? validation.nss : "") : "" ,
		nssValido: true,
		errorNss: "",

		phone: id || user_id ? customer ? customer.phone ?? "" : (validation ? validation.phone : "") : "",
		phoneValido: true,
		errorPhone: "",

		matricula: id || user_id ? customer ? customer.matricula ?? "" : (validation ? validation.matricula ?? "" : "") : "",
		matriculaValido: true,
		errorMatricula: "",

		delegacion: id || user_id ? customer ? customer.delegacion ?? "" : (validation ? validation.delegacion ?? "" : "") : "",
		delegacionValido: true,
		errorDelegacion: ""
	}

	const dispatch = useDispatch();
	const [open, setOpen] = useState(true);
	const [state, setState] = useState<IState>(initialState);

	useEffect(() => {
		dispatch({ type: CLEAR_LOADING_ERRORS_VALIDATION });
		console.log("ERRORS :" + errors)
		setState(initialState);
	}, [open, customer]);

	const validateFields = (field: string, value: string) => {
		let isNumeric = /^\d+$/.test(value);

		value = value.toString().trim();

		switch(field) {			
			case "curp":
				let errorCurp = value.length !== 18 ? "La CURP debe contener 18 caracteres" : "";

				setState({
					...state,
					curp: value.toUpperCase(),
					curpValido: value !== "" && value.length === 18,
					errorCurp: errorCurp
				})
				break;
			case "nss":
				let errorNss = value.length !== 11 ? "El NSS debe contener 11 dígitos" : "";

				setState({
					...state,
					nss: value,
					nssValido: value !== "" && value.toString().length === 11 && isNumeric,
					errorNss: errorNss
				})
				break;
			case "phone":
				let errorPhone = value.length !== 10 ? "El Teléfono debe contener al menos 10 dígitos" : "";

				setState({
					...state,
					phone: value,
					phoneValido: value !== "" && value.length >= 10 && isNumeric,
					errorPhone: errorPhone
				})
				break;
			case "matricula":
				let errorMatricula = value !== "" ? "Matrícula no puede estar vacío" : "";

				setState({
					...state,
					matricula: value,
					matriculaValido: value !== "",
					errorMatricula: errorMatricula
				})
				break;
			case "delegacion":
				let errorDelegacion = value !== "" ? "Delegación no puede estar vacío" : "";

				setState({
					...state,
					delegacion: value,
					delegacionValido: value !== "",
					errorDelegacion: errorDelegacion
				})
				break;
			default:
				break;
		}
	}

	const save = async () => {
		console.log("Saving validation");
		const { 
			curp,
			nss,
			phone,
			isJubilado,
			matricula,
			delegacion
		} = state;
		let { 
			curpValido,
			nssValido,
			phoneValido,
			matriculaValido,
			delegacionValido
		} = state;

		curpValido = curp !== "" && curp.length === 18;
		nssValido = !isJubilado ? nss !== "" && nss.length === 11: true;
		phoneValido = phone !== "" && phone.length >= 10;
		matriculaValido = isJubilado ? matricula !== "" : true;
		delegacionValido = isJubilado ? delegacion !== "" : true;

		console.log(`curp: ${curpValido}, nss: ${nssValido}, phone: ${phoneValido}`);

		if(curpValido && nssValido && phoneValido && matriculaValido && delegacionValido) {

			let validation: IValidation = {
				agentId: isAdmin ? agent.id! : userId,
				userId: user_id ?? "",
				curp: curp.toUpperCase(),
				nss: nss,
				phone: phone,
				isJubilado: isJubilado,
				matricula: matricula,
				delegacion: delegacion
			}

			if(state.isNew) {
				let created = await create(validation);
				console.log("CREATED: " + created)

				if(created) {
					onClose();
				}
			} else {
				validation.id = state.id
				// let updated = await update(validation);
				/* if(updated) {
					setOpen(false);
				} */
			}
		}

		setState({
			...state,
			curpValido,
			nssValido,
			phoneValido,
			matriculaValido,
			delegacionValido,
			errorCurp: curpValido ? "" : "La CURP debe contener 18 caracteres",
			errorNss: nssValido ? "" : "El NSS debe contener 11 dígitos",
			errorPhone: phoneValido ? "" : "El Teléfono debe contener al menos 10 dígitos",
			errorMatricula: matriculaValido ? "" : "Matrícula no puede estar vacío",
			errorDelegacion: delegacionValido ? "" : "Delegación no puede estar vacío"
		});

	}

	const onClose = () => {
		setOpen(false);
		navigate(-1);
	}

  return (
    <Modal
		onClose={() => setOpen(false)}
		className='validation_modal'
		onOpen={() => setOpen(true)}
		closeOnEscape={false}
		closeOnDimmerClick={false}
		closeOnPortalMouseLeave={false}
		closeOnTriggerBlur={false}
		closeOnTriggerMouseLeave={false}
		closeOnDocumentClick={false}
		closeOnTriggerClick={false}
		open={open}
		trigger={
			!validation ? 
			<Button
				color="green"
				floated='right'
			>
				Crear
			</Button>
			: <Button 
				color="blue" 
				floated='right'
				onClick={() => {setOpen(true)}}
			>
				Editar
			</Button>
		}
		size="large"
	>
		<Modal.Header>{state.isNew ? "Solicitud de validación" : "Editar solicitud"}</Modal.Header>
		<Modal.Content>
			<Grid centered>
			{!state.isNew ?
				<Grid.Row>
					<Modal.Description>
						<Header>ID: {state.id}</Header>
					</Modal.Description>
				</Grid.Row>
				:null
			}
			<Grid.Row columns={state.isJubilado ? 1: 2}>
				{state.isJubilado ?
					<Grid.Column>
						<h4>Delegación referida: {customer.delegacion}</h4>
						<h6>NOTA: Podría no ser exacta </h6>
					</Grid.Column>
				:null}
				<Grid.Column>
					<Segment compact floated='right'>
						<Checkbox 
							toggle
							label="Jubilado"
							defaultChecked={state.isJubilado}
							onChange={(event: any, data: any) => {
								setState({
									...state,
									isJubilado: data.checked
								})
							}}
						/>
					</Segment>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column computer={8} mobile={16}>
					<Input
						label="CURP"
						className='validation_field'
						value={state.curp}
						error={!state.curpValido}
						onChange={(e) => {validateFields("curp", e.currentTarget.value)}}
					/>
					{!state.curpValido && state.errorCurp !== "" ? 
						<Label color='red'>{state.errorCurp}</Label> 
					: null}
				</Grid.Column>
				<Grid.Column computer={8} mobile={16}>
					<Input
						label="NSS"
						type='number'
						className='validation_field'
						value={state.nss}
						error={!state.nssValido}
						onChange={(e) => {validateFields("nss", e.currentTarget.value)}}
					/>
					{!state.nssValido && state.errorNss !== "" ? 
						<Label color='red'>{state.errorNss}</Label>
					: null}
				</Grid.Column>
				{state.isJubilado ?
				<Grid.Column computer={8} mobile={16}>
					<Input
						label="Matrícula"
						type='text'
						className='validation_field'
						value={state.matricula}
						error={!state.matriculaValido}
						onChange={(e) => {validateFields("matricula", e.currentTarget.value)}}
					/>
					{!state.matriculaValido && state.errorMatricula !== "" ? 
						<Label color='red'>{state.errorMatricula}</Label>
					: null}
				</Grid.Column>
				: null}
				{state.isJubilado ?
				<Grid.Column computer={8} mobile={16}>
					<Dropdown
						className="user-filter-field"
						value={state.delegacion}
						placeholder='Delegación'
						search
						fluid
						options={DELEGACIONES.map((delegacion: string, index: number) => {
							return {
								key: `delegacion-${index}-${delegacion}`,
								text: delegacion,
								value: delegacion
							}	
						})}
						onChange={(event: any, data: any) => {
							validateFields("delegacion", data.value);
						}}
					
					/>
					{!state.delegacionValido && state.errorDelegacion !== "" ? 
						<Label color='red'>{state.errorDelegacion}</Label>
					: null}
				</Grid.Column>
				: null}
				<Grid.Column computer={16} mobile={16}>
					<Input
						label="Teléfono"
						type='number'
						className='validation_field'
						value={state.phone}
						error={!state.phoneValido}
						disabled={!state.isNew || user_id !== undefined}
						onChange={(e) => {validateFields("phone", e.currentTarget.value)}}
					/>
					{!state.phoneValido && state.errorPhone !== "" ? 
						<Label color='red'>{state.errorPhone}</Label>
					: null}
				</Grid.Column>
			</Grid.Row>
			{errors ?
				<Grid.Row>
					<Grid.Column>
					<Label
						attached="bottom"
						color="red"
						style={{ textAlign: "center" }}
					>
						{errors}
					</Label>
					</Grid.Column>
				</Grid.Row>
			: null}
			</Grid>
		</Modal.Content>
			<Modal.Actions >
				<Button
					color='black'
					onClick={onClose}
				>
					Cerrar
				</Button>
				<Button
					content={state.isNew ? "Validar" : "Editar"}
					onClick={save}
					positive
					loading={loading}
					active={!loading}
				/>
			</Modal.Actions>
    	</Modal>
	)
}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
    return {
		userId: state.users.user.id,
        validation: state.validations.validation,
        errors: state.validations.errors,
        loading: state.validations.loadingValidation,
		agent: state.adminAgents.agent,
		isAdmin: state.users.user.type === "IS_ADMIN",
		customer: state.adminUsers.user
    }
}

const mapActionsToProps = {
	create,
	update,
	getAll,
	getById
}

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector>;

export default connector(EditValidation);