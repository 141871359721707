import React, { useEffect, useState } from "react";
import { Grid, Input, Image, Icon, Button } from "semantic-ui-react";
import { IoLogoWhatsapp } from "react-icons/io";
import "../credentials.css";

const LlamadaAgente = ({ isAgent, profile, agent }: any) => {
    const [selectedProfileImage, setSelectedProfileImage] = useState(null);
    const [imageData, setImageData] = useState("");

    const handleProfileImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setSelectedProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const imageUrl = "https://i.imgur.com/sNFK20r.png";

        const imageToBase64 = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onload = () => {
                    const base64String: any = reader.result;
                    setImageData(base64String);
                };

                reader.readAsDataURL(blob);
            } catch (error) {
                console.error("Error al cargar y convertir la imagen:", error);
            }
        };
        imageToBase64();
    }, []);

    return (
        <>
            <div>
                <input
                    style={{ display: "none" }}
                    id="btn-upload"
                    type="file"
                    onChange={handleProfileImageChange}
                    multiple
                    name="btn-foto"
                    title="Subir fotos"
                />
                <h3>Carga tu foto en la credencial</h3>
                <Button
                    onClick={() => {
                        document.getElementById("btn-upload")!.click();
                    }}
                    color="green"
                >
                    <Icon name="upload" />
                    Cargar Foto
                </Button>
            </div>
            <div className="cotizador-component">
                <Grid
                    id="llamada-agente"
                    className="cotizador-container padding-0"
                >
                    <div className="header_call">
                        <div className="call_logo">
                            {imageData && (
                                <img
                                    src={imageData}
                                    alt="Logo"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                    }}
                                />
                            )}
                        </div>

                        <div className="call_question">
                            <p>
                                ¿EN QUÉ HORARIO LE PUEDO LLAMAR, PARA
                                <br /> DARLE UNA ATENCIÓN PERSONALIZADA?
                            </p>
                        </div>
                    </div>
                    <div className="call_info_container">
                        <div className="call_text_info">
                            <p>
                                Soy su asesor
                                <u>
                                    {" "}
                                    <b
                                        style={{
                                            color: "orangered",
                                        }}
                                    >
                                        {isAgent
                                            ? `${profile.name} ${profile.lastName}`
                                            : `${agent.name} ${agent.lastName}`}
                                    </b>
                                </u>
                                <br />
                                para brindarle una mayor atención.
                                <br />
                                <br />
                                Le proporciono mis datos de contacto por si
                                <br />
                                gusta comunicarse directamente conmigo.
                            </p>
                        </div>
                        <div className="call_image_profile">
                            {selectedProfileImage ? (
                                <Image
                                    src={selectedProfileImage}
                                    alt="Selected"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "350px",
                                    }}
                                />
                            ) : (
                                <div className="call_icon_select_image">
                                    <Icon name="user circle" color="orange" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="call_contact_info">
                        <div className="call_contact_phone">
                            <p>
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "25px",
                                    }}
                                >
                                    TELÉFONO:
                                </b>
                                <br />
                                <br />
                                <b
                                    style={{
                                        fontSize: "25px",
                                    }}
                                >
                                    <IoLogoWhatsapp className="call_contact_icon" />{" "}
                                    {isAgent ? profile.phone : agent.phone}
                                </b>
                                <br />
                                <br />
                                <b
                                    style={{
                                        color: "orangered",
                                        fontSize: "25px",
                                    }}
                                >
                                    CORREO:
                                </b>
                                <br />
                                <br />
                                <b
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    {isAgent ? profile.email : agent.email}
                                </b>
                            </p>
                        </div>
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default LlamadaAgente;
