import {
    SET_MESSAGES,
    LOADING_MESSAGE,
    SET_LOADING_ERRORS_MESSAGE,
    CLEAR_LOADING_ERRORS_MESSAGE,
    SET_MESSAGE_BY_ID
  } from '../constants/message.constants';
  
  import IMessageResponse from '../models/IMessage.model';
  
  const initialState = {
    loading: false,
    loadingUpdate: false,
    messages: [] as ReadonlyArray<IMessageResponse>,
    message: {} as Readonly<IMessageResponse>,
    errors: ""
  }
  
  export type UserState = Readonly<typeof initialState>;
  
  const messagesReducer = (state: UserState = initialState, action: any): UserState  => {
    switch (action.type) {
      case SET_MESSAGES:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          errors: "",
          messages: action.payload
        }
      case SET_MESSAGE_BY_ID:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          message: action.payload,
        };
      case LOADING_MESSAGE:
        return {
          ...state,
          loading: true
        };
      case SET_LOADING_ERRORS_MESSAGE:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          errors: action.payload
        };
      case CLEAR_LOADING_ERRORS_MESSAGE:
        return {
          ...state,
          loading: false,
          loadingUpdate: false,
          errors: ""
        };
      default:
        return state;
    }
  }
  
  export default messagesReducer;