import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Modal,
	Grid,
	Input,
	Button,
	Label,
	Icon,
	Dropdown,
} from "semantic-ui-react";
import { IAgentModel } from "../../../../models/Agent.model";
import { RootState } from "../../../../store/store";
import { fillUsers } from "../../../../actions/user-admin.actions";
import CAMPAIGNS, {
	TYPE_PRODUCTS,
} from "../../../../constants/dashboard/campaigns.constants";
import { getType } from "../helpers/leads.utils";

interface IState {
	amount: number;
	amountValid: boolean;
	filterCampaign: string;
	filterTypeProduct: string;
}

const FillLeadsModal: React.FC<IChangePasswordProps> = ({
	agent,
	loading,
	errors,
	fillUsers,
}) => {
	const [state, setState] = useState<IState>({
		amount: 5,
		amountValid: true,
		filterCampaign: "",
		filterTypeProduct: "",
	});

	const [open, setOpen] = useState(false);

	const fillLeads = async () => {
		const { amount, filterCampaign, filterTypeProduct } = state;
		let amountValid = amount !== null && amount > 0;
		const filters = {
			type_producto: filterTypeProduct,
			...getType(filterCampaign),
		};

		if (!filterTypeProduct) delete filters.type_producto;

		console.log("FILTERS", filters);

		if (amountValid) {
			const filled = await fillUsers(agent.id!, amount, filters);
			console.log("FILLED", filled);
			if (filled) {
				// setOpen(false);
			}
		}

		setState({
			...state,
			amountValid,
		});
	};

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			closeOnDocumentClick={false}
			closeOnTriggerClick={false}
			open={open}
			trigger={
				<Button
					size="large"
					style={{ margin: "5px" }}
					color="green"
					floated="right"
					onClick={() => setOpen(true)}
				>
					<Icon name="plus" />
					Asignar Leads
				</Button>
			}
			className="default-modal"
			dimmer={"blurring"}
			size="large"
		>
			<Modal.Header>
				Asignación: {agent.name} {agent.lastName}
			</Modal.Header>
			<Modal.Content scrolling>
				<Grid
					style={{ padding: "40px" }}
					columns="equal"
					centered
					stretched
					padded
				>
					<Grid.Row>
						<Grid.Column>
							<Input
								label="Cantidad"
								placeholder="Cantidad"
								type="number"
								value={state.amount}
								error={!state.amountValid}
								onChange={(e) =>
									setState({
										...state,
										amount: Number(e.target.value),
										amountValid:
											e.target.value.trim() !== "",
									})
								}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								selection
								button
								className="icon"
								placeholder="Seleccione el tipo de campaña"
								floating
								labeled
								clearable
								value={state.filterCampaign}
								onChange={(e, data) => {
									setState({
										...state,
										filterCampaign: data.value!.toString(),
									});
								}}
								icon="world"
								options={CAMPAIGNS}
							/>
						</Grid.Column>
						<Grid.Column>
							<Dropdown
								selection
								button
								className="icon"
								placeholder="Seleccione el tipo de cliente"
								floating
								labeled
								clearable
								value={state.filterTypeProduct}
								onChange={(e, data) => {
									setState({
										...state,
										filterTypeProduct:
											data.value!.toString(),
									});
								}}
								icon="user"
								options={TYPE_PRODUCTS}
							/>
						</Grid.Column>
					</Grid.Row>
					{errors !== "" ? (
						<Grid.Row>
							<Grid.Column>
								<Label color="red">{errors}</Label>
							</Grid.Column>
						</Grid.Row>
					) : null}
				</Grid>
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={() => setOpen(false)}>
					Cerrar
				</Button>
				<Button
					content={"Asignar"}
					onClick={fillLeads}
					positive
					disabled={loading}
					loading={loading}
				/>
			</Modal.Actions>
		</Modal>
	);
};

interface IProps {
	agent: IAgentModel;
}

const mapStateToProps = (state: RootState, props: IProps) => {
	return {
		loading: state.adminUsers.loading,
		errors: state.adminUsers.errors,
		agent: props.agent,
	};
};

const mapActionsToProps = {
	fillUsers,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IChangePasswordProps = ConnectedProps<typeof connector>;

export default connector(FillLeadsModal);
