import React, { useState } from "react";
import { useNavigate } from "react-router";
import { IAgentModel } from "../../../../models/Agent.model";
import { IUserModel } from "../../../../models/User.model";
import { status, agentStatus } from "../../../../constants/users/status.user";
import { namespace_2 } from "../../../../constants/enviroment";
import {
	Table,
	Pagination,
	Icon,
	Dropdown,
	Input,
	Button,
	Popup,
	Rating,
} from "semantic-ui-react";
import {
	getClassName,
	getIconName,
	getProximoSeguimientoColor,
	generateWaLink,
	parseDate,
	getDescription,
} from "../helpers/leads.utils";
import ILeadsResponse from "../../../../models/ILeadsResponse.model";
import { LeadCard } from "./card.lead";
import "../../../../css/users.css";

interface IProps {
	agent: IAgentModel;
	page: number;
	counter: number;
	leads: ILeadsResponse[];
	isAgent: boolean;
	loadingLead: boolean;
	setPage: (page: number) => void;
	updateSeguimiento: (leadId: string, agentId: string, date: string) => void;
	updateStatus: (lead: IUserModel, status: string) => void;
	updateProximoSeguimiento: (lead: IUserModel, date: string) => void;
	updateAmount: (lead: IUserModel, amount: number) => void;
	update: (lead: IUserModel, data: any) => void;
	getAgentName: (agentId: string) => string;
	setUser: (lead: IUserModel) => void;
	selectedLead: IUserModel;
}

const LeadsCreditos: React.FC<IProps> = ({
	agent,
	page,
	counter,
	leads,
	isAgent,
	loadingLead,
	setPage,
	updateSeguimiento,
	updateStatus,
	updateProximoSeguimiento,
	updateAmount,
	update,
	getAgentName,
	setUser,
	selectedLead,
}) => {
	const navigate = useNavigate();

	const [amount, setAmount] = useState(0);

	const pagination = () => {
		return (
			<Pagination
				style={{ float: "right" }}
				activePage={page}
				totalPages={Math.trunc(counter / 10) + 1}
				onPageChange={(event, data) => {
					console.log("PAGE", data.activePage);
					setPage(Number(data.activePage));
				}}
			/>
		);
	};

	const leadIcon = (lead: IUserModel) => {
		const description = getDescription(lead);
		return (
			<Popup
				style={{ textAlign: "center" }}
				trigger={
					<Icon
						className={getClassName(lead)}
						name={getIconName(lead)}
						circular
					/>
				}
			>
				<Popup.Header>
					<Icon
						className={getClassName(lead)}
						name={getIconName(lead)}
						circular
					/>
				</Popup.Header>
				<Popup.Content>
					CAMPAÑA: {description.tipo}
					<br />
					ORIGEN: {description.origen}
				</Popup.Content>
			</Popup>
		);
	};

	return (
		<div
			style={{
				width: "100%",
				overflowX: "scroll",
			}}
		>
			<Table
				celled
				selectable
				style={{
					minWidth: "1600px",
				}}
			>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>
							Agente: {agent.name} {agent.lastName}
						</Table.HeaderCell>
						<Table.HeaderCell colSpan="16">
							{pagination()}
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row className="jubilado" color={"yellow"}>
						<Table.HeaderCell>ID</Table.HeaderCell>
						{!isAgent ? (
							<Table.HeaderCell>Agente</Table.HeaderCell>
						) : null}
						<Table.HeaderCell>Nombre</Table.HeaderCell>

						<Table.HeaderCell>Fecha</Table.HeaderCell>
						{agent.bot_id === namespace_2 ? (
							<Table.HeaderCell>
								Fecha Actualización
							</Table.HeaderCell>
						) : null}

						{agent.bot_id !== namespace_2 ? (
							<Table.HeaderCell>Creditos</Table.HeaderCell>
						) : null}
						{agent.bot_id !== namespace_2 ? (
							<Table.HeaderCell>Descuentos</Table.HeaderCell>
						) : null}
						{agent.bot_id !== namespace_2 ? (
							<Table.HeaderCell>Tipo</Table.HeaderCell>
						) : null}
						{agent.bot_id !== namespace_2 ? (
							<Table.HeaderCell>Monto</Table.HeaderCell>
						) : null}
						<Table.HeaderCell>Estatus</Table.HeaderCell>
						<Table.HeaderCell>Acciones</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{leads
						? leads.map((leadR: ILeadsResponse, index: number) => {
								if (isAgent && leadR.doc.phone === "+52") {
									return;
								}
								return (
									<Table.Row
										key={`${index}-userId-${
											leadR.doc.wa_id ?? leadR.doc.Fid
										}`}
										className={
											selectedLead &&
											(leadR.doc.wa_id ??
												leadR.doc.Fid) ===
												(selectedLead.wa_id ??
													selectedLead.Fid)
												? "selected"
												: ""
										}
									>
										<Table.Cell textAlign="center">
											{leadIcon(leadR.doc)}
											<br />
											{agent.isChatEnabled ? (
												leadR.conversation ? (
													<Button
														icon="whatsapp"
														color={
															leadR.conversation
																?.last_message_from ===
															"client"
																? "red"
																: "green"
														}
														onClick={() =>
															navigate(
																`/chatCRM/${leadR.conversation?._id.substring(
																	leadR
																		.conversation
																		?._id
																		.length -
																		10
																)}`
															)
														}
													/>
												) : null
											) : (
												<Button
													icon="whatsapp"
													color="green"
													href={generateWaLink(
														leadR.doc.phone
													)}
													target="_blank"
												/>
											)}
											<br />
											<Rating
												size="huge"
												maxRating={1}
												rating={
													leadR.doc.isFavorite ? 1 : 0
												}
												onRate={(e, data) => {
													console.log("RATE", data);
													update(leadR.doc, {
														isFavorite:
															!leadR.doc
																.isFavorite &&
															data.rating === 1,
													});
												}}
												icon="star"
											/>
										</Table.Cell>
										{!isAgent ? (
											<Table.Cell textAlign="center">
												{getAgentName(leadR.doc.agent)}
											</Table.Cell>
										) : null}
										<Table.Cell textAlign="center">
											<LeadCard lead={leadR.doc} />
										</Table.Cell>
										<Table.Cell textAlign="center">
											{parseDate(leadR.doc.createdAt!)}
										</Table.Cell>
										{agent.bot_id === namespace_2 ? (
											<Table.Cell textAlign="center">
												{parseDate(
													leadR.doc.updatedAt!
												)}
											</Table.Cell>
										) : null}
										{agent.bot_id !== namespace_2 ? (
											<Table.Cell textAlign="center">
												{leadR.doc.creditos_activos ??
													"SIN VALIDAR"}
											</Table.Cell>
										) : null}
										{agent.bot_id !== namespace_2 ? (
											<Table.Cell textAlign="center">
												{leadR.doc.total_descuentos ??
													"SIN VALIDAR"}
											</Table.Cell>
										) : null}
										{agent.bot_id !== namespace_2 ? (
											<Table.Cell textAlign="center">
												{leadR.doc.type_producto ??
													"SIN VALIDAR"}
											</Table.Cell>
										) : null}
										{agent.bot_id !== namespace_2 ? (
											<Table.Cell textAlign="center">
												<Input
													className="amount-input"
													label="$"
													placeholder={
														leadR.doc.amount
													}
													type="number"
													min={0}
													action={
														<Button
															color="green"
															onClick={() => {
																updateAmount(
																	leadR.doc,
																	amount
																);
															}}
														>
															<Icon name="save" />
														</Button>
													}
													onChange={(e) =>
														setAmount(
															Number(
																e.currentTarget
																	.value
															)
														)
													}
												/>
											</Table.Cell>
										) : null}
										<Table.Cell textAlign="center">
											<Dropdown
												button
												className="icon w100"
												placeholder="Seleccione el estatus del cliente"
												floating
												labeled
												options={
													isAgent
														? agentStatus
														: status
												}
												value={leadR.doc.agentStatus}
												onChange={(e, { value }) => {
													updateStatus(
														leadR.doc,
														value as string
													);
												}}
												loading={loadingLead}
												disabled={loadingLead}
											/>
											<br />
											<Input
												action={{
													color: "blue",
													content:
														"Último seguimiento",
												}}
												className="w100"
												actionPosition="left"
												value={leadR.doc.ultimoSeguimiento?.slice(
													0,
													10
												)}
												onChange={(e, data) => {
													updateSeguimiento(
														leadR.doc.wa_id ??
															leadR.doc.Fid!,
														agent.id!,
														data.value
													);
												}}
												max={
													new Date()
														.toISOString()
														.split("T")[0]
												}
												type="date"
											/>
											<br />
											<Input
												action={{
													color: getProximoSeguimientoColor(
														leadR.doc
															.proximoSeguimiento!
													),
													content:
														"Próximo seguimiento",
												}}
												className="w100"
												actionPosition="left"
												value={leadR.doc.proximoSeguimiento?.slice(
													0,
													10
												)}
												onChange={(e, data) => {
													updateProximoSeguimiento(
														leadR.doc,
														data.value
													);
												}}
												min={
													new Date()
														.toISOString()
														.split("T")[0]
												}
												type="date"
											/>
										</Table.Cell>
										<Table.Cell textAlign="center">
											<Button
												color="green"
												onClick={() => {
													navigate(
														`/customers/actions/${
															leadR.doc.wa_id ??
															leadR.doc.Fid
														}`
													);
													setUser(leadR.doc);
												}}
											>
												ACCIONES
											</Button>
										</Table.Cell>
									</Table.Row>
								);
						  })
						: null}
					<Table.Row>
						<Table.Cell colSpan="16">{pagination()}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</div>
	);
};

export default LeadsCreditos;
