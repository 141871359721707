import React from 'react'

const PrivaceAdvice = () => {
    return(
        <section className="wthree-row py-lg-5" id="process">
            <div className="container py-5">
                <div className="title-wthree text-center py-lg-5">
                    <h3 className="agile-title">
                        Aviso de privacidad
                    </h3>
                    <span></span>
                    <p className="mt-md-5 mb-3 mt-3" style={{textAlign: "justify"}}>
                        “MANALI MÉXICO” Sociedad Anónima Promotora de Inversión de Capital Variable, con domicilio en Avenida Las Américas número  1701 interior 39 y 40 del Fraccionamiento Valle Dorado, Aguascalientes, Aguascalientes, es el responsable del uso y protección de sus datos personales, y al respecto le informamos que de conformidad con lo establecido en los artículos 2, 6, 7, 8, 9, 11, 12, 14, 15, 16, 17, 19, 29, 36 y demás relativos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, los datos personales que recabamos de usted, los utilizaremos para el desarrollo y ejecución del programa, servicio y/o actividad que solicita.
                        <br/>
                        El propósito de recabar, procesar y almacenar sus datos personales por parte de MANALI MÉXICO comprende las siguientes finalidades:
                        <br/>
                        <ul style={{ marginLeft: "80px"}}>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Dar a conocer y promover los servicios, productos y promociones que manejamos. 
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Dar a conocer y promover la participación en programas y actividades que organizan Instituciones a las que se encuentra afiliado MANALI MÉXICO.
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Contactar y localizar a las personas que muestren interés en nuestros servicios y/o participar en los programas y demás actividades que organizan Instituciones a las que se encuentra afiliado MANALI MÉXICO, así mismo atender a las solicitudes de información sobre los mismos. 
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Entregar comprobantes fiscales por concepto de compra de nuestros servicios y/o productos.
                            </li>
                        </ul>
                    </p>
                    <p className="mt-md-5 mb-3 mt-3" style={{textAlign: "justify"}}>
                        Los datos personales que MANALI MÉXICO recaba de forma directa, por escrito, vía telefónica y a través de medios electrónicos tales como correo electrónico y página de internet, para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, son los siguientes:
                        <br/>
                        <ul style={{marginLeft: "80px"}}>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Nombre
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Teléfono(s)
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Correo electrónico
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Servicio contratado
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                •	Datos para facturación
                            </li>
                        </ul>
                    </p>
                    <p className="mt-md-5 mb-3 mt-3" style={{textAlign: "justify"}}>
                        Todos los datos personales proporcionados serán registrados en medios físicos y/o electrónicos. La información personal que usted proporciona se almacena en bases de datos controladas y con acceso limitado, a efecto de evitar un tratamiento indebido de los mismos. De igual modo, MANALI MÉXICO utiliza medidas de seguridad técnica y de organización, a fin de proteger sus datos, de manipulación casual o deliberada, pérdida, destrucción o acceso por personas no autorizadas, para evitar el mal uso de sus datos personales por terceros.
                        <br/>
                        En virtud de que usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso), le informamos que, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO (acceso, rectificación, cancelación u oposición). Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, usted podrá contactarnos en el siguiente número telefónico (449) 997-6726, o enviarnos un correo electrónico a contacto@manali.com.mx, para ponerse en contacto con la responsable de la privacidad de datos de Mi Negocio en Web, que dará trámite a las solicitudes para el ejercicio de estos derechos, y atenderá cualquier duda que pudiera tener respecto al tratamiento de su información.
                        <br/>
                        Para ejercer los derechos ARCO usted deberá presentar la solicitud respectiva por escrito mediante correo electrónico dirigido a la responsable de la privacidad de datos de MANALI MÉXICO en Web. La solicitud deberá contener y acompañar: 
                        <br/>
                        <ul style={{marginLeft: "80px"}}>
                            <li style={{color: "black", textAlign: "left"}}>
                                a) nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud. 
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                b) documentos que acrediten la identidad o, en su caso, la representación legal del titular.
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                c) descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.
                            </li>
                            <li style={{color: "black", textAlign: "left"}}>
                                d) cualquier otro elemento o documento que facilite la localización de los datos personales.
                            </li>
                        </ul>
                    </p>
                    <p className="mt-md-5 mb-3 mt-3" style={{textAlign: "justify"}}>
                        MANALI MÉXICO se compromete a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, a través de la publicación del mismo en la página de internet www.manali.com.mx o en caso de obtener los datos de forma personal y directa, será facilitado en el momento en que sean obtenidos los datos, a través de los formatos impresos respectivos. 
                        <br/>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PrivaceAdvice;