import React, { useState, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Grid, GridRow, Icon } from "semantic-ui-react";
import { getMessagesByConversationId as getMessages } from "../../../../actions/messages.actions";
import useChat from "../../../../context/chatContext";
import IMessageResponse from "../../../../models/IMessage.model";
import IMessageModel from "../../../../models/Message.model";
import { RootState } from "../../../../store/store";

const NEW_CHAT_MESSAGE_EVENT = "NEW_MESSAGE_CRM";
const NEW_NOTIF_EVENT = "EVENT_NOTIF";

const Conversation: React.FC<IConversationProps> = ({
    conversation,
    messages,
    loading,
    getMessages,
    user,
}) => {
    const loadMessages = async () => {
        let CId: string | null = localStorage.getItem('conversationId');
        if(conversation || CId){
            await getMessages(conversation ? conversation.id : CId!, user.id)
        }
    }

    useEffect(() => {
        loadMessages()
    }, [conversation]);

    useEffect(() => {
        if(!loading){
            scrollDown(conversationComponent.current!);
        }
    }, [loading, messages]);

    let useWebhook = useChat(user.id);

    const conversationComponent = useRef(null);

useEffect(() => {
        useWebhook.socketRef.on(NEW_CHAT_MESSAGE_EVENT, (message: any) => {
            loadMessages()
            if(message.isOwn === false){
                if(!("Notification" in window)) {
                    alert("El navegador no es compatible con las notificaciones de escritorio.");
                } else if (Notification.permission === "granted") {
                    let title: string = message.userId
                    let jsonMessage = JSON.parse(message.message);
                    let options: NotificationOptions = {
                        body: jsonMessage.text.body,
                        icon: 'https://i.ibb.co/Sx4zyJ2/188581995-2575339352775149-8665990075880131609-n.jpg',
                        dir: 'auto',
                        renotify: false,
                        lang: "ES"
                    }
                    let notification = new Notification(title, options);
                    let audio = new Audio('https://www.quecodigo.com/web/antigua/sounds/notificacion.mp3')
                    audio.play();
                    setTimeout(notification.close.bind(notification), 10000);
    
                } else  if(Notification.permission !== 'denied') {
                    Notification.requestPermission(function (permission) {
                        if(permission === "granted") {
                            var notification = new Notification("Gracias!, Ahora podras recibir notificaciones.")
                            setTimeout(notification.close.bind(notification), 5000);
                        }
                    })
                }
            }
        });

        
        useWebhook.socketRef.on(NEW_NOTIF_EVENT, (message: any) => {
            if(!("Notification" in window)) {
                alert("El navegador no es compatible con las notificaciones de escritorio.");
            } else if (Notification.permission === "granted") {
                let title: string = message.message
                let options: NotificationOptions = {
                    body: message.nota,
                    icon: 'https://i.ibb.co/Sx4zyJ2/188581995-2575339352775149-8665990075880131609-n.jpg',
                    dir: 'auto',
                    lang: "ES"
                }
                let notification = new Notification(title, options);
                let audio = new Audio('https://www.quecodigo.com/web/antigua/sounds/notificacion.mp3')
                audio.play();
                setTimeout(notification.close.bind(notification), 10000);

            } else  if(Notification.permission !== 'denied') {
                Notification.requestPermission(function (permission) {
                    if(permission === "granted") {
                        var notification = new Notification("Gracias!, Ahora podras recibir notificaciones.")
                        setTimeout(notification.close.bind(notification), 5000);
                    }
                })
            }
        });
        
    }, [useWebhook.socketRef])

    const scrollDown = (element: any) => {
        element.scrollTop = element.scrollHeight;
    }
    const [newMessage, setNewMessage] = useState<IMessageModel>({
        destination: conversation ? conversation.userId : "",
        body: "",
        ownedByCurrentUser: false
    });

    const handleSendMessage = () => {
        useWebhook.sendMessage(newMessage);
        setNewMessage({
            conversation: conversation.id,
            destination: conversation ? conversation.userId : "",
            body: "",
            ownedByCurrentUser: false
        });
    };

    const handleNewMessageChange = (event: any) => {
        setNewMessage({
            ...newMessage,
            conversation: conversation.id,
            destination: conversation ? conversation.userId : "", 
            body: event.target.value,
            ownedByCurrentUser: true
        });
    };

    const setMediaMessage = (e: any) => {
        setNewMessage({
            conversation: conversation.id,
            destination: conversation ? conversation.userId : "",
            body: "",
            ownedByCurrentUser: true,
            file: e.currentTarget!.files[0]
        })
    }

    return (
        <Grid>
            <Grid.Row verticalAlign="top" stretched columns={3}>
                <Grid.Column computer={1}>
                    <Icon name="user" size="big"></Icon>
                </Grid.Column>
                <Grid.Column
                    computer={14}
                    style={{ height: "40px", maxHeight: "80px" }}
                >
                    <h3>{conversation ? conversation.userId : ""}</h3>
                </Grid.Column>
                <Grid.Column computer={1}>
                    <Icon className="pointer" name="search" size="big" />
                </Grid.Column>
            </Grid.Row>
            <div className="messages-list-container" ref={conversationComponent}>
            {Array.isArray(messages) ? messages.map((message: IMessageResponse) => {
                let msg = message.message;
                let textMessage: string = "";
                if(Array.isArray(message.message) ) {
                    msg = message.message[0];
                }
                if (message.type === "image") {
                    return (
                        <Grid.Row>
                            <Grid.Column>
                                <img
                                    src={msg.image.link}
                                    style={{
                                        float: !message.isOwn ? "left" : "right",
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        width: "300px",
                                    }}
                                ></img>
                            </Grid.Column>
                        </Grid.Row>
                    );
                } else if(message.type === "text") {
                    let jsonMessage = JSON.parse(msg);
                    if(jsonMessage.type === "button"){
                        textMessage = jsonMessage.button.text;
                    } else if(jsonMessage.type === "text") {
                        textMessage = jsonMessage.text.body;
                    }
                    return (
                        <Grid.Row
                            key={`row-${message.id!}`}
                        >
                            <Grid.Column
                                key={`col-${message.id!}`}
                            >
                                <h4
                                key={`h4-${message.id!}`}
                                style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    padding: "20px",
                                    color: "white",
                                    backgroundColor: "#423E3D",
                                    borderRadius: "10px",
                                    float: !message.isOwn ? "left" : "right",
                                    fontSize: "20px",
                                    width: "500px",
                                    
                                }}
                                >
                                {textMessage}
                                </h4>
                            </Grid.Column>
                        </Grid.Row>
                    );
                } else if(message.type === "payload") {
                    // let jsonMessage = JSON.parse(msg);
                    if(msg.type === "BUTTONS") {
                        // textMessage = msg.button.text;
                    } else if(msg.type === "BODY") {
                        textMessage = msg.text;
                        
                    } else if(msg.type === "HEADER") {
                        textMessage = "MENÚ";

                    } else {
                        let jsonMessage = JSON.parse(msg);
                        if(jsonMessage.type === "text") {
                            textMessage = jsonMessage.text.body;
                        } else if(jsonMessage.type === "image"){
                            
                            return (
                                <Grid.Row>
                                    <Grid.Column>
                                        <img
                                            src={jsonMessage.image.link}
                                            style={{
                                                float: !message.isOwn ? "left" : "right",
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                width: "300px",
                                            }}
                                        ></img>
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        }

                    }
                    return (
                        <Grid.Row>
                            <Grid.Column>
                                <h4
                                style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    padding: "20px",
                                    color: "white",
                                    backgroundColor: "#423E3D",
                                    borderRadius: "10px",
                                    float: !message.isOwn ? "left" : "right",
                                    fontSize: "20px",
                                    width: "500px",
                                }}
                                >
                                {textMessage}
                                </h4>
                            </Grid.Column>
                        </Grid.Row>
                    );
                } else if (message.type === "File") {
                    if(message.message.type === "image") {
                        return(
                            <Grid.Row>
                                <Grid.Column>
                                    <img
                                        src={message.message.image.awsURL}
                                        style={{
                                        float: !message.isOwn ? "left" : "right",
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        width: "300px",
                                        borderRadius: "5px",
                                        }}
                                    ></img>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    } else if(message.message.type === 'document') {
                        let urlArray: string = message.message.document.awsURL ? message.message.document.awsURL : message.message.document.awsURl.replace('/', "");
                        let nameFile: string[] = urlArray.split('.');
                        let type: string = nameFile[4];
                        return (
                            <Grid.Row>
                                <Grid.Column>
                                    <div
                                        style={{
                                            marginTop: "5px",
                                            marginBottom: "5px",
                                            padding: "20px",
                                            color: "white",
                                            backgroundColor: "#423E3D",
                                            borderRadius: "10px",
                                            float: !message.isOwn ? "left" : "right",
                                            fontSize: "20px",
                                            width: "300px"
                                        }}
                                    >
                                        <a
                                            href={message.message.document.awsURL ? message.message.document.awsURL : message.message.document.awsURl}
                                            download={message.message.document.awsURL ? message.message.document.awsURL : message.message.document.awsURl}
                                            style={{color: "inherit"}}
                                       >
                                            <Grid>
                                                <Grid.Row columns={2} divided>
                                                    <Grid.Column computer={13}>
                                                        <p style={{color: "white"}}>{`Documento.${type}`}</p>
                                                    </Grid.Column>
                                                    <Grid.Column computer={3}>
                                                        <Icon
                                                            name="arrow alternate circle down outline"
                                                            size="large"
                                                            color="grey"
                                                            style={{paddingBottom: 20}}
                                                        >
                                                        </Icon>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </a>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    }
                    return(
                        <Grid.Row  >
                            <Grid.Column>
                                <h4
                                style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    padding: "20px",
                                    color: "white",
                                    backgroundColor: "#423E3D",
                                    borderRadius: "10px",
                                    float: !message.isOwn ? "left" : "right",
                                    fontSize: "20px",
                                    width: "500px",
                                }}
                                >File</h4>
                            </Grid.Column>
                        </Grid.Row >
                    )
                }
            }) : ""}
            </div>
            <Grid.Row verticalAlign="bottom" stretched columns={3}>
                <input
                    className="hidden-file-input"
                    id="input_File"
                    type="file"
                    style={{visibility: 'hidden'}}
                    onChange={(e) => setMediaMessage(e)} 
                />
                <Grid.Column computer={1}>
                    <Icon name="file" size="big" onClick={() => document.getElementById("input_File")!.click()} className="pointer"></Icon>
                </Grid.Column>
                <Grid.Column
                    computer={14}
                    style={{ height: "40px", maxHeight: "80px" }}
                >
                    <textarea
                    value={newMessage.file? newMessage.file.name : newMessage.body}
                    onChange={handleNewMessageChange}
                    placeholder="Escribe un mensaje..."
                    className="new-message-input-field"
                    />
                </Grid.Column>
                <Grid.Column computer={1}>
                    <Icon
                    className="pointer"
                    name="send"
                    size="big"
                    onClick={handleSendMessage}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

interface IProps {}

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState, props: IProps) => {
  return {
    authenticated: state.users.authenticated,
    conversation: state.conversation.conversation,
    messages: state.message.messages,
    loading: state.message.loading,
    user: state.users.user,
    loadingUser: state.users.loading
  };
};

const mapActionsToProps = {
    getMessages
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IConversationProps = ConnectedProps<typeof connector>;

export default connector(Conversation);
