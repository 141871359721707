export const idsCreditosOrigins = [
    'e9a9ebd6_6ae8_461f_8586_3f409d472e41', // NAME_SPACE
    '110801437308609','850afaa6_8e93_4dce_beef_66987dc63b60', // MACRO_NAME_SPACE_2
    '951a64e2_030c_4410_a60b_8682d13c8309', // MACRO_NAME_SPACE
    '273509956349152', // pageId
    '1926291067606399',
    '393425384464181',
    '505950049815439',
    '620812891667457',
    '81405584089867',
]

export const idsDistOrigins = [
    'cde31b48_79aa_40f4_a8c0_b6ab37cf68f6', // NAME_SPACE_2
    '1440189006290195', // page_dist
]

export const idsDistFBPages = [
    "1440189006290195"
];

export const idsCreditosFBPages = [
    '273509956349152', // FB EL PRÉSTAMO DEL MES
    '110801437308609',
    '1926291067606399',
    '393425384464181',
    '505950049815439',
    '620812891667457',
    '81405584089867'
];

export const idsCreditosWhatsapp = [
    'e9a9ebd6_6ae8_461f_8586_3f409d472e41' // NAME_SPACE
]

export const idsDistWhatsapp = [
    'cde31b48_79aa_40f4_a8c0_b6ab37cf68f6', // NAME_SPACE_2
]

export const idsCreditosMacro = [
    '850afaa6_8e93_4dce_beef_66987dc63b60', // MACRO_NAME_SPACE_2
    '951a64e2_030c_4410_a60b_8682d13c8309', // MACRO_NAME_SPACE
]

export const idsDistMacro = [
    
]

export const idsCreditosBlaster = [
    'blaster'
]

export const idsDistBlaster = [
    'blaster'
]