import moment from "moment";

export const ClientMessage = ({
    item,
    setModalPreview,
    setImagePreview,
}: any) => {
    const metadata = item?.metadata ? true : false;
    const oldMessages = item?.metadata?.display_phone_number.includes(
        "951a64e2_030c_4410_a60b_8682d13c8309" // namespace de 360dialog
    );

    return (
        <div
            className={
                !metadata || oldMessages
                    ? "chatcrm-client-message-360"
                    : "chatcrm-client-message-meta"
            }
        >
            {item.type === "text" || item.type === "template" ? (
                <p>{item.body.text.replace(/\*/g, "")}</p>
            ) : item.type === "voice" ? (
                <audio controls src={item.body.voice.uri} />
            ) : item.type === "document" ? (
                <a href={item.body.document.uri} target="_blank">
                    {item.body.text}
                </a>
            ) : item.type === "audio" ? (
                <audio controls src={item.body.audio.uri} />
            ) : item.type === "image" && !!item.body.text ? (
                <>
                    <img
                        src={item.body.image.uri}
                        onClick={() => {
                            setImagePreview(item);
                            setModalPreview(true);
                        }}
                    />
                    <p>{item.body.text}</p>
                </>
            ) : (
                <img
                    src={item.body.image.uri}
                    onClick={() => {
                        setImagePreview(item);
                        setModalPreview(true);
                    }}
                />
            )}
            <div className="chatcrm-client-message-date">
                <p>{moment(item.createdAt).format("DD-MM-YYYY / h:mm A")}</p>
            </div>
        </div>
    );
};
