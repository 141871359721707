import React, { useEffect } from "react";
import { Grid, Loader } from "semantic-ui-react";
import ISolicitudCredito from "../../../models/solicitud_credito.model";
import { SolicitudComponent } from "./components/solicitud.component";
import { ConnectedProps, connect } from "react-redux";
import { create, getByExpedienteId } from "../../../actions/SolicitudCredito";
import { solicitudCredito } from "../../../initialStates";
import { useParams } from "react-router";
import { RootState } from "../../../store/store";
import { PropmtCreate } from "./components/prompt.create";

const Solicitudes: React.FC<IProps> = ({
	isAgent,
	agent,
	profile,
	expediente,
	solicitudes,
	solicitud,
	loading,
	loadingSolicitud,
	getByExpedienteId,
	create,
}) => {
	const { id } = useParams();

	useEffect(() => {
		if (expediente) {
			console.log("SOLICITUDES CAMBIO", expediente, solicitud);

			getByExpedienteId(expediente.id);
		}
	}, [expediente, solicitud]);

	const createSolicitud = () => {
		const solicitud: ISolicitudCredito = {
			...solicitudCredito,
			leadId: id!,
			agentId: isAgent ? profile.id! : agent.id!,
			expedienteId: expediente.id!,
		};

		const created = create(solicitud);

		if (!created) return;
	};

	return (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<PropmtCreate createSolicitud={createSolicitud} />
				</Grid.Column>
			</Grid.Row>
			{!loading ? (
				<Grid.Row columns={1}>
					{solicitudes?.map((solicitud) => {
						return (
							<Grid.Column>
								<SolicitudComponent
									solicitud={solicitud}
									isAgent={isAgent}
									loading={loadingSolicitud}
								/>
							</Grid.Column>
						);
					})}
				</Grid.Row>
			) : (
				<Loader active={loading}>Cargando...</Loader>
			)}
		</Grid>
	);
};

const mapStateToProps = (state: RootState) => {
	return {
		user: state.users.user,
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		expediente: state.expedientesReducer.expediente,
		solicitudes: state.solicitudes.solicitudes,
		solicitud: state.solicitudes.solicitud,
		loading: state.solicitudes.loading,
		loadingSolicitud: state.solicitudes.loadingSolicitud,
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	create,
	getByExpedienteId,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Solicitudes);
