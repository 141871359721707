import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Button, Icon } from 'semantic-ui-react';
import CotizadorTradicional from './cotizador_tradicional.component';
import CotizadorRenovacion from './cotizador_renovacion.component';
import CotizadorCompra from './cotizador_compra.component';
import CustomerDetailComponent from './customer_detail.cotization';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/store';
import html2canvas from 'html2canvas';

interface IState {
	capitalAmount: number;
    taza: number;
    oldTaza: number;
    amount: number;
	payment: number;
	amount_2: number;
	payment_2: number;
	amount_3: number;
	payment_3: number;
}

const Cotizador: React.FC<IProps> = ({
	customer,
	loading
}) => {

	const { type } = useParams();
	const navigate = useNavigate();

    useEffect(() => {

    }, [])

	const [state, setState] = useState<IState>({
        capitalAmount: 0,
        taza: 0,
        oldTaza: 0,
		amount: 0,
		payment: 0,
        amount_2: 0,
        payment_2: 0,
        amount_3: 0,
        payment_3: 0,
	});

	const handleField = (field: string, value: any) => {
		let {
			amount,
            capitalAmount,
            taza,
            oldTaza,
            payment,
            amount_2,
            payment_2,
            amount_3,
            payment_3,
		} = state;

		switch (field) {
            case 'capitalAmount':
                capitalAmount = value;
                break;
            case 'amount':
                amount = value;
                break;
            case 'taza':
                taza = value;
                break;
            case 'oldTaza':
                oldTaza = value;
                break;
            case 'payment':
                payment = value;
                break;
            case 'amount_2':
                amount_2 = value;
                break;
            case 'payment_2':
                payment_2 = value;
                break;
            case 'amount_3':
                amount_3 = value;
                break;
            case 'payment_3':
                payment_3 = value;
                break;
            default:
                break;
        }

		setState({
            amount,
            capitalAmount,
            taza,
            oldTaza,
            payment,
            amount_2,
            payment_2,
            amount_3,
            payment_3,
        });
	}

    const elementToCanvas = async (isCopy: boolean) => {
        let id = '';
        let fileName = '';

        switch(type){
            case 'tradicional':
                id = 'cotizador-tradicional-01';
                fileName = 'CotizadorTradicional.png';
                break;
            case 'renovacion':
                id = 'cotizador-renovacion-01';
                fileName = 'CotizadorRenovación.png';
                break;
            case 'compra':
                id = 'cotizador-compra-01';
                fileName = 'CotizadorCompra.png';
                break;
            default:
                return
        }

        let element:any = document.getElementById(id);

        console.log("ELEMENT", element);

        const canvas = await html2canvas(element, {
            foreignObjectRendering: true,
            allowTaint: true,
            useCORS: true,
            logging: true,
            width: 900,
            height: 900,
            scrollX: 0,
            scrollY: 0,
            scale: 1,
        });

        console.log("CANVAS", canvas);
            
        canvas.style.width = '900px';
        canvas.style.height = '900px';

        // Dibujamos el contenido del elemento en el canvas
        let ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(canvas, 0, 0);
        }

        let image = canvas.toDataURL('png', 1)

        if(isCopy) {
            await navigator.clipboard.write([
                new ClipboardItem({ 
                    'image/png': await fetch(image).then((r) => r.blob())
                })
            ]);
            return;
        }

        console.log("image", image);
        // Creamos un enlace para descargar la imagen
        let link = document.createElement('a');
        link.download = fileName;

        // Convertimos el canvas en una imagen base64 y establecemos el enlace como href
        link.href = image;
    
        // Disparamos el evento click del enlace para descargar la imagen
        link.click();
    }
    
	const buildCotizador = () => {
		switch (type) {
            case 'tradicional':
                return <
                    CotizadorTradicional
                        amount={state.amount}
                        payment={state.payment}
                        amount_2={state.amount_2}
                        payment_2={state.payment_2}
                        amount_3={state.amount_3}
                        payment_3={state.payment_3}
                        handleField={handleField} 
                    />;
			case 'renovacion':
                return <
                    CotizadorRenovacion
                        capitalAmount={state.capitalAmount}
                        payment={state.payment}
                        handleField={handleField}
                    />;
			case 'compra':
                return <
                    CotizadorCompra
                        capitalAmount={state.capitalAmount}
                        taza={state.taza}
                        oldTaza={state.oldTaza}
                        handleField={handleField} 
                    />;
            default:
                return <></>;
        }
	}

	return (
		<Grid>
			<Grid.Row columns={1}>
                {customer ?
                    <Grid.Column>
                        <CustomerDetailComponent customer={customer}/>
                    </Grid.Column>
                : null}
			</Grid.Row>
            <Grid.Row columns={1}>
				<Grid.Column
                    verticalAlign='middle'
                    textAlign='center'
                >
                    <Button
                        color="blue"
                        onClick={() => elementToCanvas(true)}
                        style={{ marginBottom: '10px' }}
                    >
                        Copiar al portapapeles
                    </Button>
                    <Button
                        color="blue"
                        style={{ marginBottom: '10px' }}
                        onClick={() => elementToCanvas(false)}
                    >
                        Descargar
                    </Button>
                    {customer ?
                        <Button color="green">
                            REGISTRAR
                        </Button>
                    : null}

				</Grid.Column>
			</Grid.Row>
            <Grid.Row columns={3}
                style={{ 
                    overflowX: 'auto', 
                    padding: '40px'
                }}
            >
                <Grid.Column computer={3}></Grid.Column>
				<Grid.Column
                    computer={10}
                    mobile={16}
                    textAlign='center'
                    style={{ 
                        overflowX: 'auto', 
                        padding: '40px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
					{buildCotizador()}
				</Grid.Column>
                <Grid.Column computer={3}></Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

const mapStateToProps = (state: RootState) => {
	return {
		customer: state.adminUsers.user,
		loading: state.adminUsers.loading
	}
}

const mapActionsToProps = {
	
}

const connector = connect(mapStateToProps, mapActionsToProps)

type IProps = ConnectedProps<typeof connector>;

export default connector(Cotizador);