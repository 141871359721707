export const NUEVO = "NUEVO";
export const SEGUIMIENTO = "SEGUIMIENTO";
export const COTIZACION = "COTIZACION";
export const NEGOCIACION = "NEGOCIACION";

export const TRAMITE = "TRAMITE";
export const OBSERVACIONES = "OBSERVACIONES";

export const CERRADO = "CERRADO";
export const NO_APLICA = "NO_APLICA";
export const NO_INTERESA = "NO_INTERESA";

export const agentStatus = [
	{
		key: "NUEVO",
		text: " NUEVO",
		value: "NUEVO",
	},
	{
		key: "SEGUIMIENTO",
		text: " SEGUIMIENTO",
		value: "SEGUIMIENTO",
	},
	{
		key: "COTIZACION",
		text: " COTIZACIÓN",
		value: "COTIZACION",
	},
	{
		key: "NEGOCIACION",
		text: " NEGOCIACIÓN",
		value: "NEGOCIACION",
	},
	{
		key: "NO_APLICA",
		text: " NO APLICA",
		value: "NO_APLICA",
	},
	{
		key: "NO_INTERESA",
		text: " NO INTERESA",
		value: "NO_INTERESA",
	},
	{
		key: "CERRADO",
		text: "CERRADO",
		value: "CERRADO",
	},
];

export const status = [
	{
		key: "NUEVO",
		text: " NUEVO",
		value: "NUEVO",
	},
	{
		key: "SEGUIMIENTO",
		text: " SEGUIMIENTO",
		value: "SEGUIMIENTO",
	},
	{
		key: "COTIZACION",
		text: " COTIZACIÓN",
		value: "COTIZACION",
	},
	{
		key: "NEGOCIACION",
		text: " NEGOCIACIÓN",
		value: "NEGOCIACION",
	},
	{
		key: "TRAMITE",
		text: " TRÁMITE",
		value: "TRAMITE",
	},
	{
		key: "OBSERVACIONES",
		text: " OBSERVACIONES",
		value: "OBSERVACIONES",
	},
	{
		key: "CERRADO",
		text: " CERRADO",
		value: "CERRADO",
	},
	{
		key: "NO_APLICA",
		text: " NO APLICA",
		value: "NO_APLICA",
	},
	{
		key: "NO_INTERESA",
		text: " NO INTERESA",
		value: "NO_INTERESA",
	},
];
