import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
	Grid,
	Table,
	Button,
	Pagination,
	Header,
	Loader,
} from "semantic-ui-react";
import { RootState } from "../../../store/store";
import {
	getAll,
	setPage,
	setSolicitudData,
} from "../../../actions/SolicitudCredito";
import { useNavigate } from "react-router-dom";
import IExpedienteCRM from "../../../models/ExpedienteCRM.model";
import Filters from "./components/solicitudes.filters";

const Expedientes: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	loading,
	errors,
	solicitudes,
	page,
	total,
	getAll,
	setPage,
	setSolicitudData,
}) => {
	const navigate = useNavigate();
	const paginate = () => {
		return (
			<Pagination
				activePage={page}
				totalPages={Math.trunc(total / 15) + 1}
				onPageChange={(event, data) => {
					console.log("PAGE", data.activePage);
					setPage(Number(data.activePage));
				}}
			/>
		);
	};

	return (
		<Grid>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Header>
						<Header.Content>
							Expedientes
							{profile.id}
						</Header.Content>
					</Header>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>
					<Filters
						getAll={getAll}
						setPage={setPage}
						isAgent={isAgent}
						agent={agent}
						profile={profile}
						page={page}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
			{!loading ? (
				<Grid.Row columns={1}>
					<Grid.Column>
						<Table>
							<Table.Header>
								<Table.HeaderCell>ID</Table.HeaderCell>
								<Table.HeaderCell>
									ID EXPEDIENTE
								</Table.HeaderCell>
								<Table.HeaderCell>FOLIO</Table.HeaderCell>
								<Table.HeaderCell>MONTO</Table.HeaderCell>
								<Table.HeaderCell>PAGOS</Table.HeaderCell>
								<Table.HeaderCell>DESCUENTO</Table.HeaderCell>
								<Table.HeaderCell>ESTATUS</Table.HeaderCell>
								<Table.HeaderCell>ACCIONES</Table.HeaderCell>
							</Table.Header>
							<Table.Body>
								{solicitudes?.map(
									(solicitud, index: number) => {
										return (
											<Table.Row
												key={`${index}-${solicitud.id}`}
												columns={1}
											>
												<Table.Cell>
													{solicitud.id}
												</Table.Cell>
												<Table.Cell>
													{solicitud.expedienteId}
												</Table.Cell>
												<Table.Cell>
													{solicitud.form.folio.value}
												</Table.Cell>
												<Table.Cell>
													{
														solicitud.form
															.monto_credito.value
													}
												</Table.Cell>
												<Table.Cell>
													{
														solicitud.form
															.numero_pagos.value
													}
												</Table.Cell>
												<Table.Cell>
													{
														solicitud.form
															.descuento_mensual
															.value
													}
												</Table.Cell>
												<Table.Cell>
													{
														solicitud.form.status
															.value
													}
												</Table.Cell>

												<Table.Cell>
													<Button
														color="blue"
														onClick={() => {
															setSolicitudData(
																solicitud
															);
															navigate(
																`/expediente/${solicitud.agentId}/${solicitud.leadId}`
															);
														}}
													>
														Administrar
													</Button>
												</Table.Cell>
											</Table.Row>
										);
									}
								)}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.Cell>{errors}</Table.Cell>
								</Table.Row>
							</Table.Footer>
						</Table>
					</Grid.Column>
				</Grid.Row>
			) : (
				<Grid.Row>
					<Loader active={loading}>Cargando...</Loader>
				</Grid.Row>
			)}
			;
			<Grid.Row columns={1}>
				<Grid.Column>{paginate()}</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		solicitudes: state.solicitudes.solicitudes,
		page: state.solicitudes.page,
		total: state.solicitudes.counter,
		errors: state.solicitudes.errors,
		loading: state.solicitudes.loading,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {
	getAll,
	setSolicitudData,
	setPage,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(Expedientes);
