import axios from "axios";
import {
    SET_FRANCHISE,
    SET_FRANCHISES,
    SET_LOADING_FRANCHISE,
    SET_LOADING_FRANCHISES,
} from "../constants/franchise.constants";
import { apiUrl } from "../constants/enviroment";

export const getFranchiseById = (agentId: any) => async (dispatch: any) => {
    try {
        const res = await axios.get(
            `${apiUrl}/franchise/getFranchise/${agentId}`
        );
        const franchise = res.data.franchise;
        dispatch(setFranchise(franchise));
    } catch (error: any) {
        console.log(
            `ERROR AL CARGAR LOS DATOS DE LA FRANQUICIA`,
            error.res.data.message
        );
    }
};

export const updateFranchise =
    (franchiseData: any) => async (dispatch: any) => {
        try {
            const res = await axios.put(
                `${apiUrl}/franchise/upsertFranchise`,
                franchiseData
            );
        } catch (error: any) {
            console.log(
                `ERROR AL ACTUALIZAR LOS DATOS DE LA FRANQUICIA`,
                error.res.data.message
            );
        }
    };

export const setFranchise = (franchise: any) => async (dispatch: any) => {
    await dispatch({
        type: SET_FRANCHISE,
        payload: franchise,
    });
};
