import axios from "axios";
import {
    SET_NOTIFICATIONS,
    SET_LOADING_NOTIFICATIONS,
    SET_NOTIFICATION,
    SET_PAGE_NOTIFICATIONS,
    SET_TOTALPAGES_NOTIFICATIONS,
    SET_COUNT_NOTIFICATIONS,
} from "../constants/notifications.constants";
import { notificationCRM } from "../constants/enviroment";
import { INotification } from "../models/INotificationCRM";

export const getNotificationsById =
    (id: any, page: number, notifications: INotification[]) =>
    async (dispatch: any) => {
        const data = {
            agent_id: id,
            page: page,
        };
        dispatch(setLoading());

        try {
            const res = await axios.post(
                `${notificationCRM}/chatCRM/getNotifications`,
                data
            );
            const { docs, totalPages, count } = res.data.notifications;
            dispatch(setNotifications(notifications.concat(docs)));
            dispatch(setTotalPages(totalPages));
            dispatch(setCount(count));
        } catch (error: any) {
            console.log(
                `ERROR AL CARGAR LAS NOTIFICACIONES: ${id}`,
                error.res.data.message
            );
        }
    };

export const handleNotification = async (id: any) => {
    await axios
        .put(`${notificationCRM}/chatCRM/updateNotifications/${id}`)
        .then((res) => {
            console.log("Update Notification", res);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const setNotification =
    (notifications: INotification) => async (dispatch: any) => {
        await dispatch({
            type: SET_NOTIFICATION,
            payload: notifications,
        });
    };

export const setNotifications =
    (notifications: INotification[]) => async (dispatch: any) => {
        await dispatch({
            type: SET_NOTIFICATIONS,
            payload: notifications,
        });
    };

export const setPage = (page: number) => async (dispatch: any) => {
    await dispatch({
        type: SET_PAGE_NOTIFICATIONS,
        payload: page,
    });
};

export const setTotalPages = (totalPages: number) => async (dispatch: any) => {
    await dispatch({
        type: SET_TOTALPAGES_NOTIFICATIONS,
        payload: totalPages,
    });
};

export const setCount = (count: number) => async (dispatch: any) => {
    await dispatch({
        type: SET_COUNT_NOTIFICATIONS,
        payload: count,
    });
};

export const setLoading = () => (dispatch: any) => {
    dispatch({
        type: SET_LOADING_NOTIFICATIONS,
    });
};
