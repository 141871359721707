import {
	SET_USERS,
	SET_USERS_CONVERSATION,
	LOADING_ADMIN_USER,
	SET_LOADING_ERRORS_ADMIN_USER,
	LOADING_UPDATE_ADMIN_USER,
	LOADING_EXPEDIENT_ADMIN_USER,
	LOADING_FOLLOWMENT_ADMIN_USER,
	CLEAR_LOADING_ERRORS_ADMIN_USER,
	SET_USER_BY_ID,
	SET_EXPEDIENT_ADMIN_USER,
	SET_COUNTER_ADMIN_USER,
	SET_PAGE_ADMIN_USER,
	SET_FOLLOWMENT_USERS,
	SET_FOLLOWMENT_USERS_CONVERSATION,
	SET_FOLLOWMENT_PAGE_ADMIN_USER,
	SET_FOLLOWMENT_COUNTER_ADMIN_USER,
	SET_ACTIONS_OPEN,
	LOADING_ADMIN_USERS,
	SET_USER_FILTERS,
} from "../constants/user-admin.constants";
import IExpedienteModel from "../models/IExpediente.model";
import ILeadsResponse from "../models/ILeadsResponse.model";

import { IUserModel } from "../models/User.model";

const initialState = {
	loading: false,
	loadingUpdate: false,
	loadingExpedient: false,
	loadingFollowmentUsers: false,
	users: [] as ReadonlyArray<IUserModel>,
	usersConversation: [] as ReadonlyArray<ILeadsResponse>,
	followmentUsers: [] as ReadonlyArray<IUserModel>,
	followumentUsersConversation: [] as ReadonlyArray<ILeadsResponse>,
	user: {} as Readonly<IUserModel>,
	filters: { hide_users: true } as Readonly<any>,
	errors: "",
	counter: 10,
	followmentCounter: 10,
	page: 1,
	followmentPage: 1,
	expedient: {} as Readonly<IExpedienteModel>,
	isActionsOpen: false,
};

export type UserState = Readonly<typeof initialState>;

const userAdminReducer = (
	state: UserState = initialState,
	action: any
): UserState => {
	switch (action.type) {
		case SET_USERS_CONVERSATION:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				usersConversation: action.payload,
			};
		case SET_USERS:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				users: action.payload,
			};
		case SET_FOLLOWMENT_USERS_CONVERSATION:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				followumentUsersConversation: action.payload,
			};
		case SET_FOLLOWMENT_USERS:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				followmentUsers: action.payload,
			};
		case SET_USER_BY_ID:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				user: action.payload,
			};

		case SET_USER_FILTERS:
			return {
				...state,
				filters: action.payload,
			};
		case SET_PAGE_ADMIN_USER:
			return {
				...state,
				page: action.payload,
			};
		case SET_FOLLOWMENT_PAGE_ADMIN_USER:
			return {
				...state,
				followmentPage: action.payload,
			};
		case SET_COUNTER_ADMIN_USER:
			return {
				...state,
				counter: action.payload,
			};
		case SET_FOLLOWMENT_COUNTER_ADMIN_USER:
			return {
				...state,
				followmentCounter: action.payload,
			};
		case LOADING_ADMIN_USER:
			return {
				...state,
				errors: "",
				loadingUpdate: true,
			};
		case LOADING_ADMIN_USERS:
			return {
				...state,
				errors: "",
				loading: true,
			};
		case LOADING_FOLLOWMENT_ADMIN_USER:
			return {
				...state,
				errors: "",
				loadingFollowmentUsers: true,
			};
		case LOADING_UPDATE_ADMIN_USER:
			return {
				...state,
				errors: "",
				loadingUpdate: true,
			};
		case SET_LOADING_ERRORS_ADMIN_USER:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: action.payload,
			};
		case SET_EXPEDIENT_ADMIN_USER:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				expedient: action.payload,
			};
		case LOADING_EXPEDIENT_ADMIN_USER:
			return {
				...state,
				errors: "",
				loadingExpedient: true,
			};
		case CLEAR_LOADING_ERRORS_ADMIN_USER:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
			};
		case SET_ACTIONS_OPEN:
			return {
				...state,
				loading: false,
				loadingUpdate: false,
				loadingExpedient: false,
				loadingFollowmentUsers: false,
				errors: "",
				isActionsOpen: action.payload,
			};
		default:
			return state;
	}
};

export default userAdminReducer;
