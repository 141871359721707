import axios from "axios";
import { apiUrl } from "../../constants/enviroment";
import { setErrors, setLoading, setBiometricoData } from "./utils.actions";
import FormData from "form-data";
import IBiometrico from "../../models/Biometrico.model";

export const update =
	(id: string, biometrico: IBiometrico) => async (dispatch: any) => {
		let loaded = false;
		dispatch(setLoading());
		try {
			const body = {
				form: biometrico.form,
				documents: biometrico.documents,
			};

			await axios.put(`${apiUrl}/biometricos/${id}`, body).then((res) => {
				if (res.status !== 200) {
					console.warn(
						"No se ha podido editar el expediente",
						res.data.message
					);
					dispatch(setErrors(res.data.message));
					return loaded;
				}
				dispatch(setBiometricoData(res.data.biometrico));
			});

			loaded = true;
		} catch (e) {
			console.error("Error al editar el expediente", e);
		}
		return loaded;
	};

export const uploadFile =
	(id: string, documento: string, file: File) => async (dispatch: any) => {
		console.log("FILE", file);
		dispatch(setLoading());
		let updated = false;

		const formData = new FormData();

		formData.append("documento", documento);

		formData.append("name", file.name);

		const file_extension = file.name.split(".").pop();

		formData.append("file", file, `${documento}.${file_extension}`);

		const config = {
			headers: {
				Accept: "multipart/form-data",
				"Content-Type": "multipart/form-data;",
			},
		};

		await axios
			.post(`${apiUrl}/biometricos/file/${id}`, formData, config)
			.then((res) => {
				if (res.status !== 200) {
					console.log("ERROR", res.data.message);
					dispatch(setErrors(res.data.message));
					return updated;
				}
				dispatch(setBiometricoData(res.data.biometrico));
				updated = true;
			})
			.catch((err) => {
				console.error(err.response.data.message);
				setErrors(err.response.data.message);
			});

		return updated;
	};
