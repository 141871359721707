import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import Login from "./components/login/LoginMaterial";
import Admin from "./components/admin/admin";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import Users from "./components/users/users";
import TermsAndConditions from "./components/landing/components/terms_and_conditions";
import PrivacyAdvice from "./components/landing/components/privacy_advice";
import DataDelete from "./components/landing/components/data_delete";
import SignUp from "./components/login/SignUp";
import CRM from "./components/crm/CRM";
import Dashboard from "./components/dashboard/Dashboard";
import PercentageCalculator from "./components/utilities/PercentageCalculator";
import AgentDashboard from "./components/agent_dashboard/AgentDashboard";
import Validations from "./components/validations/validations";
import EditValidation from "./components/validations/validation.update";
import ValidationDetail from "./components/validations/validation.detail";
import ValidationsModal from "./components/validations/validations.modal";
import EditUser from "./components/users/editUser";
import GroupTemplates from "./components/templates/group_templates";
import GroupTemplateEdit from "./components/templates/group_template.edit";
import Assignation from "./components/leads/assignation/assignation";
import LoadXLSX from "./components/users/components/load_xlsz";
import EditCotization from "./components/cotizations/edit.cotization";
import Cotizador from "./components/cotizations/components/Cotizador";
import Reports from "./components/reports/Reports";
import ActionsModal from "./components/users/components/actions.component";
import ExpedientUserModal from "./components/expedientes/expediente.update";
import ChatCRM from "./components/chatCRM/Chat/chatCrm";
import Agents from "./components/agents/agents";
import { namespace_1, namespace_2 } from "../constants/enviroment";
import EditAgent from "./components/agents/editAgent";
import Credentials from "./components/credentials/credentials";
import ModalExpediente from "./components/expedientes/expedientes.modal";
import InfoLeads from "./components/info/Leads/info.leads";
import Expedientes from "./components/expedientes/expedientes";
import Solicitudes from "./components/solicitudes/solicitudes";
import Biometricos from "./components/biometricos/biometricos";
import BiometricoEdit from "./components/biometricos/biometrico.edit";
import PendingChat from "./components/pendingChats/pendingChat";
import Services from "./components/services/components/Services/Services";
import RequestedServices from "./components/services/RequestedServices";

const Routes: React.FC<IRoutesProps> = () => {
	return (
		<Switch>
			<Route path="/" element={<Login />} />
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/reportes" element={<Reports />} />
			<Route
				path="/agent/:id/actions/:customer_id"
				element={<ActionsModal />}
			/>
			<Route path="/agent/dashboard/" element={<AgentDashboard />} />
			<Route path="/customers" element={<Users />} />
			<Route path="/customers/actions/:id" element={<ActionsModal />} />
			<Route path="/customer/edit/:id" element={<EditUser />} />
			<Route path="/customer/create" element={<EditUser />} />
			<Route path="/crm/:roomId" element={<CRM />} />
			<Route path="/crm" element={<CRM />} />
			<Route path="/crm/all/:filter" element={<CRM />} />
			<Route
				path="/services" // THIS COMPONENT IS NOT DESIGNDED
				element={<CRM />}
			/>
			<Route
				path="/services/distributions" // THIS COMPONENT IS NOT DESIGNDED
				element={<CRM />}
			/>
			<Route
				path="/utilities/percentages" // THIS COMPONENT IS NOT DESIGNDED
				element={<PercentageCalculator />}
			/>
			<Route
				path="/terms_and_conditions"
				element={<TermsAndConditions />}
			/>
			<Route path="/privacy" element={<PrivacyAdvice />} />
			<Route path="/data_delete" element={<DataDelete />} />
			<Route path="/admin" element={<Admin />} />
			<Route path="/login/" element={<Login />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/admin/users" element={<Users />} />
			<Route path="/admin/customers" element={<Users />} />
			<Route path="/p_validations" element={<Validations />} />
			<Route
				path="/p_validations/customer/:id"
				element={<ValidationsModal />}
			/>
			<Route
				path="/validation/detail/:id"
				element={<ValidationDetail />}
			/>
			<Route path="/validation/update/:id" element={<EditValidation />} />
			<Route path="/validation/create" element={<EditValidation />} />
			<Route
				path="/validation/create/:user_id"
				element={<EditValidation />}
			/>
			<Route path="/templates" element={<GroupTemplates />} />
			<Route
				path="/templates/:customer_wp"
				element={<GroupTemplates />}
			/>
			<Route path="/templates/edit/:id" element={<GroupTemplateEdit />} />
			<Route path="/templates/create" element={<GroupTemplateEdit />} />
			<Route path="/leads/assignation/:id" element={<Assignation />} />
			<Route path="/leads/load/:id" element={<LoadXLSX />} />
			<Route path="/cotizador/:type" element={<Cotizador />} />
			<Route path="/cotization/:id" element={<EditCotization />} />
			<Route
				path="/customer/editExpedient/:id"
				element={<ExpedientUserModal />}
			/>
			<Route path="/chatCRM/:id" element={<ChatCRM />} />
			<Route path="/credentials" element={<Credentials />} />
			<Route path="/pendingChat" element={<PendingChat />} />
			/* ------------------ ------------------ ------------------------ */
			<Route
				path="/team/:type"
				element={<Agents namespace={namespace_1} />}
			/>
			<Route
				path="/creditos/:type"
				element={<Agents namespace={namespace_1} />}
			/>
			<Route
				path="/creditos/coordinador/:coordinatorId"
				element={<Agents namespace={namespace_1} />}
			/>
			<Route
				path="/creditos/mentor/:coordinatorId"
				element={<Agents namespace={namespace_1} />}
			/>
			<Route
				path="/distribuciones/:type"
				element={<Agents namespace={namespace_2} />}
			/>
			<Route
				path="/distribuciones/coordinador/:coordinatorId"
				element={<Agents namespace={namespace_2} />}
			/>
			<Route
				path="/distribuciones/mentor/:coordinatorId"
				element={<Agents namespace={namespace_2} />}
			/>
			<Route path="/agente/:id" element={<Users />} />
			<Route
				path="/distribuciones/editar/:id"
				element={<EditAgent bot_id={namespace_2} />}
			/>
			<Route
				path="/creditos/editar/:id"
				element={<EditAgent bot_id={namespace_1} />}
			/>
			<Route
				path="/creditos/crear"
				element={<EditAgent bot_id={namespace_1} />}
			/>
			<Route
				path="/distribuciones/crear"
				element={<EditAgent bot_id={namespace_2} />}
			/>
			<Route path="/expediente/:id" element={<ModalExpediente />} />
			<Route
				path="/expediente/:agente_id/:id"
				element={<ModalExpediente />}
			/>
			<Route path="/expedientes" element={<Expedientes />} />
			<Route path="/solicitudes" element={<Solicitudes />} />
			<Route path="/biometricos" element={<Biometricos />} />
			<Route path="/biometrico/:id" element={<BiometricoEdit />} />
			<Route path="/info/leads" element={<InfoLeads />} />
			/* ------------------ ------------------ ------------------------ */
			<Route path="/services/:id" element={<Services />} />
			<Route path="/RequestedServices" element={<RequestedServices />} />
		</Switch>
	);
};

const mapStateToProps = (state: RootState) => ({
	userState: state.users,
});

const mapActionsToProps = {};

const connector = connect(mapStateToProps, mapActionsToProps);

type IRoutesProps = ConnectedProps<typeof connector>;

export default connector(Routes);
