import {
	SET_COUNTER_REPORTS,
	SET_LEADS_REPORTS,
	SET_LOADING_REPORTS,
	SET_ERRORS_REPORTS,
} from "../constants/reports.constants";
import axios from "axios";
import { apiUrl } from "../constants/enviroment";
import { IUserModel } from "../models/User.model";

export const getAll = (body: any) => async (dispatch: any) => {
	let loaded = false;
	await dispatch({ type: SET_LOADING_REPORTS });
	try {
		let url = `${apiUrl}/reports/`;

		const response = await axios.post(url, body);

		if (response.status !== 200) {
			console.warn("No se ha podido obtener el reporte", response);
			dispatch(setErrors("No se ha podido obtener el reporte"));
			return loaded;
		}

		let data = response.data;

		dispatch(setCounter(data.count));
		dispatch(setLeads(data.leads));
		loaded = true;
	} catch (e) {
		console.error("Error al cargar el reporte", e);
		setErrors("Error al cargar el reporte");
	}
	return loaded;
};

export const setCounter = (counter: number) => async (dispatch: any) => {
	await dispatch({
		type: SET_COUNTER_REPORTS,
		payload: counter,
	});
};

export const setLeads = (leads: IUserModel[]) => async (dispatch: any) => {
	await dispatch({
		type: SET_LEADS_REPORTS,
		payload: leads,
	});
};

export const setErrors = (errors: string) => async (dispatch: any) => {
	await dispatch({
		type: SET_ERRORS_REPORTS,
		payload: errors,
	});
};
