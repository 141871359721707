import {
	SET_VALIDATION,
	SET_VALIDATIONS,
	SET_LOADING_VALIDATION,
	SET_LOADING_VALIDATIONS,
	SET_LOADING_ERRORS_VALIDATION,
	SET_COUNTER_VALIDATION,
	SET_PAGE_VALIDATION,
} from "../constants/validation.constants";
import axios, { AxiosError, AxiosResponse } from "axios";
import IValidation from "../models/validation.model";
import { apiUrl } from "../constants/enviroment";

export const getAll =
	(agentId?: string, isValidated?: boolean) => async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: SET_LOADING_VALIDATIONS });
		let validations: IValidation[] = [];
		try {
			let url = `${apiUrl}/validation/`;

			if (agentId) {
				url = `${apiUrl}/validation/${agentId}`;
				if (isValidated !== undefined) {
					url = `${apiUrl}/validation/${agentId}/${isValidated}`;
				}
			}

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.warn(
					"No se han podido obtener las validaciones",
					response
				);
				dispatch(
					setLoadingErrors(
						"No se han podido obtener las validaciones"
					)
				);
				return loaded;
			}

			validations = response.data.validations;
			dispatch(setValidations(validations));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar las validaciones", e);
			dispatch(setLoadingErrors("Error al cargar las validaciones"));
		}
		return loaded;
	};

export const getByFilter =
	(page: number, limit: number, filters: any, order: any) =>
	async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: SET_LOADING_VALIDATIONS });
		try {
			const body = {
				page,
				limit,
				filters,
				order,
			};
			const response = await axios.post(`${apiUrl}/validation/all`, body);

			if (response.status !== 200) {
				console.warn(
					"No se han podido obtener las validaciones",
					response
				);
				dispatch(
					setLoadingErrors(
						"No se han podido obtener las validaciones"
					)
				);
				return loaded;
			}

			const validations: IValidation[] = response.data.validations;
			dispatch(setPage(response.data.page));
			dispatch(setCounter(response.data.count));
			dispatch(setValidations(validations));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar las validaciones", e);
			dispatch(setLoadingErrors("Error al cargar las validaciones"));
		}
		return loaded;
	};

export const getAllByCustomerId =
	(customerId: string) => async (dispatch: any) => {
		let loaded = false;
		await dispatch({ type: SET_LOADING_VALIDATIONS });
		let validations: IValidation[] = [];
		try {
			let url = `${apiUrl}/validation/lead/${customerId}`;

			const response = await axios.get(url);

			if (response.status !== 200) {
				console.warn(
					"No se han podido obtener las validaciones",
					response
				);
				dispatch(
					setLoadingErrors(
						"No se han podido obtener las validaciones"
					)
				);
				return loaded;
			}

			validations = response.data.validations;
			dispatch(setValidations(validations));
			loaded = true;
		} catch (e) {
			console.error("Error al cargar las validaciones", e);
			dispatch(setLoadingErrors("Error al cargar las validaciones"));
		}
		return loaded;
	};

export const getById = (id: string) => async (dispatch: any) => {
	await dispatch({ type: SET_LOADING_VALIDATION });
	let loaded = false;
	try {
		const response = await axios.get(`${apiUrl}/validation/detail/${id}`);

		if (response.status !== 200) {
			console.warn(`No se han podido cargar la validacion con ID: ${id}`);
			dispatch(
				setLoadingErrors(
					`No se han podido cargar la validacion con ID: ${id}`
				)
			);
			return loaded;
		}

		const validation = response.data.validation;

		dispatch(setValidation(validation));
		loaded = true;
	} catch (e) {
		console.error(`Error al cargar la validacion con ID: ${id}`, e);
		dispatch(
			setLoadingErrors(`Error al cargar la validacion con ID: ${id}`)
		);
	}
	return loaded;
};

export const create = (validation: IValidation) => async (dispatch: any) => {
	let created = false;

	await dispatch({ type: SET_LOADING_VALIDATION });

	await axios
		.post(`${apiUrl}/validation/`, validation)
		.then((response: AxiosResponse) => {
			if (response.status !== 201) {
				console.warn(
					"No se ha podido crear la validacion",
					response.data.message
				);
				setLoadingErrors(response.data.message);
				return created;
			}

			dispatch(setValidation(response.data.validation));

			created = true;
		})
		.catch((error: AxiosError) => {
			console.error("Error al crear la validacion");
			dispatch(setLoadingErrors(error.response!.data.message));
		});

	return created;
};

export const update = (validation: IValidation) => async (dispatch: any) => {
	let updated = false;

	await dispatch({ type: SET_LOADING_VALIDATION });

	const body = {
		data: validation,
	};

	await axios
		.put(`${apiUrl}/validation/${validation.id!}`, body)
		.then((response: AxiosResponse) => {
			if (response.status !== 200) {
				console.warn(
					"No se ha podido actualizar la validacion",
					response.data.message
				);
				setLoadingErrors(response.data.message);
				return updated;
			}

			dispatch(setValidation(response.data.validation));

			updated = true;
		})
		.catch((error: AxiosError) => {
			console.error(
				"Error al actualizar la validacion",
				error.response!.data.message
			);
			dispatch(setLoadingErrors(error.response!.data.message));
		});

	return updated;
};

export const setValidations =
	(validations: IValidation[]) => async (dispatch: any) => {
		await dispatch({
			type: SET_VALIDATIONS,
			payload: validations,
		});
	};

export const setValidation =
	(validation: IValidation) => async (dispatch: any) => {
		await dispatch({
			type: SET_VALIDATION,
			payload: validation,
		});
	};

export const setLoadingErrors = (error: string) => async (dispatch: any) => {
	console.error("setLoadingErrors: ", error);
	await dispatch({
		type: SET_LOADING_ERRORS_VALIDATION,
		payload: error,
	});
};

export const setCounter = (counter: number) => async (dispatch: any) => {
	await dispatch({
		type: SET_COUNTER_VALIDATION,
		payload: counter,
	});
};

export const setPage = (page: number) => async (dispatch: any) => {
	await dispatch({
		type: SET_PAGE_VALIDATION,
		payload: page,
	});
};
