import React from "react";
import { Button, Icon } from "semantic-ui-react";
import JSZipt from "jszip";
import { saveAs } from "file-saver";

interface IProps {
	id: string;
	documents: any;
	nombreCompleto: string;
}

const DownloadDocuments: React.FC<IProps> = ({
	id,
	documents,
	nombreCompleto,
}) => {
	const zip = new JSZipt();

	const downloadDocuments = () => {
		const keys = Object.keys(documents);

		const urls = keys
			.map((key: any) => {
				return {
					url: documents[key]?.url,
					filename: documents[key]?.name,
				};
			})
			.filter((url) => url.url !== "");

		const downloads = urls.map(async (url) => {
			const response = await fetch(url.url);
			const blob = await response.blob();

			zip.file(url.filename, blob, { base64: true });
		});

		Promise.all(downloads).then(() => {
			zip.generateAsync({ type: "blob" })
				.then((content) => {
					saveAs(content, `expediente_${nombreCompleto}_${id}.zip`);
				})
				.catch((e) => {
					console.log("ERROR AT ZIP", e);
				});
		});
	};

	return (
		<Button color="blue" floated="right" onClick={downloadDocuments}>
			<Icon name="download" />
			Descargar Expediente
		</Button>
	);
};

export default DownloadDocuments;
