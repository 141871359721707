import moment from "moment";
import { Popup } from "semantic-ui-react";

export const OwnMessage = ({
    item,
    setModalPreview,
    setImagePreview,
    statusMessage,
}: any) => {
    const oldMessages = item?.metadata?.phone_number_id.includes(
        "951a64e2_030c_4410_a60b_8682d13c8309" // namespace de 360dialog
    );
    const metadata = item?.metadata ? true : false;

    const status =
        item?._id === statusMessage.id && item?.status === statusMessage.status
            ? true
            : false;

    return (
        <>
            <div
                className={
                    item.status === "failed" || status
                        ? "chatcrm-message-not-send"
                        : !metadata || oldMessages
                        ? "chatcrm-own-message-360"
                        : "chatcrm-own-message-meta"
                }
            >
                {item.type === "text" || item.type === "template" ? (
                    <p>{item.body.text.replace(/\*/g, "")}</p>
                ) : item.type === "voice" ? (
                    <audio controls src={item.body.voice.uri} />
                ) : item.type === "document" ? (
                    <a href={item.body.document.uri} target="_blank">
                        {item.body.text}
                    </a>
                ) : item.type === "audio" ? (
                    <audio controls src={item.body.audio.uri} />
                ) : item.type === "image" && !!item.body.text ? (
                    <>
                        <img
                            src={item.body.image.uri}
                            onClick={() => {
                                setImagePreview(item);
                                setModalPreview(true);
                            }}
                        />
                        <p>{item.body.text}</p>
                    </>
                ) : (
                    <img
                        src={item.body.image.uri}
                        onClick={() => {
                            setImagePreview(item);
                            setModalPreview(true);
                        }}
                    />
                )}
                <div className="chatcrm-own-message-date">
                    <p>
                        {moment(item.createdAt).format("DD-MM-YYYY / h:mm A")}
                    </p>
                </div>
                {item.status === "failed" || status ? (
                    <div className="chatcrm-message-not-send-icon">
                        <Popup
                            trigger={
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                    }}
                                >
                                    !
                                </p>
                            }
                            content="Error al enviar el mensaje, favor de consultar a su coordinador."
                            position="bottom center"
                            size="large"
                            inverted
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};
