import {
	SET_TEMPLATE_CATEGORY,
	SET_TEMPLATE_CATEGORIES,
	SET_TEMPLATE_CATEGORY_LOADING,
	SET_TEMPLATE_CATEGORIES_LOADING,
	SET_TEMPLATE_CATEGORIES_ERRORS,
} from "../constants/templates_categories.constants";

import ICategoryTemplate from "../models/CategoryTemplate.model";

const initialState = {
	loading: false,
	loadingCategoria: false,
	categorias: [] as ReadonlyArray<ICategoryTemplate>,
	categoria: null as unknown as Readonly<ICategoryTemplate>,
	errors: "",
};

export type State = Readonly<typeof initialState>;

const reducer = (state: State = initialState, action: any): State => {
	switch (action.type) {
		case SET_TEMPLATE_CATEGORY:
			return {
				...state,
				loading: false,
				loadingCategoria: false,
				categoria: action.payload,
				errors: "",
			};
		case SET_TEMPLATE_CATEGORIES:
			return {
				...state,
				loading: false,
				loadingCategoria: false,
				categorias: action.payload,
				errors: "",
			};
		case SET_TEMPLATE_CATEGORY_LOADING:
			return {
				...state,
				loadingCategoria: true,
				errors: "",
			};
		case SET_TEMPLATE_CATEGORIES_LOADING:
			return {
				...state,
				loading: true,
				errors: "",
			};
		case SET_TEMPLATE_CATEGORIES_ERRORS:
			return {
				...state,
				loading: false,
				loadingCategoria: false,
				errors: action.payload,
			};
		default:
			return state;
	}
};

export default reducer;
