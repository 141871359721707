import React, { useState } from "react";
import { Modal, Grid, Header, Button, Icon } from "semantic-ui-react";
import AgentDashboard from "../../agent_dashboard/AgentDashboard";
import { IAgentModel } from "../../../../models/Agent.model";
import { setAgentData } from "../../../../actions/Agent/utils.actions";
import { useDispatch } from "react-redux";
import "../../../../css/agent_dashboard.modal.css";

interface IProps {
	agent: IAgentModel;
}

const DashboardModal: React.FC<IProps> = ({ agent }) => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const onOpen = () => {
		dispatch(setAgentData(agent));
		setOpen(true);
	};

	return (
		<Modal
			className="dashboard-modal"
			onClose={() => setOpen(false)}
			open={open}
			trigger={
				<Button color="green" onClick={onOpen}>
					<Icon name="chart line" /> Métricas
				</Button>
			}
			dimmer={"blurring"}
			size="fullscreen"
		>
			<Modal.Header>
				<Header textAlign="center">Dashboard</Header>
			</Modal.Header>
			<Modal.Content style={{ minHeight: "600px", textAlign: "center" }}>
				<AgentDashboard />
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={() => setOpen(false)}>
					Cerrar
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default DashboardModal;
