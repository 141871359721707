import axios from "axios";
import { chatCRMUrl } from "../constants/enviroment";
import { IConversationCRM } from "../models/IConversationCRM.model";
import {
    SET_CONVERSATION,
    SET_CONVERSATIONS,
    SET_LOADING_CONVERSATION,
    SET_LOADING_CONVERSATIONS,
} from "../constants/conversationsCRM.constants";

export const getConversationsByAgentId =
    (agentId: any) => async (dispatch: any) => {
        dispatch(setLoading());
        try {
            const res = await axios.get(
                `${chatCRMUrl}/api/getConversationByAgentId/${agentId}`
            );
            dispatch(setConversations(res.data.conversations));
        } catch (error: any) {
            console.log(
                `ERROR AL OBTENER LOS CHATS PENDIENTES: ${agentId}`,
                error.res.data.message
            );
        }
    };

export const updateStatusNotifications =
    (leadPhone: any) => async (dispatch: any) => {
        try {
            const res = await axios.put(
                `${chatCRMUrl}/api/switchNotifications/${leadPhone}`
            );
            console.log("ESTATUS DE NOTIFICACIONES", res.data);
        } catch (error: any) {
            console.log("ERROR AL CAMBIAR EL ESTATUS DE LAS NOTIFICACIONES");
        }
    };

export const setConversations =
    (conversations: IConversationCRM[]) => async (dispatch: any) => {
        await dispatch({
            type: "SET_CONVERSATIONS",
            payload: conversations,
        });
    };

export const setLoading = () => (dispatch: any) => {
    dispatch({
        type: SET_LOADING_CONVERSATIONS,
    });
};
