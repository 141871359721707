import {
    SET_EXPEDIENTE,
    SET_EXPEDIENTES,
    SET_EXPEDIENTE_COUNTER,
    SET_EXPEDIENTE_PAGE,
    SET_EXPEDIENTE_LOADING,
    SET_EXPEDIENTES_LOADING,
    SET_EXPEDIENTE_ERRORS
  } from '../constants/expedientesCRM.constansts';
  
  import IExpedienteCRM from '../models/ExpedienteCRM.model';
  
  const initialState = {
    loading: false,
    loadingExpediente: false,
    expedientes: [] as ReadonlyArray<IExpedienteCRM>,
    expediente: null as unknown as Readonly<IExpedienteCRM>,
    counter: 10,
    page: 1,
    errors: ""
  }
  
  export type State = Readonly<typeof initialState>;
  
  const reducer = (state: State = initialState, action: any): State  => {
    switch (action.type) {
      case SET_EXPEDIENTE:
        return {
          ...state,
          loading: false,
          loadingExpediente: false,
          expediente: action.payload,
          errors: ""
        }
      case SET_EXPEDIENTES:
        return {
          ...state,
          loading: false,
          loadingExpediente: false,
          expedientes: action.payload,
          errors: ""
        }
      case SET_EXPEDIENTE_COUNTER:
        return {
          ...state,
          counter: action.payload
        }
      case SET_EXPEDIENTE_PAGE:
        return {
          ...state,
          page: action.payload
        }
      case SET_EXPEDIENTE_LOADING:
        return {
          ...state,
          loadingExpediente: true,
          errors: ""
        };
      case SET_EXPEDIENTES_LOADING:
        return {
          ...state,
          loading: true,
          errors: ""
        };
      case SET_EXPEDIENTE_ERRORS:
        return {
          ...state,
          loading: false,
          loadingExpediente: false,
          errors: action.payload
        };
      default:
        return state;
    }
  }
  
  export default reducer;