const macroTypes = [
    {
        key: "CREDITO",
        text: "CRÉDITO",
        value: "credito",
        icon: "money"
    },
    {
        key: "RENOVACION",
        text: "RENOVACIÓN",
        value: "renovacion",
        icon: "handshake outline"
    },
    {
        key: 'RENOVACION_IMSS',
        text: 'COMPRA DE DEUDA',
        value: 'renovacion_base_imss',
        icon: 'money'
    },
    {
        key: 'MMULTIVA',
        text: 'CARTERA MULTIVA',
        value: 'multiva',
        icon: 'money'
    },
    {
        key: 'SMS',
        text: 'SMS',
        value: 'SMS',
        icon: 'comment alternate outline'
    },
    {
        key: 'BLASTER',
        text: 'BLASTER',
        value: 'blaster',
        icon: 'phone volume'
    }
]

export default macroTypes;