import * as React from 'react';
import { IUserModel } from '../../../../models/User.model';
import UserList from './UserList.modal';

interface IDataShowProps {
    total: number,
    title: string,
    color?: string,
    users: IUserModel[],
    actual_page: number,
    pagination: Function,
    jsonToExcel?: Function,
    getAgentName?: Function,
    next_button: boolean,
    show_modal_button: boolean,
    loading?: boolean
}

const DataShow: React.FC<IDataShowProps> = ({
    total,
    title,
    color,
    users,
    actual_page,
    pagination,
    jsonToExcel,
    getAgentName,
    next_button,
    show_modal_button,
    loading
}) => {
    return(
        <div>
            <h2>{total}</h2>
            <h3>{title}</h3>
            {show_modal_button ? 
            <UserList
                users={users}
                pagination={pagination} 
                page={actual_page} 
                next={next_button} 
                loading={loading!} 
                jsonToExcel={jsonToExcel!}
                getAgentName={getAgentName!}
                />
            : null}
        </div>
    );
}

export default DataShow;