import {
	SET_COUNTER_REPORTS,
	SET_LEADS_REPORTS,
	SET_LOADING_REPORTS,
	SET_ERRORS_REPORTS,
} from '../constants/reports.constants';
import { IUserModel } from '../models/User.model';

const initialState = {
	loading: false,
	status: "",
	counter: 0,
	leads: [] as Array<IUserModel>,
	errors: ""
}

type State = Readonly<typeof initialState>;

const reportsReducer = (state: State = initialState, action: any): State => {
	switch (action.type) {
		case SET_COUNTER_REPORTS:
			return {
				...state,
				loading: false,
				counter: action.payload,
				errors: ""
			}
		case SET_LEADS_REPORTS:
			return {
				...state,
				loading: false,
				leads: action.payload,
				errors: ""
			}
		case SET_LOADING_REPORTS:
			return {
				...state,
				loading: true,
				errors: ""
			};
		case SET_ERRORS_REPORTS:
			return {
				...state,
				counter: 0,
				leads: [],
				loading: false,
				errors: action.payload
			};
		default:
			return state;
	}
}

export default reportsReducer;