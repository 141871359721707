import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiUrl } from '../constants/enviroment';
import {response_total_Users} from '../models/User.model';

export default class User_Api {
    static getTotalFilteredUsers = (filter: string, isDateFilter: boolean, isRangeFilter: boolean, Date?: any, secondary_filter?: string, page?: number, allUsers?: boolean, agentFilter?: string, macroType?: string) => {
        return new Promise<response_total_Users>( async (resolve, reject) => {
            const route: string = `/user/getTotalFilteredUsers/${filter}/${isDateFilter}/${isRangeFilter}/${page}`
            try {
                const { Total_Users, Total_phones, success_rate, users } = await axios(`${apiUrl}${route}`, {
                    method: 'POST',
                    headers: {
                        contentType: 'application/json'
                    },
                    data: {
                        final_Date: Date ?  Date.final_date : undefined,
                        initial_Date: Date ? Date.initial_date : undefined,
                        secondary_filter: secondary_filter !== "" ? secondary_filter : undefined,
                        page: page,
                        allUsers: allUsers ? allUsers : false,
                        agent: agentFilter !== "" ? agentFilter : undefined,
                        macro_type: macroType !== "" ? macroType : undefined
                    }
                }).then((response: AxiosResponse) => {
                    return response.data;
                });
                resolve({Total_Users, Total_phones, success_rate, users})
            } catch(err) {
                console.log(err);
                reject(err);
            };
        });
    };

    static getTotalUsers (page:number, allUsers?: boolean) {
        return new Promise<response_total_Users>( async (resolve, reject) => {
            try {
                const { Total_Users, Total_phones, success_rate, users } = await axios.get(`${apiUrl}/user/getTotalUsers/${page}/${allUsers ? allUsers : false}`).then((response: AxiosResponse) => {
                    return response.data;
                });

                resolve({Total_Users, Total_phones, success_rate, users});
            } catch(err) {
                console.log(err);
                reject(err);
            };
        });
    };
};