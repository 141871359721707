import { namespace_2 } from "../../../../constants/enviroment";
import { IUserModel } from "../../../../models/User.model";

export const getClassName = (user: IUserModel) => {
	let className = "tradicional";
	if (
		user.isJubilado === true ||
		user.isCompraJubilado === true ||
		user.isRenovacionJubilado === true ||
		user.source === "5214499401720"
	) {
		className = "jubilado";
	} else if (user.isRenovation === true) {
		className = "renovation";
	} else if (user.isCompra === true) {
		className = "compra";
	} else if (user.isNL === true) {
		className = "nuevo-leon";
	} else if (user.isUNAM === true) {
		className = "unam";
	} else if (user.isGEM === true) {
		className = "gem-tradicional";
	} else if (user.isGEMCompra === true) {
		className = "gem-compra";
	} else if (user.isCDMX === true) {
		className = "cdmx";
	} else if (user.isCDMXCompra === true) {
		className = "cdmx-compra";
	} else if (user.isExitusIMSS === true) {
		className = "exitus-imss";
	} else if (user.isExitusISSSTE === true) {
		className = "exitus-issste";
	} else if (
		user.sourceId === "120209266257870689" ||
		user.sourceId === "120209430598670689" ||
		user.sourceId === "120209836911220689" ||
		user.sourceId === "120209855470230689"
	) {
		return "gem-tradicional";
	} else if (
		user.sourceId === "23859100597080688" ||
		user.sourceId === "23859348281190688" ||
		user.sourceId === "23862277042370688" ||
		user.sourceId === "23862026561660688" ||
		user.sourceId === "23859611836160688" ||
		user.sourceId === "23859100597050688" ||
		user.sourceId === "724384163047162" ||
		user.sourceId === "23862277042360688" ||
		user.sourceId === "23862026561770688" ||
		user.sourceId === "23859611836170688" ||
		user.sourceId === "120200436962490689" ||
		user.sourceId === "120205720303890689" ||
		user.isFranquicia
	) {
		className = "gem-compra";
	}
	return className;
};

export const generateWaLink = (phone: string) => {
	let link = "";
	switch (phone.length) {
		case 10:
			link = `https://api.whatsapp.com/send/?phone=52${phone}`;
			break;
		case 13:
		default:
			link = `https://api.whatsapp.com/send/?phone=${phone}`;
			break;
	}
	return link;
};

export const parseDate = (date: string) => {
	let dateParsed: Date = new Date(date);
	return dateParsed.toLocaleDateString();
};

export const getProximoSeguimientoColor = (proximoSeguimiento: string) => {
	const now = new Date();
	const usDate = new Date(proximoSeguimiento);

	let color = "green";

	if (now >= usDate) {
		color = "red";
	}

	return color;
};

export const getIconName = (user: IUserModel) => {
	if (user.status === "Reassigned") {
		return "fire";
	} else if (user.wa_id?.includes("xlsx")) {
		return "file excel";
	} else if (user.wa_id?.includes("own")) {
		return "user";
	} else if (user.type_macro === "blaster" && user.isFijo === true) {
		return "phone";
	} else if (user.type_macro === "blaster" && user.isFijo === false) {
		return "mobile";
	}
	return user.wa_id ? "whatsapp" : "facebook";
};

export const getDescription = (user: IUserModel) => {
	let tipo = "IMSS Tradicional";
	let origen = user.wa_id ? "Whatsapp" : "Facebook";

	if (user.source === "5214499401720") {
		tipo = "Asociación Mexicana de Franquicias";
	} else if (
		user.sourceId === "120209266257870689" ||
		user.sourceId === "120209430598670689" ||
		user.sourceId === "120209836911220689" ||
		user.sourceId === "120209855470230689"
	) {
		tipo = "Franquicia Modalidad 40";
	} else if (
		user.sourceId === "23859100597080688" ||
		user.sourceId === "23859348281190688" ||
		user.sourceId === "23862277042370688" ||
		user.sourceId === "23862026561660688" ||
		user.sourceId === "23859611836160688" ||
		user.sourceId === "23859100597050688" ||
		user.sourceId === "724384163047162" ||
		user.sourceId === "23862277042360688" ||
		user.sourceId === "23862026561770688" ||
		user.sourceId === "23859611836170688" ||
		user.sourceId === "120200436962490689" ||
		user.sourceId === "120205720303890689" ||
		user.isFranquicia
	) {
		tipo = "Venta de franquicias";
	} else if (user.isRenovation) {
		tipo = "IMSS Renovación Multiva";
	} else if (user.isCompra) {
		tipo = "IMSS Compra";
	} else if (user.isJubilado) {
		tipo = "IMSS Jubilado";
	} else if (user.isNL) {
		tipo = "Nuevo León";
	} else if (user.isUNAM) {
		tipo = "UNAM";
	} else if (user.isGEM) {
		tipo = "GEM Tradicional";
	} else if (user.isGEMCompra) {
		tipo = "GEM Compra";
	} else if (user.isCompraJubilado) {
		tipo = "IMSS Jubilado Compra";
	} else if (user.isRenovacionJubilado) {
		tipo = "IMSS Jubilado Renovación Multiva";
	} else if (user.pageId === namespace_2) {
		tipo = "Distribuciones";
	} else if (user.isCDMX) {
		tipo = "CDMX Tradicional";
	} else if (user.isCDMXCompra) {
		tipo = "CDMX Compra";
	} else if (user.isExitusIMSS) {
		tipo = "Exitus IMSS";
	} else if (user.isExitusISSSTE) {
		tipo = "Exitus ISSSTE";
	}

	if (user.status === "Reassigned") {
		origen = "Asignación Automática";
	} else if (user.wa_id?.includes("xlsx")) {
		origen = "Carga de Excel";
	} else if (user.wa_id?.includes("own")) {
		origen = "Registro Manual";
	} else if (user.type_macro === "blaster" && user.isFijo === true) {
		origen = "Blaster fijo";
	} else if (user.type_macro === "blaster" && user.isFijo === false) {
		origen = "Blaster movil";
	}

	return {
		tipo,
		origen,
	};
};

export const getType = (tipoLead: string) => {
	const values: any = {
		all: {},
		tradicional: {
			isTradicional: true,
		},
		renovacion: {
			isRenovation: true,
		},
		compra: {
			isCompra: true,
		},
		jubilados: {
			isJubilado: true,
		},
		unam: {
			isUNAM: true,
		},
		nl: {
			isNL: true,
		},
		gem: {
			isGEM: true,
		},
		gemCompra: {
			isGEMCompra: true,
		},
		cdmx: {
			isCDMX: true,
		},
		cdmxCompra: {
			isCDMXCompra: true,
		},
		exitusIMSS: {
			isExitusIMSS: true,
		},
		exitusISSSTE: {
			isExitusISSSTE: true,
		},
		amf: {
			source: "5214499401720",
		},
	};

	return values[tipoLead ?? "Tradicional"];
};
