import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Menu, Button, Modal, Grid } from "semantic-ui-react";
import { RootState } from "../../../store/store";
/* import {
	getByLeadByAgent,
	update,
	uploadFile,
} from "../../../../actions/expedientes.actions"; */
import { useNavigate, useParams } from "react-router-dom";
import Expediente from "./expediente.update";
import Solicitudes from "../solicitudes/solicitudes.edit";
import ISolicitudCredito from "../../../models/solicitud_credito.model";

interface IState {
	activeItem: string;
	solicitudes: ISolicitudCredito[];
}

const ModalExpediente: React.FC<IProps> = ({
	isAgent,
	profile,
	agent,
	expediente,
	loading,
}) => {
	const { id } = useParams();
	const [state, setState] = useState<IState>({
		activeItem: "expediente",
		solicitudes: [],
	});
	const [open, setOpen] = useState(true);
	const navigate = useNavigate();

	const onClose = () => {
		navigate(-1);
		setOpen(false);
	};

	return (
		<Modal
			onClose={onClose}
			onOpen={() => setOpen(true)}
			open={open}
			size="fullscreen"
			dimmer={"blurring"}
			style={{
				left: "auto",
				right: "auto",
				top: "auto",
				height: "fit-content",
			}}
		>
			<Modal.Header>
				<Grid style={{ margin: "10px" }}>
					<Grid.Row columns={2}>
						<Grid.Column>
							<h1>Expediente {id}</h1>
						</Grid.Column>
						<Grid.Column></Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Menu tabular>
							<Menu.Item
								name="expediente"
								active={state.activeItem === "expediente"}
								onClick={() =>
									setState({
										...state,
										activeItem: "expediente",
									})
								}
							>
								Expediente
							</Menu.Item>

							<Menu.Item
								name="solicitudes"
								active={state.activeItem === "solicitudes"}
								onClick={() =>
									setState({
										...state,
										activeItem: "solicitudes",
									})
								}
							>
								Solicitudes
							</Menu.Item>
						</Menu>
					</Grid.Row>
				</Grid>
			</Modal.Header>
			<Modal.Content style={{ minHeight: "600px" }}>
				{state.activeItem === "expediente" ? (
					<Expediente />
				) : (
					<Solicitudes />
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button color="black" onClick={onClose}>
					Cerrar
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

//this map the states to our props in this functional component
const mapStateToProps = (state: RootState) => {
	return {
		user: state.users.user,
		profile: state.users.profile,
		agent: state.adminAgents.agent,
		lead: state.adminUsers.user,
		expediente: state.expedientesReducer.expediente,
		errors: state.expedientesReducer.errors,
		loading: state.expedientesReducer.loadingExpediente,
		isAdmin: state.users.user.type === "IS_ADMIN",
		isCoordinator: state.users.user.type === "IS_COORDINATOR",
		isAgent:
			state.users.user.type === "IS_AGENT" ||
			state.users.user.type === "IS_TOP",
	};
};

const mapActionsToProps = {};

const connector = connect(mapStateToProps, mapActionsToProps);

type IProps = ConnectedProps<typeof connector>;

export default connector(ModalExpediente);
