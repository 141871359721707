export const SET_VALIDATION='SET_VALIDATION';
export const SET_VALIDATIONS='SET_VALIDATIONS';
export const GET_VALIDATIONS='GET_VALIDATIONS';
export const SET_VALIDATION_BY_ID='SET_VALIDATION_BY_ID';
export const GET_VALIDATION_BY_ID='GET_VALIDATION_BY_ID';
export const SET_LOADING_VALIDATION='SET_LOADING_VALIDATION';
export const SET_LOADING_VALIDATIONS='SET_LOADING_VALIDATIONS';
export const SET_LOADING_ERRORS_VALIDATION='SET_LOADING_ERRORS_VALIDATION';
export const CLEAR_LOADING_ERRORS_VALIDATION='CLEAR_LOADING_ERRORS_VALIDATION';
export const SET_COUNTER_VALIDATION='SET_COUNTER_VALIDATION';
export const SET_PAGE_VALIDATION='SET_PAGE_VALIDATION';