import * as React from 'react';
import UserList from './UserList.modal';
import { RootState } from '../../../../store/store';
import { ConnectedProps, connect } from 'react-redux';

interface IProps {
    title: string,
    jsonToExcel?: Function,
    getAgentName?: Function,
    find: Function
}

const DataShow: React.FC<IDataShowProps> = ({
    title,
    jsonToExcel,
    getAgentName,
    find,
    leads,
    counter,
}) => {

    return(
        <div>
            <h2>{counter}</h2>
            <h3>{title}</h3>
            <UserList
                users={leads}
                jsonToExcel={jsonToExcel!}
                getAgentName={getAgentName!}
                find={find}
            />
        </div>
    );
}

const mapActionsToProps = {
    
}

const mapStateToProps = (state: RootState, props: IProps) => {
    return {
        title: props.title,
        jsonToExcel: props.jsonToExcel,
        getAgentName: props.getAgentName,
        find: props.find,
        counter: state.reports.counter,
        leads: state.reports.leads,
        loading: state.reports.loading,
    }
}

const connector = connect(mapStateToProps, mapActionsToProps);

type IDataShowProps = ConnectedProps<typeof connector>;

export default connector(DataShow);