import React from 'react'

const DataDelete = () => {
    return(
        <section className="wthree-row py-lg-5" id="process">
            <div className="container py-5">
                <div className="title-wthree text-center py-lg-5">
                    <h3 className="agile-title">
                        URL Instrucciones de Eliminación de Datos
                    </h3>
                    <span></span>
                    <p className="mt-md-5 mb-3 mt-3" style={{ textAlign: "justify" }}>
                        En concordancia con las regulaciones de Facebook para Apps y sitios web, debemos brindarle a los usuarios las instrucciones para eliminar sus datos. Si quieres eliminar tu actividad relacionada al Manali App, lo puedes hacer de la siguiente manera.
                        <br/>
                        <ul style={{ marginLeft: "80px" }}>
                            <li style={{ color: "black", textAlign: "left" }}>
                                1. Ingresa a tu cuenta de Facebook y haz clic en “Configuración y privacidad”. Después haz clic en “Configuración”. 
                            </li>
                            <li style={{ color: "black", textAlign: "left" }}>
                                2. Ve a la sección de “Apps y sitios web”, aquí podrás ver toda tu actividad relacionada a aplicaciones y páginas web registradas en tu cuenta de Facebook. 
                                <img src="images/data_delete/eliminacion1.png" alt="" width='100%' />
                            </li>
                            <li style={{ color: "black", textAlign: "left" }}>
                                3. Selecciona la casilla correspondiente al Manali App y haz clic en “Eliminar”. 
                                <img src="images/data_delete/eliminacion2.png" alt="" width='100%' />

                            </li>
                            <li style={{ color: "black", textAlign: "left" }}>
                                4. Selecciona las casillas de acuerdo a tu preferencia y haz clic en “Eliminar”. 
                                <img src="images/data_delete/eliminacion3.png" alt="" width='100%' />

                            </li>
                            <li style={{ color: "black", textAlign: "left" }}>
                                5. ¡Listo! Eliminaste a Manali App de tus actividades de manera exitosa. 
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </section>
    );
}

export default DataDelete;